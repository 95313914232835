// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDealershipJoinRequestFields() {
    val props: Array<SerializableProperty<DealershipJoinRequest, *>> = arrayOf(DealershipJoinRequest__id, DealershipJoinRequest_createdAt, DealershipJoinRequest_name, DealershipJoinRequest_address, DealershipJoinRequest_url, DealershipJoinRequest_billingContact, DealershipJoinRequest_usedCarManager, DealershipJoinRequest_transportManager, DealershipJoinRequest_titleManager, DealershipJoinRequest_stripeBilling, DealershipJoinRequest_makes, DealershipJoinRequest_notes, DealershipJoinRequest_accepted, DealershipJoinRequest_archived)
    DealershipJoinRequest.serializer().properties { props }
}
val <K> DataClassPath<K, DealershipJoinRequest>._id: DataClassPath<K, UUID> get() = this[DealershipJoinRequest__id]
val <K> DataClassPath<K, DealershipJoinRequest>.createdAt: DataClassPath<K, Instant> get() = this[DealershipJoinRequest_createdAt]
val <K> DataClassPath<K, DealershipJoinRequest>.name: DataClassPath<K, String> get() = this[DealershipJoinRequest_name]
val <K> DataClassPath<K, DealershipJoinRequest>.address: DataClassPath<K, Address> get() = this[DealershipJoinRequest_address]
val <K> DataClassPath<K, DealershipJoinRequest>.url: DataClassPath<K, String?> get() = this[DealershipJoinRequest_url]
val <K> DataClassPath<K, DealershipJoinRequest>.billingContact: DataClassPath<K, DealershipJoinRequestUser> get() = this[DealershipJoinRequest_billingContact]
val <K> DataClassPath<K, DealershipJoinRequest>.usedCarManager: DataClassPath<K, DealershipJoinRequestUser> get() = this[DealershipJoinRequest_usedCarManager]
val <K> DataClassPath<K, DealershipJoinRequest>.transportManager: DataClassPath<K, DealershipJoinRequestUser> get() = this[DealershipJoinRequest_transportManager]
val <K> DataClassPath<K, DealershipJoinRequest>.titleManager: DataClassPath<K, DealershipJoinRequestUser> get() = this[DealershipJoinRequest_titleManager]
val <K> DataClassPath<K, DealershipJoinRequest>.stripeBilling: DataClassPath<K, Boolean> get() = this[DealershipJoinRequest_stripeBilling]
val <K> DataClassPath<K, DealershipJoinRequest>.makes: DataClassPath<K, Set<String>> get() = this[DealershipJoinRequest_makes]
val <K> DataClassPath<K, DealershipJoinRequest>.notes: DataClassPath<K, String> get() = this[DealershipJoinRequest_notes]
val <K> DataClassPath<K, DealershipJoinRequest>.accepted: DataClassPath<K, Instant?> get() = this[DealershipJoinRequest_accepted]
val <K> DataClassPath<K, DealershipJoinRequest>.archived: DataClassPath<K, Instant?> get() = this[DealershipJoinRequest_archived]
inline val DealershipJoinRequest.Companion.path: DataClassPath<DealershipJoinRequest, DealershipJoinRequest> get() = path<DealershipJoinRequest>()


object DealershipJoinRequest__id: SerializableProperty<DealershipJoinRequest, UUID> {
    override val name: String = "_id"
    override fun get(receiver: DealershipJoinRequest): UUID = receiver._id
    override fun setCopy(receiver: DealershipJoinRequest, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object DealershipJoinRequest_createdAt: SerializableProperty<DealershipJoinRequest, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: DealershipJoinRequest): Instant = receiver.createdAt
    override fun setCopy(receiver: DealershipJoinRequest, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = now()
}
object DealershipJoinRequest_name: SerializableProperty<DealershipJoinRequest, String> {
    override val name: String = "name"
    override fun get(receiver: DealershipJoinRequest): String = receiver.name
    override fun setCopy(receiver: DealershipJoinRequest, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("name")
    override val default: String = ""
}
object DealershipJoinRequest_address: SerializableProperty<DealershipJoinRequest, Address> {
    override val name: String = "address"
    override fun get(receiver: DealershipJoinRequest): Address = receiver.address
    override fun setCopy(receiver: DealershipJoinRequest, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("address")
    override val default: Address = Address.MOCK
}
object DealershipJoinRequest_url: SerializableProperty<DealershipJoinRequest, String?> {
    override val name: String = "url"
    override fun get(receiver: DealershipJoinRequest): String? = receiver.url
    override fun setCopy(receiver: DealershipJoinRequest, value: String?) = receiver.copy(url = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("url")
    override val default: String? = null
}
object DealershipJoinRequest_billingContact: SerializableProperty<DealershipJoinRequest, DealershipJoinRequestUser> {
    override val name: String = "billingContact"
    override fun get(receiver: DealershipJoinRequest): DealershipJoinRequestUser = receiver.billingContact
    override fun setCopy(receiver: DealershipJoinRequest, value: DealershipJoinRequestUser) = receiver.copy(billingContact = value)
    override val serializer: KSerializer<DealershipJoinRequestUser> = DealershipJoinRequestUser.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("billingContact")
    override val default: DealershipJoinRequestUser = DealershipJoinRequestUser.EMPTY
}
object DealershipJoinRequest_usedCarManager: SerializableProperty<DealershipJoinRequest, DealershipJoinRequestUser> {
    override val name: String = "usedCarManager"
    override fun get(receiver: DealershipJoinRequest): DealershipJoinRequestUser = receiver.usedCarManager
    override fun setCopy(receiver: DealershipJoinRequest, value: DealershipJoinRequestUser) = receiver.copy(usedCarManager = value)
    override val serializer: KSerializer<DealershipJoinRequestUser> = DealershipJoinRequestUser.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("usedCarManager")
    override val default: DealershipJoinRequestUser = DealershipJoinRequestUser.EMPTY
}
object DealershipJoinRequest_transportManager: SerializableProperty<DealershipJoinRequest, DealershipJoinRequestUser> {
    override val name: String = "transportManager"
    override fun get(receiver: DealershipJoinRequest): DealershipJoinRequestUser = receiver.transportManager
    override fun setCopy(receiver: DealershipJoinRequest, value: DealershipJoinRequestUser) = receiver.copy(transportManager = value)
    override val serializer: KSerializer<DealershipJoinRequestUser> = DealershipJoinRequestUser.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("transportManager")
    override val default: DealershipJoinRequestUser = DealershipJoinRequestUser.EMPTY
}
object DealershipJoinRequest_titleManager: SerializableProperty<DealershipJoinRequest, DealershipJoinRequestUser> {
    override val name: String = "titleManager"
    override fun get(receiver: DealershipJoinRequest): DealershipJoinRequestUser = receiver.titleManager
    override fun setCopy(receiver: DealershipJoinRequest, value: DealershipJoinRequestUser) = receiver.copy(titleManager = value)
    override val serializer: KSerializer<DealershipJoinRequestUser> = DealershipJoinRequestUser.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("titleManager")
    override val default: DealershipJoinRequestUser = DealershipJoinRequestUser.EMPTY
}
object DealershipJoinRequest_stripeBilling: SerializableProperty<DealershipJoinRequest, Boolean> {
    override val name: String = "stripeBilling"
    override fun get(receiver: DealershipJoinRequest): Boolean = receiver.stripeBilling
    override fun setCopy(receiver: DealershipJoinRequest, value: Boolean) = receiver.copy(stripeBilling = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("stripeBilling")
    override val default: Boolean = false
}
object DealershipJoinRequest_makes: SerializableProperty<DealershipJoinRequest, Set<String>> {
    override val name: String = "makes"
    override fun get(receiver: DealershipJoinRequest): Set<String> = receiver.makes
    override fun setCopy(receiver: DealershipJoinRequest, value: Set<String>) = receiver.copy(makes = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("makes")
    override val default: Set<String> = setOf()
}
object DealershipJoinRequest_notes: SerializableProperty<DealershipJoinRequest, String> {
    override val name: String = "notes"
    override fun get(receiver: DealershipJoinRequest): String = receiver.notes
    override fun setCopy(receiver: DealershipJoinRequest, value: String) = receiver.copy(notes = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("notes")
    override val default: String = ""
}
object DealershipJoinRequest_accepted: SerializableProperty<DealershipJoinRequest, Instant?> {
    override val name: String = "accepted"
    override fun get(receiver: DealershipJoinRequest): Instant? = receiver.accepted
    override fun setCopy(receiver: DealershipJoinRequest, value: Instant?) = receiver.copy(accepted = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("accepted")
    override val default: Instant? = null
}
object DealershipJoinRequest_archived: SerializableProperty<DealershipJoinRequest, Instant?> {
    override val name: String = "archived"
    override fun get(receiver: DealershipJoinRequest): Instant? = receiver.archived
    override fun setCopy(receiver: DealershipJoinRequest, value: Instant?) = receiver.copy(archived = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = DealershipJoinRequest.serializer().tryFindAnnotations("archived")
    override val default: Instant? = null
}
