// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareMakeFields() {
    val props: Array<SerializableProperty<Make, *>> = arrayOf(Make__id, Make_chromeId)
    Make.serializer().properties { props }
}
val <K> DataClassPath<K, Make>._id: DataClassPath<K, String> get() = this[Make__id]
val <K> DataClassPath<K, Make>.chromeId: DataClassPath<K, Int> get() = this[Make_chromeId]
inline val Make.Companion.path: DataClassPath<Make, Make> get() = path<Make>()


object Make__id: SerializableProperty<Make, String> {
    override val name: String = "_id"
    override fun get(receiver: Make): String = receiver._id
    override fun setCopy(receiver: Make, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Make.serializer().tryFindAnnotations("_id")
}
object Make_chromeId: SerializableProperty<Make, Int> {
    override val name: String = "chromeId"
    override fun get(receiver: Make): Int = receiver.chromeId
    override fun setCopy(receiver: Make, value: Int) = receiver.copy(chromeId = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Make.serializer().tryFindAnnotations("chromeId")
}
