package com.ilussobsa.views

import com.ilussobsa.Resources
import com.ilussobsa.Strings
import com.ilussobsa.utils.defaultAppTheme
import com.ilussobsa.utils.fullLogo
import com.ilussobsa.utils.overimage
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

fun ViewWriter.nothingSelected(message: String) {
    stack {
        col {
            expanding - space()
            centered - text(message)
            expanding - space()
            centered - image {
                ::source { Resources.fullLogo(defaultAppTheme) }
                opacity = 0.25
                this.description = ""
            }
            weight(3f) - space()
        }
    }
}