// Automatically generated based off ExternalAsyncTaskRequest.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.externalintegration

import com.lightningkite.lightningdb.AdminTableColumns
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningdb.Index
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlinx.datetime.Instant
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant
fun prepareExternalAsyncTaskRequestFields() {
    val props: Array<SerializableProperty<ExternalAsyncTaskRequest, *>> = arrayOf(ExternalAsyncTaskRequest__id, ExternalAsyncTaskRequest_ourData, ExternalAsyncTaskRequest_expiresAt, ExternalAsyncTaskRequest_createdAt, ExternalAsyncTaskRequest_response, ExternalAsyncTaskRequest_result, ExternalAsyncTaskRequest_action, ExternalAsyncTaskRequest_lastAttempt, ExternalAsyncTaskRequest_processingError)
    ExternalAsyncTaskRequest.serializer().properties { props }
}
val <K> DataClassPath<K, ExternalAsyncTaskRequest>._id: DataClassPath<K, String> get() = this[ExternalAsyncTaskRequest__id]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.ourData: DataClassPath<K, String> get() = this[ExternalAsyncTaskRequest_ourData]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.expiresAt: DataClassPath<K, Instant> get() = this[ExternalAsyncTaskRequest_expiresAt]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.createdAt: DataClassPath<K, Instant> get() = this[ExternalAsyncTaskRequest_createdAt]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.response: DataClassPath<K, String?> get() = this[ExternalAsyncTaskRequest_response]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.result: DataClassPath<K, String?> get() = this[ExternalAsyncTaskRequest_result]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.action: DataClassPath<K, String?> get() = this[ExternalAsyncTaskRequest_action]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.lastAttempt: DataClassPath<K, Instant> get() = this[ExternalAsyncTaskRequest_lastAttempt]
val <K> DataClassPath<K, ExternalAsyncTaskRequest>.processingError: DataClassPath<K, String?> get() = this[ExternalAsyncTaskRequest_processingError]
inline val ExternalAsyncTaskRequest.Companion.path: DataClassPath<ExternalAsyncTaskRequest, ExternalAsyncTaskRequest> get() = path<ExternalAsyncTaskRequest>()


object ExternalAsyncTaskRequest__id: SerializableProperty<ExternalAsyncTaskRequest, String> {
    override val name: String = "_id"
    override fun get(receiver: ExternalAsyncTaskRequest): String = receiver._id
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("_id")
}
object ExternalAsyncTaskRequest_ourData: SerializableProperty<ExternalAsyncTaskRequest, String> {
    override val name: String = "ourData"
    override fun get(receiver: ExternalAsyncTaskRequest): String = receiver.ourData
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: String) = receiver.copy(ourData = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("ourData")
}
object ExternalAsyncTaskRequest_expiresAt: SerializableProperty<ExternalAsyncTaskRequest, Instant> {
    override val name: String = "expiresAt"
    override fun get(receiver: ExternalAsyncTaskRequest): Instant = receiver.expiresAt
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: Instant) = receiver.copy(expiresAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("expiresAt")
}
object ExternalAsyncTaskRequest_createdAt: SerializableProperty<ExternalAsyncTaskRequest, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: ExternalAsyncTaskRequest): Instant = receiver.createdAt
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = Clock.System.now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ourData([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)response([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)result([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)action([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAttempt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)processingError([^a-zA-Z0-9]|$)
}
object ExternalAsyncTaskRequest_response: SerializableProperty<ExternalAsyncTaskRequest, String?> {
    override val name: String = "response"
    override fun get(receiver: ExternalAsyncTaskRequest): String? = receiver.response
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: String?) = receiver.copy(response = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("response")
    override val default: String? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ourData([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)response([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)result([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)action([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAttempt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)processingError([^a-zA-Z0-9]|$)
}
object ExternalAsyncTaskRequest_result: SerializableProperty<ExternalAsyncTaskRequest, String?> {
    override val name: String = "result"
    override fun get(receiver: ExternalAsyncTaskRequest): String? = receiver.result
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: String?) = receiver.copy(result = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("result")
    override val default: String? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ourData([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)response([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)result([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)action([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAttempt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)processingError([^a-zA-Z0-9]|$)
}
object ExternalAsyncTaskRequest_action: SerializableProperty<ExternalAsyncTaskRequest, String?> {
    override val name: String = "action"
    override fun get(receiver: ExternalAsyncTaskRequest): String? = receiver.action
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: String?) = receiver.copy(action = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("action")
    override val default: String? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ourData([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)response([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)result([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)action([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAttempt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)processingError([^a-zA-Z0-9]|$)
}
object ExternalAsyncTaskRequest_lastAttempt: SerializableProperty<ExternalAsyncTaskRequest, Instant> {
    override val name: String = "lastAttempt"
    override fun get(receiver: ExternalAsyncTaskRequest): Instant = receiver.lastAttempt
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: Instant) = receiver.copy(lastAttempt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("lastAttempt")
    override val default: Instant = Instant.DISTANT_PAST // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ourData([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)response([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)result([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)action([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAttempt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)processingError([^a-zA-Z0-9]|$)
}
object ExternalAsyncTaskRequest_processingError: SerializableProperty<ExternalAsyncTaskRequest, String?> {
    override val name: String = "processingError"
    override fun get(receiver: ExternalAsyncTaskRequest): String? = receiver.processingError
    override fun setCopy(receiver: ExternalAsyncTaskRequest, value: String?) = receiver.copy(processingError = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ExternalAsyncTaskRequest.serializer().tryFindAnnotations("processingError")
    override val default: String? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ourData([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)response([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)result([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)action([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAttempt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)processingError([^a-zA-Z0-9]|$)
}
