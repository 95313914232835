// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.auth.oauth

import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlinx.datetime.Instant
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.Instant
fun prepareOauthClientFields() {
    val props: Array<SerializableProperty<OauthClient, *>> = arrayOf(OauthClient__id, OauthClient_niceName, OauthClient_logo, OauthClient_scopes, OauthClient_secrets, OauthClient_redirectUris)
    OauthClient.serializer().properties { props }
}
val <K> DataClassPath<K, OauthClient>._id: DataClassPath<K, String> get() = this[OauthClient__id]
val <K> DataClassPath<K, OauthClient>.niceName: DataClassPath<K, String> get() = this[OauthClient_niceName]
val <K> DataClassPath<K, OauthClient>.logo: DataClassPath<K, ServerFile?> get() = this[OauthClient_logo]
val <K> DataClassPath<K, OauthClient>.scopes: DataClassPath<K, Set<String>> get() = this[OauthClient_scopes]
val <K> DataClassPath<K, OauthClient>.secrets: DataClassPath<K, Set<OauthClientSecret>> get() = this[OauthClient_secrets]
val <K> DataClassPath<K, OauthClient>.redirectUris: DataClassPath<K, Set<String>> get() = this[OauthClient_redirectUris]
inline val OauthClient.Companion.path: DataClassPath<OauthClient, OauthClient> get() = path<OauthClient>()


object OauthClient__id: SerializableProperty<OauthClient, String> {
    override val name: String = "_id"
    override fun get(receiver: OauthClient): String = receiver._id
    override fun setCopy(receiver: OauthClient, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = OauthClient.serializer().tryFindAnnotations("_id")
}
object OauthClient_niceName: SerializableProperty<OauthClient, String> {
    override val name: String = "niceName"
    override fun get(receiver: OauthClient): String = receiver.niceName
    override fun setCopy(receiver: OauthClient, value: String) = receiver.copy(niceName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = OauthClient.serializer().tryFindAnnotations("niceName")
}
object OauthClient_logo: SerializableProperty<OauthClient, ServerFile?> {
    override val name: String = "logo"
    override fun get(receiver: OauthClient): ServerFile? = receiver.logo
    override fun setCopy(receiver: OauthClient, value: ServerFile?) = receiver.copy(logo = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = OauthClient.serializer().tryFindAnnotations("logo")
    override val default: ServerFile? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)niceName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)logo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secrets([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)redirectUris([^a-zA-Z0-9]|$)
}
object OauthClient_scopes: SerializableProperty<OauthClient, Set<String>> {
    override val name: String = "scopes"
    override fun get(receiver: OauthClient): Set<String> = receiver.scopes
    override fun setCopy(receiver: OauthClient, value: Set<String>) = receiver.copy(scopes = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = OauthClient.serializer().tryFindAnnotations("scopes")
    override val default: Set<String> = setOf() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)niceName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)logo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secrets([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)redirectUris([^a-zA-Z0-9]|$)
}
object OauthClient_secrets: SerializableProperty<OauthClient, Set<OauthClientSecret>> {
    override val name: String = "secrets"
    override fun get(receiver: OauthClient): Set<OauthClientSecret> = receiver.secrets
    override fun setCopy(receiver: OauthClient, value: Set<OauthClientSecret>) = receiver.copy(secrets = value)
    override val serializer: KSerializer<Set<OauthClientSecret>> = SetSerializer(OauthClientSecret.serializer())
    override val annotations: List<Annotation> = OauthClient.serializer().tryFindAnnotations("secrets")
    override val default: Set<OauthClientSecret> = setOf() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)niceName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)logo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secrets([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)redirectUris([^a-zA-Z0-9]|$)
}
object OauthClient_redirectUris: SerializableProperty<OauthClient, Set<String>> {
    override val name: String = "redirectUris"
    override fun get(receiver: OauthClient): Set<String> = receiver.redirectUris
    override fun setCopy(receiver: OauthClient, value: Set<String>) = receiver.copy(redirectUris = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = OauthClient.serializer().tryFindAnnotations("redirectUris")
    override val default: Set<String> = setOf() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)niceName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)logo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secrets([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)redirectUris([^a-zA-Z0-9]|$)
}
