package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.game.*
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.canvas.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import kotlin.random.Random
import kotlin.time.DurationUnit
import kotlinx.coroutines.*
import kotlinx.datetime.Instant

@Routable("live-auction/{id}")
class LiveAuctionScreen(val id: UUID) : KiteUiScreen {

    override val title: Readable<String> get() = Constant("Live")
    val defaultAuctioneer = PersistentProperty<Auctioneer?>("defaultAuctioneer", Auctioneer.Recording1)

    data class LocalPoke(
        val message: String,
        override val at: Instant = now(),
    ) : HasTimestamp

    enum class AuctionState {
        NotOpen,
        Waiting,
        Open
    }

    override fun ViewWriter.render() {
        val auction = shared { currentSession().auctions.get(id)() }
        val live = shared { currentSession().liveAuctionDataCache.watch(id)() }
        val runlistMeta = shared {
            currentSessionNullable.awaitNotNull().vehicles.watch(
                Query(
                    orderBy = sort { it.orderingValue.ascending() },
                    limit = 1000,
                    condition = auction.awaitNotNull().runlist
                )
            )
        }
        val runlist = shared { runlistMeta.await().await() }
        val bidsMeta = shared {
            currentSessionNullable.awaitNotNull().bids.watch(
                Query(orderBy = sort { it.price.ascending(); it.at.ascending() },
                    limit = 1000,
                    condition = condition { it.vehicle eq live.awaitNotNull().currentVehicle })
            )
        }
        val currentLotBids = shared {
            // Post process to hide equal value non-winning bids
            bidsMeta.await().await().distinctBy { it.price }
        }
        val talkingMeta = shared {
            currentSession().sellerTalkingPoints.watch(
                Query(orderBy = sort { it.at.ascending() },
                    limit = 1000,
                    condition = condition { it.vehicle eq live.awaitNotNull().currentVehicle })
            )
        }
        val talking = shared { talkingMeta.await().await() }
        val localPokes = Property<List<LocalPoke>>(listOf())
        val biddingStart = shared { live()?.startedAt ?: now() }
        val saleHistory = shared { (talking.await() + currentLotBids.await()).sortedBy { it.at }.takeUnless { it.isEmpty() } ?: listOf(listOf(LocalPoke("No bids yet", biddingStart()))) }
        val nowBySecond = Property(now())
        val playSecondaryAnimation = BasicListenable()
        var currentVideoSrc: VideoSource? = null

        var currentAnimationAction:Property<AnimationAction> = Property(AnimationAction.IDLE)

//        val currentLotId = shared { runlist.await().firstOrNull()?._id }
//        val laneState = shared { AuctionState.Open }
        val currentLotId = shared { live.await()?.currentVehicle }
        val laneState = shared {
            live.await()?.let {
                if (nowBySecond.await() > it.laneStartedAt) AuctionState.Open
                else AuctionState.Waiting
            } ?: AuctionState.NotOpen
        }

        val finalTimeout = shared {
            live.await()?.let {
                if ((it.increment == it.bottomIncrement || (it.dropsLeft == 0 && it.bids == 0)) && it.completion == null)
                    it.timeout
                else
                    null
            }
        }
        val currentLot =
            shared { currentLotId.await()?.let { currentSessionNullable.awaitNotNull().vehicles.watch(it).await() } }

        row {
            this.onRemove {
                launchGlobal {
                    currentDealershipId.await()?.let {
                        currentSession().liveAuctionData.notifyLeave(id, it)
                    }
                }
            }
            val dontShowLoading = CoroutineScope(coroutineContext + object: StatusListener {
                override fun loading(readable: Readable<*>) {}
            })
            dontShowLoading.reactiveSuspending {
                while (true) {
                    currentDealershipId.await()?.let {
                        currentSession().liveAuctionData.notifyJoin(id, it)
                    }
                    delay(10_000)
                }
            }

            dontShowLoading.reactiveSuspending {
                while (true) {
                    delay(100)
                    nowBySecond.value = now()
                }
            }

            reactiveScope {
                currentLotId.await()
                localPokes.value = listOf()
            }

            var permitPoke = true
            val shouldPoke = shared {
                currentLotBids.await().let {
                    it.getOrNull(it.lastIndex - 1)?.buyer == currentDealershipId.await() &&
                            it.getOrNull(it.lastIndex)?.buyer != currentDealershipId.await() &&
                            live.await()?.reserveMet == true
                }
            }
            dontShowLoading.reactiveSuspending {
                if (shouldPoke()) {
                    delay(2000)
                    if (permitPoke) {
                        localPokes.value += LocalPoke(
                            defaultAuctioneer.value?.audioPack()?.pokes?.random()?.text ?: "Don't let it go!"
                        )
                    }
                    permitPoke = false
                }
            }

            // Disjoint warning
            run {
                val collectionBids = shared { currentLotBids().size }
                val liveBids = shared { live()?.bids ?: 0 }
                val mismatch = shared { collectionBids() != liveBids() }
                val isBroken = mismatch.debounce(10_000)
                dontShowLoading.reactiveScope {
                    if(isBroken()) {
                        ConsoleRoot.warn("Live: ", live())
                        ConsoleRoot.warn("Current Bids: ", currentLotBids())
                        Exception("Disjoint warning: Bid count mismatch for over 10 seconds.").report()
                    }
                }
            }

            var startup = true
            launch {
                delay(1000)
                startup = false
            }
            suspend fun playClip(includeGavel: Boolean = false, getter: (AuctioneerPack) -> EventResources) {
                // We don't play right at startup.  It sounds odd.
                if(startup) return
                val auctioneer = defaultAuctioneer.value
//                val auctioneer = currentLot.awaitOnce()?.auctioneer ?: defaultAuctioneer.awaitOnce()
                val pack = auctioneer?.audioPack() ?: return
                pack.let(getter).audio.randomOrNull()?.also { println(it) }?.load()?.play()
                if (includeGavel) Resources.auctioneerGavel.load().play()
                pack.let(getter).video.randomOrNull()?.also { println(it) }?.let {
                    currentVideoSrc = it
                    playSecondaryAnimation.invokeAll()
                }
//                (currentLot.awaitOnce()?.auctioneer ?: defaultAuctioneer.awaitOnce())?.audioPack()?.let(getter)?.load()?.play()
            }

            backgroundAudio(Resources.auctioneerCrowdBackground, 0.4f) {
                laneState() == AuctionState.Open && screenNavigator.currentScreen() == this@LiveAuctionScreen
            }

            expanding - swapView {
                swapping(
                    current = { laneState.await() },
                    views = {
                        when (it) {
                            AuctionState.NotOpen -> col {
                                expanding - space()
                                centered - h2(Strings.thankYouForYourParticipation)
                                centered - h3("A timer will appear here when we're about to start the next auction.")
                                centered - onlyWhen { currentUser.awaitNotNull().role >= UserRole.Manager } - important - button {
                                    text(Strings.startTheAuction)
                                    onClick {
                                        currentSession().liveAuctionData.start(id)
                                        currentSession().liveAuctionDataCache[id].invalidate()
                                    }
                                }
                                centered - card - link {
                                    ::exists { WindowInfo.await().width <= 70.rem }
                                    to = { LiveAuctionRunlistScreen(id) }
                                    row {
                                        icon {
                                            source = Icon.list
                                            description = ""
                                        }
                                        centered - text(Strings.viewRunlist)
                                    }
                                }
                                expanding - space()
                            }

                            AuctionState.Waiting -> col {
                                expanding - space()
                                centered - h2(Strings.thisAuctionWillOpenSoon)
                                centered - h3 {
                                    launch {
                                        playClip(getter = AuctioneerPack::welcome)
                                    }
                                    ::content {
                                        (live.awaitNotNull().laneStartedAt - nowBySecond.await())
                                            .toComponents { minutes, seconds, nanoseconds ->
                                                "$minutes:${
                                                    seconds.toString().padStart(2, '0')
                                                }"
                                            }
                                    }
                                }
                                centered - card - link {
                                    ::exists { WindowInfo.await().width <= 70.rem }
                                    to = { LiveAuctionRunlistScreen(id) }
                                    row {
                                        icon {
                                            source = Icon.list
                                            description = ""
                                        }
                                        centered - text(Strings.viewRunlist)
                                    }
                                }
                                expanding - space()
                            }

                            AuctionState.Open -> scrolls - col {
                                rowCollapsingToColumn(60.rem) {
                                    onlyWhen { WindowInfo().width > 60.rem } - sizeConstraints(height = 18.rem) - card - gravity(
                                        Align.Start,
                                        Align.End
                                    ) - sizeConstraints(
                                        width = 20.rem,
                                        height = 18.rem
                                    ) - stack {
                                        spacing = 0.px

                                        if (Platform.current != Platform.Web) {
                                            video {
                                                showControls = false
                                                scaleType = ImageScaleType.Crop
                                                val shouldBeDancing = shared {
                                                    live()?.let { it.bids > 0 && it.completion == null } == true
                                                }
                                                reactiveSuspending {
                                                    source =
                                                        if (shouldBeDancing().also { delay(500) }) {
                                                            defaultAuctioneer.await()?.audioPack()?.idleAfterMoney?.randomOrNull()
                                                        } else {
                                                            defaultAuctioneer.await()?.audioPack()?.idle?.randomOrNull()
                                                        }
                                                    time set 0.0
                                                    playing set true
                                                    loop = true
                                                }
                                            }
                                            video {
                                                reactiveSuspending {
                                                    rerunOn(playSecondaryAnimation)
                                                    source = currentVideoSrc
                                                    time set 0.0
                                                    playing set true
                                                    exists = true
                                                }
                                                var wasPlaying = true
                                                reactiveScope {
                                                    val isPlaying = playing()
                                                    if (!isPlaying && wasPlaying) {
                                                        exists = false
                                                    }
                                                    wasPlaying = isPlaying
                                                }
                                                showControls = false
                                                scaleType = ImageScaleType.Crop
                                                launch {
                                                    volume set 0f
                                                    playing set true
                                                }
                                            }
                                        } else {
                                            auctioneer {
                                                reactiveSuspending {
                                                    animationAction = currentAnimationAction()
                                                    if(currentAnimationAction() != AnimationAction.COUNTING_1_ACTION && currentAnimationAction() != AnimationAction.COUNTING_2_ACTION){
                                                        delay(100)
                                                        currentAnimationAction.set(AnimationAction.IDLE)
                                                    }
                                                }
                                            }
                                        }
                                        atTopStart - button {
                                            icon(Icon.person, Strings.auctioneer)
                                            onClick {
                                                dialogScreenNavigator.navigate(object : KiteUiScreen {
                                                    override fun ViewWriter.render() {
                                                        dismissBackground {
                                                            centered - card - col {
                                                                row {
                                                                    h2(Strings.selectAuctioneer)
                                                                    button {
                                                                        icon(Icon.close, Strings.close)
                                                                        onClick { navigator.dismiss() }
                                                                    }
                                                                }
                                                                Auctioneer.values().forEach { auctioneer ->
                                                                    radioToggleButton {
                                                                        text(auctioneer.displayName)
                                                                        checked bind defaultAuctioneer.equalTo(
                                                                            auctioneer
                                                                        )
                                                                    }
                                                                }
                                                                radioToggleButton {
                                                                    text(Strings.off)
                                                                    checked bind defaultAuctioneer.equalTo(null)
                                                                }
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    expanding - sizeConstraints(height = 18.rem) - stack {
                                        canvas {
                                            val delegate = AuctioneerSpeechDelegate()
                                            this.delegate = delegate
                                            var last = clockMillis()
                                            reactiveScope {
                                                rerunOn(AnimationFrame)
                                                val now = clockMillis()
                                                delegate.step((now - last) / 1000.0)
                                                last = now
                                            }
                                            var goingOncePlayed = false
                                            var goingTwicePlayed = false
                                            var lastAsk = 0
                                            var soldPlayed = false
                                            reactiveSuspending {
                                                rerunOn(currentLotId)
                                                delegate.status.text = ""
                                                delegate.comment.text = ""
                                                delegate.winningBidBuyer.text = "Asking Price"
                                                goingOncePlayed = false
                                                goingTwicePlayed = false
                                                soldPlayed = false
                                                lastAsk = 0
                                                val l = live.awaitOnce()
                                                if (l != null && l.completion == null) {
                                                    playClip(getter = AuctioneerPack::next)
                                                }
                                            }
                                            reactiveSuspending {
                                                val l = live.await() ?: return@reactiveSuspending
                                                val newAsk = l.asking
                                                if (newAsk < lastAsk) {
                                                    if (l.bids == 0 && l.dropsLeft == 0)
                                                        playClip(getter = AuctioneerPack::bottomPrice)
                                                }
                                                lastAsk = newAsk
                                            }

                                            var reserveMetPlayed = false
                                            var lastBids = 0
                                            reactiveSuspending {
                                                val l = live.await() ?: return@reactiveSuspending
                                                val newCount = l.bids
                                                println("newCount $newCount")
                                                if (newCount == 0) {
                                                    reserveMetPlayed = false
                                                    lastBids = 0
                                                }
//                                                println("lastBids $lastBids")
//                                                println("reserveMetPlayed $reserveMetPlayed")
//                                                println("l.reserveMet ${l.reserveMet}")
                                                if (newCount > lastBids) {

                                                    println("newCount > lastBids")
                                                    playClip(getter = AuctioneerPack::bidPlaced)
                                                    if (!reserveMetPlayed && l.reserveMet && newCount > 0) {
                                                        reserveMetPlayed = true
                                                        playClip(getter = AuctioneerPack::reserveMet)
                                                        delegate.winningBidBuyer.text = "Reserve met!"
                                                        if(newCount == 1) {
                                                            currentAnimationAction.set(AnimationAction.LIVE_MONEY)
                                                        }
                                                        permitPoke = true
                                                        delay(100)
                                                    } else if (newCount == 1) {
                                                        currentAnimationAction.set(AnimationAction.LIVE_MONEY)
                                                        playClip(getter = AuctioneerPack::firstBidPlaced)
                                                    } else {
                                                        println("Bidding action")
                                                        currentAnimationAction.set(AnimationAction.BIDDING_ACTION)
                                                    }
                                                }
                                                lastBids = newCount
                                            }
                                            val goingStatus = shared {
                                                finalTimeout()?.let {
                                                    if (it - nowBySecond() < LiveAuctionData.lagAccounting + LiveAuctionData.goingDuration) {
                                                        2
                                                    } else if (it - nowBySecond() < LiveAuctionData.lagAccounting + LiveAuctionData.goingDuration * 2) {
                                                        1
                                                    } else {
                                                        0
                                                    }
                                                } ?: 0
                                            }
                                            reactiveSuspending {
                                                when (val gs = goingStatus().also { println("goingStatus: $it") }) {
                                                    2 -> {
                                                        delegate.status.text = Strings.goingTwice
                                                        if (!goingTwicePlayed) {
                                                            goingTwicePlayed = true
                                                            playClip(getter = AuctioneerPack::goingTwice)
                                                            currentAnimationAction.set(AnimationAction.COUNTING_1_OUT_ACTION)
                                                            delay(100)
                                                            currentAnimationAction.set(AnimationAction.COUNTING_2_ACTION)

                                                        }
                                                    }

                                                    1 -> {
                                                        delegate.status.text = Strings.goingOnce
                                                        if (!goingOncePlayed) {
                                                            goingOncePlayed = true
                                                            playClip(getter = AuctioneerPack::goingOnce)
                                                            currentAnimationAction.set(AnimationAction.COUNTING_1_ACTION)
                                                            delay(100)
                                                        }
                                                    }

                                                    0 -> {
                                                        goingOncePlayed = false
                                                        goingTwicePlayed = false
                                                        delegate.status.text = ""
                                                    }
                                                }
                                            }
                                            reactiveSuspending {
                                                live.await()?.completion?.let {
                                                    delegate.status.text =
                                                        if (it.bids == 0) Strings.noSale else if (it.sold) "SOLD" else Strings.offerReady
                                                    delegate.comment.text = ""
                                                    if (!soldPlayed) {
                                                        soldPlayed = true
                                                        playClip(
                                                            includeGavel = true,
                                                            getter = when {
                                                                it.bids == 0 -> AuctioneerPack::noSale
                                                                it.sold -> AuctioneerPack::sold
                                                                else -> AuctioneerPack::offerReady
                                                            }
                                                        )

                                                        if(currentAnimationAction() == AnimationAction.COUNTING_2_ACTION) {
                                                            currentAnimationAction.set(AnimationAction.COUNTING_2_OUT_ACTION)
                                                            delay(100)
                                                        }
                                                        currentAnimationAction.set(when
                                                        {it.bids == 0 -> AnimationAction.NO_SALE_ACTION
                                                            it.sold -> AnimationAction.SOLD_ACTION
                                                            else ->AnimationAction.OFFER_ACTION
                                                        })
                                                    }
                                                }
                                            }
                                            reactiveScope {
                                                localPokes.await().lastOrNull()?.let {
                                                    delegate.comment.text = it.message
                                                }
                                            }
                                            reactiveScope {
                                                val l = live()
                                                if (l?.winningBid?.buyer == currentDealershipId() && l?.completion == null) {
                                                    delegate.comment.text = ""
                                                }
                                            }
                                            reactiveScope {
                                                delegate.winningBid.text =
                                                    live.await()?.asking?.renderPriceInDollars() ?: ""
                                            }
                                        }
                                        atTopEnd - card - link {
                                            ::exists { WindowInfo.await().width <= 70.rem }
                                            to = { LiveAuctionRunlistScreen(id) }
                                            icon {
                                                source = Icon.list
                                                description = "View Runlist"
                                            }
                                        }
                                    }
                                    centered - sizeConstraints(width = 20.rem, height = 18.rem) - col {
                                        expanding - card - stack {
                                            recyclerView {
                                                children(saleHistory) {
                                                    text {
                                                        ::content {
                                                            when (val item = it.await()) {
                                                                is Bid -> {
                                                                    val dealership =
                                                                        currentSessionNullable.awaitNotNull().dealerships[item.buyer].await()
                                                                    "${dealership?.name ?: "-"} bid ${item.price.renderPriceInDollars()}"
                                                                }

                                                                is SellerTalkingPoint -> {
                                                                    Strings.sellerSaysX(item.message)
                                                                }

                                                                is LocalPoke -> {
                                                                    item.message
                                                                }

                                                                else -> ""
                                                            }
                                                        }
                                                    }
                                                }
                                                reactiveSuspending {
                                                    val last = saleHistory.await().lastIndex
                                                    delay(100)
                                                    scrollToIndex(last, Align.End)
                                                }
                                            }
                                        }

                                        val weHaveTopBid = Property(false)
                                        reactiveScope {
                                            weHaveTopBid.value =
                                                live.await()?.winningBid?.buyer.let { it != null && it == currentDealershipId.await() }
                                        }
                                        onlyWhen {
                                            val cl = currentLot.await() ?: return@onlyWhen false
                                            val cd = currentDealership.await() ?: return@onlyWhen false
                                            cl?.seller != cd._id && cd.makes.contains(cl.make)
                                        } - button {
                                            dynamicTheme { if (weHaveTopBid()) AffirmativeSemantic else CriticalSemantic }
                                            centered - h2 {
                                                ::content {
                                                    if (weHaveTopBid()) Strings.youHaveTheTopBid else live.await()?.asking?.renderPriceInDollars()
                                                        ?.let { Strings.bidX(it) } ?: "-"
                                                }
                                            }
                                            ::enabled {
                                                (live.await()
                                                    ?.let { nowBySecond() > it.startedAt && it.completion == null }
                                                    ?: false)
                                            }
                                            launch {
                                                while (true) {
                                                    finalTimeout.state.getOrNull()?.let {
                                                        if (it - now() < LiveAuctionData.lagAccounting + LiveAuctionData.goingDuration) {
                                                            animateEmphasis()
                                                        }
                                                    }
                                                    delay(1100)
                                                }
                                            }
                                            onClick {
                                                if (weHaveTopBid()) return@onClick
                                                val vehicle = currentLotId.await() ?: run { return@onClick }
                                                val buyer = currentDealershipId.await() ?: run { return@onClick }
                                                val price = live.await()?.asking ?: run { return@onClick }
                                                currentSession().bids.insert(
                                                    Bid(
                                                        vehicle = vehicle,
                                                        buyer = buyer,
                                                        price = price,
                                                    )
                                                )
                                            }
                                            hoverEvents(
                                                onMouseEnter = {
                                                    currentDealershipId.await()?.let {
                                                        currentSession().liveAuctionData.notifyHover(id, it)
                                                    }
                                                },
                                                onMouseLeave = {
                                                    currentDealershipId.await()?.let {
                                                        currentSession().liveAuctionData.notifyUnhover(id, it)
                                                    }
                                                },
                                            )
                                        }
                                        onlyWhen { currentLot.await()?.seller == currentDealershipId.await() } - col {
                                            val tf: TextField
                                            row {
                                                val messageToSend = Property("")
                                                expanding - fieldTheme - textField {
                                                    tf = this
                                                    hint = Strings.sendSellerMessageHint
                                                    content bind messageToSend
                                                }
                                                button {
                                                    icon(Icon.send, Strings.send)
                                                    onClickAssociatedField(tf) {
                                                        currentSession().sellerTalkingPoints.insert(
                                                            SellerTalkingPoint(
                                                                vehicle = currentLotId.awaitNotNull(),
                                                                seller = currentDealershipId.awaitNotNull(),
                                                                message = messageToSend.value
                                                            )
                                                        )
                                                        messageToSend.value = ""
                                                    }
                                                }
                                            }
                                            critical - button {
                                                centered - h2 {
                                                    ::content {
                                                        if (live.awaitNotNull().reserveMet) Strings.reserveMet else Strings.authorizeSale
                                                    }
                                                }
                                                ::enabled {
                                                    live.await()
                                                        ?.let { nowBySecond() > it.startedAt && it.completion == null && !it.reserveMet }
                                                        ?: false
                                                }
                                                onClick {
                                                    val vehicle = currentLotId.await() ?: run { return@onClick }
                                                    currentSession().vehicles.get(vehicle).modify(modification {
                                                        it.reserve assign null
                                                    })
                                                }
                                            }
                                        }
                                        progressBar {
                                            ::exists { currentUser()?.role?.let { it > UserRole.Manager } == true }
                                            ::ratio {
                                                (live.awaitNotNull().timeout - nowBySecond()).toDouble(DurationUnit.SECONDS)
                                                    .div(20).coerceIn(0.0, 1.0).toFloat()
                                            }
                                        }
                                    }
                                }
                                swapView {
                                    swapping(
                                        current = { currentLotId.await() },
                                        views = { selectedLot ->
                                            if (selectedLot == null) {
                                                col {
                                                    text(Strings.theAuctionIsntLive)
                                                    important - button {
                                                        ::exists { currentSession().me.awaitNotNull().role >= UserRole.Manager }
                                                        text(Strings.startNow)
                                                        onClick {
                                                            currentSession().liveAuctionData.start(id)
                                                        }
                                                    }
                                                }
                                                return@swapping
                                            }

                                            vehicleDetail(
                                                selectedLot,
                                                immutable = true,
                                                goto = {},
                                                null
                                            )
                                        }
                                    )
                                }
                            }
                        }
                    }
                )
            }

            sizeConstraints(width = 25.rem) - onlyWhen { WindowInfo.await().width > 70.rem } - col {
                val jumpToCurrent = BasicListenable()
                padded - stack {
                    compact - important - button {
                        centered - text(Strings.jumpToCurrent)
                        ::exists { laneState.await() == AuctionState.Open }
                        onClick { jumpToCurrent.invokeAll() }
                    }
                }
                expanding - padded - recyclerView {
                    children(runlist) {
                        compact - button {
                            dynamicTheme { if (currentLotId.await() == it.await()._id) SelectedSemantic else null }
                            sellingLotShortDetails(it)
                            onClick {
                                dialogScreenNavigator.navigate(SellingDialogWrapper(VehicleDetailScreen(it.await()._id)))
                            }
                        }
                    }
                    reactiveScope {
                        rerunOn(jumpToCurrent)
                        val current = currentLotId.awaitOnce()
                        val index = runlist.awaitOnce().indexOfFirst { it._id == current }
                        if (index != -1) scrollToIndex(index, Align.Start)
                    }
                }
            }
        }
    }
}

class AuctioneerSpeechDelegate : GameDelegate() {
    val status = AnimatedChangingText(isTitle = true)
    val winningBid = AnimatedChangingText(isTitle = true)
    val winningBidBuyer = AnimatedChangingText()
    val comment = AnimatedChangingText()

    init {
        comment.announcementDestination.x = 500.0
        comment.announcementDestination.y = 900.0
        comment.destination.x = 500.0
        comment.destination.y = 900.0
        comment.fixedTextSize = 200.0
        comment.text = "Comment"
        comment.fullRadius = 500.0
        comment.endRadius = 400.0
        objects.add(comment)
        status.announcementDestination.x = 500.0
        status.announcementDestination.y = 750.0
        status.destination.x = 500.0
        status.destination.y = 750.0
        status.text = "Status"
        status.fullRadius = 700.0
        status.endRadius = 600.0
        objects.add(status)
        winningBid.announcementDestination.x = 500.0
        winningBid.announcementDestination.y = 400.0 - 100.0
        winningBid.destination.x = 500.0
        winningBid.destination.y = 400.0 - 100.0
        winningBid.text = "$100,000"
        winningBid.fixedTextSize = 280.0
        winningBid.fullRadius = 900.0
        winningBid.endRadius = 800.0
        objects.add(winningBid)
        winningBidBuyer.announcementDestination.x = 500.0
        winningBidBuyer.announcementDestination.y = 550.0 - 100.0
        winningBidBuyer.destination.x = 500.0
        winningBidBuyer.destination.y = 550.0 - 100.0
        winningBidBuyer.text = "Some Dealership"
        winningBidBuyer.fixedTextSize = 140.0
        winningBidBuyer.fullRadius = 600.0
        winningBidBuyer.endRadius = 500.0
        objects.add(winningBidBuyer)

        comment.text = "asdf"
        status.text = ""
        winningBid.text = ""
        winningBidBuyer.text = ""
    }

    fun comment(text: String) {
        val obj = CommentText()
        obj.current.x = (200..800).random().toDouble()
        obj.current.y = (600..900).random().toDouble()
        obj.fixedTextSize = 200.0
        obj.radius = 200.0
        obj.text = text
        objects.add(obj)
    }
}

class CrowdDelegate : GameDelegate() {
    class CrowdMember(val id: UUID) : PositionedGameObject() {
        override fun innerDraw(context: DrawingContext2D) = with(context) {
            fillPaint = Color.black
            beginPath()
            appendArc(0.0, -250.0, 200.0, Angle.zero, Angle.circle, true)
            closePath()
            fill()
            beginPath()
            appendArc(0.0, 100.0, 300.0, Angle.zero, Angle.circle, true)
            closePath()
            fill()
            fillRect(-300.0, 100.0, 600.0, 1200.0)
        }

        var jumping = 1.0
        var leaving = false
        fun leave() {
            leaving = true
        }

        var destYPos = 500.0
        override fun step(seconds: Double): Boolean {
            if (leaving) {
                current.y += seconds * 1000.0
            } else {
                if (jumping < 1.0) jumping += seconds
                if (current.y > destYPos) current.y -= seconds * 1000.0
                else if (jumping < 0.5) current.y -= seconds * 100.00
                else if (jumping < 1.0) current.y += seconds * 100.00
                else current.y = destYPos - 1.0
            }
            return current.y > 2000
        }
    }

    init {
        objects.add(object : GameObject {
            override fun draw(context: DrawingContext2D) {
//                context.fillPaint = appTheme.important().background
//                context.fillRect(worldLeft, worldHeight / 3, worldWidth, worldHeight / 3)
                context.fillPaint = Color.black
                context.fillRect(worldLeft, worldHeight * 2 / 3, worldWidth, worldHeight / 3)
            }
        })
        objects.add(CrowdMember(nullUuid).also { it.current.x = 500.0; it.current.y = 500.0; it.radius = 300.0 })
    }

    fun onBid(id: UUID) {
        objects.filterIsInstance<CrowdMember>().find { it.id == id }?.jumping = 0.0
    }

    fun crowdUpdate(participants: Set<UUID>) {
        val remaining = participants.toMutableSet()
        objects.toList().filterIsInstance<CrowdMember>().forEach {
            if (!remaining.remove(it.id)) {
                println("${it.id} is leaving")
                it.leave()
            }
        }
        for (id in remaining) {
            println("$id arriived")
            objects.add(CrowdMember(id).apply {
                radius = Random.nextDouble(200.0, 400.0)
//                current.x = 200.0
//                current.y = 500.0
                current.x = Random.nextDouble(worldLeft + worldWidth / 4, worldRight - worldWidth / 4)
                current.y = 1200.0
                destYPos = 500.0
            })
        }
    }
}