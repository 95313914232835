package com.ilussobsa.views

import com.ilussobsa.PriceInDollars
import com.ilussobsa.renderPriceInDollars
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.titledSection

@Routable("proposal")
class ProposalScreen: KiteUiScreen {
    override fun ViewWriter.render() {
        scrolls - col {
            var totalPoints = 0
            var totalPriceInDollars = 0
            var totalHours = 0
            fun lineItem(
                description: String,
                points: Int,
                hours: Int = points * 2 * 4,
                priceInDollars: PriceInDollars = hours * 150,
                details: ContainingView.() -> Unit = {}
            ) {
                totalPoints += points
                totalHours += hours
                totalPriceInDollars += priceInDollars
                col {
                    row {
                        expanding - text(description)
                        expanding - text((hours).toString() + " hours")
                        expanding - text {
                            content = (priceInDollars).renderPriceInDollars()
                            align = Align.End
                        }
                    }
                    col {
                        details()
                    } in withDefaultPadding
                }
            }

            gravity(Align.Center, Align.Stretch) - col {
                titledSection("User Interface") {
                    lineItem("Log In", 1)
                    lineItem("App-wide Navigation", 3)
                    titledSection("Buying") {
                        lineItem("Auction List", 1)
                        lineItem("Search", 3) {
                            subtext("Complexity increased due to saved searches")
                        }
                        lineItem("Buying Center", 2){
                            subtext("New in latest pass for better UX")
                        }
                    }
                    titledSection("Selling") {
                        lineItem("Selling Center", 2){
                            subtext("New in latest pass for better UX")
                        }
                        titledSection("Register Vehicle") {
                            subtext("Made of many subscreens")
                            lineItem("VIN Scan", 4)
                            lineItem("Pictures", 8)
                            lineItem("Year / make / model / trim" , 1)
                            lineItem("Mileage", 1)
                            lineItem("Transmission", 1)
                            lineItem("Colors", 1)
                            lineItem("Title / Branding", 2)
                            lineItem("Prices", 1)
                            lineItem("Keys", 1)
                            lineItem("Tires", 1)
                            lineItem("Issues", 4)
                            lineItem("Upgrades", 2)
                            lineItem("Consumer Rating", 1)
                        }
                    }
                    lineItem("Live Auction", 8) {
                        subtext("This is large due to both live communication and and animation focus.")
                    }
                    titledSection("Vehicle Detail") {
                        lineItem("Basics", 2)
                        lineItem("Bids + Graph", 2)
                        lineItem("Auctions", 2)
                        lineItem("Reviews", 1)
                    }
                    lineItem("View Dealership Profile", 2)
                    lineItem("Report Issues", 2)
                    lineItem("My Profile", 2)
                    lineItem("Settings", 1)
                }
                titledSection("Model Design and Back End") {
                    lineItem("Vehicle Relationship", 4) {
                        subtext("Merging of Proxy Bids and Favorites")
                    }
                    lineItem("User", 2)
                    lineItem("Dealership", 2)
                    lineItem("Dealership Group", 1) {
                        subtext("Introduced in new pass")
                    }
                    lineItem("Auction Line", 4){
                        subtext("Changed to represent dynamically changing rules for grouping vehicles to be sold to reduce manual work needed to run the platform")
                    }
                    lineItem("Vehicle", 6)  {
                        subtext("Decreased slightly as complexities were removed during modeling")
                    }
                    lineItem("Bid", 3)
                    lineItem("Notifications", 4)
                    lineItem("Reports", 2) {
                        subtext("Replaces reviews, which were previously unaccounted for.")
                    }
                    lineItem("Saved Search", 4) {
                        subtext("Were previously unaccounted for")
                    }
                    lineItem("Transport Request", 2){
                        subtext("Not previously accounted for here")
                    }
                    lineItem("Automatic Auctioneer", 6) {
                        subtext("Complexity uncovered during the latest pass")
                    }
                    subtext("Chat was removed")
                }
                titledSection("Design") {
                    lineItem("Branding and Application", 0, 10_000 / 150, 10_000)
                }
                titledSection("Total") {
                    row {
                        expanding - text("Total")
                        expanding - text("$totalHours hours")
                        expanding - text {
                            content = totalPriceInDollars.renderPriceInDollars()
                            align = Align.End
                        }
                    }
                }
            }
        }
    }
}