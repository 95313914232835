package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser
import com.lightningkite.GeoCoordinate
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import kotlinx.coroutines.*


@Routable("/dealership/{id}/manage/profile")
class DealershipManageProfileScreen(val id: UUID) : KiteUiScreen {

    override val title: Readable<String> = shared {
        listOfNotNull(
            Strings.dealershipProfile,
        ).joinToString(" ")
    }

    override fun ViewWriter.render() {
        val dealership = shared { currentSessionNullable.awaitNotNull().dealerships[id] }
        val name = Property("")
        val street = Property("")
        val city = Property("")
        val state = Property(UsState.AL)
        val zip = Property("")
        val description = Property("")
        val url = Property("")
        val makes = Property<Set<String>>(setOf())
        val profile = Property<ServerFile?>(null)

        val modification = shared {
            modification<Dealership> {
                it.name assign name()
                it.address assign Address(
                    street = street(),
                    city = city(),
                    state = state(),
                    zip = zip(),
                    geoCoordinate = GeoCoordinate(0.0, 0.0)
                )
                it.description assign description()
                it.url assign url()
                if (currentUser.awaitNotNull().role >= UserRole.Manager) {
                    it.makes assign makes()
                }
                it.profilePicture assign profile()
            }
        }

        suspend fun save() {
            try {
                val me = currentUser()?._id ?: return
                if (dealership()() == null) {
                    currentSession().dealerships.insert(
                        Dealership(
                            _id = id,
                            name = name.value,
                            description = description.value,
                            url = url.value,
                            address = Address(
                                street = street.value,
                                city = city.value,
                                state = state.value,
                                zip = zip.value,
                                geoCoordinate = GeoCoordinate(0.0, 0.0)
                            ),
                            makes = makes.value,
                            profilePicture = profile.value,
                            usedCarManager = me
                        )
                    )
                    currentSession().users.totallyInvalidate()
                } else {
                    dealership().modify(modification())
                }
                screenNavigator.goBack()
            } catch (e: Exception) {
                e.report("DealershipManageProfileScreen")
                alert(Strings.error, Strings.thereWasAnErrorUpdatingThisProfile)
            }
        }
        pageWithMaxWidth(80.rem) {
            scrolls - col {
                launch {
                    dealership().awaitNotNull().let {
                        name.value = it.name
                        url.value = it.url ?: ""
                        description.value = it.description
                        street.value = it.address.street
                        city.value = it.address.city
                        state.value = it.address.state
                        makes.value = it.makes
                        zip.value = it.address.zip
                        profile.value = it.profilePicture
                    }
                }
                h1 {
                    ::content { name().takeUnless { it.isBlank() } ?: Strings.newDealership }
                }
                card - col {
                    h2(Strings.basicInformation)
                    row {
                        label {
                            content = Strings.profilePicture
                            card - button {
                                centered - sizeConstraints(width = 5.rem, height = 5.rem) - image {
                                    ::source { profile()?.toImage() ?: Icon.person.toImageSource(Color.gray) }
                                    this.description = ""
                                    scaleType = ImageScaleType.Crop
                                }
                                onClick {
                                    ExternalServices.requestFile(listOf("image/png", "image/jpeg")) { image ->
                                        if (image == null) return@requestFile
                                        launch {
                                            val session = currentSessionNullable.await() ?: return@launch
                                            try {
                                                val futureCallToken =
                                                    session.uploadFileForRequest().upload(RequestBodyFile(image))
                                                tempImagePointers[futureCallToken] = ImageLocal(image)
                                                profile.value = futureCallToken
                                            } catch (e: Exception) {
                                                println("Error updating profile picture")
                                                e.report("DealershipManageProfileScreen upload image")
                                                alert(Strings.error, Strings.failedToUploadPicture)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        expanding - label {
                            content = Strings.dealershipName
                            fieldTheme - textField {
                                content bind name
                            }
                        }
                    }
                    rowCollapsingToColumn(50.rem) {
                        weight(2f) - label {
                            content = Strings.streetAddress

                            fieldTheme - textField {
                                content bind street
                            }
                        }
                        weight(1f) - label {
                            content = Strings.city
                            fieldTheme - textField {
                                content bind city
                            }
                        }
                        weight(1f) - label {
                            content = Strings.state
                            fieldTheme - select {
                                bind(
                                    state,
                                    Constant(UsState.entries),
                                    render = {
                                        it.toString()
                                    }
                                )
                            }
                        }
                        weight(1f) - label {
                            content = Strings.zip
                            fieldTheme - textInput {
                                content bind zip
                            }
                        }
                    }
                    label {
                        content = Strings.description
                        fieldTheme - textArea {
                            content bind description
                        }

                    }
                    label {
                        content = "URL"
                        fieldTheme - textField {
                            content bind url
                            hint = "https://mydealership.com"
                        }
                    }
                    label {
                        content = Strings.dealershipSellsMakes
                        stack {
                            text {
                                ::exists { currentUser.awaitNotNull().role < UserRole.Manager }
                                ::content { dealership().awaitNotNull().makes.sorted().joinToString(",") }
                            }
                            val makesGetter =
                                shared { currentSession().makes.query(Query(orderBy = sort { it._id.ascending() })) }
                            multiselect(
                                query = { input ->
                                    val makes = makesGetter()().map { it._id }
                                    input.takeUnless { it.isBlank() }?.let { s ->
                                        makes.filter { it.contains(s, true) }
                                    } ?: makes
                                },
                                draw = { it },
                                items = makes,
                                setup = {
                                    ::exists { currentUser.awaitNotNull().role >= UserRole.Manager }
                                }
                            )
                        }
                    }
                    row {
                        expanding - space()
                        card - button {
                            text(Strings.cancel)
                            onClick { screenNavigator.goBack() }
                        }
                        important - button {
                            infix fun Dealership.changed(other: Dealership): Boolean {
                                if(this.name != other.name) return true
                                if(this.address.street != other.address.street) return true
                                if(this.address.city != other.address.city) return true
                                if(this.address.state != other.address.state) return true
                                if(this.address.zip != other.address.zip) return true
                                if(this.description != other.description) return true
                                if(this.url != other.url) return true
                                if(this.makes != other.makes) return true
                                if(this.profilePicture?.location?.substringBefore('?') != other.profilePicture?.location?.substringBefore('?')) return true
                                return false
                            }
                            text {
                                ::content {
                                    val d = dealership()()
                                    if (d == null || (modification()(d) changed d)) {
                                        Strings.save
                                    } else Strings.saved
                                }
                            }
                            ::enabled {
                                val d = dealership()()
                                d == null || modification()(d) changed d
                            }
                            onClick(action = ::save)
                        }
                    }
                }
            }
        }
    }
}

suspend fun UploadInformation.upload(requestBody: RequestBody): ServerFile {
    val futureCallToken = ServerFile(futureCallToken)
    val uploadUrl = uploadUrl
    val response = fetch(
        url = uploadUrl,
        method = HttpMethod.PUT,
        body = requestBody
    )
    return futureCallToken
}