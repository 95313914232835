package com.ilussobsa.views.dialogs

import com.ilussobsa.Strings
import com.ilussobsa.User
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.report
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.delay


class RemoveUserFromDealership(val id: UUID, val user: Readable<User>) : KiteUiScreen {
    val dealership = shared { currentSessionNullable.awaitNotNull().dealerships[id]() }
    override fun ViewWriter.render() {
        dismissBackground {
            centered - card - col {
                h2(Strings.removeUser)
                text { ::content{ Strings.thisActionWillRemoveTheUserFromX(dealership.awaitNotNull().name) } }
                gravity(Align.Center, Align.Center) - row {
                    danger - button {
                        h6(Strings.remove)
                        onClick {
                            try {
                                val dealership = dealership.awaitNotNull()
                                val user = user.awaitNotNull()
                                currentSession().dealership.removeUserFromDealership(
                                    dealership._id,
                                    user.email
                                )
                                delay(200)
                                currentSession().users.localSignalUpdate(
                                    matching = { it._id == user._id },
                                    modify = { it.copy(managesDealerships = it.managesDealerships - dealership._id) }
                                )
                                this.screenNavigator.dismiss()
                            } catch (e: Exception) {
                                e.report()
                                alert(
                                    Strings.error,
                                    Strings.thereWasAnErrorRemovingTheUser
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}