package com.ilussobsa.views

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*

@Routable("/dealership/{id}")
class ProfileScreen(val id: UUID) : KiteUiScreen {
    override fun ViewWriter.render() {
      dealershipProfile(id)
    }
}