package com.ilussobsa.sdk

import com.ilussobsa.*
import com.ilussobsa.views.LogInScreen
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import kotlin.time.Duration.Companion.minutes
import kotlinx.coroutines.*
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlinx.serialization.builtins.nullable

class UserSession(
    override val api: Api,
    override val userToken: String,
    override val userAccessToken: suspend () -> String
) : AbstractUserSession(api, userToken, userAccessToken) {
    fun refresh() {
        users.totallyInvalidate()
        notifications.totallyInvalidate()
        dealerships.totallyInvalidate()
        dealershipRelationships.totallyInvalidate()
        auctions.totallyInvalidate()
        liveAuctionDataCache.totallyInvalidate()
        bids.totallyInvalidate()
        vehicleRelationships.totallyInvalidate()
        vehicles.totallyInvalidate()
        savedSearches.totallyInvalidate()
        sellerTalkingPoints.totallyInvalidate()
        transportRequests.totallyInvalidate()
        makes.totallyInvalidate()
        models.totallyInvalidate()
        subscriptionPayments.totallyInvalidate()
        dealershipJoinRequests.totallyInvalidate()
    }

    val userId = asyncGlobal { userAuth.getSelf()._id }
    val me = shared { users.get(userId()) }.flatten()
    val users = ModelCache(user, User.serializer(), cacheTime = 1.minutes)
    val notifications = ModelCache(notification, Notification.serializer(), cacheTime = 1.minutes)
    val dealerships = ModelCache(dealership, Dealership.serializer(), cacheTime = 1.minutes)
    val dealershipRelationships =
        ModelCache(dealershipRelationship, DealershipRelationship.serializer(), cacheTime = 1.minutes)
    val auctions = ModelCache(auctionLane, AuctionLane.serializer(), cacheTime = 1.minutes)
    val liveAuctionDataCache = ModelCache(liveAuctionData, LiveAuctionData.serializer(), cacheTime = 1.minutes)
    val bids = ModelCache(bid, Bid.serializer(), cacheTime = 1.minutes)
    val vehicleRelationships = ModelCache(vehicleRelationship, VehicleRelationship.serializer(), cacheTime = 1.minutes)
    val vehicles = ModelCache(vehicle, Vehicle.serializer(), cacheTime = 1.minutes) { v ->
        vehicleRelationships.localSignalUpdate(
            matching = { it._id.vehicle == v._id },
            modify = { it.copy(vehicleDenormalizedInfo = v.short()) }
        )
    }
    val savedSearches = ModelCache(savedSearch, SavedSearch.serializer(), cacheTime = 1.minutes)
    val sellerTalkingPoints = ModelCache(sellerTalkingPoint, SellerTalkingPoint.serializer(), cacheTime = 1.minutes)
    val transportRequests = ModelCache(transportRequest, TransportRequest.serializer(), cacheTime = 1.minutes)
    val makes = ModelCache(make, Make.serializer(), cacheTime = 1.minutes)
    val models = ModelCache(model, Model.serializer(), cacheTime = 1.minutes)
    val subscriptionPayments = ModelCache(subscriptionPayment, SubscriptionPayment.serializer(), cacheTime = 1.minutes)
    val dealershipJoinRequests =
        ModelCache(dealershipJoinRequest, DealershipJoinRequest.serializer(), cacheTime = 1.minutes)

    init {
        launchGlobal {
            val me = me.await() ?: return@launchGlobal
            val dealerships = dealerships.query(Query(condition = condition {
                it._id.inside(me.managesDealerships) and it.active.neq(false)
            }))()
            if (currentDealershipId.await().let { it == null || !(me.role >= UserRole.Manager || it in dealerships.map { it._id }.toSet()) }) {
                val it = dealerships.firstOrNull()?._id
                currentDealershipId set it
            }
        }
    }
}

val currentSessionNullable = shared<UserSession?> {
    val refresh = sessionToken.await() ?: run {
        println("Session token not found!!")
        return@shared null
    }
    val api = selectedApi.await().api

    var lastRefresh: Instant = now()

    fun tokenGetter() = asyncGlobal {
        try {
            api.userAuth.getTokenSimple(refresh)
        } catch (e: LsErrorException) {
            if (e.status == 400.toShort()) {
                println("Got a 400; we think we've messed up our auth")
                sessionToken.set(null)
                mainNavigatorGlobalIsBad.navigate(LogInScreen())
            }
            throw e
        }
    }
    var token: Async<String> = tokenGetter()

    val generateToken = suspend {
        if (lastRefresh <= now().minus(4.minutes)) {
            lastRefresh = now()
            token = tokenGetter()
        }
        token.await()
    }
    UserSession(
        api = api,
        userToken = refresh,
        userAccessToken = generateToken
    )
}

val ViewWriter.currentSession get() = shared {
    val result = currentSessionNullable.await()
    if (result == null) {
        if(mainScreenNavigator.stack.value.lastOrNull() !is LogInScreen) {
            println("Kicking ya out!")
            mainScreenNavigator.reset(LogInScreen())
        }
        throw CancelledException("Failed")
    }
    result
}

//suspend fun ViewWriter.clearSession() {
//    try {
//        currentSession.await()?.userSession?.terminateSession()
//    } catch (e: Exception) {
//        /*squish*/
//    }
//    println("Session cleared")
//    sessionToken set null
//}


val currentDealershipId = PersistentProperty<UUID?>("currentDealershipId", null, UUIDSerializer.nullable)

val sessionToken = PersistentProperty<String?>("sessionToken", null)

val currentUser = shared { currentSessionNullable.awaitNotNull().me }.flatten()
val currentDealership =
    shared {
        currentDealershipId.await()?.let { id -> currentSessionNullable.awaitNotNull().dealerships.get(id) } ?: Constant(
            null
        )
    }.flatten()
val myDealerships = shared { currentSessionNullable.awaitNotNull().me.await()?.managesDealerships ?: setOf() }
val preferredDealerships = shared {
    currentSessionNullable.awaitNotNull().dealershipRelationships.watch(Query(limit = 1000, condition = condition {
        it._id.from.eqNn(
            currentDealershipId.await()
        )
    })).await().map { it._id.to }.toSet()
}
