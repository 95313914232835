// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDealershipVehiclePairFields() {
    val props: Array<SerializableProperty<DealershipVehiclePair, *>> = arrayOf(DealershipVehiclePair_dealership, DealershipVehiclePair_vehicle)
    DealershipVehiclePair.serializer().properties { props }
}
val <K> DataClassPath<K, DealershipVehiclePair>.dealership: DataClassPath<K, UUID> get() = this[DealershipVehiclePair_dealership]
val <K> DataClassPath<K, DealershipVehiclePair>.vehicle: DataClassPath<K, UUID> get() = this[DealershipVehiclePair_vehicle]
inline val DealershipVehiclePair.Companion.path: DataClassPath<DealershipVehiclePair, DealershipVehiclePair> get() = path<DealershipVehiclePair>()


object DealershipVehiclePair_dealership: SerializableProperty<DealershipVehiclePair, UUID> {
    override val name: String = "dealership"
    override fun get(receiver: DealershipVehiclePair): UUID = receiver.dealership
    override fun setCopy(receiver: DealershipVehiclePair, value: UUID) = receiver.copy(dealership = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DealershipVehiclePair.serializer().tryFindAnnotations("dealership")
}
object DealershipVehiclePair_vehicle: SerializableProperty<DealershipVehiclePair, UUID> {
    override val name: String = "vehicle"
    override fun get(receiver: DealershipVehiclePair): UUID = receiver.vehicle
    override fun setCopy(receiver: DealershipVehiclePair, value: UUID) = receiver.copy(vehicle = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DealershipVehiclePair.serializer().tryFindAnnotations("vehicle")
}
