package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.modification
import com.lightningkite.now
import com.lightningkite.serialization.notNull
import kotlinx.coroutines.launch

@Routable("vehicle/{id}/complete/buyer")
class SellingCompleteBuyerScreen(val id: UUID) : KiteUiScreen {
    val inaccuracyReport = IssueComponent(Strings.theVehiclesDescriptionWasNotAccurate)
    val roughBuyReport = IssueComponent(Strings.theyMadePayingForAndReceivingTheVehicle)
    val sellerDidNotHonor = SimpleIssueComponent(Strings.dealCancelled, Strings.reportWillCancelTransaction)
    override fun ViewWriter.render() {
        dismissBackground {

            launch {
                val vehicle = currentSession().vehicles.get(id).awaitNotNull()
                roughBuyReport.wasIssue set (vehicle.roughBuyReport != null)
                roughBuyReport.explanation set (vehicle.roughBuyReport?.description ?: "")
                roughBuyReport.severity set (vehicle.roughBuyReport?.severity ?: Severity.Minor)
                inaccuracyReport.wasIssue set (vehicle.inaccuracyReport != null)
                inaccuracyReport.explanation set (vehicle.inaccuracyReport?.description ?: "")
                inaccuracyReport.severity set (vehicle.inaccuracyReport?.severity ?: Severity.Minor)
            }

            centered - scrolls - card -  col {
                spacing = 2.rem
                h1(Strings.reportIssues)
                inaccuracyReport.render(this)
                roughBuyReport.render(this)
                sellerDidNotHonor.render(this)
                reactiveScope {
                    listOf(inaccuracyReport, roughBuyReport).forEach {
                        if (sellerDidNotHonor.wasIssue()) {
                            it.wasIssue.value = false
                        }
                        it.enabled.value = !sellerDidNotHonor.wasIssue()
                    }
                }
                row {
                    expanding - space()
                    danger - button {
                        text(Strings.removeIssueReport)
                        onClick {
                            currentSessionNullable.awaitNotNull().vehicles.get(id).modify(modification {
                                it.roughBuyReport assign roughBuyReport.reset().report()
                                it.inaccuracyReport assign inaccuracyReport.reset().report()
                            })
                            navigator.dismiss()
                        }
                    }
                    important - button {
                        text(Strings.submitIssueReport)
                        onClick {
                            currentSessionNullable.awaitNotNull().vehicles.get(id).modify(modification {
                                if (sellerDidNotHonor.wasIssue()) {
                                    it.cancelled assign now()
                                    it.cancellationReason assign CancellationReason.CANCELLED_BY_BUYER
                                    it.completion.notNull.sold assign false
                                    it.roughSellReport assign null
                                    it.roughBuyReport assign null
                                    it.inaccuracyReport assign null
                                } else {
                                    it.roughBuyReport assign roughBuyReport.report()
                                    it.inaccuracyReport assign inaccuracyReport.report()
                                }
                            })
                            navigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}