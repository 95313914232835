package com.ilussobsa.views

import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.utils.checkCircle
import com.ilussobsa.utils.emptyCircle
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


@Routable("/tutorials")
class TutorialsScreen() : Screen {
    val checklist = shared {
        TutorialItem.values().map { it to (currentSessionNullable()?.me?.await()?.tutorialsComplete?.contains(it.name) ?: false) }
    }
    override val title: Readable<String>
        get() = shared { Strings.tutorialsXY(checklist().count { it.second }, TutorialItem.values().size) }
    override fun ViewWriter.render() {
        col {
            expanding - recyclerView {
                ::columns { (WindowInfo().width.px / 350).toInt() + 1 }
                children(checklist) {
                    card - link {
                        spacing = 0.px
                        card - col {
                            spacing = 0.px
                            padded - stack {
                                centered - icon {
                                    ::source { it().first.icon.copy(width = 5.rem, height = 5.rem) }
                                }
                            }
                            card - row {
                                centered - icon {
                                    ::source { if(it().second) Icon.checkCircle else Icon.emptyCircle }
                                }
                                centered - text { ::content { it().first.title } }
                            }
                        }
                        ::to { val id = it().first; { TutorialScreen(id) } }
                    }
                }
            }
        }
    }
}