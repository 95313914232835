// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareIssueReportFields() {
    val props: Array<SerializableProperty<IssueReport, *>> = arrayOf(IssueReport_description, IssueReport_severity, IssueReport_attachments)
    IssueReport.serializer().properties { props }
}
val <K> DataClassPath<K, IssueReport>.description: DataClassPath<K, String> get() = this[IssueReport_description]
val <K> DataClassPath<K, IssueReport>.severity: DataClassPath<K, Severity> get() = this[IssueReport_severity]
val <K> DataClassPath<K, IssueReport>.attachments: DataClassPath<K, List<ServerFile>> get() = this[IssueReport_attachments]
inline val IssueReport.Companion.path: DataClassPath<IssueReport, IssueReport> get() = path<IssueReport>()


object IssueReport_description: SerializableProperty<IssueReport, String> {
    override val name: String = "description"
    override fun get(receiver: IssueReport): String = receiver.description
    override fun setCopy(receiver: IssueReport, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = IssueReport.serializer().tryFindAnnotations("description")
}
object IssueReport_severity: SerializableProperty<IssueReport, Severity> {
    override val name: String = "severity"
    override fun get(receiver: IssueReport): Severity = receiver.severity
    override fun setCopy(receiver: IssueReport, value: Severity) = receiver.copy(severity = value)
    override val serializer: KSerializer<Severity> = Severity.serializer()
    override val annotations: List<Annotation> = IssueReport.serializer().tryFindAnnotations("severity")
    override val default: Severity = Severity.Minor
}
object IssueReport_attachments: SerializableProperty<IssueReport, List<ServerFile>> {
    override val name: String = "attachments"
    override fun get(receiver: IssueReport): List<ServerFile> = receiver.attachments
    override fun setCopy(receiver: IssueReport, value: List<ServerFile>) = receiver.copy(attachments = value)
    override val serializer: KSerializer<List<ServerFile>> = ListSerializer(ContextualSerializer(ServerFile::class))
    override val annotations: List<Annotation> = IssueReport.serializer().tryFindAnnotations("attachments")
    override val default: List<ServerFile> = listOf()
}
