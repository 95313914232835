// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDamageFields() {
    val props: Array<SerializableProperty<Damage, *>> = arrayOf(Damage_comment, Damage_image)
    Damage.serializer().properties { props }
}
val <K> DataClassPath<K, Damage>.comment: DataClassPath<K, String> get() = this[Damage_comment]
val <K> DataClassPath<K, Damage>.image: DataClassPath<K, ServerFile> get() = this[Damage_image]
inline val Damage.Companion.path: DataClassPath<Damage, Damage> get() = path<Damage>()


object Damage_comment: SerializableProperty<Damage, String> {
    override val name: String = "comment"
    override fun get(receiver: Damage): String = receiver.comment
    override fun setCopy(receiver: Damage, value: String) = receiver.copy(comment = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Damage.serializer().tryFindAnnotations("comment")
}
object Damage_image: SerializableProperty<Damage, ServerFile> {
    override val name: String = "image"
    override fun get(receiver: Damage): ServerFile = receiver.image
    override fun setCopy(receiver: Damage, value: ServerFile) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = Damage.serializer().tryFindAnnotations("image")
}
