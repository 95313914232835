package com.ilussobsa

import kotlinx.browser.window

actual fun getDefaultServerBackend(): ApiOption {
    val host = window.location.hostname
    return when {
        host.contains("lightningkite") -> ApiOption.Dev
        host.contains("stag") -> ApiOption.Staging
        host.contains("localhost") -> ApiOption.Local
        host.contains("jivie") -> ApiOption.Jivie
        host.contains("demo2") -> ApiOption.Demo2
        host.contains("demo") -> ApiOption.Demo
        else -> ApiOption.Prod
    }.also { println("Host is $host, default is $it") }
}