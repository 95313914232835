package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.UUID
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import com.lightningkite.uuid
import kotlin.time.Duration.Companion.days

@Routable("/dealerships")
class DealershipsScreen() : KiteUiScreen {

    @QueryParameter("preferredOnly")
    val preferredOnly = Property<Boolean>(false)

    @QueryParameter("sort")
    val sort = Property<List<SortPart<Dealership>>>(sort { it.name.ascending() })

    @QueryParameter("query")
    val search = Property<String>("")

    @QueryParameter("distanceLimit")
    val distanceLimit = Property<Int?>(null)

    @QueryParameter("selectedDealership")
    val selectedDealership = Property<UUID?>(null)

    private val showOnlyInactive = Property(false)

    override val title: Readable<String> = shared {
        listOfNotNull(
            Strings.dealerships,
        ).joinToString(" ")
    }
    val query = shared {
        Query(
            condition<Dealership> {
                Condition.And(
                    listOfNotNull(
                        search.debounce(500L).await().takeUnless { it.isBlank() }
                            ?.let { s ->
                                Condition.And(s.split(' ').filter { it.isNotBlank() }.map { s ->
                                    Condition.Or(
                                        listOf(
                                            it.name.contains(s, true),
                                            it.address.city.contains(s, true),
                                            it.address.street.contains(s, true),
                                        )
                                    )
                                })
                            },
                        if (preferredOnly.await()) it._id inside preferredDealerships.await() else null,
                        distanceLimit.await()?.let { limit ->
                            it.address.geoCoordinate.distanceBetween(
                                currentDealership.awaitNotNull().address.geoCoordinate,
                                lessThan = limit.miles
                            )
                        },
                        showOnlyInactive().let { x -> if (x) it.active eq false else it.active neq false },
                    )
                )
            },
            orderBy = sort.await(),
            limit = 500,
        )
    }

    override fun ViewWriter.render() {
        col {
            var lastCurrent: UUID? = null
            reactiveScope {
                val c = currentDealershipId.await()
                if (selectedDealership.value == null || selectedDealership.value == lastCurrent) selectedDealership.value = c
                lastCurrent = c
            }
            tabTheme - scrollsHorizontally - row {

                compact - row {
                    centered - checkbox {
                        checked bind preferredOnly
                    }
                    centered - text(Strings.preferredOnly)
                }
                separator()
                distanceFilter(distanceLimit)
                onlyWhen { currentUser()?.role?.let { it >= UserRole.Manager } ?: false } - row {
                    separator()
                    toggleButton {
                        text(Strings.inactive)
                        checked bind showOnlyInactive
                    }
                    select {
                        bind(
                            sort, Constant(
                                listOf(
                                    sort<Dealership> { it.name.ascending() },
                                    sort<Dealership> { it.createdAt.descending() },
                                    sort<Dealership> { it.lastPayment.ascending() },
                                )
                            )
                        ) {
                            when (it) {
                                sort<Dealership> { it.name.ascending() } -> "Name A-Z"
                                sort<Dealership> { it.createdAt.descending() } -> "Created At New-Old"
                                sort<Dealership> { it.lastPayment.ascending() } -> "Last Payment Old-New"
                                else -> "Unknown Sort"
                            }
                        }
                    }
                }
            }


            val toListen = shared {
                currentSessionNullable.awaitNotNull().dealerships.query(query.await())
            }
            expanding - listDetailV2(
                selected = selectedDealership,
                list = { view ->
                    col {
                        card - row {
                            centered - sizeConstraints(
                                width = 1.5.rem,
                                height = 1.5.rem
                            ) - icon(
                                Icon.search,
                                Strings.search
                            )

                            centered - expanding - textField {
                                hint = Strings.search
                                content bind search
                            }
                            centered - compact - button {
                                ::exists { search.await().isNotEmpty() }
                                sizeConstraints(width = 1.5.rem, height = 1.5.rem) - icon(
                                    Icon.close,
                                    Strings.clearSearch
                                )
                                onClick {
                                    search set ""
                                }
                            }
                            onlyWhen {
                                val u = currentUser() ?: return@onlyWhen false
                                u.role >= UserRole.Manager
                            } - centered - button {
                                icon {
                                    source = Icon.add
                                    description = "Update"
                                }
                                onClick {
                                    mainScreenNavigator.navigate(DealershipManageProfileScreen(uuid()))
                                }
                            }
                        }
                        expanding - navList - stack {
                            val items = shared { toListen.await().await() }
                            recyclerView {
                                children(items) {
                                    psuedoRadioToggleButton(shared { it()?._id }, selectedDealership, view) {
                                        dealershipShortDetails(it)
                                    }
                                }
                            }
                            centered - text {
                                content = Strings.noDealershipsMatchedTheseConditions
                                exists = false
                                ::exists { items.await().isEmpty() }
                            }
                        }
                    }
                },
                detail = {
                    if (it == null) object : Screen {
                        override fun ViewWriter.render() {
                            nothingSelected(Strings.pleaseSelectADealershipOnTheLeft)
                        }
                    } else DealershipProfileScreen(it)
                }
            )
        }
    }

    enum class DealerContext(val buy: Boolean, val sell: Boolean) {
        Buy(true, false),
        Sell(false, true),
        Both(true, true)
    }
}

fun ViewWriter.dealershipProfile(selectedDealership: UUID, backButtonInfo: BackButtonInfo? = null) {
    val selected = shared { currentSessionNullable.awaitNotNull().dealerships.get(selectedDealership)() }
    scrolls - col {
        card - row {
            backButtonIfNeeded(backButtonInfo)
            expanding - centered - rowCollapsingToColumn(30.rem) {
                centered - sizeConstraints(width = 5.rem, height = 5.rem) - image {
                    ::source {
                        selected.awaitNotNull().profilePicture?.location?.let(::ImageRemote)
                            ?: Icon.person.toImageSource(Color.gray)
                    }
                    this.description = ""
                    scaleType = ImageScaleType.Crop
                }
                compact - col {
                    h2 {
                        ::content { selected.awaitNotNull().name }
                    }
                    row {
                        centered - icon(
                            Icon.starFilled.copy(width = 1.rem, height = 1.rem),
                            Strings.preferredDealershipQuestionMark
                        )
                        centered - text {
                            ::content { Strings.preferredByXOtherDealerships(selected.await()?.stars ?: 0) }
                        }
                    }
                }
            }
            gravity(Align.End, Align.Center) - row {
                onlyWhen {
                    selectedDealership != currentDealershipId()
                } - centered - toggleButton {
                    icon {
                        val dr = shared {
                            currentDealershipId()?.let {
                                currentSessionNullable.await()?.dealershipRelationships?.get(
                                    DealershipPair(currentDealershipId.awaitNotNull(), selectedDealership)
                                )
                            }
                        }

                        val isPreferred = shared {
                            dr()?.await() != null
                        }.withWrite {
                            if (it) {
                                currentSessionNullable.awaitNotNull().dealershipRelationships.insert(
                                    DealershipRelationship(
                                        DealershipPair(
                                            currentDealershipId.awaitNotNull(),
                                            selectedDealership
                                        )
                                    )
                                )
                            } else {
                                dr()?.delete()
                            }
                        }
                        ::source {
                            if (isPreferred()) Icon.starFilled
                            else Icon.star
                        }
                        description = Strings.preferredDealershipQuestionMark
                        checked bind isPreferred
                    }
                }

                onlyWhen {
                    val u = currentUser() ?: return@onlyWhen false
                    u.role >= UserRole.Manager || selectedDealership in u.managesDealerships
                } - centered - link {
                    icon {
                        source = Icon.edit
                        description = "Update"
                    }
                    to = { DealershipManageProfileScreen(selectedDealership) }
                }

                onlyWhen {
                    val u = currentUser() ?: return@onlyWhen false
                    u.role >= UserRole.Manager || selectedDealership in u.managesDealerships
                } - centered - link {
                    icon {
                        source = Icon.group
                        description = "Update People"
                    }
                    to = { DealershipManagePeopleScreen(selectedDealership) }
                }
            }
        }

        val showAdmin = shared {
            val u = currentUser() ?: return@shared false
            u.role >= UserRole.Manager
        }
        onlyWhen { showAdmin() } - card - col {
            h3("Admin")
            rowCollapsingToColumn(60.rem) {
                expanding - buttonTheme - button {
                    row {
                        centered - icon(Icon.person, "")
                        centered - text("Masquerade")
                    }
                    onClick {
                        currentDealershipId set selectedDealership
                    }
                }

                expanding - buttonTheme - button {
                    row {
                        centered - icon(Icon.money, "Payment Information")
                        centered - text {
                            ::content {
                                if(selected()?.subscriptionId == null) "Payment not started"
                                else "Open Subscription in Stripe"
                            }
                        }
                    }
                    onClick {
                        val sel = selected() ?: return@onClick
                        if (sel.subscriptionId == null) {
                            currentSession().let {
                                val result = it.subscription.createSubscription(sel._id)
                                it.dealerships.localSignalUpdate(
                                    matching = { it._id == sel._id },
                                    modify = { result }
                                )
                                alert("Subscription Started", "Their subscription has been started and they've been emailed.")
                            }
                        } else {
                            val url = (currentSession().api as? LiveApi)?.httpUrl ?: return@onClick
                            ExternalServices.openTab("$url/subscriptions/admin-open/${sel.subscriptionId}")
                        }
                    }
                }

                expanding - row {
                    centered - switch {
                        checked bind shared { selected()?.active ?: true }
                            .withWrite { value ->
                                currentSession().dealerships.get(selectedDealership)
                                    .modify(modification { it.active assign value })
                            }
                    }
                    centered - expanding - text("Active")
                }
            }
            rowCollapsingToColumn(60.rem) {
                expanding - text {
                    reactiveSuspending {
                        if(!showAdmin()) {
                            content = ""
                            return@reactiveSuspending
                        }
                        val total = currentSession().vehicles.skipCache.count(condition { it.seller.eq(selectedDealership) and it.completion.notNull.sold.eq(true) })
                        val failed = currentSession().vehicles.skipCache.count(condition { it.seller.eq(selectedDealership) and it.cancellationReason.inside(setOf(
                                    CancellationReason.CANCELLED_BY_BUYER,
                                    CancellationReason.CANCELLED_BY_SELLER,
                        )) })
                        content = Strings.cancelledSales(failed)
                    }
                }
                expanding - text {
                    reactiveSuspending {
                        if(!showAdmin()) {
                            content = ""
                            return@reactiveSuspending
                        }
                        val total = currentSession().vehicles.skipCache.count(condition { it.completion.notNull.winner.eq(selectedDealership) and it.completion.notNull.sold.eq(true) })
                        val failed = currentSession().vehicles.skipCache.count(condition { it.completion.notNull.winner.eq(selectedDealership) and it.cancellationReason.inside(setOf(
                                    CancellationReason.CANCELLED_BY_BUYER,
                                    CancellationReason.CANCELLED_BY_SELLER,
                        )) })
                        content = Strings.cancelledBuys(failed)
                    }
                }
            }
        }

        rowCollapsingToColumn(50.rem) {
            fun grade(
                icon: Icon,
                title: String,
                hint: String,
                getterA: (Dealership) -> Grade,
                getterB: (Grade) -> String
            ) =
                card - stack {
                    col {
                        centered - themeFromLast {
                            val colors = listOf(Color.fromHexString("#ffcf40"), Color.fromHexString("#ffdc73"))
                            it.copy(
                                font = it.font.copy(size = 4.rem),
                                spacing = 0.rem,
                                foreground = colors.first().highlight(1f),
                                background = LinearGradient(colors.mapIndexed { index, color ->
                                    GradientStop(
                                        index / colors.size.toFloat(),
                                        color
                                    )
                                }, Angle.eighthTurn),
                                cornerRadii = CornerRadii.RatioOfSize(),
                                elevation = 0.25.rem
                            )
                        } - sizeConstraints(width = 6.rem, height = 6.rem) - stack {
                            centered - text {
                                ::content {
                                    selected.await()?.let(getterA)?.let {
                                        if (it == Grade.Unknown) Grade.A else it
                                    }?.display ?: "-"
                                }
                            }
                        }
                        centered - row {
                            centered - icon(icon, "")
                            centered - h5(title)
                        }
                        centered - text {
                            ::content {
                                selected.await()?.let(getterA)?.let {
                                    if (it == Grade.Unknown) Grade.A else it
                                }?.let(getterB) ?: ""
                            }
                        }
                    }
                    atTopEnd - menuButton {
                        icon(
                            Icon.info,
                            Strings.moreInfo
                        )
                        opensMenu {
                            sizeConstraints(width = 30.rem) - text(hint)
                        }
                    }
                }
            expanding - grade(
                Icon.accuracy,
                Strings.accuracy,
                Strings.ratingHintAccuracy,
                { it.accuracyGrade },
                { it.let { Strings.fullTextAccuracy(it) } })
            expanding - grade(
                Icon.sell,
                Strings.sellingTransaction,
                Strings.ratingHintSelling,
                { it.sellingTransactionGrade },
                { it.let { Strings.fullTextSellingTransaction(it) } })
            expanding - grade(
                Icon.buy,
                Strings.buyingTransaction,
                Strings.ratingHintBuying,
                { it.buyingTransactionGrade },
                { it.let { Strings.fullTextBuyingTransaction(it) } })
        }
        card - col {
            h3("Contacts")
            scrollsHorizontally - row {
                contactInfo(
                    Strings.usedCarManager,
                    shared { currentSession().users[selected.awaitNotNull().usedCarManager].awaitNotNull() })
                contactInfo(
                    Strings.titleManager,
                    shared { currentSession().users[selected.awaitNotNull().titleManager].awaitNotNull() })
                contactInfo(
                    Strings.transportContact,
                    shared { currentSession().users[selected.awaitNotNull().transportManager].awaitNotNull() })
                onlyWhen { currentUser()?.role?.let { it >= UserRole.Manager } ?: false } - contactInfo(
                    Strings.billingContact,
                    shared { currentSession().users[selected.awaitNotNull().billingContact].awaitNotNull() })
            }
        }
        card - vehicleSection(Strings.comingNextAuction, filter = {
            condition {
                it.seller.eq(selectedDealership) and it.completion.eq(null) and it.submitted.neq(null)
            }
        }, seeMore = {
            {
                SearchScreen().apply {
                    this.limitToSeller.value = selectedDealership
                }
            }
        })
        val now = now()
        card - vehicleSection(Strings.recentlySold1, filter = {
            condition {
                it.seller.eq(selectedDealership) and it.completion.notNull.at.gt(now - 7.days)
            }
        }, seeMore = null)
    }
}

fun ViewWriter.dealershipShortDetails(
    it: Readable<Dealership>,
    context: DealershipsScreen.DealerContext = DealershipsScreen.DealerContext.Both
) = row {
    centered - sizeConstraints(width = 4.rem, height = 4.rem) - image {
        ::source {
            it.await().profilePicture?.let { ImageRemote(it.location) }
                ?: Icon.person.toImageSource(Color.gray)
        }
        this.description = ""
        scaleType = ImageScaleType.Crop
    }
    compact - col {
        HeaderSemantic.onNext - text {
            ::content { it.await().name }
        }
        dealerIcons(it, 1.rem, context = context)
        row {
            centered - subtext {
                ::content { it.await().address.cityState }
            }
            centered - subtext("|")
            centered - subtext {
                ::content {
                    val dist = it.await().address.geoCoordinate distanceToMiles
                            currentDealership.awaitNotNull().address.geoCoordinate
                    if (dist < 1.0) Strings.rightHere
                    else Strings.xMilesAway(dist.miles)
                }
            }
        }
    }
}

fun ViewWriter.dealerIcons(
    selected: Readable<Dealership?>,
    size: Dimension = 2.rem,
    context: DealershipsScreen.DealerContext = DealershipsScreen.DealerContext.Both,
) {
    row {
        spacing = 0.75.rem
        row {
            spacing = 0.25.rem
            centered - sizeConstraints(width = size, height = size) - icon(Icon.star, Strings.preferredDealership2)
            centered - text {
                ::content {
                    selected.awaitNotNull().stars.toString()
                }
//                textSize = size
            }
        } in
                hintPopover(preferredDirection = PopoverPreferredDirection.belowRight) {
                    col {
                        h3(Strings.preferredDealership2)
                        text {
                            ::content content@{
                                val preferred =
                                    currentSessionNullable.await()?.dealershipRelationships?.query(Query(condition {
                                        it._id.to eq selected.awaitNotNull()._id
                                    }))?.await()
                                Strings.xMarkedThisDealershipAs(preferred?.size ?: 0)
                            }
                        }
                    }
                }

        if (context.sell) {
            row {
                ::exists { selected.awaitNotNull().accuracyGrade != Grade.Unknown }
                spacing = 0.25.rem
                centered - sizeConstraints(width = size, height = size) - icon(Icon.accuracy, Strings.accuracyGrade)
                centered - text {
                    ::exists { selected.awaitNotNull().accuracyGrade != Grade.Unknown }
                    ::content { selected.awaitNotNull().accuracyGrade.display }
//                    textSize = size
                }
            } in hintPopover(preferredDirection = PopoverPreferredDirection.belowRight) {
                col {
                    h3 { ::content { Strings.accuracyGradeX(selected.awaitNotNull().accuracyGrade.display) } }
                    text { ::content { selected.awaitNotNull().accuracyGrade.let { Strings.fullTextAccuracy(it) } } }
                }
            }
            row {
                spacing = 0.25.rem
                centered - sizeConstraints(width = size, height = size) - icon(Icon.sell, Strings.sellingGrade)
                centered - text {
                    ::exists { selected.awaitNotNull().sellingTransactionGrade != Grade.Unknown }
                    ::content { selected.awaitNotNull().sellingTransactionGrade.display }
//                    textSize = size
                }
                centered - text {
                    ::content { selected.awaitNotNull().sells.toString() }
//                    textSize = size
                }
            } in hintPopover(preferredDirection = PopoverPreferredDirection.belowRight) {
                col {
                    h3 {
                        ::content {
                            Strings.sellingTransactionGradeX(
                                selected.awaitNotNull().sellingTransactionGrade.display
                            )
                        }
                    }
                    text {
                        ::content {
                            selected.awaitNotNull().sellingTransactionGrade.let {
                                Strings.fullTextSellingTransaction(
                                    it
                                )
                            }
                        }
                    }
                    text { ::content { Strings.totalSalesX(selected.awaitNotNull().sells) } }
                }
            }

//        gravity(Align.End, Align.Center)- icon {
//                ::exists{inVehicleDetail == true}
//                Icon.chevronRight }


        }
        if (context.buy) {
            row {
                spacing = 0.25.rem
                centered - sizeConstraints(width = size, height = size) - icon(Icon.buy, Strings.buyingGrade)
                centered - text {
                    ::exists { selected.awaitNotNull().buyingTransactionGrade != Grade.Unknown }
                    ::content { selected.awaitNotNull().buyingTransactionGrade.display }
//                    textSize = size
                }
                centered - text {
                    ::content { selected.awaitNotNull().buys.toString() }
//                    textSize = size
                }
            } in hintPopover(preferredDirection = PopoverPreferredDirection.belowRight) {
                col {
                    h3 {
                        ::content {
                            Strings.buyingTransactionGradeX(
                                selected.awaitNotNull().buyingTransactionGrade.display
                            )
                        }
                    }
                    text {
                        ::content {
                            selected.awaitNotNull().buyingTransactionGrade.let {
                                Strings.fullTextBuyingTransaction(
                                    it
                                )
                            }
                        }
                    }
                    text { ::content { Strings.totalPurchasesX(selected.awaitNotNull().buys) } }
                }
            }
        }
    }
}