package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.navList
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.*
import com.lightningkite.uuid


@Routable("/search")
class SearchScreen() : KiteUiScreen {

    @QueryParameter("limitToSeller")
    val limitToSeller = Property<UUID?>(null)

    @QueryParameter("query")
    val query2 = Property<String>("")

    @QueryParameter("selectedLot")
    val selectedLot = Property<UUID?>(null)

    @QueryParameter
    val savedSearchId = Property<UUID?>(null)

    @QueryParameter
    val search = Property(SearchParamsOnly(make = setOf()))

    @QueryParameter("sort")
    val sort = Property<List<SortPart<Vehicle>>>(sort { it.submitted.notNull.ascending() })

    override val title: Readable<String> = shared {
        listOfNotNull(
            Strings.searchVehicles,
            limitToSeller.await()
                ?.let { currentSessionNullable.awaitNotNull().dealerships.get(it).await()?.run { "sold by $name" } ?: "" },
        ).joinToString(" ")
    }

    val query = shared {
        Query(
            condition<Vehicle> {
                Condition.And(
                    listOfNotNull(
                        limitToSeller.await()?.let { id -> it.seller eq id },
                        it.submitted.neq(null) and it.completion.eq(null),
                        query2.debounce(500L).await().takeUnless { it.isBlank() }
                            ?.let { q ->
                                q.split(' ').filter { it.isNotBlank() }.map { p ->
                                    Condition.Or(
                                        listOf(
                                            it.make.notNull.contains(p, true),
                                            it.yearString.notNull.contains(p, true),
                                            it.model.notNull.contains(p, true),
                                            it.trim.notNull.contains(p, true),
                                        )
                                    )
                                }.let { Condition.And(it) }
                            },
                        search.await().condition(
                            currentDealership()?.address?.geoCoordinate,
                            preferredDealerships = preferredDealerships.await()),
                        currentDealershipId()?.let { id -> it.seller.neq(id) },
                        condition(true)
                    )
                )
            },
            orderBy = sort.await(),
            limit = 500,
        )

    }
    private val toListen = shared {
        currentSessionNullable.awaitNotNull().vehicles.watch(query.await())
    }

    override fun ViewWriter.render() {
        listDetailV2(
            selected = selectedLot,
            list = {
                col {
                    var lastMakes: Set<String>? = null
                    reactiveScope {
                        val m = currentDealership()?.makes
                        if (search.value.make?.isEmpty() == true || search.value.make == m) {
                            search.value = search.value.copy(make = currentDealership()?.makes)
                        }
                        lastMakes = m
                    }
                    card - searchBar(it)
                    expanding - mainList(it)
                }
            },
            detail = {
                when {
                    it == null -> SearchFilterScreen().also {
                        it.connectedTo = this@SearchScreen
                    }
                    else -> VehicleDetailScreen(it).apply { gotoOverride = { selectedLot.value = it } }
                }
            }
        )
    }

    private fun ViewWriter.searchBar(open: suspend () -> Unit) {
        sizeConstraints(height = 5.rem) - row {
            centered - icon(
                Icon.search,
                Strings.search
            )
            centered - expanding - textField {
                hint = Strings.search
                content bind query2
            }
            centered - button {
                ::exists { query2.await().isNotEmpty() }
                compact - icon(Icon.close, Strings.clear)
                onClick {
                    query2 set ""
                }
            }
            centered - compact - row {
                button {
                    centered - icon {
                        source = Icon.filterList
                        description = "Advanced Search"
                    }
                    onClick {
                        selectedLot.value = null
                        open()
                    }
                }
            }
        }
    }


    private fun ViewWriter.mainList(open: suspend () -> Unit) {
        navList - stack {
            val items = shared { toListen.await().await() }
            recyclerView {
                reactiveScope {
                    items.await()
                }
                children(items) {
                    compact - psuedoRadioToggleButton(shared { it()?._id }, selectedLot, open) {
                        sellingLotShortDetails(it)
                    }
                }
            }
            text {
                exists = false
                content = Strings.noVehiclesMatchedTheseConditionsSave
                ::exists { items.await().isEmpty() }
            }
        }
    }


}

@Routable("/search-filter")
class SearchFilterScreen() : Screen {

    @QueryParameter("limitToSeller")
    val limitToSeller = Property<UUID?>(null)

    @QueryParameter("query")
    val query2 = Property<String>("")

    @QueryParameter("selectedLot")
    val selectedLot = Property<UUID?>(null)

    @QueryParameter
    val savedSearchId = Property<UUID?>(null)

    @QueryParameter
    val search = Property(SearchParamsOnly(make = setOf()))

    @QueryParameter("sort")
    val sort = Property<List<SortPart<Vehicle>>>(sort { it.submitted.notNull.ascending() })

    val newSearchName = Property("")

    var connectedTo: SearchScreen? = null
        set(value) {
            field = value
            value?.let { value ->
                limitToSeller.value = value.limitToSeller.value
                query2.value = value.query2.value
                selectedLot.value = value.selectedLot.value
                savedSearchId.value = value.savedSearchId.value
                search.value = value.search.value
                sort.value = value.sort.value
            }
        }

    override fun ViewWriter.render() {
        col {
            var once = false
            reactiveScope {
                currentDealershipId()
                if (!once) {
                    savedSearchId.value = null
                    newSearchName.value = ""
                    once = true
                }
            }

            connectedTo?.let {
                println("Binding to active screen!")
                limitToSeller bind it.limitToSeller
                query2 bind it.query2
                selectedLot bind it.selectedLot
                savedSearchId bind it.savedSearchId
                search bind it.search
                sort bind it.sort
            }

            card - row {
                expanding - sizeConstraints(height = 3.rem) - horizontalRecyclerView {
                    val a = shared {
                        currentDealershipId()?.let { c ->
                            currentSessionNullable.awaitNotNull().savedSearches.query(Query(
                                condition { it.dealership eq c },
                                limit = 50
                            ))
                        } ?: Constant(listOf())
                    }
                    children(shared { listOf<SavedSearch?>(null) + a()() }) {
                        radioToggleButton {
                            centered - text {
                                dynamicTheme { if(it() == null) ThemeDerivation { it.copy(font = it.font.copy(italic = true)).withoutBack } else null }
                                ::content { it.await()?.name ?: Strings.newSearch }
                            }
                            checked bind shared { savedSearchId() == it()?._id }
                                .withWrite { on ->
                                    val v = it()
                                    if (!on) return@withWrite
                                    savedSearchId.value = v?._id
                                    search.value = v?.simplify() ?: SearchParamsOnly(make = currentDealership()?.makes)
                                    newSearchName.value = v?.name ?: ""
                                }
                        }
                    }
                }
            }
            expanding - scrolls - col {
                rowCollapsingToColumn(60.rem) {
                    expanding - card - col {
                        h6(Strings.makes)
                        val makesGetter =
                            shared { currentSession().makes.query(Query(orderBy = sort { it._id.ascending() })) }
                        multiselect(
                            query = { input ->
                                val makes = makesGetter()().map { it._id }
                                input.takeUnless { it.isBlank() }?.let { s ->
                                    makes.filter { it.contains(s, true) }
                                } ?: makes
                            },
                            draw = { it },
                            items = shared { search().make ?: setOf() }
                                .withWrite { value ->
                                    search.modify {
                                        it.copy(make = value.takeUnless { it.isEmpty() })
                                    }
                                }
                        )
                    }
                    expanding - card - col {
                        h6(Strings.models)
                        val modelsGetter = shared {
                            currentSession().models.query(
                                Query(orderBy = sort { it._id.ascending() },
                                    condition = condition { it.make.inside(search().make ?: setOf()) })
                            )
                        }
                        multiselect(
                            query = { input ->
                                val makes = modelsGetter()().map { it.model }
                                input.takeUnless { it.isBlank() }?.let { s ->
                                    makes.filter { it.contains(s, true) }
                                } ?: makes
                            },
                            draw = { it },
                            items = shared { search().model ?: setOf() }
                                .withWrite { value -> search.modify { it.copy(model = value.takeUnless { it.isEmpty() }) } }
                        )
                    }
                }

                rowCollapsingToColumn(60.rem) {
                    expanding - card - col {
                        h6(Strings.trim2)
                        fieldTheme - textField {
                            hint = Strings.trim2
                            content bind shared { search().trim ?: "" }
                                //todo debounce
                                .withWrite { value -> search.modify { it.copy(trim = value.takeUnless { it.isBlank() }) } }
                        }
                    }

                    expanding - card - col {
                        h6(Strings.year)
                        row {
                            expanding - col {
                                subtext(Strings.yearRangeMinLabel)
                                fieldTheme - select {
                                    bind(
                                        edits = shared { search().minYear }
                                            .withWrite { value -> search.modify { it.copy(minYear = value) } },
                                        data = Constant(listOf<Int?>(null) + (2024 downTo 1990).toList())
                                    ) {
                                        it?.toString()
                                            ?: Strings.all
                                    }
                                }
                            }
                            expanding - col {
                                subtext(Strings.yearRangeMaxLabel)
                                fieldTheme - select {
                                    bind(
                                        edits = shared { search().maxYear }
                                            .withWrite { value -> search.modify { it.copy(maxYear = value) } },
                                        data = Constant(listOf<Int?>(null) + (2024 downTo 1990).toList())
                                    ) {
                                        it?.toString()
                                            ?: Strings.all
                                    }
                                }
                            }
                        }
                    }
                }
                rowCollapsingToColumn(60.rem) {
                    card - col {
                        h6(Strings.mileage)
                        fieldTheme - select {
                            bind(
                                edits = shared { search().mileage }
                                    .withWrite { value -> search.modify { it.copy(mileage = value) } },
                                data = Constant(
                                    listOf(
                                        null,
                                        5000,
                                        10000,
                                        20000,
                                        30000,
                                        40000,
                                        50000,
                                        60000,
                                        70000,
                                        80000,
                                        90000,
                                        100000,
                                        120000,
                                        150000,
                                        200000,
                                    )
                                ),
                                render = {
                                    it?.let { Strings.lessThanOdometer(it) } ?: Strings.all
                                }
                            )
                        }
                    }

                    expanding - card - col {
                        h6(Strings.fuelType)
                        fieldTheme - select {
                            bind<FuelType?>(
                                edits = shared { search().fuelType }
                                    .withWrite { value -> search.modify { it.copy(fuelType = value) } },
                                data = Constant(listOf(null) + FuelType.values().toList())
                            ) {
                                when (it) {
                                    FuelType.Gasoline -> Strings.gasoline
                                    FuelType.Hybrid -> Strings.hybrid
                                    FuelType.Diesel -> Strings.diesel
                                    FuelType.Electric -> Strings.electric
                                    null -> Strings.all
                                }
                            }
                        }
                    }
                    expanding - card - col {
                        h6(Strings.transmission1)
                        fieldTheme - select {
                            bind<Transmission?>(
                                edits = shared { search().transmission }
                                    .withWrite { value -> search.modify { it.copy(transmission = value) } },
                                data = Constant(listOf(null) + Transmission.values().toList())
                            ) {
                                when (it) {
                                    Transmission.Manual -> Strings.manual
                                    Transmission.Automatic -> Strings.automatic
                                    null -> Strings.all
                                }
                            }
                        }
                    }


                }
                card - col {
                    h6(Strings.soldBy)
                    rowCollapsingToColumn(90.rem) {
                        centered - row {
                            centered - fieldTheme - checkbox {
                                checked bind shared { search().preferredSellerOnly }
                                    .withWrite { value -> search.modify { it.copy(preferredSellerOnly = value) } }
                            }
                            centered - text(Strings.preferredDealershipsOnly)
                        }
                        separator()
                        distanceFilter(shared { search().maxDistanceMiles }.withWrite { v ->
                            search.modify {
                                it.copy(
                                    maxDistanceMiles = v
                                )
                            }
                        })
                    }
                }
            }
            rowCollapsingToColumn(60.rem) {
                row {
                    sizeConstraints(width = 10.rem) - fieldTheme - textField {
                        hint = Strings.searchName
                        content bind newSearchName
                    }
                    sizeConstraints(width = 8.rem) - important - buttonTheme - button {
                        centered - text {
                            ::content {
                                savedSearchId().let {
                                    when {
                                        it == null -> Strings.saveSearch
                                        currentSessionNullable.awaitNotNull().savedSearches.get(it).await()
                                            ?.let { it.simplify() to it.name } != search() to newSearchName() -> Strings.saveChanges

                                        else -> Strings.saved
                                    }
                                }
                            }
                        }
                        ::enabled {
                            savedSearchId().let {
                                it == null || currentSessionNullable.awaitNotNull().savedSearches.get(it).await()
                                    ?.let { it.simplify() to it.name } != search() to newSearchName()
                            } && newSearchName().isNotBlank()
                        }
                        onClick {
                            if (newSearchName().isBlank()) return@onClick
                            val oldId = savedSearchId()
                            if (oldId == null) {
                                val id = uuid()
                                val dealershipId = currentDealershipId.await() ?: return@onClick
                                savedSearchId.value = id
                                currentSession().savedSearches.insert(
                                    search.value.toSavedSearch(
                                        id,
                                        dealershipId,
                                        newSearchName.value
                                    )
                                )
                            } else {
                                val search = search()
                                currentSessionNullable.awaitNotNull().savedSearches.get(oldId).modify(modification {
                                    it.name assign newSearchName.value
                                    it.fuelType assign search.fuelType
                                    it.transmission assign search.transmission
                                    it.minYear assign search.minYear
                                    it.maxYear assign search.maxYear
                                    it.mileage assign search.mileage
                                    it.make assign search.make
                                    it.model assign search.model
                                    it.trim assign search.trim
                                    it.preferredSellerOnly assign search.preferredSellerOnly
                                })
                            }
                        }
                    }
                }
                expanding - space()
                row {
                    onlyWhen { savedSearchId() != null } - sizeConstraints(width = 8.rem) - danger - buttonTheme - button {
                        centered - text(Strings.delete)
                        onClick {
                            currentSessionNullable.awaitNotNull<UserSession>().savedSearches.get(
                                savedSearchId() ?: return@onClick
                            )
                                .delete()
                            search.value = SearchParamsOnly(make = currentDealership()?.makes)
                            newSearchName.value = ""
                            savedSearchId.value = null
                        }
                    }
                    card - button {
                        centered - text(Strings.clear)
                        onClick {
                            search.value = SearchParamsOnly(make = currentDealership()?.makes)
                            savedSearchId.value = null
                            newSearchName.value = ""
                        }
                    }
                    critical - button {
                        centered - text(Strings.search)
                        onClick {
                            connectedTo?.let { screen ->
                                navigator.stack.value = navigator.stack.value.takeWhile { it != screen } + screen
                            } ?: run {
                                navigator.replace(SearchScreen().also {
                                    it.limitToSeller.value = limitToSeller.value
                                    it.query2.value = query2.value
                                    it.selectedLot.value = selectedLot.value
                                    it.savedSearchId.value = savedSearchId.value
                                    it.search.value = search.value
                                    it.sort.value = sort.value
                                })
                            }
                        }
                    }
                }
            }
        }
    }
}