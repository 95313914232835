// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareTransportAddressFields() {
    val props: Array<SerializableProperty<TransportAddress, *>> = arrayOf(TransportAddress_address, TransportAddress_contactName, TransportAddress_contactBusiness, TransportAddress_contactEmail, TransportAddress_contactPhone, TransportAddress_dealership)
    TransportAddress.serializer().properties { props }
}
val <K> DataClassPath<K, TransportAddress>.address: DataClassPath<K, Address> get() = this[TransportAddress_address]
val <K> DataClassPath<K, TransportAddress>.contactName: DataClassPath<K, String> get() = this[TransportAddress_contactName]
val <K> DataClassPath<K, TransportAddress>.contactBusiness: DataClassPath<K, String> get() = this[TransportAddress_contactBusiness]
val <K> DataClassPath<K, TransportAddress>.contactEmail: DataClassPath<K, String> get() = this[TransportAddress_contactEmail]
val <K> DataClassPath<K, TransportAddress>.contactPhone: DataClassPath<K, String> get() = this[TransportAddress_contactPhone]
val <K> DataClassPath<K, TransportAddress>.dealership: DataClassPath<K, UUID?> get() = this[TransportAddress_dealership]
inline val TransportAddress.Companion.path: DataClassPath<TransportAddress, TransportAddress> get() = path<TransportAddress>()


object TransportAddress_address: SerializableProperty<TransportAddress, Address> {
    override val name: String = "address"
    override fun get(receiver: TransportAddress): Address = receiver.address
    override fun setCopy(receiver: TransportAddress, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = TransportAddress.serializer().tryFindAnnotations("address")
}
object TransportAddress_contactName: SerializableProperty<TransportAddress, String> {
    override val name: String = "contactName"
    override fun get(receiver: TransportAddress): String = receiver.contactName
    override fun setCopy(receiver: TransportAddress, value: String) = receiver.copy(contactName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = TransportAddress.serializer().tryFindAnnotations("contactName")
    override val default: String = ""
}
object TransportAddress_contactBusiness: SerializableProperty<TransportAddress, String> {
    override val name: String = "contactBusiness"
    override fun get(receiver: TransportAddress): String = receiver.contactBusiness
    override fun setCopy(receiver: TransportAddress, value: String) = receiver.copy(contactBusiness = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = TransportAddress.serializer().tryFindAnnotations("contactBusiness")
    override val default: String = ""
}
object TransportAddress_contactEmail: SerializableProperty<TransportAddress, String> {
    override val name: String = "contactEmail"
    override fun get(receiver: TransportAddress): String = receiver.contactEmail
    override fun setCopy(receiver: TransportAddress, value: String) = receiver.copy(contactEmail = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = TransportAddress.serializer().tryFindAnnotations("contactEmail")
    override val default: String = ""
}
object TransportAddress_contactPhone: SerializableProperty<TransportAddress, String> {
    override val name: String = "contactPhone"
    override fun get(receiver: TransportAddress): String = receiver.contactPhone
    override fun setCopy(receiver: TransportAddress, value: String) = receiver.copy(contactPhone = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = TransportAddress.serializer().tryFindAnnotations("contactPhone")
    override val default: String = ""
}
object TransportAddress_dealership: SerializableProperty<TransportAddress, UUID?> {
    override val name: String = "dealership"
    override fun get(receiver: TransportAddress): UUID? = receiver.dealership
    override fun setCopy(receiver: TransportAddress, value: UUID?) = receiver.copy(dealership = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = TransportAddress.serializer().tryFindAnnotations("dealership")
    override val default: UUID? = null
}
