package com.ilussobsa.views

import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.tutorialsComplete
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.modification

@Routable("/tutorial/{id}")
class TutorialScreen(val id: TutorialItem) : Screen {
    override val title: Readable<String> get() = id.title.let(::Constant)
    override fun ViewWriter.render() {
        pageWithMaxWidth(60.rem) {
            scrolls - col {
                id.render(this)
                important - button {
                    centered - text(Strings.markComplete)
                    onClick {
                        currentSession().let {
                            it.users[it.userId.await()].modify(modification {
                                it.tutorialsComplete.addAll(setOf(id.name))
                            })
                        }
                        navigator.goBack()
                    }
                }
            }
        }
    }
}