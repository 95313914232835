
package com.ilussobsa

@JsModule("@js-joda/timezone")
@JsNonModule
external object JsJodaTimeZoneModule

private val jsJodaTz = JsJodaTimeZoneModule

actual fun timeZoneInit() {
    jsJodaTz
}