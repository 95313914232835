package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.game.*
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.ilussobsa.views.LiveAuctionScreen.AuctionState
import com.ilussobsa.views.LiveAuctionScreen.LocalPoke
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.canvas.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import kotlin.random.Random
import kotlin.time.DurationUnit
import kotlinx.coroutines.*
import kotlinx.datetime.Instant
import kotlin.math.exp
import kotlin.time.Duration.Companion.seconds

@Routable("live-auction/{id}/stress-test")
class LiveAuctionStressTestScreen(val id: UUID) : KiteUiScreen {

    override val title: Readable<String> get() = Constant("Live")

    override fun ViewWriter.render() {
        val live = shared { currentSession().liveAuctionDataCache.watch(id)() }

        val bidsMeta = shared {
            currentSession().bids.watch(
                Query(orderBy = sort { it.price.ascending(); it.at.ascending() },
                    limit = 1000,
                    condition = condition { it.vehicle eq live.awaitNotNull().currentVehicle })
            )
        }
        val outerBidsList = shared {
            // Post process to hide equal value non-winning bids
            bidsMeta()().distinctBy { it.price }
        }

        fun ViewWriter.stressor(instance: String, dealership: UUID) = row {
            val session = shared {
                val c = currentSession()
                val a = c.api as LiveApi
                UserSession(LiveApi(a.httpUrl.replace("://", "://${instance}@"), a.socketUrl.replace("://", "://${instance}@")), c.userToken, c.userAccessToken)
            }

            val live = shared { session().liveAuctionDataCache.watch(id)() }

            val bidsMeta = shared {
                session().bids.watch(
                    Query(orderBy = sort { it.price.ascending(); it.at.ascending() },
                        limit = 1000,
                        condition = condition { it.vehicle eq live.awaitNotNull().currentVehicle })
                )
            }
            val currentLotBids = shared {
                // Post process to hide equal value non-winning bids
                bidsMeta()().distinctBy { it.price }
            }
            val talkingMeta = shared {
                session().sellerTalkingPoints.watch(
                    Query(orderBy = sort { it.at.ascending() },
                        limit = 1000,
                        condition = condition { it.vehicle eq live.awaitNotNull().currentVehicle })
                )
            }
            val talking = shared { talkingMeta.await().await() }
            val biddingStart = shared { live()?.startedAt ?: now() }
            val nowBySecond = Property(now())
            val currentLotId = shared { live.await()?.currentVehicle }
            val laneState = shared {
                live.await()?.let {
                    if (nowBySecond.await() > it.laneStartedAt) AuctionState.Open
                    else AuctionState.Waiting
                } ?: AuctionState.NotOpen
            }
            val localPokes = Property<List<LocalPoke>>(listOf())
            val saleHistory = shared { (talking.await() + currentLotBids.await()).sortedBy { it.at }.takeUnless { it.isEmpty() } ?: listOf(listOf(LocalPoke("No bids yet", biddingStart()))) }

            val finalTimeout = shared {
                live.await()?.let {
                    if ((it.increment == it.bottomIncrement || (it.dropsLeft == 0 && it.bids == 0)) && it.completion == null)
                        it.timeout
                    else
                        null
                }
            }

            val dontShowLoading = CoroutineScope(coroutineContext + object: StatusListener {
                override fun loading(readable: Readable<*>) {}
            })

            dontShowLoading.reactiveSuspending {
                while (true) {
                    delay(100)
                    nowBySecond.value = now()
                }
            }

            reactiveScope {
                currentLotId.await()
                localPokes.value = listOf()
            }

            var permitPoke = true
            val shouldPoke = shared {
                currentLotBids.await().let {
                    it.getOrNull(it.lastIndex - 1)?.buyer == currentDealershipId.await() &&
                            it.getOrNull(it.lastIndex)?.buyer != currentDealershipId.await() &&
                            live.await()?.reserveMet == true
                }
            }
            dontShowLoading.reactiveSuspending {
                if (shouldPoke()) {
                    delay(2000)
                    if (permitPoke) {
                        localPokes.value += LocalPoke(
                            "Don't let it go!"
                        )
                    }
                    permitPoke = false
                }
            }
            // Disjoint warning
            run {
                val collectionBids = shared { currentLotBids().size }
                val liveBids = shared { live()?.bids ?: 0 }
                val mismatch = shared { collectionBids() != liveBids() }
                val isBroken = mismatch.debounce(10_000)
                dontShowLoading.reactiveScope {
                    if(isBroken()) {
                    }
                }
            }

            var startup = true
            launch {
                delay(1000)
                startup = false
            }

            var goingOncePlayed = false
            var goingTwicePlayed = false
            var lastAsk = 0
            var soldPlayed = false
            reactiveSuspending {
                rerunOn(currentLotId)
                goingOncePlayed = false
                goingTwicePlayed = false
                soldPlayed = false
                lastAsk = 0
                val l = live.awaitOnce()
                if (l != null && l.completion == null) {
                }
            }
            reactiveSuspending {
                val l = live.await() ?: return@reactiveSuspending
                val newAsk = l.asking
                if (newAsk < lastAsk) {
                    if (l.bids == 0 && l.dropsLeft == 0){}
                }
                lastAsk = newAsk
            }

            var reserveMetPlayed = false
            var lastBids = 0
            reactiveSuspending {
                val l = live.await() ?: return@reactiveSuspending
                val newCount = l.bids
                if (newCount == 0) {
                    reserveMetPlayed = false
                    lastBids = 0
                }
                if (newCount > lastBids) {
                    if (!reserveMetPlayed && l.reserveMet && newCount > 0) {
                        reserveMetPlayed = true
                        permitPoke = true
                    } else if (newCount == 1) {
                    }
                }
                lastBids = newCount
            }
            val goingStatus = shared {
                finalTimeout.await()?.let {
                    if (it - nowBySecond.await() < LiveAuctionData.lagAccounting + LiveAuctionData.goingDuration) {
                        2
                    } else if (it - nowBySecond.await() < LiveAuctionData.lagAccounting + LiveAuctionData.goingDuration * 2) {
                        1
                    } else {
                        0
                    }
                } ?: 0
            }
            reactiveSuspending {
                when (val gs = goingStatus()) {
                    2 -> {
                        if (!goingTwicePlayed) {
                            goingTwicePlayed = true
                        }
                    }

                    1 -> {
                        if (!goingOncePlayed) {
                            goingOncePlayed = true
                        }
                    }

                    0 -> {
                        goingOncePlayed = false
                        goingTwicePlayed = false
                    }
                }
            }
            reactiveSuspending {
                live.await()?.completion?.let {
                        if (it.bids == 0) Strings.noSale else if (it.sold) "SOLD" else Strings.offerReady
                    if (!soldPlayed) {
                        soldPlayed = true
                    }
                }
            }
            reactiveScope {
                localPokes.await().lastOrNull()?.let {
                }
            }
            reactiveScope {
                val l = live()
                if (l?.winningBid?.buyer == currentDealershipId() && l?.completion == null) {
                }
            }
            reactiveScope {
                live.await()?.asking?.renderPriceInDollars() ?: ""
            }

            val weHaveTopBid = Property(false)
            reactiveScope {
                weHaveTopBid.value =
                    live.await()?.winningBid?.buyer.let { it != null && it == currentDealershipId.await() }
            }

            dynamicTheme {
                val isDiff = outerBidsList().size != currentLotBids().size
                if(isDiff) ErrorSemantic else CardSemantic
            }
            weight(1f) - text(instance)
            weight(6f) - text {
                ::content { live()?.currentVehicle?.toString() ?: "None" }
            }
            weight(3f) - text {
                ::content { live()?.winningBidPrice?.renderPriceInDollars() ?: "N/A" }
            }
            weight(3f) - text {
                ::content { currentLotBids().maxOfOrNull { it.price }?.renderPriceInDollars() ?: "N/A" }
            }
            weight(1f) - text {
                ::content { live()?.bids?.toString() ?: "N/A" }
            }
            weight(1f) - text {
                ::content { currentLotBids().size.toString() }
            }
            weight(1f) - text {
                ::content { saleHistory().size.toString() }
            }

            // bidder
            dontShowLoading.reactiveSuspending {
                rerunOn(currentLotId)
                dontShowLoading.launch onClick@{
                    while(true) {
                        val livestate = live.state.getOrNull() ?: break
                        if (now() - livestate.startedAt > 60.seconds) break
                        delay(Random.nextInt(4, 10).seconds.also { println("$instance delaying $it") })
                        println("$instance is bidding!")
                        live.state.getOrNull()?.let {
                            if (it.completion != null) return@onClick
                            if (it.winningBid?.buyer == dealership) return@onClick
                        }
                        println("$instance is bidding! A")
                        val vehicle = currentLotId.await() ?: run { return@onClick }
                        println("$instance is bidding! B")
                        val buyer = dealership
                        val price = live.await()?.asking ?: run { return@onClick }
                        println("$instance is bidding! C")
                        session().bids.insert(
                            Bid(
                                vehicle = vehicle,
                                buyer = buyer,
                                price = price,
                            )
                        )
                    }
                }
            }
        }
        scrolls - col {
            val dealershipList = asyncReadable {
                currentSession().dealerships.query(Query())()
            }
            reactive {
                val dealerships = dealershipList.await()
                repeat(15) { stressor(('A' + it).toString(), dealerships[it % dealerships.size]._id) }
            }
        }
    }
}