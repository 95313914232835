// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareAuctionLaneFields() {
    val props: Array<SerializableProperty<AuctionLane, *>> = arrayOf(AuctionLane__id, AuctionLane_startsAt, AuctionLane_name, AuctionLane_description, AuctionLane_vehicles, AuctionLane_requiresOneOfMakes, AuctionLane_condition, AuctionLane_active)
    AuctionLane.serializer().properties { props }
}
val <K> DataClassPath<K, AuctionLane>._id: DataClassPath<K, UUID> get() = this[AuctionLane__id]
val <K> DataClassPath<K, AuctionLane>.startsAt: DataClassPath<K, LocalTime> get() = this[AuctionLane_startsAt]
val <K> DataClassPath<K, AuctionLane>.name: DataClassPath<K, String> get() = this[AuctionLane_name]
val <K> DataClassPath<K, AuctionLane>.description: DataClassPath<K, String> get() = this[AuctionLane_description]
val <K> DataClassPath<K, AuctionLane>.vehicles: DataClassPath<K, Int> get() = this[AuctionLane_vehicles]
val <K> DataClassPath<K, AuctionLane>.requiresOneOfMakes: DataClassPath<K, Set<MakeName>?> get() = this[AuctionLane_requiresOneOfMakes]
val <K> DataClassPath<K, AuctionLane>.condition: DataClassPath<K, Condition<Vehicle>> get() = this[AuctionLane_condition]
val <K> DataClassPath<K, AuctionLane>.active: DataClassPath<K, Boolean> get() = this[AuctionLane_active]
inline val AuctionLane.Companion.path: DataClassPath<AuctionLane, AuctionLane> get() = path<AuctionLane>()


object AuctionLane__id: SerializableProperty<AuctionLane, UUID> {
    override val name: String = "_id"
    override fun get(receiver: AuctionLane): UUID = receiver._id
    override fun setCopy(receiver: AuctionLane, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object AuctionLane_startsAt: SerializableProperty<AuctionLane, LocalTime> {
    override val name: String = "startsAt"
    override fun get(receiver: AuctionLane): LocalTime = receiver.startsAt
    override fun setCopy(receiver: AuctionLane, value: LocalTime) = receiver.copy(startsAt = value)
    override val serializer: KSerializer<LocalTime> = LocalTime.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("startsAt")
    override val default: LocalTime = LocalTime(12, 0, 0)
}
object AuctionLane_name: SerializableProperty<AuctionLane, String> {
    override val name: String = "name"
    override fun get(receiver: AuctionLane): String = receiver.name
    override fun setCopy(receiver: AuctionLane, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("name")
}
object AuctionLane_description: SerializableProperty<AuctionLane, String> {
    override val name: String = "description"
    override fun get(receiver: AuctionLane): String = receiver.description
    override fun setCopy(receiver: AuctionLane, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("description")
}
object AuctionLane_vehicles: SerializableProperty<AuctionLane, Int> {
    override val name: String = "vehicles"
    override fun get(receiver: AuctionLane): Int = receiver.vehicles
    override fun setCopy(receiver: AuctionLane, value: Int) = receiver.copy(vehicles = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("vehicles")
}
object AuctionLane_requiresOneOfMakes: SerializableProperty<AuctionLane, Set<MakeName>?> {
    override val name: String = "requiresOneOfMakes"
    override fun get(receiver: AuctionLane): Set<MakeName>? = receiver.requiresOneOfMakes
    override fun setCopy(receiver: AuctionLane, value: Set<MakeName>?) = receiver.copy(requiresOneOfMakes = value)
    override val serializer: KSerializer<Set<MakeName>?> = SetSerializer(MakeName.serializer()).nullable2
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("requiresOneOfMakes")
    override val default: Set<MakeName>? = null
}
object AuctionLane_condition: SerializableProperty<AuctionLane, Condition<Vehicle>> {
    override val name: String = "condition"
    override fun get(receiver: AuctionLane): Condition<Vehicle> = receiver.condition
    override fun setCopy(receiver: AuctionLane, value: Condition<Vehicle>) = receiver.copy(condition = value)
    override val serializer: KSerializer<Condition<Vehicle>> = Condition.serializer(Vehicle.serializer())
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("condition")
}
object AuctionLane_active: SerializableProperty<AuctionLane, Boolean> {
    override val name: String = "active"
    override fun get(receiver: AuctionLane): Boolean = receiver.active
    override fun setCopy(receiver: AuctionLane, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("active")
    override val default: Boolean = true
}
