// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDynamicSettingsFields() {
    val props: Array<SerializableProperty<DynamicSettings, *>> = arrayOf(DynamicSettings__id, DynamicSettings_billOfSale)
    DynamicSettings.serializer().properties { props }
}
val <K> DataClassPath<K, DynamicSettings>._id: DataClassPath<K, String> get() = this[DynamicSettings__id]
val <K> DataClassPath<K, DynamicSettings>.billOfSale: DataClassPath<K, ServerFile?> get() = this[DynamicSettings_billOfSale]
inline val DynamicSettings.Companion.path: DataClassPath<DynamicSettings, DynamicSettings> get() = path<DynamicSettings>()


object DynamicSettings__id: SerializableProperty<DynamicSettings, String> {
    override val name: String = "_id"
    override fun get(receiver: DynamicSettings): String = receiver._id
    override fun setCopy(receiver: DynamicSettings, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = DynamicSettings.serializer().tryFindAnnotations("_id")
    override val default: String = "main"
}
object DynamicSettings_billOfSale: SerializableProperty<DynamicSettings, ServerFile?> {
    override val name: String = "billOfSale"
    override fun get(receiver: DynamicSettings): ServerFile? = receiver.billOfSale
    override fun setCopy(receiver: DynamicSettings, value: ServerFile?) = receiver.copy(billOfSale = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = DynamicSettings.serializer().tryFindAnnotations("billOfSale")
    override val default: ServerFile? = null
}
