package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.sdk.UserSession
import com.ilussobsa.utils.appNavPreference
import com.ilussobsa.utils.appThemePreference
import com.lightningkite.UUID
import com.lightningkite.kiteui.reactive.ReactiveContext
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.lightningdb.*
import kotlinx.datetime.Instant

enum class Achievements(val text: String, val determine: ReactiveContext.(session: UserSession, dealership: UUID) -> Boolean) {
    FillOutTransportContact("Fill Out Transport Contact", { session, it -> session.dealerships.get(it).await()?.transportManager != session.dealerships.get(it).await()?.usedCarManager }),
    FillOutTitleManagerContact("Fill Out Title Manager Contact", { session, it -> session.dealerships.get(it).await()?.titleManager != session.dealerships.get(it).await()?.usedCarManager }),
    AddADealershipProfilePicture("Add A Dealership Profile Picture", { session, it -> session.dealerships.get(it).await()?.profilePicture != null }) ,
    AddDealershipURL("Add Dealership URL", { session, it -> session.dealerships.get(it).await()?.url != null }),
    AddAUserProfilePicture("Add A User Profile Picture", { session, it -> session.me.await()?.profilePicture != null }),
    AddUserPhoneNumberAndEmail("Add User Phone Number", { session, it -> session.me.await()?.phoneNumber != null }),
    ToggleNotificationPreferences("Toggle Notification Preferences", { session, it -> session.me.await()?.let { it.notificationSettingsChanged } == true }),
    ViewNotificationList("View Notification List", { session, it -> session.me.await()?.let { it.notificationsLastCheckedAt > Instant.fromEpochSeconds(1) } == true }),
    ChangeMenuOptions("Change Menu Options", { session, it -> com.ilussobsa.utils.appNavPreference.await() != null }),
    UploadAVehicle("Upload A Vehicle", { session, d -> session.vehicles.query(Query(condition { it.seller.eq(d) }, limit = 1)).await().isNotEmpty() }),
//    ReceiveABid("Receive A Bid", { d -> vehicles.query(Query(condition { it.seller.eq(d) and it. }, limit = 1)).await().isNotEmpty() }),
    PlaceALiveBid("Place A Live Bid", { session, d -> session.bids.query(Query(condition { it.buyer.eq(d) }, limit = 1)).await().isNotEmpty() }),
    FavoriteAVehicle("Favorite A Vehicle", { session, d -> session.vehicleRelationships.query(Query(condition { it.favorite.eq(true) and it._id.dealership.eq(d) }, limit = 1)).await().isNotEmpty() }),
    SetALaneAlert("Set A Lane Alert", { session, d -> session.vehicleRelationships.query(Query(condition { it.notifications.eq(true) and it._id.dealership.eq(d) }, limit = 1)).await().isNotEmpty() }),
    PlaceAProxyBid("Place A Proxy Bid", { session, d -> session.vehicleRelationships.query(Query(condition { it.autobid.neq(null) and it._id.dealership.eq(d) }, limit = 1)).await().isNotEmpty() }),
    CreateASavedSearch("Create a Saved Search", { session, d -> session.savedSearches.query(Query(condition { it.dealership.eq(d) }, limit = 1)).await().isNotEmpty() }),
    RequestATransportQuote("Request A Transport Quote", { session, d -> session.transportRequests.query(Query(condition { it.requester.eq(d) }, limit = 1)).await().isNotEmpty() }),
//    AttendLiveAuction("Attend Live Auction", { d -> vehicleRelationships.query(Query(condition { it.favorite.eq(true) and it._id.dealership.eq(d) }, limit = 1)).await().isNotEmpty() }),
    PreferAnotherDealer("Prefer Another Dealer", { session, d -> session.dealershipRelationships.query(Query(condition { it._id.from.eq(d) }, limit = 1)).await().isNotEmpty() }),
}