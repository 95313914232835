package com.ilussobsa.views

import com.ilussobsa.Strings
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

data class BackButtonInfo(
    val needed: Readable<Boolean>,
    val action: ()->Unit
)

fun ViewWriter.backButtonIfNeeded(backButtonInfo: BackButtonInfo?) {
    backButtonInfo?.let {
        button {
            ::exists { it.needed() }
            centered - icon(Icon.list, Strings.returnToList)
            onClick { it.action() }
        }
    }
}

inline fun <T> ViewWriter.psuedoRadioToggleButton(
    me: Readable<T>,
    selected: Writable<T>,
    crossinline open: suspend ()->Unit,
    crossinline setup: ViewWriter.()->Unit
) {
    val immediateify = Property(false)
    button {
        dynamicTheme { if(immediateify()) SelectedSemantic else UnselectedSemantic }
        reactiveScope { immediateify.value = selected() == me() }
        setup()
        onClick {
            selected set me()
            open()
        }
    }
}

fun <T> ViewWriter.listDetailV2(
    selected: Writable<T>,
    listSize: Dimension = 25.rem,
    detailSizeRequired: Dimension = 55.rem,
    list: ViewWriter.(open: suspend ()->Unit) -> Unit,
    detail: ReactiveContext.(T)->Screen
) {
    val useCollapsed = shared { WindowInfo.invoke().width < listSize + detailSizeRequired }
    swapView {
        swapping(
            current = { useCollapsed() },
            views = {
                if(it) {
                    list {
                        navigator.navigate(detail(selected()))
                    }
                } else {
                    row {
                        sizeConstraints(
                            width = listSize
                        ) - list {
                            if(useCollapsed()) {
                                navigator.navigate(detail(selected()))
                            }
                        }
                        expanding - unpadded - swapView {
                            val screen = shared { selected().let { detail(it) } }
                            swapping(current = { screen() }, views = {
                                unpadded; it.render(this@swapping)
                            })
                        }
                    }
                }
            }
        )
    }
}