package com.ilussobsa

import com.lightningkite.serialization.*
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsShared() { SharedModelsObject }
object SharedModelsObject {
    init {
        SerializationRegistry.master.registerShared()
        com.ilussobsa.prepareDynamicSettingsFields()
        com.ilussobsa.prepareUserFields()
        com.ilussobsa.prepareNotificationTypeSettingsFields()
        com.ilussobsa.prepareFcmTokenFields()
        com.ilussobsa.prepareShortVehicleFields()
        com.ilussobsa.prepareVehicleRelationshipFields()
        com.ilussobsa.prepareDealershipVehiclePairFields()
        com.ilussobsa.prepareVehicleSearchFields()
        com.ilussobsa.prepareCompletionFields()
        com.ilussobsa.prepareLiveAuctionDataFields()
        com.ilussobsa.prepareSellerTalkingPointFields()
        com.ilussobsa.prepareVehicleFields()
        com.ilussobsa.prepareAttachmentFields()
        com.ilussobsa.prepareExtraInfoFields()
        com.ilussobsa.prepareIssueReportFields()
        com.ilussobsa.prepareDamageFields()
        com.ilussobsa.prepareTransportAddressFields()
        com.ilussobsa.prepareTransportRequestFields()
        com.ilussobsa.prepareAddressFields()
        com.ilussobsa.preparePurchasedInfoFields()
        com.ilussobsa.prepareDealershipGroupFields()
        com.ilussobsa.prepareMakeFields()
        com.ilussobsa.prepareModelFields()
        com.ilussobsa.prepareDealershipJoinRequestFields()
        com.ilussobsa.prepareDealershipJoinRequestUserFields()
        com.ilussobsa.prepareDealershipFields()
        com.ilussobsa.prepareDealershipRelationshipFields()
        com.ilussobsa.prepareSavedSearchFields()
        com.ilussobsa.prepareDealershipPairFields()
        com.ilussobsa.prepareAuctionLaneFields()
        com.ilussobsa.prepareBidFields()
        com.ilussobsa.prepareNotificationFields()
        com.ilussobsa.prepareStripeWebhookSecretFields()
        com.ilussobsa.prepareItemFields()
        com.ilussobsa.prepareSubscriptionPaymentFields()
    }
}

fun SerializationRegistry.registerShared() {
    register(com.ilussobsa.ResetDataRequest.serializer())
    register(com.ilussobsa.RoundingLevel.serializer())
    register(com.ilussobsa.UserPartial.serializer())
    register(com.ilussobsa.DynamicSettings.serializer())
    register(com.ilussobsa.SaleData.serializer())
    register(com.ilussobsa.User.serializer())
    register(com.ilussobsa.NotificationTypeSettings.serializer())
    register(com.ilussobsa.UserRole.serializer())
    register(com.ilussobsa.FcmToken.serializer())
    register(com.ilussobsa.ShortVehicle.serializer())
    register(com.ilussobsa.VehicleRelationship.serializer())
    register(com.ilussobsa.DealershipVehiclePair.serializer())
    register(com.ilussobsa.VehicleSearch.serializer())
    register(com.ilussobsa.Completion.serializer())
    register(com.ilussobsa.WinnerAndPrice.serializer())
    register(com.ilussobsa.CancellationReason.serializer())
    register(com.ilussobsa.LiveAuctionData.serializer())
    register(com.ilussobsa.SellerTalkingPoint.serializer())
    register(com.ilussobsa.Auctioneer.serializer())
    register(com.ilussobsa.AnimationAction.serializer())
    register(com.ilussobsa.Vehicle.serializer())
    register(com.ilussobsa.Attachment.serializer())
    register(com.ilussobsa.ExtraInfo.serializer())
    register(com.ilussobsa.Severity.serializer())
    register(com.ilussobsa.IssueReport.serializer())
    register(com.ilussobsa.TireStatus.serializer())
    register(com.ilussobsa.KeyCount.serializer())
    register(com.ilussobsa.ReconditioningStatus.serializer())
    register(com.ilussobsa.Damage.serializer())
    register(com.ilussobsa.Transmission.serializer())
    register(com.ilussobsa.FuelType.serializer())
    register(com.ilussobsa.TransportAddress.serializer())
    register(com.ilussobsa.TransportRequest.serializer())
    register(com.ilussobsa.Address.serializer())
    register(com.ilussobsa.PurchasedInfo.serializer())
    register(com.ilussobsa.DealershipGroup.serializer())
    register(com.ilussobsa.Make.serializer())
    register(com.ilussobsa.Model.serializer())
    register(com.ilussobsa.Grade.serializer())
    register(com.ilussobsa.DealershipJoinRequest.serializer())
    register(com.ilussobsa.DealershipJoinRequestUser.serializer())
    register(com.ilussobsa.Dealership.serializer())
    register(com.ilussobsa.DealershipRelationship.serializer())
    register(com.ilussobsa.SearchParamsOnly.serializer())
    register(com.ilussobsa.SavedSearch.serializer())
    register(com.ilussobsa.DealershipPair.serializer())
    register(com.ilussobsa.AuctionLane.serializer())
    register(com.ilussobsa.Bid.serializer())
    register(com.ilussobsa.Notification.serializer())
    register(com.ilussobsa.NotificationTopic.serializer())
    register(com.ilussobsa.UsState.serializer())
    register(com.ilussobsa.StripeWebhookSecret.serializer())
    register(com.ilussobsa.Item.serializer())
    register(com.ilussobsa.SubscriptionPayment.serializer())
}
