package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.UUID
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.serialization.*



fun ViewWriter.vehicleDetail(
    selectedLot: UUID,
    immutable: Boolean = false,
    goto: suspend (vehicleId: UUID?) -> Unit,
    backButtonInfo: BackButtonInfo? = null
) {
    val selected = shared { currentSessionNullable.awaitNotNull().vehicles.get(selectedLot)() }
    val isSeller = shared { selected()?.seller == (currentDealershipId() ?: nullUuid) }
    val notify = vehicleRelationshipUpsertProp(selectedLot, VehicleRelationship.path.notifications)
    val favorite = vehicleRelationshipUpsertProp(selectedLot, VehicleRelationship.path.favorite)
    val allowMake = shared { selected()?.make in (currentDealership.await()?.makes ?: setOf()) }

    col {
        card - col {
            row {
                backButtonIfNeeded(backButtonInfo)
                centered - sizeConstraints(1.5.rem, 1.5.rem) - icon {
                    ::exists{ selected.await()?.certifiedPreOwned != null }
                    source = Icon.certification
                } in hintPopover(preferredDirection = PopoverPreferredDirection.aboveRight) {
                    col {
                        centered - text {
                            ::content{ Strings.thisVehicleAppearsToMeetTheMinimumCriteria }
                        }
                        centered - bold - text {
                            ::content{ selected.await()?.certifiedPreOwned ?: "" }
                        }
                    }
                }
                centered - expanding - h2 { ::content.invoke { selected.awaitNotNull().ymmt } }
                gravity(Align.End, Align.Start) - row {
                    ::exists {
                        val sel = selected()
                        val d = currentDealership()
                        !isSeller() && d != null && sel?.liveAt == null && ((sel?.make ?: "") in (d?.makes ?: setOf()))
                    }
                    compact - toggleButton {
                        icon {
                            ::source {
                                if (favorite()) Icon.starFilled
                                else Icon.star
                            }
                            description = Strings.favorite
                            checked bind favorite
                        }
                    }
                    compact - toggleButton {
                        icon {
                            ::source {
                                if (notify()) Icon.notificationFilled
                                else Icon.notification
                            }
                            description = Strings.notificationsEnabled
                        }
                        checked bind notify
                    }
                }
            }
            rowCollapsingToColumn(40.rem) {
                row {
                    centered - text {
                        ::content {
                            selected.awaitNotNull().vin.let { "VIN: $it" }
                        }
                    }
                    centered - button {
                        spacing = 0.px
                        sizeConstraints(width = 1.25.rem, height = 1.25.rem) - icon {
                            source = Icon.copy
                            description = Strings.copyVINToClipboard
                        }
                        onClick { ExternalServices.setClipboardText(selected.awaitNotNull().vin) }
                    } in hintPopover(preferredDirection = PopoverPreferredDirection.belowCenter) {
                        h6(Strings.copyVINToClipboard)
                    }
                }
                row {
                    centered - text {
                        ::exists { !isSeller() }
                        ::content{
                            val dist = selected.awaitNotNull().geoCoordinate distanceToMiles
                                    currentDealership.awaitNotNull().address.geoCoordinate
                            if (dist < 1.0) Strings.rightHere
                            else Strings.xMilesAway(dist.miles)
                        }
                    }
                    centered - text {
                        ::content {
                            selected.awaitNotNull().odometer?.let { Strings.odometerXMi(it.toCommaString()) }
                                ?: Strings.noMileageInformation
                        }
                    }
                }
                gravity(Align.Start, Align.Center) - sellingIssueIcons(selected.waitForNotNull, 1.5.rem)

//            gravity(Align.End, Align.Center) - toggleButton {
//                row {
//                    icon {
//                        ::source { if (!showKey.value) Icon.add else Icon.close }
//                        description = "Display key?"
//                    }
//                    gravity(Align.Start, Align.Center) - text {
//                        ::content{ (if (!showKey.value) "Show Key" else "Hide Key").toString() }
//                    }
//                }
//                launch {condition { it.vehicleDenormalizedInfo.make inside (currentDealership.await()?.makes ?: setOf()) }
//                    checked bind showKey
//
//                }
//            }
            }
        }

        vehicleInfo(selectedLot, immutable, goto)
    }
}


private data class SellingExtraInfoPlus(
    val icon: Icon,
    val text: String,
    val getInfo: ExtraInfo
)

fun ViewWriter.sellingIssueIcons(selected: Readable<Vehicle>, size: Dimension = 2.rem) {

    compact - row {
        forEachUpdating(shared {
            buildList<SellingExtraInfoPlus> {
                val v = selected.awaitNotNull()
                v.completelyClean?.let { add(SellingExtraInfoPlus(Icon.clean, Strings.completelyClean, it)) }
                if (v.transmission == Transmission.Manual) {
                    add(
                        SellingExtraInfoPlus(
                            Icon.manual,
                            Strings.manualTransmission,
                            ExtraInfo(Strings.thisVehicleHasAManualTransmission)
                        )
                    )
                }
                v.priorAccident?.let {
                    add(
                        SellingExtraInfoPlus(
                            Icon.priorAccident,
                            Strings.priorAccidentReported,
                            it
                        )
                    )
                }
                if (v.fuelType == FuelType.Electric) {
                    add(
                        SellingExtraInfoPlus(
                            Icon.electric,
                            Strings.electric,
                            ExtraInfo(Strings.thisIsAnElectricVehicle)
                        )
                    )
                }
                v.paintwork?.let { add(SellingExtraInfoPlus(Icon.paintwork, Strings.paintWork, it)) }
                v.warningLights?.let { add(SellingExtraInfoPlus(Icon.warning, Strings.warningLights, it)) }
                v.towRequired?.let { add(SellingExtraInfoPlus(Icon.towing, Strings.towRequired, it)) }
                v.nonRunner?.let { add(SellingExtraInfoPlus(Icon.close, Strings.nonRunner, it)) }
                v.structuralDamage?.let { add(SellingExtraInfoPlus(Icon.damage, Strings.structuralDamage, it)) }
                v.airConditioningIssue?.let {
                    add(
                        SellingExtraInfoPlus(
                            Icon.acIssues,
                            Strings.airConditioningIssue,
                            it
                        )
                    )
                }
                v.transmissionIssue?.let { add(SellingExtraInfoPlus(Icon.close, Strings.transmissionIssue, it)) }
                v.odometerIssue?.let { add(SellingExtraInfoPlus(Icon.speedometer, Strings.odometerIssue, it)) }
                v.canadian?.let { add(SellingExtraInfoPlus(Icon.canada, Strings.canadian, it)) }
                v.titleNotPresent?.let { add(SellingExtraInfoPlus(Icon.missingTitle, Strings.titleNotPresent, it)) }
                v.salvage?.let { add(SellingExtraInfoPlus(Icon.recycling, Strings.salvage, it)) }
                v.lemonLaw?.let { add(SellingExtraInfoPlus(Icon.lemon, Strings.lemonLaw, it)) }
                v.flood?.let { add(SellingExtraInfoPlus(Icon.waterDrop, Strings.floodDamage, it)) }
                v.stolenOrRecovery?.let { add(SellingExtraInfoPlus(Icon.money, Strings.stolenRecovery, it)) }
                v.rentalOrTaxi?.let { add(SellingExtraInfoPlus(Icon.taxi, Strings.rentalTaxi, it)) }
                v.trueMileageUnknown?.let { add(SellingExtraInfoPlus(Icon.close, Strings.trueMileageUnknown, it)) }
            }
        }, 0) { extraInfo ->
            hintPopover(preferredDirection = PopoverPreferredDirection.belowCenter) {
                col {
                    h3 { ::content{ extraInfo.await().text } }
//                    text { ::content { extraInfo.await().getInfo.description ?: Strings.noAdditionalInformationGiven } }
                }
            }
            sizeConstraints(width = size, height = size)

            icon {
                ::source { extraInfo.await().icon }
                ::description { extraInfo.await().text }
            }
        }
    }
}

fun ViewWriter.sellingLotShortDetails(it: Readable<Vehicle>) {
    row {
        centered - sizeConstraints(
            width = 7.5.rem,
            height = 5.rem
        ) - tweakTheme { it.copy(cornerRadii = CornerRadii.Constant(0.75.rem),) } - image {
            this.description = ""
            ::source { it.await().exteriorThumbnail?.let { ImageRemote(it.location) } }
            scaleType = ImageScaleType.Crop
        }
        space()
        centered - expanding - compact - col {
            row {
                centered - sizeConstraints(1.5.rem, 1.5.rem) - icon {
                    ::exists{ it.await().seller == currentDealershipId() }
                    source = Icon.sell
                } in hintPopover(preferredDirection = PopoverPreferredDirection.aboveRight) {
                    col {
                        centered - text {
                            ::content{ Strings.thisIsAVehicleYouAreSelling }
                        }
                    }
                }
                centered - sizeConstraints(1.5.rem, 1.5.rem) - icon {
                    ::exists{ it.await().certifiedPreOwned != null }
                    source = Icon.certification
                } in hintPopover(preferredDirection = PopoverPreferredDirection.aboveRight) {
                    col {
                        centered - text {
                            ::content{ Strings.thisVehicleAppearsToMeetTheMinimumCriteria }
                        }
                        centered - bold - text {
                            ::content{ it.await().certifiedPreOwned ?: "" }
                        }
                    }
                }
                centered - HeaderSemantic.onNext - text {
                    ::content { it.await().run { "$year $make $model" } }
                }

            }

            subtext { ::content { it.await().statusDescription() } }
            row {
                centered - subtext {
                    ::content {
                        it.await().odometer?.toCommaString()?.plus(" mi")
                            ?: Strings.noMileageInformation
                    }
                }
                centered - subtext(" | ")
                sizeConstraints(width = 1.rem, height = 1.rem) - icon {
                    exists = false
                    ::exists {
                        currentDealershipId.await()?.let { c ->
                            currentSessionNullable.await()?.vehicleRelationships?.get(
                                DealershipVehiclePair(c, it.await()._id)
                            )?.await()?.favorite == true
                        } ?: false
                    }
                    ::source { Icon.star }
                } in
                        hintPopover(preferredDirection = PopoverPreferredDirection.belowCenter) {
                            col {
                                h3(Strings.favorite)
                                text(Strings.youveMarkedThisVehicleAsAFavorite)
                            }
                        }

                sellingIssueIcons(it, size = 1.rem)
            }
//            sellingShortSellerLine(shared { dealerships().get(it.await().seller).awaitNotNull() })
        }
    }
}

fun <V> vehicleRelationshipUpsertProp(selectedLot: UUID, key: DataClassPath<VehicleRelationship, V>): Writable<V> {
    val basis = shared {
        currentSessionNullable()?.vehicleRelationships?.get(
            DealershipVehiclePair(
                currentDealershipId() ?: return@shared null, selectedLot
            )
        )
    }
    val flat = shared { basis()?.invoke() }
    val default =
        VehicleRelationship(DealershipVehiclePair(nullUuid, nullUuid), Vehicle(seller = nullUuid, vin = "").short())
    return shared<V> { (flat() ?: default).let { key.get(it) } as V }
        .withWrite { value -> vehicleRelationshipUpsert(selectedLot, modification { key.assign(value) }) }
}

suspend fun vehicleRelationshipUpsert(selectedLot: UUID, update: Modification<VehicleRelationship>) {
    val existing =
        currentSessionNullable()?.vehicleRelationships?.get(DealershipVehiclePair(currentDealershipId() ?: return, selectedLot))
            ?.invoke()
    if (existing == null) {
        currentSessionNullable.awaitNotNull().vehicleRelationships.insert(
            VehicleRelationship(
                DealershipVehiclePair(currentDealershipId.awaitNotNull(), selectedLot),
                vehicleDenormalizedInfo = currentSessionNullable.awaitNotNull().vehicles[selectedLot].awaitNotNull().short(),
                firstInteraction = now(),
            ).let { update(it) }
        )
    } else {
        currentSessionNullable.awaitNotNull().vehicleRelationships.get(
            DealershipVehiclePair(currentDealershipId.awaitNotNull(), selectedLot)
        ).modify(update)
    }
}