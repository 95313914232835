package com.ilussobsa.views.dialogs

import com.ilussobsa.Strings
import com.ilussobsa.UserPartial
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.report
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import kotlinx.coroutines.delay


class AddUserToDealership (val id: UUID): KiteUiScreen {
    val dealership = shared { currentSessionNullable.awaitNotNull().dealerships[id]() }
    val newEmail = Property("")
    val newName = Property("")
    val newPhone = Property("")
    override fun ViewWriter.render() {
        dismissBackground {
            onClick {  }    // Override default close behavior
            centered - sizeConstraints(width = 50.rem) - card - col {
                row {
                    h2(Strings.addAUser)
                    expanding - space()
                    gravity(Align.End, Align.Center) - button {
                        icon(Icon.close, Strings.close)
                        onClick {
                            dialogScreenNavigator.clear()
                        }
                    }
                }
                label {
                    content = Strings.name1
                    fieldTheme - textField {
                        content bind newName
                    }
                }
                label {
                    content = Strings.email2
                    fieldTheme - textField {
                        content bind newEmail
                    }
                }
                label {
                    content = Strings.phone
                    fieldTheme - textField {
                        content bind newPhone
                    }
                }
                space {}
                important - button {
                    gravity(Align.Center, Align.Center) - h6(Strings.save)
                    onClick {
                        try {
                            currentSession().dealership.addUserToDealership(
                                dealership.awaitNotNull()._id,
                                UserPartial(
                                    email = newEmail.await(),
                                    phoneNumber = newPhone.await(),
                                    name = newName.await(),
                                )
                            )
                            delay(200)
                            currentSession().users.totallyInvalidate()
                            newEmail set ""
                            navigator.dismiss()
                        } catch (e: Exception) {
                            e.report()
                            alert(Strings.error, Strings.thereWasAnErrorAddingThisUser)
                        }
                    }
                }
            }
        }
    }
}