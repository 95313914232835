// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareExtraInfoFields() {
    val props: Array<SerializableProperty<ExtraInfo, *>> = arrayOf(ExtraInfo_description)
    ExtraInfo.serializer().properties { props }
}
val <K> DataClassPath<K, ExtraInfo>.description: DataClassPath<K, String?> get() = this[ExtraInfo_description]
inline val ExtraInfo.Companion.path: DataClassPath<ExtraInfo, ExtraInfo> get() = path<ExtraInfo>()


object ExtraInfo_description: SerializableProperty<ExtraInfo, String?> {
    override val name: String = "description"
    override fun get(receiver: ExtraInfo): String? = receiver.description
    override fun setCopy(receiver: ExtraInfo, value: String?) = receiver.copy(description = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ExtraInfo.serializer().tryFindAnnotations("description")
    override val default: String? = null
}
