package com.ilussobsa

import com.lightningkite.kiteui.navigation.*
import com.ilussobsa.DealershipProfileScreen
import com.ilussobsa.views.AnimationTestingScreen
import com.ilussobsa.views.AuctionsScreen
import com.ilussobsa.views.BuyingCenterScreen
import com.ilussobsa.views.DashboardScreen
import com.ilussobsa.views.DealershipManagePeopleScreen
import com.ilussobsa.views.DealershipManageProfileScreen
import com.ilussobsa.views.DealershipRequestReviewScreen
import com.ilussobsa.views.DealershipRequestReviewDetailScreen
import com.ilussobsa.views.DealershipRequestReviewCompleteScreen
import com.ilussobsa.views.DealershipsScreen
import com.ilussobsa.views.EditVehicleScreen
import com.ilussobsa.views.LiveAuctionRunlistScreen
import com.ilussobsa.views.LiveAuctionScreen
import com.ilussobsa.views.LiveAuctionStressTestScreen
import com.ilussobsa.views.LogInScreen
import com.ilussobsa.views.NotificationsScreen
import com.ilussobsa.views.NotificationSettingsScreen
import com.ilussobsa.views.PrivacyPolicyScreen
import com.ilussobsa.views.ProfileScreen
import com.ilussobsa.views.ProposalScreen
import com.ilussobsa.views.RequestTransportQuoteScreen
import com.ilussobsa.views.SearchScreen
import com.ilussobsa.views.SearchFilterScreen
import com.ilussobsa.views.SellingCenterScreen
import com.ilussobsa.views.SellingCompleteBuyerScreen
import com.ilussobsa.views.SellingCompleteSellerScreen
import com.ilussobsa.views.SettingsScreen
import com.ilussobsa.views.SimulationScreen
import com.ilussobsa.views.TakeNewVehicleImagesScreen
import com.ilussobsa.views.TransportRequestsScreen
import com.ilussobsa.views.TutorialScreen
import com.ilussobsa.views.TutorialsScreen
import com.ilussobsa.views.VehicleDetailScreen
import com.ilussobsa.views.ViewTransportQuoteScreen
import com.lightningkite.kiteui.navigation.Screen.Empty
import com.ilussobsa.SearchParamsOnly
import com.ilussobsa.TransportAddress
import com.ilussobsa.views.BuyingCenterScreen.BuyingTab
import com.ilussobsa.views.DealershipRequestReviewScreen.JoinRequestFilterTab
import com.ilussobsa.views.EditVehicleScreen.Tab
import com.ilussobsa.views.LogInScreen.Notice
import com.ilussobsa.views.PhotoTakeStage
import com.ilussobsa.views.SellingCenterScreen.SellingTab
import com.ilussobsa.views.TransportRequestsScreen.TransportRequestsTab
import com.ilussobsa.views.TutorialItem
import com.lightningkite.UUID
import com.lightningkite.lightningdb.SortPart


val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "dealership") return@label null
            DealershipProfileScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "animation-test") return@label null
            AnimationTestingScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "auctions") return@label null
            AuctionsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "buying-center") return@label null
            BuyingCenterScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.VehicleRelationship>>>("sort", it.parameters)?.let { this.sort.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.BuyingCenterScreen.BuyingTab>("tab", it.parameters)?.let { this.tab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            DashboardScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "dealership") return@label null
            if (it.segments[2] != "manage") return@label null
            if (it.segments[3] != "people") return@label null
            DealershipManagePeopleScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "dealership") return@label null
            if (it.segments[2] != "manage") return@label null
            if (it.segments[3] != "profile") return@label null
            DealershipManageProfileScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "dealership-requests") return@label null
            DealershipRequestReviewScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.DealershipRequestReviewScreen.JoinRequestFilterTab>("tab", it.parameters)?.let { this.tab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "dealership-requests") return@label null
            DealershipRequestReviewDetailScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "dealership-requests-complete") return@label null
            DealershipRequestReviewCompleteScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "dealerships") return@label null
            DealershipsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.Int?>("distanceLimit", it.parameters)?.let { this.distanceLimit.value = it }
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("preferredOnly", it.parameters)?.let { this.preferredOnly.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedDealership", it.parameters)?.let { this.selectedDealership.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.Dealership>>>("sort", it.parameters)?.let { this.sort.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "edit-vehicle") return@label null
            EditVehicleScreen(
                vehicleId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("flowMode", it.parameters)?.let { this.flowMode.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.EditVehicleScreen.Tab>("tab", it.parameters)?.let { this.tab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "live-auction") return@label null
            if (it.segments[2] != "runlist") return@label null
            LiveAuctionRunlistScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "live-auction") return@label null
            LiveAuctionScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "live-auction") return@label null
            if (it.segments[2] != "stress-test") return@label null
            LiveAuctionStressTestScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "login") return@label null
            LogInScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String?>("backend", it.parameters)?.let { this.backend.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.LogInScreen.Notice?>("notice", it.parameters)?.let { this.notice.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String?>("proof", it.parameters)?.let { this.proof.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "notifications") return@label null
            NotificationsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "notifications") return@label null
            if (it.segments[1] != "settings") return@label null
            NotificationSettingsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "privacy") return@label null
            PrivacyPolicyScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "dealership") return@label null
            ProfileScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "proposal") return@label null
            ProposalScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "request-transport-quote") return@label null
            RequestTransportQuoteScreen(
                vehicleId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("deliveryMessage", it.parameters)?.let { this.deliveryMessage.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.TransportAddress>("dropoff", it.parameters)?.let { this.dropoff.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.TransportAddress>("pickup", it.parameters)?.let { this.pickup.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "search") return@label null
            SearchScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("limitToSeller", it.parameters)?.let { this.limitToSeller.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.query2.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("savedSearchId", it.parameters)?.let { this.savedSearchId.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.SearchParamsOnly>("search", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.Vehicle>>>("sort", it.parameters)?.let { this.sort.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "search-filter") return@label null
            SearchFilterScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("limitToSeller", it.parameters)?.let { this.limitToSeller.value = it }
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.query2.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("savedSearchId", it.parameters)?.let { this.savedSearchId.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.SearchParamsOnly>("search", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.Vehicle>>>("sort", it.parameters)?.let { this.sort.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "selling-center") return@label null
            SellingCenterScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.String>("query", it.parameters)?.let { this.search.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedLot", it.parameters)?.let { this.selectedLot.value = it }
                UrlProperties.decodeFromStringMap<kotlin.collections.List<com.lightningkite.lightningdb.SortPart<com.ilussobsa.Vehicle>>>("sort", it.parameters)?.let { this.sort.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.SellingCenterScreen.SellingTab>("tab", it.parameters)?.let { this.tab.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "vehicle") return@label null
            if (it.segments[2] != "complete") return@label null
            if (it.segments[3] != "buyer") return@label null
            SellingCompleteBuyerScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "vehicle") return@label null
            if (it.segments[2] != "complete") return@label null
            if (it.segments[3] != "seller") return@label null
            SellingCompleteSellerScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "settings") return@label null
            SettingsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "simulation") return@label null
            SimulationScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "upload-vehicle") return@label null
            if (it.segments[2] != "images") return@label null
            TakeNewVehicleImagesScreen(
                vehicleId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap<kotlin.Boolean>("single", it.parameters)?.let { this.single.value = it }
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.PhotoTakeStage?>("stage", it.parameters)?.let { this.stage.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "transport-requests") return@label null
            TransportRequestsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap<com.ilussobsa.views.TransportRequestsScreen.TransportRequestsTab>("filter", it.parameters)?.let { this.selectedTab.value = it }
                UrlProperties.decodeFromStringMap<com.lightningkite.UUID?>("selectedRequest", it.parameters)?.let { this.selectedRequest.value = it }
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "tutorial") return@label null
            TutorialScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "tutorials") return@label null
            TutorialsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "vehicle") return@label null
            VehicleDetailScreen(
                id = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "transport-quote") return@label null
            ViewTransportQuoteScreen(
                quoteId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
    ),
    renderers = mapOf(
        DealershipProfileScreen::class to label@{
            if (it !is DealershipProfileScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("dealership", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        AnimationTestingScreen::class to label@{
            if (it !is AnimationTestingScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("animation-test"),
                parameters = p
            ), listOf())
        },
        AuctionsScreen::class to label@{
            if (it !is AuctionsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("auctions"),
                parameters = p
            ), listOf())
        },
        BuyingCenterScreen::class to label@{
            if (it !is BuyingCenterScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "query", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("buying-center"),
                parameters = p
            ), listOf(it.search, it.selectedLot, it.sort, it.tab))
        },
        DashboardScreen::class to label@{
            if (it !is DashboardScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        DealershipManagePeopleScreen::class to label@{
            if (it !is DealershipManagePeopleScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("dealership", UrlProperties.encodeToString(it.id), "manage", "people"),
                parameters = p
            ), listOf())
        },
        DealershipManageProfileScreen::class to label@{
            if (it !is DealershipManageProfileScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("dealership", UrlProperties.encodeToString(it.id), "manage", "profile"),
                parameters = p
            ), listOf())
        },
        DealershipRequestReviewScreen::class to label@{
            if (it !is DealershipRequestReviewScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("dealership-requests"),
                parameters = p
            ), listOf(it.tab))
        },
        DealershipRequestReviewDetailScreen::class to label@{
            if (it !is DealershipRequestReviewDetailScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("dealership-requests", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        DealershipRequestReviewCompleteScreen::class to label@{
            if (it !is DealershipRequestReviewCompleteScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("dealership-requests-complete"),
                parameters = p
            ), listOf())
        },
        DealershipsScreen::class to label@{
            if (it !is DealershipsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.distanceLimit.value, "distanceLimit", p)
            UrlProperties.encodeToStringMap(it.preferredOnly.value, "preferredOnly", p)
            UrlProperties.encodeToStringMap(it.search.value, "query", p)
            UrlProperties.encodeToStringMap(it.selectedDealership.value, "selectedDealership", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            RouteRendered(UrlLikePath(
                segments = listOf("dealerships"),
                parameters = p
            ), listOf(it.distanceLimit, it.preferredOnly, it.search, it.selectedDealership, it.sort))
        },
        EditVehicleScreen::class to label@{
            if (it !is EditVehicleScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.flowMode.value, "flowMode", p)
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("edit-vehicle", UrlProperties.encodeToString(it.vehicleId)),
                parameters = p
            ), listOf(it.flowMode, it.tab))
        },
        LiveAuctionRunlistScreen::class to label@{
            if (it !is LiveAuctionRunlistScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("live-auction", UrlProperties.encodeToString(it.id), "runlist"),
                parameters = p
            ), listOf())
        },
        LiveAuctionScreen::class to label@{
            if (it !is LiveAuctionScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("live-auction", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        LiveAuctionStressTestScreen::class to label@{
            if (it !is LiveAuctionStressTestScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("live-auction", UrlProperties.encodeToString(it.id), "stress-test"),
                parameters = p
            ), listOf())
        },
        LogInScreen::class to label@{
            if (it !is LogInScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.backend.value, "backend", p)
            UrlProperties.encodeToStringMap(it.notice.value, "notice", p)
            UrlProperties.encodeToStringMap(it.proof.value, "proof", p)
            RouteRendered(UrlLikePath(
                segments = listOf("login"),
                parameters = p
            ), listOf(it.backend, it.notice, it.proof))
        },
        NotificationsScreen::class to label@{
            if (it !is NotificationsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("notifications"),
                parameters = p
            ), listOf())
        },
        NotificationSettingsScreen::class to label@{
            if (it !is NotificationSettingsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("notifications", "settings"),
                parameters = p
            ), listOf())
        },
        PrivacyPolicyScreen::class to label@{
            if (it !is PrivacyPolicyScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("privacy"),
                parameters = p
            ), listOf())
        },
        ProfileScreen::class to label@{
            if (it !is ProfileScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("dealership", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        ProposalScreen::class to label@{
            if (it !is ProposalScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("proposal"),
                parameters = p
            ), listOf())
        },
        RequestTransportQuoteScreen::class to label@{
            if (it !is RequestTransportQuoteScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.deliveryMessage.value, "deliveryMessage", p)
            UrlProperties.encodeToStringMap(it.dropoff.value, "dropoff", p)
            UrlProperties.encodeToStringMap(it.pickup.value, "pickup", p)
            RouteRendered(UrlLikePath(
                segments = listOf("request-transport-quote", UrlProperties.encodeToString(it.vehicleId)),
                parameters = p
            ), listOf(it.deliveryMessage, it.dropoff, it.pickup))
        },
        SearchScreen::class to label@{
            if (it !is SearchScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.limitToSeller.value, "limitToSeller", p)
            UrlProperties.encodeToStringMap(it.query2.value, "query", p)
            UrlProperties.encodeToStringMap(it.savedSearchId.value, "savedSearchId", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            RouteRendered(UrlLikePath(
                segments = listOf("search"),
                parameters = p
            ), listOf(it.limitToSeller, it.query2, it.savedSearchId, it.search, it.selectedLot, it.sort))
        },
        SearchFilterScreen::class to label@{
            if (it !is SearchFilterScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.limitToSeller.value, "limitToSeller", p)
            UrlProperties.encodeToStringMap(it.query2.value, "query", p)
            UrlProperties.encodeToStringMap(it.savedSearchId.value, "savedSearchId", p)
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            RouteRendered(UrlLikePath(
                segments = listOf("search-filter"),
                parameters = p
            ), listOf(it.limitToSeller, it.query2, it.savedSearchId, it.search, it.selectedLot, it.sort))
        },
        SellingCenterScreen::class to label@{
            if (it !is SellingCenterScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "query", p)
            UrlProperties.encodeToStringMap(it.selectedLot.value, "selectedLot", p)
            UrlProperties.encodeToStringMap(it.sort.value, "sort", p)
            UrlProperties.encodeToStringMap(it.tab.value, "tab", p)
            RouteRendered(UrlLikePath(
                segments = listOf("selling-center"),
                parameters = p
            ), listOf(it.search, it.selectedLot, it.sort, it.tab))
        },
        SellingCompleteBuyerScreen::class to label@{
            if (it !is SellingCompleteBuyerScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("vehicle", UrlProperties.encodeToString(it.id), "complete", "buyer"),
                parameters = p
            ), listOf())
        },
        SellingCompleteSellerScreen::class to label@{
            if (it !is SellingCompleteSellerScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("vehicle", UrlProperties.encodeToString(it.id), "complete", "seller"),
                parameters = p
            ), listOf())
        },
        SettingsScreen::class to label@{
            if (it !is SettingsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("settings"),
                parameters = p
            ), listOf())
        },
        SimulationScreen::class to label@{
            if (it !is SimulationScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("simulation"),
                parameters = p
            ), listOf())
        },
        TakeNewVehicleImagesScreen::class to label@{
            if (it !is TakeNewVehicleImagesScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.single.value, "single", p)
            UrlProperties.encodeToStringMap(it.stage.value, "stage", p)
            RouteRendered(UrlLikePath(
                segments = listOf("upload-vehicle", UrlProperties.encodeToString(it.vehicleId), "images"),
                parameters = p
            ), listOf(it.single, it.stage))
        },
        TransportRequestsScreen::class to label@{
            if (it !is TransportRequestsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.selectedTab.value, "filter", p)
            UrlProperties.encodeToStringMap(it.selectedRequest.value, "selectedRequest", p)
            RouteRendered(UrlLikePath(
                segments = listOf("transport-requests"),
                parameters = p
            ), listOf(it.selectedTab, it.selectedRequest))
        },
        TutorialScreen::class to label@{
            if (it !is TutorialScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("tutorial", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        TutorialsScreen::class to label@{
            if (it !is TutorialsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("tutorials"),
                parameters = p
            ), listOf())
        },
        VehicleDetailScreen::class to label@{
            if (it !is VehicleDetailScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("vehicle", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        ViewTransportQuoteScreen::class to label@{
            if (it !is ViewTransportQuoteScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("transport-quote", UrlProperties.encodeToString(it.quoteId)),
                parameters = p
            ), listOf())
        },
    ),
    fallback = Empty
)
