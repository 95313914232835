// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareVehicleSearchFields() {
    val props: Array<SerializableProperty<VehicleSearch, *>> = arrayOf(VehicleSearch__id, VehicleSearch_owner, VehicleSearch_name)
    VehicleSearch.serializer().properties { props }
}
val <K> DataClassPath<K, VehicleSearch>._id: DataClassPath<K, UUID> get() = this[VehicleSearch__id]
val <K> DataClassPath<K, VehicleSearch>.owner: DataClassPath<K, UUID> get() = this[VehicleSearch_owner]
val <K> DataClassPath<K, VehicleSearch>.name: DataClassPath<K, String> get() = this[VehicleSearch_name]
inline val VehicleSearch.Companion.path: DataClassPath<VehicleSearch, VehicleSearch> get() = path<VehicleSearch>()


object VehicleSearch__id: SerializableProperty<VehicleSearch, UUID> {
    override val name: String = "_id"
    override fun get(receiver: VehicleSearch): UUID = receiver._id
    override fun setCopy(receiver: VehicleSearch, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = VehicleSearch.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object VehicleSearch_owner: SerializableProperty<VehicleSearch, UUID> {
    override val name: String = "owner"
    override fun get(receiver: VehicleSearch): UUID = receiver.owner
    override fun setCopy(receiver: VehicleSearch, value: UUID) = receiver.copy(owner = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = VehicleSearch.serializer().tryFindAnnotations("owner")
}
object VehicleSearch_name: SerializableProperty<VehicleSearch, String> {
    override val name: String = "name"
    override fun get(receiver: VehicleSearch): String = receiver.name
    override fun setCopy(receiver: VehicleSearch, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = VehicleSearch.serializer().tryFindAnnotations("name")
}
