// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareUserFields() {
    val props: Array<SerializableProperty<User, *>> = arrayOf(User__id, User_email, User_phoneNumber, User_name, User_profilePicture, User_notifyAuctionStarting, User_notifyLastCallToEnroll, User_notifySearchMatch, User_notifyLaneNotifications, User_notifyProxyBidBeaten, User_notifyVehicleBought, User_notifyReceivedCounterOffer, User_notifyVehicleSold, User_notifyPendingOffer, User_notifyCounterOfferResult, User_notifyPendingOfferExpirationWarning, User_notifyTransportQuoteReceived, User_notifySystem, User_notificationSummaries, User_notificationsLastCheckedAt, User_role, User_managesMakes, User_managesDealerships, User_managesDealershipGroups, User_presentAtAuction, User_lastPresent, User_tutorialsComplete, User_checklistIgnore)
    User.serializer().properties { props }
}
val <K> DataClassPath<K, User>._id: DataClassPath<K, UUID> get() = this[User__id]
val <K> DataClassPath<K, User>.email: DataClassPath<K, String> get() = this[User_email]
val <K> DataClassPath<K, User>.phoneNumber: DataClassPath<K, String?> get() = this[User_phoneNumber]
val <K> DataClassPath<K, User>.name: DataClassPath<K, String> get() = this[User_name]
val <K> DataClassPath<K, User>.profilePicture: DataClassPath<K, ServerFile?> get() = this[User_profilePicture]
val <K> DataClassPath<K, User>.notifyAuctionStarting: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyAuctionStarting]
val <K> DataClassPath<K, User>.notifyLastCallToEnroll: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyLastCallToEnroll]
val <K> DataClassPath<K, User>.notifySearchMatch: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifySearchMatch]
val <K> DataClassPath<K, User>.notifyLaneNotifications: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyLaneNotifications]
val <K> DataClassPath<K, User>.notifyProxyBidBeaten: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyProxyBidBeaten]
val <K> DataClassPath<K, User>.notifyVehicleBought: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyVehicleBought]
val <K> DataClassPath<K, User>.notifyReceivedCounterOffer: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyReceivedCounterOffer]
val <K> DataClassPath<K, User>.notifyVehicleSold: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyVehicleSold]
val <K> DataClassPath<K, User>.notifyPendingOffer: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyPendingOffer]
val <K> DataClassPath<K, User>.notifyCounterOfferResult: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyCounterOfferResult]
val <K> DataClassPath<K, User>.notifyPendingOfferExpirationWarning: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyPendingOfferExpirationWarning]
val <K> DataClassPath<K, User>.notifyTransportQuoteReceived: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifyTransportQuoteReceived]
val <K> DataClassPath<K, User>.notifySystem: DataClassPath<K, NotificationTypeSettings> get() = this[User_notifySystem]
val <K> DataClassPath<K, User>.notificationSummaries: DataClassPath<K, Boolean> get() = this[User_notificationSummaries]
val <K> DataClassPath<K, User>.notificationsLastCheckedAt: DataClassPath<K, Instant> get() = this[User_notificationsLastCheckedAt]
val <K> DataClassPath<K, User>.role: DataClassPath<K, UserRole> get() = this[User_role]
val <K> DataClassPath<K, User>.managesMakes: DataClassPath<K, Set<MakeName>> get() = this[User_managesMakes]
val <K> DataClassPath<K, User>.managesDealerships: DataClassPath<K, Set<UUID>> get() = this[User_managesDealerships]
val <K> DataClassPath<K, User>.managesDealershipGroups: DataClassPath<K, Set<UUID>> get() = this[User_managesDealershipGroups]
val <K> DataClassPath<K, User>.presentAtAuction: DataClassPath<K, UUID?> get() = this[User_presentAtAuction]
val <K> DataClassPath<K, User>.lastPresent: DataClassPath<K, Instant> get() = this[User_lastPresent]
val <K> DataClassPath<K, User>.tutorialsComplete: DataClassPath<K, Set<String>> get() = this[User_tutorialsComplete]
val <K> DataClassPath<K, User>.checklistIgnore: DataClassPath<K, Set<String>> get() = this[User_checklistIgnore]
inline val User.Companion.path: DataClassPath<User, User> get() = path<User>()


object User__id: SerializableProperty<User, UUID> {
    override val name: String = "_id"
    override fun get(receiver: User): UUID = receiver._id
    override fun setCopy(receiver: User, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object User_email: SerializableProperty<User, String> {
    override val name: String = "email"
    override fun get(receiver: User): String = receiver.email
    override fun setCopy(receiver: User, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("email")
}
object User_phoneNumber: SerializableProperty<User, String?> {
    override val name: String = "phoneNumber"
    override fun get(receiver: User): String? = receiver.phoneNumber
    override fun setCopy(receiver: User, value: String?) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("phoneNumber")
    override val default: String? = null
}
object User_name: SerializableProperty<User, String> {
    override val name: String = "name"
    override fun get(receiver: User): String = receiver.name
    override fun setCopy(receiver: User, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("name")
    override val default: String = ""
}
object User_profilePicture: SerializableProperty<User, ServerFile?> {
    override val name: String = "profilePicture"
    override fun get(receiver: User): ServerFile? = receiver.profilePicture
    override fun setCopy(receiver: User, value: ServerFile?) = receiver.copy(profilePicture = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("profilePicture")
    override val default: ServerFile? = null
}
object User_notifyAuctionStarting: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyAuctionStarting"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyAuctionStarting
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyAuctionStarting = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyAuctionStarting")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.AuctionStarting)
}
object User_notifyLastCallToEnroll: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyLastCallToEnroll"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyLastCallToEnroll
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyLastCallToEnroll = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyLastCallToEnroll")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.LastCallToEnroll)
}
object User_notifySearchMatch: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifySearchMatch"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifySearchMatch
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifySearchMatch = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifySearchMatch")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.SearchMatch)
}
object User_notifyLaneNotifications: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyLaneNotifications"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyLaneNotifications
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyLaneNotifications = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyLaneNotifications")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.LaneNotifications)
}
object User_notifyProxyBidBeaten: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyProxyBidBeaten"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyProxyBidBeaten
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyProxyBidBeaten = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyProxyBidBeaten")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.ProxyBidBeaten)
}
object User_notifyVehicleBought: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyVehicleBought"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyVehicleBought
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyVehicleBought = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyVehicleBought")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.VehicleBought)
}
object User_notifyReceivedCounterOffer: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyReceivedCounterOffer"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyReceivedCounterOffer
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyReceivedCounterOffer = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyReceivedCounterOffer")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.ReceivedCounterOffer)
}
object User_notifyVehicleSold: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyVehicleSold"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyVehicleSold
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyVehicleSold = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyVehicleSold")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.VehicleSold)
}
object User_notifyPendingOffer: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyPendingOffer"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyPendingOffer
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyPendingOffer = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyPendingOffer")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.PendingOffer)
}
object User_notifyCounterOfferResult: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyCounterOfferResult"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyCounterOfferResult
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyCounterOfferResult = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyCounterOfferResult")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.CounterOfferResult)
}
object User_notifyPendingOfferExpirationWarning: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyPendingOfferExpirationWarning"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyPendingOfferExpirationWarning
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyPendingOfferExpirationWarning = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyPendingOfferExpirationWarning")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.PendingOfferExpirationWarning)
}
object User_notifyTransportQuoteReceived: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifyTransportQuoteReceived"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifyTransportQuoteReceived
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifyTransportQuoteReceived = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifyTransportQuoteReceived")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.TransportQuoteReceived)
}
object User_notifySystem: SerializableProperty<User, NotificationTypeSettings> {
    override val name: String = "notifySystem"
    override fun get(receiver: User): NotificationTypeSettings = receiver.notifySystem
    override fun setCopy(receiver: User, value: NotificationTypeSettings) = receiver.copy(notifySystem = value)
    override val serializer: KSerializer<NotificationTypeSettings> = NotificationTypeSettings.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notifySystem")
    override val default: NotificationTypeSettings = NotificationTypeSettings(defaultFor = NotificationTopic.System)
}
object User_notificationSummaries: SerializableProperty<User, Boolean> {
    override val name: String = "notificationSummaries"
    override fun get(receiver: User): Boolean = receiver.notificationSummaries
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(notificationSummaries = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notificationSummaries")
    override val default: Boolean = false
}
object User_notificationsLastCheckedAt: SerializableProperty<User, Instant> {
    override val name: String = "notificationsLastCheckedAt"
    override fun get(receiver: User): Instant = receiver.notificationsLastCheckedAt
    override fun setCopy(receiver: User, value: Instant) = receiver.copy(notificationsLastCheckedAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("notificationsLastCheckedAt")
    override val default: Instant = Instant.fromEpochSeconds(0)
}
object User_role: SerializableProperty<User, UserRole> {
    override val name: String = "role"
    override fun get(receiver: User): UserRole = receiver.role
    override fun setCopy(receiver: User, value: UserRole) = receiver.copy(role = value)
    override val serializer: KSerializer<UserRole> = UserRole.serializer()
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("role")
    override val default: UserRole = UserRole.Customer
}
object User_managesMakes: SerializableProperty<User, Set<MakeName>> {
    override val name: String = "managesMakes"
    override fun get(receiver: User): Set<MakeName> = receiver.managesMakes
    override fun setCopy(receiver: User, value: Set<MakeName>) = receiver.copy(managesMakes = value)
    override val serializer: KSerializer<Set<MakeName>> = SetSerializer(MakeName.serializer())
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("managesMakes")
    override val default: Set<MakeName> = setOf()
}
object User_managesDealerships: SerializableProperty<User, Set<UUID>> {
    override val name: String = "managesDealerships"
    override fun get(receiver: User): Set<UUID> = receiver.managesDealerships
    override fun setCopy(receiver: User, value: Set<UUID>) = receiver.copy(managesDealerships = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("managesDealerships")
    override val default: Set<UUID> = setOf()
}
object User_managesDealershipGroups: SerializableProperty<User, Set<UUID>> {
    override val name: String = "managesDealershipGroups"
    override fun get(receiver: User): Set<UUID> = receiver.managesDealershipGroups
    override fun setCopy(receiver: User, value: Set<UUID>) = receiver.copy(managesDealershipGroups = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("managesDealershipGroups")
    override val default: Set<UUID> = setOf()
}
object User_presentAtAuction: SerializableProperty<User, UUID?> {
    override val name: String = "presentAtAuction"
    override fun get(receiver: User): UUID? = receiver.presentAtAuction
    override fun setCopy(receiver: User, value: UUID?) = receiver.copy(presentAtAuction = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("presentAtAuction")
    override val default: UUID? = null
}
object User_lastPresent: SerializableProperty<User, Instant> {
    override val name: String = "lastPresent"
    override fun get(receiver: User): Instant = receiver.lastPresent
    override fun setCopy(receiver: User, value: Instant) = receiver.copy(lastPresent = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("lastPresent")
    override val default: Instant = now()
}
object User_tutorialsComplete: SerializableProperty<User, Set<String>> {
    override val name: String = "tutorialsComplete"
    override fun get(receiver: User): Set<String> = receiver.tutorialsComplete
    override fun setCopy(receiver: User, value: Set<String>) = receiver.copy(tutorialsComplete = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("tutorialsComplete")
    override val default: Set<String> = setOf()
}
object User_checklistIgnore: SerializableProperty<User, Set<String>> {
    override val name: String = "checklistIgnore"
    override fun get(receiver: User): Set<String> = receiver.checklistIgnore
    override fun setCopy(receiver: User, value: Set<String>) = receiver.copy(checklistIgnore = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = User.serializer().tryFindAnnotations("checklistIgnore")
    override val default: Set<String> = setOf()
}
