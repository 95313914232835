package com.ilussobsa.views.dialogs

import com.ilussobsa.*
import com.ilussobsa.sdk.currentDealershipId
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentSessionNullable
import com.lightningkite.UUID
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.notNull

class SoldToAnotherBuyer(val id: UUID) : Screen {
    override fun ViewWriter.render() {
        val price = Property<PriceInDollars?>(null)

        val vehicle = shared { currentSession().vehicles.get(id) }.flatten()
        val selectedDealership = Property<Dealership?>(null)

        val dealerships = shared {
            currentSession().dealerships.query(Query(condition { it.makes.any {
                    vehicle()?.make?.let { desiredMake ->
                        it.eq(desiredMake)
                    } ?: Condition.Never
            } }))().filter { it._id != currentDealershipId() } + null
        }

        dismissBackground {
            centered - card - col {
                spacing = 2.rem
                h1(Strings.soldToAnotherBuyer)

                field("Dealership") {
                    select {
                        bind(selectedDealership, dealerships) {
                            it?.name ?: "Pick one"
                        }
                    }
                }

                field("Price") {
                    row {
                        text("$")
                        expanding - numberInput {
                            content bind price.asDouble()
                        }
                    }
                }

                important - button {
                    centered - text("Set Buyer")
                    ::enabled {
                        price() != null && selectedDealership() != null
                    }
                    onClick {
                        currentSessionNullable.awaitNotNull().vehicle.markManualCompletion(id, WinnerAndPrice(
                            price = price()!!,
                            winner = selectedDealership()!!._id,
                        ))
                        currentSessionNullable.awaitNotNull().vehicles.totallyInvalidate()
                        dialogScreenNavigator.clear()
                    }
                }
            }

        }
    }
}