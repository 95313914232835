// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareNotificationFields() {
    val props: Array<SerializableProperty<Notification, *>> = arrayOf(Notification__id, Notification_receiver, Notification_title, Notification_content, Notification_topic, Notification_link, Notification_at)
    Notification.serializer().properties { props }
}
val <K> DataClassPath<K, Notification>._id: DataClassPath<K, UUID> get() = this[Notification__id]
val <K> DataClassPath<K, Notification>.receiver: DataClassPath<K, UUID> get() = this[Notification_receiver]
val <K> DataClassPath<K, Notification>.title: DataClassPath<K, String> get() = this[Notification_title]
val <K> DataClassPath<K, Notification>.content: DataClassPath<K, String?> get() = this[Notification_content]
val <K> DataClassPath<K, Notification>.topic: DataClassPath<K, NotificationTopic> get() = this[Notification_topic]
val <K> DataClassPath<K, Notification>.link: DataClassPath<K, String?> get() = this[Notification_link]
val <K> DataClassPath<K, Notification>.at: DataClassPath<K, Instant> get() = this[Notification_at]
inline val Notification.Companion.path: DataClassPath<Notification, Notification> get() = path<Notification>()


object Notification__id: SerializableProperty<Notification, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Notification): UUID = receiver._id
    override fun setCopy(receiver: Notification, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Notification.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Notification_receiver: SerializableProperty<Notification, UUID> {
    override val name: String = "receiver"
    override fun get(receiver: Notification): UUID = receiver.receiver
    override fun setCopy(receiver: Notification, value: UUID) = receiver.copy(receiver = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Notification.serializer().tryFindAnnotations("receiver")
}
object Notification_title: SerializableProperty<Notification, String> {
    override val name: String = "title"
    override fun get(receiver: Notification): String = receiver.title
    override fun setCopy(receiver: Notification, value: String) = receiver.copy(title = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Notification.serializer().tryFindAnnotations("title")
}
object Notification_content: SerializableProperty<Notification, String?> {
    override val name: String = "content"
    override fun get(receiver: Notification): String? = receiver.content
    override fun setCopy(receiver: Notification, value: String?) = receiver.copy(content = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Notification.serializer().tryFindAnnotations("content")
    override val default: String? = null
}
object Notification_topic: SerializableProperty<Notification, NotificationTopic> {
    override val name: String = "topic"
    override fun get(receiver: Notification): NotificationTopic = receiver.topic
    override fun setCopy(receiver: Notification, value: NotificationTopic) = receiver.copy(topic = value)
    override val serializer: KSerializer<NotificationTopic> = NotificationTopic.serializer()
    override val annotations: List<Annotation> = Notification.serializer().tryFindAnnotations("topic")
}
object Notification_link: SerializableProperty<Notification, String?> {
    override val name: String = "link"
    override fun get(receiver: Notification): String? = receiver.link
    override fun setCopy(receiver: Notification, value: String?) = receiver.copy(link = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Notification.serializer().tryFindAnnotations("link")
    override val default: String? = null
}
object Notification_at: SerializableProperty<Notification, Instant> {
    override val name: String = "at"
    override fun get(receiver: Notification): Instant = receiver.at
    override fun setCopy(receiver: Notification, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Notification.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
