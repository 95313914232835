// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareSavedSearchFields() {
    val props: Array<SerializableProperty<SavedSearch, *>> = arrayOf(SavedSearch__id, SavedSearch_dealership, SavedSearch_name, SavedSearch_fuelType, SavedSearch_transmission, SavedSearch_minYear, SavedSearch_maxYear, SavedSearch_mileage, SavedSearch_make, SavedSearch_model, SavedSearch_trim, SavedSearch_preferredSellerOnly, SavedSearch_maxDistanceMiles)
    SavedSearch.serializer().properties { props }
}
val <K> DataClassPath<K, SavedSearch>._id: DataClassPath<K, UUID> get() = this[SavedSearch__id]
val <K> DataClassPath<K, SavedSearch>.dealership: DataClassPath<K, UUID> get() = this[SavedSearch_dealership]
val <K> DataClassPath<K, SavedSearch>.name: DataClassPath<K, String> get() = this[SavedSearch_name]
val <K> DataClassPath<K, SavedSearch>.fuelType: DataClassPath<K, FuelType?> get() = this[SavedSearch_fuelType]
val <K> DataClassPath<K, SavedSearch>.transmission: DataClassPath<K, Transmission?> get() = this[SavedSearch_transmission]
val <K> DataClassPath<K, SavedSearch>.minYear: DataClassPath<K, Int?> get() = this[SavedSearch_minYear]
val <K> DataClassPath<K, SavedSearch>.maxYear: DataClassPath<K, Int?> get() = this[SavedSearch_maxYear]
val <K> DataClassPath<K, SavedSearch>.mileage: DataClassPath<K, Int?> get() = this[SavedSearch_mileage]
val <K> DataClassPath<K, SavedSearch>.make: DataClassPath<K, Set<String>?> get() = this[SavedSearch_make]
val <K> DataClassPath<K, SavedSearch>.model: DataClassPath<K, Set<String>?> get() = this[SavedSearch_model]
val <K> DataClassPath<K, SavedSearch>.trim: DataClassPath<K, String?> get() = this[SavedSearch_trim]
val <K> DataClassPath<K, SavedSearch>.preferredSellerOnly: DataClassPath<K, Boolean> get() = this[SavedSearch_preferredSellerOnly]
val <K> DataClassPath<K, SavedSearch>.maxDistanceMiles: DataClassPath<K, Int?> get() = this[SavedSearch_maxDistanceMiles]
inline val SavedSearch.Companion.path: DataClassPath<SavedSearch, SavedSearch> get() = path<SavedSearch>()


object SavedSearch__id: SerializableProperty<SavedSearch, UUID> {
    override val name: String = "_id"
    override fun get(receiver: SavedSearch): UUID = receiver._id
    override fun setCopy(receiver: SavedSearch, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object SavedSearch_dealership: SerializableProperty<SavedSearch, UUID> {
    override val name: String = "dealership"
    override fun get(receiver: SavedSearch): UUID = receiver.dealership
    override fun setCopy(receiver: SavedSearch, value: UUID) = receiver.copy(dealership = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("dealership")
}
object SavedSearch_name: SerializableProperty<SavedSearch, String> {
    override val name: String = "name"
    override fun get(receiver: SavedSearch): String = receiver.name
    override fun setCopy(receiver: SavedSearch, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("name")
}
object SavedSearch_fuelType: SerializableProperty<SavedSearch, FuelType?> {
    override val name: String = "fuelType"
    override fun get(receiver: SavedSearch): FuelType? = receiver.fuelType
    override fun setCopy(receiver: SavedSearch, value: FuelType?) = receiver.copy(fuelType = value)
    override val serializer: KSerializer<FuelType?> = FuelType.serializer().nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("fuelType")
    override val default: FuelType? = null
}
object SavedSearch_transmission: SerializableProperty<SavedSearch, Transmission?> {
    override val name: String = "transmission"
    override fun get(receiver: SavedSearch): Transmission? = receiver.transmission
    override fun setCopy(receiver: SavedSearch, value: Transmission?) = receiver.copy(transmission = value)
    override val serializer: KSerializer<Transmission?> = Transmission.serializer().nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("transmission")
    override val default: Transmission? = null
}
object SavedSearch_minYear: SerializableProperty<SavedSearch, Int?> {
    override val name: String = "minYear"
    override fun get(receiver: SavedSearch): Int? = receiver.minYear
    override fun setCopy(receiver: SavedSearch, value: Int?) = receiver.copy(minYear = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("minYear")
    override val default: Int? = null
}
object SavedSearch_maxYear: SerializableProperty<SavedSearch, Int?> {
    override val name: String = "maxYear"
    override fun get(receiver: SavedSearch): Int? = receiver.maxYear
    override fun setCopy(receiver: SavedSearch, value: Int?) = receiver.copy(maxYear = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("maxYear")
    override val default: Int? = null
}
object SavedSearch_mileage: SerializableProperty<SavedSearch, Int?> {
    override val name: String = "mileage"
    override fun get(receiver: SavedSearch): Int? = receiver.mileage
    override fun setCopy(receiver: SavedSearch, value: Int?) = receiver.copy(mileage = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("mileage")
    override val default: Int? = null
}
object SavedSearch_make: SerializableProperty<SavedSearch, Set<String>?> {
    override val name: String = "make"
    override fun get(receiver: SavedSearch): Set<String>? = receiver.make
    override fun setCopy(receiver: SavedSearch, value: Set<String>?) = receiver.copy(make = value)
    override val serializer: KSerializer<Set<String>?> = SetSerializer(String.serializer()).nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("make")
    override val default: Set<String>? = null
}
object SavedSearch_model: SerializableProperty<SavedSearch, Set<String>?> {
    override val name: String = "model"
    override fun get(receiver: SavedSearch): Set<String>? = receiver.model
    override fun setCopy(receiver: SavedSearch, value: Set<String>?) = receiver.copy(model = value)
    override val serializer: KSerializer<Set<String>?> = SetSerializer(String.serializer()).nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("model")
    override val default: Set<String>? = null
}
object SavedSearch_trim: SerializableProperty<SavedSearch, String?> {
    override val name: String = "trim"
    override fun get(receiver: SavedSearch): String? = receiver.trim
    override fun setCopy(receiver: SavedSearch, value: String?) = receiver.copy(trim = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("trim")
    override val default: String? = null
}
object SavedSearch_preferredSellerOnly: SerializableProperty<SavedSearch, Boolean> {
    override val name: String = "preferredSellerOnly"
    override fun get(receiver: SavedSearch): Boolean = receiver.preferredSellerOnly
    override fun setCopy(receiver: SavedSearch, value: Boolean) = receiver.copy(preferredSellerOnly = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("preferredSellerOnly")
    override val default: Boolean = false
}
object SavedSearch_maxDistanceMiles: SerializableProperty<SavedSearch, Int?> {
    override val name: String = "maxDistanceMiles"
    override fun get(receiver: SavedSearch): Int? = receiver.maxDistanceMiles
    override fun setCopy(receiver: SavedSearch, value: Int?) = receiver.copy(maxDistanceMiles = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = SavedSearch.serializer().tryFindAnnotations("maxDistanceMiles")
    override val default: Int? = null
}
