package com.ilussobsa.utils

import com.ilussobsa.Auctioneer
import com.ilussobsa.Resources
import com.lightningkite.kiteui.models.AudioResource
import com.lightningkite.kiteui.models.VideoResource

data class AuctioneerPack(
    val idle: List<VideoResource>,
    val idleAfterMoney: List<VideoResource>,
    val auctionEnd: EventResources,
    val goingOnce: EventResources,
    val goingTwice: EventResources,
    val next: EventResources,
    val priceDrop: EventResources,
    val bottomPrice: EventResources,
    val reserveMet: EventResources,
    val sold: EventResources,
    val offerReady: EventResources,
    val noSale: EventResources,
    val welcome: EventResources,
    val bidPlaced: EventResources,
    val firstBidPlaced: EventResources,
    val pokes: List<AuctioneerPoke>
)

data class EventResources(
    val video: List<VideoResource>,
    val audio: List<AudioResource>,
)

data class AuctioneerPoke(
    val audio: AudioResource,
    val text: String,
)

data class AuctioneerAudioPack(
    val auctionEnd: List<AudioResource>,
    val goingOnce: List<AudioResource>,
    val goingTwice: List<AudioResource>,
    val next: List<AudioResource>,
    val priceDrop: List<AudioResource>,
    val bottomPrice: List<AudioResource>,
    val reserveMet: List<AudioResource>,
    val sold: List<AudioResource>,
    val offerReady: List<AudioResource>,
    val noSale: List<AudioResource>,
    val welcome: List<AudioResource>,
    val bidPlaced: List<AudioResource>,
    val firstBidPlaced: List<AudioResource>,
    val pokes: List<AuctioneerPoke>
)

data class AuctioneerVideoPack(
    val idle: List<VideoResource>,
    val idleAfterMoney: List<VideoResource>,
    val auctionEnd: List<VideoResource>,
    val goingOnce: List<VideoResource>,
    val goingTwice: List<VideoResource>,
    val next: List<VideoResource>,
    val priceDrop: List<VideoResource>,
    val bottomPrice: List<VideoResource>,
    val reserveMet: List<VideoResource>,
    val sold: List<VideoResource>,
    val offerReady: List<VideoResource>,
    val noSale: List<VideoResource>,
    val welcome: List<VideoResource>,
    val bidPlaced: List<VideoResource>,
    val firstBidPlaced: List<VideoResource>,
)

operator fun AuctioneerAudioPack.plus(other: AuctioneerVideoPack) = AuctioneerPack(
    idle = other.idle,
    idleAfterMoney = other.idleAfterMoney,
    auctionEnd = EventResources(video = other.auctionEnd, audio = this.auctionEnd),
    goingOnce = EventResources(video = other.goingOnce, audio = this.goingOnce),
    goingTwice = EventResources(video = other.goingTwice, audio = this.goingTwice),
    next = EventResources(video = other.next, audio = this.next),
    priceDrop = EventResources(video = other.priceDrop, audio = this.priceDrop),
    bottomPrice = EventResources(video = other.bottomPrice, audio = this.bottomPrice),
    reserveMet = EventResources(video = other.reserveMet, audio = this.reserveMet),
    sold = EventResources(video = other.sold, audio = this.sold),
    offerReady = EventResources(video = other.offerReady, audio = this.offerReady),
    noSale = EventResources(video = other.noSale, audio = this.noSale),
    welcome = EventResources(video = other.welcome, audio = this.welcome),
    bidPlaced = EventResources(video = other.bidPlaced, audio = this.bidPlaced),
    firstBidPlaced = EventResources(video = other.firstBidPlaced, audio = this.firstBidPlaced),
    pokes = this.pokes
)

private val properAuctioneer = AuctioneerVideoPack(
    idle = listOf(Resources.auctioneerProperIdleStart),
    idleAfterMoney = listOf(Resources.auctioneerProperDancing),
    auctionEnd = listOf(),
    goingOnce = listOf(),
    goingTwice = listOf(),
    next = listOf(),
    priceDrop = listOf(),
    bottomPrice = listOf(),
    reserveMet = listOf(),
    sold = listOf(Resources.auctioneerProperSoldPoint),
    offerReady = listOf(Resources.auctioneerProperOfferReadyCall),
    noSale = listOf(Resources.auctioneerProperDieFromRight),
    welcome = listOf(),
    bidPlaced = listOf(),
    firstBidPlaced = listOf(Resources.auctioneerProperBackflip),
)

fun Auctioneer.audioPack(): AuctioneerPack = audioPacks[this] ?: audioPacks.values.first()
private val audioPacks = mapOf(
    Auctioneer.Recording1 to AuctioneerAudioPack(
        auctionEnd = listOf(
            Resources.auctioneerRecording1IntroIntro1,
            Resources.auctioneerRecording1IntroIntro2,
            Resources.auctioneerRecording1IntroIntro3
        ),
        goingOnce = listOf(
            Resources.auctioneerRecording1SoldGoingonce1,
            Resources.auctioneerRecording1SoldGoingonce2,
            Resources.auctioneerRecording1SoldGoingonce3
        ),
        goingTwice = listOf(
            Resources.auctioneerRecording1SoldGoingtwice4,
            Resources.auctioneerRecording1SoldGoingtwice5,
            Resources.auctioneerRecording1SoldGoingtwice6
        ),
        next = listOf(
            Resources.auctioneerRecording1NextNextUp1,
            Resources.auctioneerRecording1NextNextUp10,
            Resources.auctioneerRecording1NextNextUp2,
            Resources.auctioneerRecording1NextNextUp3,
            Resources.auctioneerRecording1NextNextUp4,
            Resources.auctioneerRecording1NextNextUp5,
            Resources.auctioneerRecording1NextNextUp6,
            Resources.auctioneerRecording1NextNextUp7,
            Resources.auctioneerRecording1NextNextUp8,
            Resources.auctioneerRecording1NextNextUp9,
        ),
        priceDrop = listOf(),
        bottomPrice = listOf(
            Resources.auctioneerRecording1BottomPriceCheap1,
            Resources.auctioneerRecording1BottomPriceCheap2,
            Resources.auctioneerRecording1BottomPriceCheap3,
            Resources.auctioneerRecording1BottomPriceWhosFirst1,
            Resources.auctioneerRecording1BottomPriceWhosFirst2,
            Resources.auctioneerRecording1BottomPriceWhosFirst3,
            Resources.auctioneerRecording1BottomPriceWhosFirst4,
            Resources.auctioneerRecording1BottomPriceWhosFirst5,
        ),
        reserveMet = listOf(
            Resources.auctioneerRecording1ReserveMetReserve1,
            Resources.auctioneerRecording1ReserveMetReserve10,
            Resources.auctioneerRecording1ReserveMetReserve11,
            Resources.auctioneerRecording1ReserveMetReserve12,
            Resources.auctioneerRecording1ReserveMetReserve13,
            Resources.auctioneerRecording1ReserveMetReserve14,
            Resources.auctioneerRecording1ReserveMetReserve2,
            Resources.auctioneerRecording1ReserveMetReserve3,
            Resources.auctioneerRecording1ReserveMetReserve4,
            Resources.auctioneerRecording1ReserveMetReserve5,
            Resources.auctioneerRecording1ReserveMetReserve6,
            Resources.auctioneerRecording1ReserveMetReserve7,
            Resources.auctioneerRecording1ReserveMetReserve8,
            Resources.auctioneerRecording1ReserveMetReserve9,
        ),
        sold = listOf(
            Resources.auctioneerRecording1SoldSold7,
            Resources.auctioneerRecording1SoldSold8,
            Resources.auctioneerRecording1SoldSold9,
        ),
        offerReady = listOf(
            Resources.auctioneerRecording1SoldOfferReady1,
            Resources.auctioneerRecording1SoldOfferReady2,
            Resources.auctioneerRecording1SoldOfferReady3,
        ),
        noSale = listOf(),
        welcome = listOf(
            Resources.auctioneerRecording1IntroIntro1,
            Resources.auctioneerRecording1IntroIntro2,
            Resources.auctioneerRecording1IntroIntro3,
        ),
        bidPlaced = listOf(
            Resources.auctioneerYepYep1,
            Resources.auctioneerYepYep2,
            Resources.auctioneerYepYep3,
            Resources.auctioneerYepYep4,
            Resources.auctioneerYepYep5,
            Resources.auctioneerYepYep6,
            Resources.auctioneerYepYep7,
            Resources.auctioneerYepYep8,
            Resources.auctioneerYepYep9,
            Resources.auctioneerYepYep10,
            Resources.auctioneerYepYep11,
            Resources.auctioneerYepYep12
        ),
        firstBidPlaced = listOf(
            Resources.auctioneerRecording1FirstBidFirstBid1,
            Resources.auctioneerRecording1FirstBidFirstBid10,
            Resources.auctioneerRecording1FirstBidFirstBid11,
            Resources.auctioneerRecording1FirstBidFirstBid12,
            Resources.auctioneerRecording1FirstBidFirstBid13,
            Resources.auctioneerRecording1FirstBidFirstBid14,
            Resources.auctioneerRecording1FirstBidFirstBid2,
            Resources.auctioneerRecording1FirstBidFirstBid3,
            Resources.auctioneerRecording1FirstBidFirstBid4,
            Resources.auctioneerRecording1FirstBidFirstBid5,
            Resources.auctioneerRecording1FirstBidFirstBid6,
            Resources.auctioneerRecording1FirstBidFirstBid7,
            Resources.auctioneerRecording1FirstBidFirstBid8,
            Resources.auctioneerRecording1FirstBidFirstBid9,
        ),
        pokes = listOf(
            AuctioneerPoke(
                Resources.auctioneerRecording1EncouragementEncourage1,
                "You are so close.  Don't give up now!"
            ),
            AuctioneerPoke(
                Resources.auctioneerRecording1EncouragementEncourage2,
                "You're almost there.  Don't give up!"
            ),
            AuctioneerPoke(
                Resources.auctioneerRecording1EncouragementEncourage3,
                "Almost to the finish line.  Don't give up now!"
            ),
            AuctioneerPoke(Resources.auctioneerRecording1EncouragementEncourage4, "Do not let them win!"),
            AuctioneerPoke(Resources.auctioneerRecording1EncouragementEncourage5, "Don't let them win!"),
            AuctioneerPoke(Resources.auctioneerRecording1EncouragementEncourage6, "You can't let them win!"),
            AuctioneerPoke(
                Resources.auctioneerRecording1EncouragementEncourage7,
                "You've come to far to stop now.  Keep going!"
            ),
            AuctioneerPoke(
                Resources.auctioneerRecording1EncouragementEncourage8,
                "You've come to far to stop now.  Keep going!"
            ),
            AuctioneerPoke(
                Resources.auctioneerRecording1EncouragementEncourage9,
                "You've come to far to stop now.  Keep going!"
            ),
        )
    ) + properAuctioneer,
)