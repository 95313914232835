// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareSellerTalkingPointFields() {
    val props: Array<SerializableProperty<SellerTalkingPoint, *>> = arrayOf(SellerTalkingPoint__id, SellerTalkingPoint_at, SellerTalkingPoint_vehicle, SellerTalkingPoint_seller, SellerTalkingPoint_message)
    SellerTalkingPoint.serializer().properties { props }
}
val <K> DataClassPath<K, SellerTalkingPoint>._id: DataClassPath<K, UUID> get() = this[SellerTalkingPoint__id]
val <K> DataClassPath<K, SellerTalkingPoint>.at: DataClassPath<K, Instant> get() = this[SellerTalkingPoint_at]
val <K> DataClassPath<K, SellerTalkingPoint>.vehicle: DataClassPath<K, UUID> get() = this[SellerTalkingPoint_vehicle]
val <K> DataClassPath<K, SellerTalkingPoint>.seller: DataClassPath<K, UUID> get() = this[SellerTalkingPoint_seller]
val <K> DataClassPath<K, SellerTalkingPoint>.message: DataClassPath<K, String> get() = this[SellerTalkingPoint_message]
inline val SellerTalkingPoint.Companion.path: DataClassPath<SellerTalkingPoint, SellerTalkingPoint> get() = path<SellerTalkingPoint>()


object SellerTalkingPoint__id: SerializableProperty<SellerTalkingPoint, UUID> {
    override val name: String = "_id"
    override fun get(receiver: SellerTalkingPoint): UUID = receiver._id
    override fun setCopy(receiver: SellerTalkingPoint, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = SellerTalkingPoint.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object SellerTalkingPoint_at: SerializableProperty<SellerTalkingPoint, Instant> {
    override val name: String = "at"
    override fun get(receiver: SellerTalkingPoint): Instant = receiver.at
    override fun setCopy(receiver: SellerTalkingPoint, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = SellerTalkingPoint.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
object SellerTalkingPoint_vehicle: SerializableProperty<SellerTalkingPoint, UUID> {
    override val name: String = "vehicle"
    override fun get(receiver: SellerTalkingPoint): UUID = receiver.vehicle
    override fun setCopy(receiver: SellerTalkingPoint, value: UUID) = receiver.copy(vehicle = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = SellerTalkingPoint.serializer().tryFindAnnotations("vehicle")
}
object SellerTalkingPoint_seller: SerializableProperty<SellerTalkingPoint, UUID> {
    override val name: String = "seller"
    override fun get(receiver: SellerTalkingPoint): UUID = receiver.seller
    override fun setCopy(receiver: SellerTalkingPoint, value: UUID) = receiver.copy(seller = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = SellerTalkingPoint.serializer().tryFindAnnotations("seller")
}
object SellerTalkingPoint_message: SerializableProperty<SellerTalkingPoint, String> {
    override val name: String = "message"
    override fun get(receiver: SellerTalkingPoint): String = receiver.message
    override fun setCopy(receiver: SellerTalkingPoint, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = SellerTalkingPoint.serializer().tryFindAnnotations("message")
}
