// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDealershipJoinRequestUserFields() {
    val props: Array<SerializableProperty<DealershipJoinRequestUser, *>> = arrayOf(DealershipJoinRequestUser_name, DealershipJoinRequestUser_email, DealershipJoinRequestUser_phone)
    DealershipJoinRequestUser.serializer().properties { props }
}
val <K> DataClassPath<K, DealershipJoinRequestUser>.name: DataClassPath<K, String> get() = this[DealershipJoinRequestUser_name]
val <K> DataClassPath<K, DealershipJoinRequestUser>.email: DataClassPath<K, String> get() = this[DealershipJoinRequestUser_email]
val <K> DataClassPath<K, DealershipJoinRequestUser>.phone: DataClassPath<K, String> get() = this[DealershipJoinRequestUser_phone]
inline val DealershipJoinRequestUser.Companion.path: DataClassPath<DealershipJoinRequestUser, DealershipJoinRequestUser> get() = path<DealershipJoinRequestUser>()


object DealershipJoinRequestUser_name: SerializableProperty<DealershipJoinRequestUser, String> {
    override val name: String = "name"
    override fun get(receiver: DealershipJoinRequestUser): String = receiver.name
    override fun setCopy(receiver: DealershipJoinRequestUser, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequestUser.serializer().tryFindAnnotations("name")
}
object DealershipJoinRequestUser_email: SerializableProperty<DealershipJoinRequestUser, String> {
    override val name: String = "email"
    override fun get(receiver: DealershipJoinRequestUser): String = receiver.email
    override fun setCopy(receiver: DealershipJoinRequestUser, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequestUser.serializer().tryFindAnnotations("email")
}
object DealershipJoinRequestUser_phone: SerializableProperty<DealershipJoinRequestUser, String> {
    override val name: String = "phone"
    override fun get(receiver: DealershipJoinRequestUser): String = receiver.phone
    override fun setCopy(receiver: DealershipJoinRequestUser, value: String) = receiver.copy(phone = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = DealershipJoinRequestUser.serializer().tryFindAnnotations("phone")
}
