package com.ilussobsa.views

import com.ilussobsa.Resources
import com.ilussobsa.Strings
import com.ilussobsa.utils.buy
import com.ilussobsa.utils.sell
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import kotlinx.serialization.Serializable

@Serializable
enum class TutorialItem(
    val title: String,
    val icon: Icon,
    val render: ViewWriter.() -> Unit
) {
    Starting(
        Strings.starting,
        Icon.person,
        {
            h1("Starting")
            h2("Profile Information")
            text("""
                 • Navigate from the menu to “My Account” and go to the “Settings” page
                 • Here you will be able to edit your user profile picture, phone number and display name
                 • After making a change click save in the bottom right corner
            """.trimIndent())
            image { source = Resources.tutorialsStartingImage6 } // image1
            h2("Dealership Information")
            text("""
                 • Navigate from the menu to “My Account” and go to the “Settings” page
                 • Scroll down the page to the “Dealerships” section 
                 • Find the dealership that you would like to edit and click “Profile”
            """.trimIndent())
            image { source = Resources.tutorialsStartingImage5 } // image2
            text("""
                 • Here on this page you can edit all the dealership information 
            """.trimIndent())
            image { source = Resources.tutorialsStartingImage3 } // image3
            h2("Users")
            text("""
                 • Navigate from the menu to “My Account” and go to the “Settings” page
                 • Scroll down the page to the “Dealerships” section 
                 • Find the dealership that you would like to manage the users and click “People”
            """.trimIndent())
            image { source = Resources.tutorialsStartingImage5 } // image4
            text("""
                 • Here you will be able to see all the current users attached to a dealership
                 • Users can be assigned as the “Title Manager”, “Used Car Manager” or “Transport Contact” 
                 • The users that are selected for these roles will have their information displayed publicly on the system to assist with the transactions 
                 • To add a new user, click the plus in the top right corner. Fill out the name, email, phone number and click save
                 • To switch roles for a user, find the user in the list that you would like to assign a role to. Click on the name of that roll on the right hand side. This will highlight the role blue and that user will now represent the dealership in that role
            """.trimIndent())
            image { source = Resources.tutorialsStartingImage4 } // image5
            h2("Notifications")
            text("""
                 • Click on the notification bell up in the top right corner of the screen to view all notifications 
            """.trimIndent())
            sizeConstraints(maxWidth = 20.rem) - image { source = Resources.tutorialsStartingImage2 } // image6
            text("""
                 • This will show all your notifications. The most recent notifications will be shown at the top
            """.trimIndent())
            image { source = Resources.tutorialsStartingImage7 } // image7
            text("""
                 • Notification preferences can be found by clicking on the settings icon on the top right of the page
                 • This page shows all of the possible ways BSA can notify you
                 • Each notification reason can be set to be an app notification, email notification or both
                 • Click on the icons to the right to toggle between on and off
                 • When the icon is highlighted blue that means the notification is on 
            """.trimIndent())
            image { source = Resources.tutorialsStartingImage1 } // image8
        }
    ),
    Selling(
        Strings.selling,
        Icon.sell,
        {
            h1("Selling")
            h2("Upload a vehicle")
            text("""
                 • Navigate from the menu to “Sell” and select “Upload New” 
                 • Uploads can be done on the app or from a computer 
                 • On desktop - Start by entering the vin and then uploading a picture of the exterior, interior, vin and odometer
                 • On mobile - Start by taking the pictures of the car and scanning the VIN
            """.trimIndent())

            rowCollapsingToColumn(60.rem) {
                expanding - space()
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage15 }
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage18 }
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage1 }
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage22 }
                expanding - space()
            }
            text("""
                 • Fill out the vehicle basics (Year, Make, Model, Trim, Color)
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage20 }
            text("""
                 • Enter the current odometer reading
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage17 }
            text("""
                 • Enter information on keys and tires 
                 • Choose if the car is a New Trade, Frontline Unit or already a CPO vehicle
                 • Enter if there is an active warranty on the vehicle
                 • If there was an inspection or any document with more information on the vehicle upload it here
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage6 }
            text("""
                 • Declare any known issues with the vehicle along with the title branding
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage12 }
            text("""
                 • Is the title available? If there was a payoff on the vehicle and the title is not immediately available, declare that here. (Brand Specific Marketplace will expect you to release the vehicle to the buyer at the conclusion of the auction and collect funds once the title is in your possession)
            """.trimIndent())
            centered - rowCollapsingToColumn(60.rem) {
                expanding - space()
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage5 }
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage24 }
                expanding - space()
            }
            text("""
                 • Take pictures of any damage on the vehicle. Take the picture and then add a description next to the photo explaining it
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage2 }
            text("""
                 • Here you have the option to add a description of the car. This is where you can mention any other issues or modifications on the car. Any special features or desirable options
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage9 }
            text("""
                 • The last step is to set a reserve price. This is the bottom price that you are willing to accept to sell the vehicle 
                 • This page will also ask you if you want to list this car in the next upcoming auction. Check the box yes will put the car in your inventory enrolled in the next auction. If you continue the upload without checking the box the car will just go to your drafts and can be enrolled anytime after
            """.trimIndent())
            centered - rowCollapsingToColumn(60.rem) {
                expanding - space()
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage16 }
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsSellingImage21 }
                expanding - space()
            }
            h2("Prepare for auction")
            text("""
                 • Navigate from the menu to “Sell” and select “Selling Center” 
                 • Here you will be able to view all the cars that you have uploaded to sell
                 • Check your drafts for any cars that have not yet been completed and enrolled in the auction
            """.trimIndent())
            image { source = Resources.tutorialsSellingImage3 }
            text("""
                 • Browse your inventory that is enrolled in the auction under the inventory tab
                 • Here you can unlist any cars that are no longer for sale
                 • Here you can adjust the reserve on any of your vehicles
            """.trimIndent())
            image { source = Resources.tutorialsSellingImage13 }
            h2("Attend auction")
            text("""
                 • Navigate from the menu to “Buy” and select “Live Auctions” 
                 • Select the lane that you would like to view 
                 • The cars that you are selling will be listed in the lanes based on Brand
                 • While watching an auction on a Brand that is not represented by your dealership you will be unable to bid 
                 • When your inventory is up on the block for sale you are able to authorize the sale and remove the reserve at any point
                 • As the seller you can also send messages into the live bid box for all the buyers to see
            """.trimIndent())
            centered - image { source = Resources.tutorialsSellingImage8 }
            text("""
                 • As a seller it is optional to attend the live auctions. The benefit to attending the live auction as a seller is to be able to send messages to all the live buyers to maximize the sale price of your vehicles
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 20.rem) - image { source = Resources.tutorialsSellingImage14 }
            h2("Complete transaction")
            text("""
                 • Once the auction has concluded return to the “Selling Center” to view all the results on your inventory 
                 • Navigate from the menu to “Sell” and select “Selling Center”
                 • Any cars that received bids but the reserve not met will be found under “Pending Offer”
                 • Here the seller will have the option to accept, decline or counter the highest bid
            """.trimIndent())
            image { source = Resources.tutorialsSellingImage23 }
            text("""
                 • Any pending offers that have been countered will be shown in the “Countered” tab until the buyer accepts or declines the offer
            """.trimIndent())
            image { source = Resources.tutorialsSellingImage19 }
            text("""
                 • The no sale tab will have all the vehicles that either did not receive a bid or that the offer was declined. Here you will have the option to either re • run the car in the next upcoming auction or to archive the listing
            """.trimIndent())
            image { source = Resources.tutorialsSellingImage4 }
            text("""
                 • All cars that successfully sell will be found in the “Sold” tab 
                 • Here you will find the information on the buying dealership
                 • There will be the name of the dealership, the used car managers contact information, the title managers contact information and the transport contact information
            """.trimIndent())
            image { source = Resources.tutorialsSellingImage10 }
            text("""
                 • Once you complete the selling transaction with the buyer on the “Sold” tab you can mark the transaction complete and report any issues you had with the buyer
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsSellingImage11 }
            text("""
                 • When a vehicle in the “Sold” tab is marked “Payment Received” it will move to the “Complete” tab
            """.trimIndent())
            image { source = Resources.tutorialsSellingImage7 }
        }
    ),
    Buying(
        Strings.buying,
        Icon.buy,
        {
            h1("Buying")
            h2("Search vehicles")
            text("""
                 • To search for vehicles to buy navigate from the menu to “Buy” and select “Search Vehicles” 
                 • Click the icon at the end of the search bar to pull up the filters
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsBuyingImage5 }
            text("""
                 • There are filters for year, make, model, trim, mileage, distance, fuel type and transmission
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage4 }
            text("""
                 • Click on a vehicle on the left to see the additional details and pictures of the vehicle 
                 • While on the vehicle page you can view all the available information about that vehicle 
                 • In the top right corner you can favorite the vehicle by clicking the star or set lane alerts by clicking the bell
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsBuyingImage1 }
            text("""
                 • While looking at vehicles you can also place proxy bids on the vehicles 
                 • To place a proxy bid enter the bid amount in the proxy bid box and click the check mark to confirm 
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 20.rem) - image { source = Resources.tutorialsBuyingImage6 }
            h2("Prepare for auction")
            text("""
                 • Navigate from the menu to “Buy” and select “Buying Center”
                 • Here there is tabs for “Favorites”, “Proxy Bids” and “Lane Alerts”
                 • This is where you can manage all the cars you have interacted with from the “Search Vehicles” page
            """.trimIndent())
            h3("Favorites")
            image { source = Resources.tutorialsBuyingImage10 }
            h3("Proxy Bids")
            image { source = Resources.tutorialsBuyingImage21 }
            h3("Lane Alerts")
            image { source = Resources.tutorialsBuyingImage15 }
            h2("Attend auction")
            text("""
                 • Navigate from the menu to “Buy” and select “Live Auctions”
                 • Select the lane that you want to enter (You will be able to enter any lane but only purchase vehicles that match the brand your dealership represents) 
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsBuyingImage8 }
            text("""
                 • Once you have entered the lane you will have a clear view of the vehicle for sale on the block
                 • On the right side all the vehicles in order are shown in the run list
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage2 }
            text("""
                 • The current asking price for the vehicle on the block will be shown on the bid button along with on the middle of the screen
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage17 }
            text("""
                 • Here you can see all of the vehicle information including VIN, Miles, Year, Make, Model, Trim, Photos, Damage Photos etc.
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage11 }
            text("""
                 • To place a bid click the big yellow bid button
            """.trimIndent())
            centered - rowCollapsingToColumn(60.rem) {
                expanding - space()
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsBuyingImage13 }
                sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsBuyingImage12 }
                expanding - space()
            }
            text("""
                 • All bid history on the vehicle will be displayed in the bid box
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsBuyingImage16 }
            text("""
                 • Past auction results for each vehicle will be displayed on the right side above the vehicle that is currently on the block 
                 • All upcoming vehicles will be displayed on the right side below the vehicle that is currently on the block
                 • On the list on the right side vehicles can be clicked on to view more information, favorite, set lane alerts or proxy bid
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsBuyingImage9 }
            text("""
                 • The highest current bid will be the bid that is in the bid box closest to the bottom 
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 15.rem) - image { source = Resources.tutorialsBuyingImage14 }
            h2("Complete transaction")
            text("""
                 • Once the auction has concluded return to the “Buying Center” to see the results from the auction
                 • Navigate from the menu to “Buy” and select “Buying Center” 
                 • Any cars that you are the highest bidder but the sellers reserve was not met will be found under “If Bids”
                 • If the seller accepts this offer before the end of the day then you have successfully won the car
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage20 }
            text("""
                 • Any “If Bids” get countered by the seller will end up in the “Received Counter” tab. Here you can choose to accept or decline the counter offer
                 • There is also a timer to show you how long you have to respond to the counter offer
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage19 }
            text("""
                 • All cars that are successfully purchased will be found in the “Purchased” tab 
                 • Here you will find the information on the selling dealership
                 • There will be the name of the dealership, the used car managers contact information, the title managers contact information and the transport contact information
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage3 }
            text("""
                 • Once you complete the buying transaction with the seller on the “Purchased” tab you can mark “Vehicle Received” to move the car out to the “Complete Tab”
            """.trimIndent())
            image { source = Resources.tutorialsBuyingImage7 }
            text("""
                 • Here you can also “Report Issues” if there were any complications with the seller
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 20.rem) - image { source = Resources.tutorialsBuyingImage18 }
        }
    ),
    Completing(
        Strings.completing,
        Icon.done,
        {
            h1("Completing")
            h2("Connect")
            text("""
                 • An email will be sent to both the buyer and seller when a car is sold to connect them with the contact information they need. This email will automatically going out to both used car managers and both title managers
                 • Contact information can also be found in the “Dealerships” section under “Buy”
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsCompletingImage4 }
            text("""
                 • In the “Buying Center” under “Buy” there is also all the seller's dealership information attached to the vehicle listing page. If the car has already been won at auction then you will navigate to the “Purchased” tab to find the vehicle and the contact information
            """.trimIndent())
            image { source = Resources.tutorialsCompletingImage5 }
            text("""
                 • In the “Selling Center” under “Sell” there is also all the buyer’s dealership information attached to the vehicle listing page. If the car has already been won at auction then you will navigate to the “Sold” tab to find the vehicle and the contact information
            """.trimIndent())
            image { source = Resources.tutorialsCompletingImage9 }
            h2("BSA Transport")
            text("""
                 • The buyer is responsible for transporting the vehicles they purchase 
                 • To order BSA Transport quote navigate the menu to “Buy” and select the “Buying Center” 
                 • Go to the “Purchased” tab and select the vehicle you would like to transport
                 • Confirm the shown pickup and drop off instructions 
            """.trimIndent())
            image { source = Resources.tutorialsCompletingImage3 }
            image { source = Resources.tutorialsCompletingImage10 }
            text("""
                 • Once the transport quote is ready return to this page to accept or decline the quote
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsCompletingImage1 }
            h2("Mark transaction complete")
            text("""
                 • Once you complete the buying transaction with the seller on the “Purchased” tab you can mark “Vehicle Received” to move the car out to the “Complete” Tab
            """.trimIndent())
            image { source = Resources.tutorialsCompletingImage11 }
            text("""
                 • Once you complete the selling transaction with the buyer on the “Sold” tab you can mark “Payment Received” to move the car out to the “Complete” Tab
            """.trimIndent())
            image { source = Resources.tutorialsCompletingImage7 }
            h2("Reputation/feedback")
            text("""
                 • On the “Dealerships” page you can view the dealerships contact information along with the ratings for that dealership
            """.trimIndent())
            image { source = Resources.tutorialsCompletingImage8 }
            text("""
                 • The accuracy rating rating represents a dealership's history of accurately representing the vehicles they sell on the platform. It is adjusted based on feedback from buyers who have made purchases from the dealership. An 'A' rating denotes the highest level of accuracy, while a 'B' indicates some past issues. A 'C' rating reflects multiple historical issues. Dealerships can improve their rating by completing more transactions without any reported problems.
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsCompletingImage6 }
            text("""
                 • The selling transaction rating reflects a dealership's historical performance on the platform, specifically evaluating the ease of transaction when selling a vehicle. It considers factors such as the simplicity of the payment process and the convenience of vehicle pickup. An 'A' rating signifies that the dealership is prompt and easy to work with, while a 'B' rating indicates some past issues. A 'C' rating denotes multiple historical challenges. Dealerships can enhance their rating by consistently completing transactions without reported issues
            """.trimIndent())
            centered - sizeConstraints(maxWidth = 30.rem) - image { source = Resources.tutorialsCompletingImage2 }
            text("""
                 • The buying transaction rating reflects a dealership's historical performance on the platform, specifically evaluating the ease of transaction when buying a vehicle. It considers factors such as the simplicity paying for the vehicle and getting the vehicle picked up. An 'A' rating signifies that the dealership is prompt and easy to work with, while a 'B' rating indicates some past issues. A 'C' rating denotes multiple historical challenges. Dealerships can enhance their rating by consistently completing transactions without reported issues
            """.trimIndent())
        }
    ),
}