package com.ilussobsa.views


import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.CancelledException
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.ImageLocal
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Writable
import com.lightningkite.kiteui.requestCaptureEnvironment
import com.lightningkite.kiteui.suspendCoroutineCancellable
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import org.w3c.dom.HTMLDivElement

actual class CameraPreview actual constructor(context: RContext): RView(context) {
    override fun internalAddChild(index: Int, view: RView) {
        super.internalAddChild(index, view)
        Stack.internalAddChildStack(this, index, view)
    }
    init {
        col {
            expanding - space()
            centered - h2("You poor soul.")
            centered - text("If you do this in the mobile app, taking pictures is really easy and comes with automatic VIN reading.")
            centered - text("However, the web is not currently configured to do this.  Perhaps you should consider switching?")
            centered - text("You can still upload vehicle information manually, though - click the checkmark below to upload a file.")
            expanding - space()
        }
    }
    actual suspend fun capture(): ImageLocal? {
        return suspendCoroutineCancellable<ImageLocal> { cont ->
//            ExternalServices.requestCaptureEnvironment(listOf("image/*")) {
//                if(it != null) cont.resume(ImageLocal(it))
//                else cont.resumeWithException(CancelledException())
//            }
            return@suspendCoroutineCancellable {}
        }
    }

    @ViewDsl
    actual fun onBarcode(action: (List<String>, Long) -> Unit) {
    }

    @ViewDsl
    actual fun onOCR(action: (String, Long) -> Unit) {
    }

    @ViewDsl
    actual val hasPermissions: Writable<Boolean>
        get() = Property(true)
}