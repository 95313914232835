package com.ilussobsa.views.dialogs

import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.views.LogInScreen
import com.ilussobsa.views.SettingsScreen
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon

//import com.lightningkite.kiteui.navigation.ScreenStack

fun  ViewWriter.selectDealership(close: ()->Unit) = sizeConstraints(minWidth = 20.rem) - col {
    sizeConstraints(maxHeight = 30.rem) - scrolls - col {
        onlyWhen {
            currentDealershipId() != null && currentDealershipId() !in myDealerships()
        } - button {
            row {
                centered - icon {
                    source = Icon.close
                }
                centered - text(Strings.endMasquerade)
            }
            onClick {
                currentDealershipId.set(myDealerships().firstOrNull())
            }
        }
        forEachUpdating(shared {
            myDealerships.awaitOnce().toList()
        }) { id ->
            val it = shared { currentSessionNullable.awaitNotNull().dealerships.get(id.await())() }
            button {
                ::enabled { it()?.active == true }
                row {
                    centered - sizeConstraints(width = 4.rem, height = 4.rem) - image {
                        scaleType = ImageScaleType.Crop
                        ::source {
                            it.await()?.profilePicture?.location?.let(::ImageRemote)
                                ?: Icon.person.toImageSource(Color.gray)
                        }
                        this.description = ""
                    }
                    centered - expanding - compact - col {
                        h4 { ::content { it.awaitNotNull().name } }
                        subtext { ::content { it.awaitNotNull().let { if(it.active) it.address.cityState else "Disabled" } } }
                    }
                }
                onClick {
                    currentDealershipId set id.await()
                    close()
                }
            }
        }
    }
    row {
        expanding - link {
            row {
                centered - icon(Icon.settings, "")
                centered - text(Strings.settings)
            }
            to = { SettingsScreen() }
            onNavigate { close() }
        }
        expanding - link {
            row {
                centered - icon(Icon.logout, "")
                centered - text(Strings.logOut)
            }
            to = { LogInScreen() }
            onNavigate { close() }
        }
    }
}