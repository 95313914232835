package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.checkCircle
import com.ilussobsa.utils.emptyCircle
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderTimeToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.nowLocal
import com.lightningkite.serialization.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.*

@Routable("/")
class DashboardScreen : KiteUiScreen {

    override fun ViewWriter.render() {
        AppScope.reactiveScope {
            if (currentUser()?.role == UserRole.TransportRep)
                navigator.reset(TransportRequestsScreen())
        }
        val now = now()
        scrolls - col {
            launch { delay(10); currentSession() }
            h1 { ::content { Strings.welcomeBackX(currentUser()?.name ?: "user") } }
            if (nowLocal().date.dayOfWeek == auctionWeekday) {
                rowCollapsingToColumn(60.rem) {
                    expanding - sizeConstraints(height = 15.rem) - liveAuctionsCard()
                    expanding - sizeConstraints(height = 15.rem) - achievements()
                    expanding - sizeConstraints(height = 15.rem) - tutorials()
                }
            } else {
                rowCollapsingToColumn(40.rem) {
                    expanding - sizeConstraints(height = 15.rem) - achievements()
                    expanding - sizeConstraints(height = 15.rem) - tutorials()
                }
            }
            card - vehicleRelationshipSection(BuyingCenterScreen.BuyingTab.ReceivedCounter.text, filter = {
                condition {
                    BuyingCenterScreen.BuyingTab.ReceivedCounter.filter() and it._id.dealership.eqNn(currentDealershipId())
                }
            }, seeMore = {
                { BuyingCenterScreen().apply { tab.value = BuyingCenterScreen.BuyingTab.ReceivedCounter } }
            })
            card - vehicleRelationshipSection(BuyingCenterScreen.BuyingTab.Bought.text, filter = {
                condition {
                    BuyingCenterScreen.BuyingTab.Bought.filter() and it._id.dealership.eqNn(currentDealershipId())
                }
            }, seeMore = {
                { BuyingCenterScreen().apply { tab.value = BuyingCenterScreen.BuyingTab.Bought } }
            })
            card - vehicleSection(SellingCenterScreen.SellingTab.HasBestOffer.text, filter = {
                condition {
                    it.seller.eqNn(currentDealershipId()) and SellingCenterScreen.SellingTab.HasBestOffer.filter()
                }
            }, seeMore = {
                { SellingCenterScreen().apply { tab.value = SellingCenterScreen.SellingTab.HasBestOffer } }
            })
            card - vehicleSection(SellingCenterScreen.SellingTab.Sold.text, filter = {
                condition {
                    it.seller.eqNn(currentDealershipId()) and SellingCenterScreen.SellingTab.Sold.filter()
                }
            }, seeMore = {
                { SellingCenterScreen().apply { tab.value = SellingCenterScreen.SellingTab.Sold } }
            })
            statisticsBlock(currentDealershipId)
        }
    }

    private fun ViewWriter.tutorials() {
        card - col {
            val checklist = shared {
                TutorialItem.values()
                    .map { it to (currentSession()?.me?.await()?.tutorialsComplete?.contains(it.name) ?: false) }
            }
            exists = false
            ::exists { checklist().any { !it.second } }
            h2 { ::content { Strings.tutorialsXY(checklist().count { it.second }, TutorialItem.values().size) } }
            scrollsHorizontally - row {
                forEachUpdating(checklist) {
                    themeFromLast { it.card().copy(cornerRadii = CornerRadii.Constant(it.spacing)) } - link {
                        spacing = 0.px
                        col {
                            ::opacity { if (it().second) 0.5 else 1.0 }
                            spacing = 0.px
                            padded - stack {
                                centered - icon {
                                    ::source { it().first.icon.copy(width = 5.rem, height = 5.rem) }
                                }
                            }
                            card - row {
                                centered - icon {
                                    ::source { if (it().second) Icon.checkCircle else Icon.emptyCircle }
                                }
                                centered - text { ::content { it().first.title } }
                            }
                        }
                        ::to { val id = it().first; { TutorialScreen(id) } }
                    }
                }
            }
        }
    }

    private fun ViewWriter.achievements() {
        card - col {
            val achievementStatus = Achievements.values()
                .associateWith {
                    shared {
                        it.determine(
                            currentSession(),
                            currentDealershipId() ?: nullUuid
                        ) || currentSession().me.await()?.checklistIgnore?.contains(it.name) == true
                    }
                }
            val achievementProgress = shared {
                achievementStatus.values.count { it() }
            }
            exists = false
            ::exists {
                achievementProgress() != Achievements.values().size
            }
            stack {
                h2 { ::content { Strings.welcomeChecklistXY(achievementProgress(), Achievements.values().size) } }
                atEnd - button {
                    icon(Icon.close, "Close")
                    onClick {
                        currentSession().let {
                            it.users[it.userId.await()].modify(modification {
                                it.checklistIgnore.addAll(Achievements.values().map { it.name }.toSet())
                            })
                        }
                    }
                }
            }
            expanding - scrolls - col {
                for (a in Achievements.values()) {
                    row {
                        val checked = shared { achievementStatus[a]!!() }
                        sizeConstraints(width = 2.rem, height = 2.rem) - unpadded - button {
                            dynamicTheme { if(checked()) ImportantSemantic else CardSemantic }
                            centered - icon {
                                source = Icon.done
                                ::exists { checked() }
                            }
                            onClick {
                                currentSession().let {
                                    it.users[it.userId.await()].modify(modification {
                                        it.checklistIgnore.addAll(setOf(a.name))
                                    })
                                }
                            }
                        }
                        centered - expanding - text(a.text)
                    }
                }
            }
        }
    }

    private fun ViewWriter.liveAuctionsCard() {
        card - col {
            h2(Strings.liveAuctionsToday)
            expanding - scrolls - col {
                forEachUpdating(placeholdersWhileLoading = 1, items = shared {
                    val session = currentSession()
                    currentDealershipId.await()?.let { session.dealerships[it].await() }?.let { myDealership ->
                        session.auctions.query(Query(condition {
                            it.requiresOneOfMakes.notNull.any { it.inside(myDealership.makes) } or it.requiresOneOfMakes.eq(
                                null
                            )
                        }))()
                    } ?: listOf()
                }) {
                    link {
                        row {
                            centered - text {
                                ::content {
                                    it.await().nextOrTodayStart.renderTimeToString(
                                        RenderSize.Abbreviation
                                    )
                                }
                            }
                            expanding - compact - col {
                                h3 { ::content { it.await().name } }
                                text { ::content { it.await().description } }
                            }
                            centered - text { ::content { "${it.await().vehicles} vehicles" } }
                        }
                        ::to { val id = it.await()._id; { LiveAuctionScreen(id) } }
                    }
                }
            }
        }
    }

    private fun ViewWriter.statisticsBlock(currentDealershipId: PersistentProperty<UUID?>) {
        card - col {
            h2(Strings.statistics)
            fun ViewWriter.stat(label: String, value: suspend () -> String) {
                centered - sizeConstraints(width = 30.rem) - row {
                    text(label)
                    expanding - col {
                        expanding - stack {}
                        separator()
                        expanding - stack {}
                    }
                    text {
                        align = Align.End
                        reactiveSuspending { content = value() }
                    }
                }
            }
            fun ViewWriter.statSet(filter: Condition<Vehicle>) {
                stat(Strings.vehiclesSold) {
                    currentSession().vehicles.skipCache.count(condition {
                        it.seller.eqNn(currentDealershipId()) and
                                it.completion.notNull.sold.eq(true) and
                                filter
                    }).toString()
                }
                stat(Strings.saleValueTotal) {
                    (currentSession().vehicles.skipCache.aggregate(AggregateQuery(Aggregate.Sum, condition {
                        it.seller.eqNn(currentDealershipId()) and
                                it.completion.notNull.sold.eq(true) and
                                filter
                    }, Vehicle.path.completion.notNull.price))?.toInt() ?: 0).renderPriceInDollars()
                }
                stat(Strings.vehiclesPurchased) {
                    currentSession().vehicles.skipCache.count(condition {
                        it.completion.notNull.winner.eq(currentDealershipId()) and
                                it.completion.notNull.sold.eq(true) and
                                filter
                    }).toString()
                }
                stat(Strings.purchaseValueTotal) {
                    (currentSession().vehicles.skipCache.aggregate(AggregateQuery(Aggregate.Sum, condition {
                        it.completion.notNull.winner.eq(currentDealershipId()) and
                                it.completion.notNull.sold.eq(true) and
                                filter
                    }, Vehicle.path.completion.notNull.price))?.toInt() ?: 0).renderPriceInDollars()
                }
            }
            col {
                ::exists { nowLocal().date.dayOfWeek >= auctionWeekday }
                val monday =
                    nowLocal().date.previousOrCurrentWeekday(DayOfWeek.MONDAY).atStartOfDayIn(TimeZone.UTC)
                val filter = condition<Vehicle> {
                    it.completion.notNull.at.gt(monday)
                }

                h4(Strings.lastWeek)
                statSet(filter)
            }
            col {
                val filter = condition<Vehicle>(true)
                h4(Strings.allTime)
                statSet(filter)
            }
        }
    }
}

private val sectionLoadCount = 10
fun ViewWriter.vehicleSection(
    name: String,
    filter: ReactiveContext.() -> Condition<Vehicle>,
    seeMore: (ReactiveContext.() -> () -> Screen)?
) {
    val items = shared {
        currentSessionNullable.awaitNotNull().vehicles.watch(
            Query(
                condition = filter(),
                limit = sectionLoadCount + 1
            )
        ).await()
    }
    stack {
        exists = false
        ::exists { items.await().isNotEmpty() }
        col {
            h3(name)
            stack {
                scrollsHorizontally - row {
                    forEachUpdating(items, placeholdersWhileLoading = 0) {
                        vehicleLink(it)
                    }
                }
                gravity(Align.End, Align.Center) - compact - card - link {
                    ::to { seeMore?.invoke() ?: { Screen.Empty } }
                    centered - icon(Icon.chevronRight, Strings.seeMore)
                    exists = false
                    ::exists { items.await().size > sectionLoadCount && seeMore != null }
                }
            }
        }
    }
}

fun ViewWriter.vehicleRelationshipSection(
    name: String,
    filter: ReactiveContext.() -> Condition<VehicleRelationship>,
    seeMore: (ReactiveContext.() -> () -> Screen)?
) {
    val items = shared {
        currentSessionNullable.awaitNotNull().vehicleRelationships.watch(
            Query(
                condition = filter() and condition { it._id.dealership eqNn currentDealershipId() },
                limit = sectionLoadCount + 1
            )
        ).await()
    }
    stack {
        exists = false
        ::exists { items.await().isNotEmpty() }
        col {
            h3(name)
            stack {
                scrollsHorizontally - row {
                    forEachUpdating(items, placeholdersWhileLoading = 0) {
                        vehicleLink(shared {
                            currentSessionNullable.awaitNotNull().vehicles.watch(it.await()._id.vehicle).awaitNotNull()
                        })
                    }
                }
                gravity(Align.End, Align.Center) - compact - card - link {
                    ::to { seeMore?.invoke() ?: { Screen.Empty } }
                    centered - icon(Icon.chevronRight, Strings.seeMore)
                    exists = false
                    ::exists { items.await().size > sectionLoadCount && seeMore != null }
                }
            }
        }
    }
}

fun ViewWriter.vehicleLink(vehicle: Readable<Vehicle>) {
    card - button {
        onClick {
            if (useTwoPane.await()) {
                dialogScreenNavigator.navigate(
                    SellingDialogWrapper(VehicleDetailScreen(vehicle.await()._id))
                )
            } else {
                navigator.navigate(VehicleDetailScreen(vehicle.await()._id))
//                navigator.navigate(VehicleDetailDummyScreen())
            }

        }
        col {
            sizeConstraints(width = 20.rem, height = 12.rem) - image {
                description = ""
                ::source { vehicle.await().exteriorThumbnail?.let { ImageRemote(it.location) } }
                scaleType = ImageScaleType.Crop
            }
            compact - col {
                h6 { ::content { vehicle.await().run { "$year $make $model" } } }
                subtext { ::content { vehicle.await().statusDescription() } }
                row {
                    centered - subtext {
                        ::content {
                            vehicle.await().odometer?.let { "${it.toCommaString()} mi" }
                                ?: Strings.noMileageInformation
                        }
                    }
                    centered - subtext("|")
                    centered - issueIcons(vehicle, size = 1.rem)
                }
            }
        }
    }
}