
package com.ilussobsa.sdk

import com.lightningkite.*
import com.lightningkite.lightningdb.*
import com.lightningkite.kiteui.*
import kotlinx.datetime.*
import com.lightningkite.serialization.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import kotlin.String
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.ilussobsa.User
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.UUID
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.lightningkite.lightningdb.ListChange
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.OtpSecret
import com.lightningkite.lightningserver.auth.proof.EstablishOtp
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.proof.PasswordSecret
import com.lightningkite.lightningserver.auth.proof.EstablishPassword
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.lightningkite.lightningserver.auth.subject.Session
import com.ilussobsa.FcmToken
import com.ilussobsa.DynamicSettings
import com.ilussobsa.VehicleRelationship
import com.ilussobsa.DealershipVehiclePair
import com.ilussobsa.Vehicle
import com.ilussobsa.WinnerAndPrice
import com.lightningkite.lightningdb.CollectionUpdates
import com.ilussobsa.TransportRequest
import com.ilussobsa.DealershipGroup
import com.ilussobsa.Dealership
import com.ilussobsa.UserPartial
import kotlin.Boolean
import com.ilussobsa.DealershipRelationship
import com.ilussobsa.DealershipPair
import com.ilussobsa.AuctionLane
import com.ilussobsa.Bid
import com.ilussobsa.LiveAuctionData
import com.ilussobsa.SellerTalkingPoint
import com.ilussobsa.SavedSearch
import com.ilussobsa.Notification
import com.ilussobsa.ResetDataRequest
import com.ilussobsa.Make
import com.ilussobsa.Model
import com.ilussobsa.SubscriptionPayment
import com.ilussobsa.DealershipJoinRequest

class LiveApi(val httpUrl: String, val socketUrl: String): Api {
    override val user: Api.UserApi = LiveUserApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val emailProof: Api.EmailProofApi = LiveEmailProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val otpSecret: Api.OtpSecretApi = LiveOtpSecretApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val oneTimePasswordProof: Api.OneTimePasswordProofApi = LiveOneTimePasswordProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val passwordSecret: Api.PasswordSecretApi = LivePasswordSecretApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val passwordProof: Api.PasswordProofApi = LivePasswordProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userAuth: Api.UserAuthApi = LiveUserAuthApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userSession: Api.UserSessionApi = LiveUserSessionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val fcmToken: Api.FcmTokenApi = LiveFcmTokenApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val dynamicSettings: Api.DynamicSettingsApi = LiveDynamicSettingsApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val vehicleRelationship: Api.VehicleRelationshipApi = LiveVehicleRelationshipApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val vehicle: Api.VehicleApi = LiveVehicleApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val transportRequest: Api.TransportRequestApi = LiveTransportRequestApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val dealershipGroup: Api.DealershipGroupApi = LiveDealershipGroupApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val dealership: Api.DealershipApi = LiveDealershipApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val dealershipRelationship: Api.DealershipRelationshipApi = LiveDealershipRelationshipApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val auctionLane: Api.AuctionLaneApi = LiveAuctionLaneApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val bid: Api.BidApi = LiveBidApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val liveAuctionData: Api.LiveAuctionDataApi = LiveLiveAuctionDataApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val sellerTalkingPoint: Api.SellerTalkingPointApi = LiveSellerTalkingPointApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val savedSearch: Api.SavedSearchApi = LiveSavedSearchApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val notification: Api.NotificationApi = LiveNotificationApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val subscription: Api.SubscriptionApi = LiveSubscriptionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val faker: Api.FakerApi = LiveFakerApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val make: Api.MakeApi = LiveMakeApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val model: Api.ModelApi = LiveModelApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val subscriptionPayment: Api.SubscriptionPaymentApi = LiveSubscriptionPaymentApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val dealershipJoinRequest: Api.DealershipJoinRequestApi = LiveDealershipJoinRequestApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override suspend fun logInViaApple(): String = fetch(
        url = "$httpUrl/proof/oauth/apple/login",
        method = HttpMethod.GET,
    )
    override suspend fun logInViaGoogle(): String = fetch(
        url = "$httpUrl/proof/oauth/google/login",
        method = HttpMethod.GET,
    )
    override suspend fun logInViaMicrosoft(): String = fetch(
        url = "$httpUrl/proof/oauth/microsoft/login",
        method = HttpMethod.GET,
    )
    override suspend fun uploadFileForRequest(): UploadInformation = fetch(
        url = "$httpUrl/upload-early",
        method = HttpMethod.GET,
    )
    override suspend fun getServerHealth(userAccessToken: suspend () -> String): ServerHealth = fetch(
        url = "$httpUrl/meta/health",
        method = HttpMethod.GET,
            token = userAccessToken,
    )
    override suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = fetch(
        url = "$httpUrl/meta/bulk",
        method = HttpMethod.POST,
        body = input
    )
    class LiveUserApi(val httpUrl: String, val socketUrl: String): Api.UserApi {
        override suspend fun default(userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<User>, userAccessToken: suspend () -> String): List<User> = fetch(
            url = "$httpUrl/users/rest/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<User>, userAccessToken: suspend () -> String): List<Partial<User>> = fetch(
            url = "$httpUrl/users/rest/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<User>, userAccessToken: suspend () -> String): List<User> = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: User, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: User, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<User>, userAccessToken: suspend () -> String): List<User> = fetch(
            url = "$httpUrl/users/rest",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: User, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<User>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/users/rest/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>, userAccessToken: suspend () -> String): EntryChange<User> = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<User>, userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<User>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/users/rest/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/users/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<User>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/users/rest/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<User>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/users/rest/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<User>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/users/rest/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/users/rest/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun watch(userToken: String): TypedWebSocket<Query<User>, ListChange<User>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/users/rest", 
            token = userToken,
        )
    }
    class LiveEmailProofApi(val httpUrl: String, val socketUrl: String): Api.EmailProofApi {
        override suspend fun beginEmailOwnershipProof(input: String): String = fetch(
            url = "$httpUrl/proof/email/start",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = fetch(
            url = "$httpUrl/proof/email/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveOtpSecretApi(val httpUrl: String, val socketUrl: String): Api.OtpSecretApi {
        override suspend fun query(input: Query<OtpSecret>, anyAccessToken: suspend () -> String): List<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets/query",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<OtpSecret>, anyAccessToken: suspend () -> String): List<Partial<OtpSecret>> = fetch(
            url = "$httpUrl/proof/otp/secrets/query-partial",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, anyAccessToken: suspend () -> String): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.GET,
            token = anyAccessToken,
        )
        override suspend fun insertBulk(input: List<OtpSecret>, anyAccessToken: suspend () -> String): List<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets/bulk",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun insert(input: OtpSecret, anyAccessToken: suspend () -> String): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: OtpSecret, anyAccessToken: suspend () -> String): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<OtpSecret>, anyAccessToken: suspend () -> String): List<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: OtpSecret, anyAccessToken: suspend () -> String): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<OtpSecret>, anyAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/proof/otp/secrets/bulk",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret>, anyAccessToken: suspend () -> String): EntryChange<OtpSecret> = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<OtpSecret>, anyAccessToken: suspend () -> String): OtpSecret = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<OtpSecret>, anyAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/proof/otp/secrets/bulk-delete",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, anyAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/proof/otp/secrets/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = anyAccessToken,
        )
        override suspend fun count(input: Condition<OtpSecret>, anyAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/proof/otp/secrets/count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret>, anyAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/proof/otp/secrets/group-count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<OtpSecret>, anyAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/proof/otp/secrets/aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret>, anyAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/proof/otp/secrets/group-aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
    }
    class LiveOneTimePasswordProofApi(val httpUrl: String, val socketUrl: String): Api.OneTimePasswordProofApi {
        override suspend fun establishOneTimePassword(input: EstablishOtp, anyAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/proof/otp/establish",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = fetch(
            url = "$httpUrl/proof/otp/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LivePasswordSecretApi(val httpUrl: String, val socketUrl: String): Api.PasswordSecretApi {
        override suspend fun query(input: Query<PasswordSecret>, anyAccessToken: suspend () -> String): List<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets/query",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PasswordSecret>, anyAccessToken: suspend () -> String): List<Partial<PasswordSecret>> = fetch(
            url = "$httpUrl/proof/password/secrets/query-partial",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, anyAccessToken: suspend () -> String): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.GET,
            token = anyAccessToken,
        )
        override suspend fun insertBulk(input: List<PasswordSecret>, anyAccessToken: suspend () -> String): List<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets/bulk",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun insert(input: PasswordSecret, anyAccessToken: suspend () -> String): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PasswordSecret, anyAccessToken: suspend () -> String): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<PasswordSecret>, anyAccessToken: suspend () -> String): List<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: PasswordSecret, anyAccessToken: suspend () -> String): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.PUT,
            token = anyAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PasswordSecret>, anyAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/proof/password/secrets/bulk",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PasswordSecret>, anyAccessToken: suspend () -> String): EntryChange<PasswordSecret> = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PasswordSecret>, anyAccessToken: suspend () -> String): PasswordSecret = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = anyAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PasswordSecret>, anyAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/proof/password/secrets/bulk-delete",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, anyAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = anyAccessToken,
        )
        override suspend fun count(input: Condition<PasswordSecret>, anyAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/proof/password/secrets/count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PasswordSecret>, anyAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/proof/password/secrets/group-count",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PasswordSecret>, anyAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/proof/password/secrets/aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PasswordSecret>, anyAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/proof/password/secrets/group-aggregate",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
    }
    class LivePasswordProofApi(val httpUrl: String, val socketUrl: String): Api.PasswordProofApi {
        override suspend fun establishPassword(input: EstablishPassword, anyAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/proof/password/establish",
            method = HttpMethod.POST,
            token = anyAccessToken,
            body = input
        )
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = fetch(
            url = "$httpUrl/proof/password/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveUserAuthApi(val httpUrl: String, val socketUrl: String): Api.UserAuthApi {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/login",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/login2",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = fetch(
            url = "$httpUrl/auth/proofs-check",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun openSession(input: String): String = fetch(
            url = "$httpUrl/auth/open-session",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun createSubSession(input: SubSessionRequest, userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/auth/sub-session",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = fetch(
            url = "$httpUrl/auth/token",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getTokenSimple(input: String): String = fetch(
            url = "$httpUrl/auth/token/simple",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getSelf(userAccessToken: suspend () -> String): User = fetch(
            url = "$httpUrl/auth/self",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun terminateSession(userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auth/terminate",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override suspend fun terminateOtherSession(sessionId: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auth/${sessionId.urlify()}/terminate",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
    }
    class LiveUserSessionApi(val httpUrl: String, val socketUrl: String): Api.UserSessionApi {
        override suspend fun query(input: Query<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Partial<Session<User, UUID>>> = fetch(
            url = "$httpUrl/auth/sessions/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Session<User, UUID>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Session<User, UUID>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Session<User, UUID>>, userAccessToken: suspend () -> String): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Session<User, UUID>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/sessions/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>, userAccessToken: suspend () -> String): EntryChange<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>, userAccessToken: suspend () -> String): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/sessions/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auth/sessions/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Session<User, UUID>>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auth/sessions/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/auth/sessions/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/auth/sessions/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/sessions/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveFcmTokenApi(val httpUrl: String, val socketUrl: String): Api.FcmTokenApi {
        override suspend fun default(userAccessToken: suspend () -> String): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<FcmToken>, userAccessToken: suspend () -> String): List<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<FcmToken>, userAccessToken: suspend () -> String): List<Partial<FcmToken>> = fetch(
            url = "$httpUrl/fcmTokens/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: String, userAccessToken: suspend () -> String): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<FcmToken>, userAccessToken: suspend () -> String): List<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: FcmToken, userAccessToken: suspend () -> String): FcmToken = fetch(
            url = "$httpUrl/fcmTokens",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: String, input: FcmToken, userAccessToken: suspend () -> String): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<FcmToken>, userAccessToken: suspend () -> String): List<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: String, input: FcmToken, userAccessToken: suspend () -> String): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<FcmToken>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/fcmTokens/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<FcmToken>, userAccessToken: suspend () -> String): EntryChange<FcmToken> = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<FcmToken>, userAccessToken: suspend () -> String): FcmToken = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<FcmToken>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/fcmTokens/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: String, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<FcmToken>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/fcmTokens/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<FcmToken>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/fcmTokens/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<FcmToken>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/fcmTokens/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<FcmToken>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/fcmTokens/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun testInAppNotifications(id: String, userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/fcmTokens/${id.urlify()}/test",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
    }
    class LiveDynamicSettingsApi(val httpUrl: String, val socketUrl: String): Api.DynamicSettingsApi {
        override suspend fun default(userAccessToken: suspend () -> String): DynamicSettings = fetch(
            url = "$httpUrl/dynamic-settings/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<DynamicSettings>, userAccessToken: suspend () -> String): List<DynamicSettings> = fetch(
            url = "$httpUrl/dynamic-settings/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<DynamicSettings>, userAccessToken: suspend () -> String): List<Partial<DynamicSettings>> = fetch(
            url = "$httpUrl/dynamic-settings/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: String, userAccessToken: suspend () -> String): DynamicSettings = fetch(
            url = "$httpUrl/dynamic-settings/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<DynamicSettings>, userAccessToken: suspend () -> String): List<DynamicSettings> = fetch(
            url = "$httpUrl/dynamic-settings/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: DynamicSettings, userAccessToken: suspend () -> String): DynamicSettings = fetch(
            url = "$httpUrl/dynamic-settings",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: String, input: DynamicSettings, userAccessToken: suspend () -> String): DynamicSettings = fetch(
            url = "$httpUrl/dynamic-settings/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<DynamicSettings>, userAccessToken: suspend () -> String): List<DynamicSettings> = fetch(
            url = "$httpUrl/dynamic-settings",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: String, input: DynamicSettings, userAccessToken: suspend () -> String): DynamicSettings = fetch(
            url = "$httpUrl/dynamic-settings/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<DynamicSettings>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dynamic-settings/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<DynamicSettings>, userAccessToken: suspend () -> String): EntryChange<DynamicSettings> = fetch(
            url = "$httpUrl/dynamic-settings/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<DynamicSettings>, userAccessToken: suspend () -> String): DynamicSettings = fetch(
            url = "$httpUrl/dynamic-settings/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<DynamicSettings>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dynamic-settings/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: String, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/dynamic-settings/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<DynamicSettings>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dynamic-settings/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<DynamicSettings>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/dynamic-settings/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<DynamicSettings>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/dynamic-settings/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<DynamicSettings>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/dynamic-settings/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveVehicleRelationshipApi(val httpUrl: String, val socketUrl: String): Api.VehicleRelationshipApi {
        override suspend fun default(userAccessToken: suspend () -> String): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<VehicleRelationship>, userAccessToken: suspend () -> String): List<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<VehicleRelationship>, userAccessToken: suspend () -> String): List<Partial<VehicleRelationship>> = fetch(
            url = "$httpUrl/vehicle-relationship/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: DealershipVehiclePair, userAccessToken: suspend () -> String): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<VehicleRelationship>, userAccessToken: suspend () -> String): List<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: VehicleRelationship, userAccessToken: suspend () -> String): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: DealershipVehiclePair, input: VehicleRelationship, userAccessToken: suspend () -> String): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<VehicleRelationship>, userAccessToken: suspend () -> String): List<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: DealershipVehiclePair, input: VehicleRelationship, userAccessToken: suspend () -> String): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<VehicleRelationship>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/vehicle-relationship/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: DealershipVehiclePair, input: Modification<VehicleRelationship>, userAccessToken: suspend () -> String): EntryChange<VehicleRelationship> = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: DealershipVehiclePair, input: Modification<VehicleRelationship>, userAccessToken: suspend () -> String): VehicleRelationship = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<VehicleRelationship>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/vehicle-relationship/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: DealershipVehiclePair, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/vehicle-relationship/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<VehicleRelationship>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/vehicle-relationship/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<VehicleRelationship>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/vehicle-relationship/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<VehicleRelationship>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/vehicle-relationship/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<VehicleRelationship>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/vehicle-relationship/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveVehicleApi(val httpUrl: String, val socketUrl: String): Api.VehicleApi {
        override suspend fun default(userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Vehicle>, userAccessToken: suspend () -> String): List<Vehicle> = fetch(
            url = "$httpUrl/vehicle/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Vehicle>, userAccessToken: suspend () -> String): List<Partial<Vehicle>> = fetch(
            url = "$httpUrl/vehicle/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Vehicle>, userAccessToken: suspend () -> String): List<Vehicle> = fetch(
            url = "$httpUrl/vehicle/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Vehicle, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Vehicle, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Vehicle>, userAccessToken: suspend () -> String): List<Vehicle> = fetch(
            url = "$httpUrl/vehicle",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Vehicle, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Vehicle>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/vehicle/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Vehicle>, userAccessToken: suspend () -> String): EntryChange<Vehicle> = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Vehicle>, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Vehicle>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/vehicle/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Vehicle>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/vehicle/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Vehicle>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/vehicle/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Vehicle>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/vehicle/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Vehicle>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/vehicle/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun markManualCompletion(id: UUID, input: WinnerAndPrice, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}/manual-completion",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun acceptInitialOffer(id: UUID, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}/accept-offer",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override suspend fun acceptCounterOffer(id: UUID, userAccessToken: suspend () -> String): Vehicle = fetch(
            url = "$httpUrl/vehicle/${id.urlify()}/accept-counter",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Vehicle>, CollectionUpdates<Vehicle, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/vehicle", 
            token = userToken,
        )
    }
    class LiveTransportRequestApi(val httpUrl: String, val socketUrl: String): Api.TransportRequestApi {
        override suspend fun default(userAccessToken: suspend () -> String): TransportRequest = fetch(
            url = "$httpUrl/transport-request/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<TransportRequest>, userAccessToken: suspend () -> String): List<TransportRequest> = fetch(
            url = "$httpUrl/transport-request/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<TransportRequest>, userAccessToken: suspend () -> String): List<Partial<TransportRequest>> = fetch(
            url = "$httpUrl/transport-request/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): TransportRequest = fetch(
            url = "$httpUrl/transport-request/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<TransportRequest>, userAccessToken: suspend () -> String): List<TransportRequest> = fetch(
            url = "$httpUrl/transport-request/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: TransportRequest, userAccessToken: suspend () -> String): TransportRequest = fetch(
            url = "$httpUrl/transport-request",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: TransportRequest, userAccessToken: suspend () -> String): TransportRequest = fetch(
            url = "$httpUrl/transport-request/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<TransportRequest>, userAccessToken: suspend () -> String): List<TransportRequest> = fetch(
            url = "$httpUrl/transport-request",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: TransportRequest, userAccessToken: suspend () -> String): TransportRequest = fetch(
            url = "$httpUrl/transport-request/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<TransportRequest>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/transport-request/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<TransportRequest>, userAccessToken: suspend () -> String): EntryChange<TransportRequest> = fetch(
            url = "$httpUrl/transport-request/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<TransportRequest>, userAccessToken: suspend () -> String): TransportRequest = fetch(
            url = "$httpUrl/transport-request/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<TransportRequest>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/transport-request/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/transport-request/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<TransportRequest>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/transport-request/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<TransportRequest>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/transport-request/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<TransportRequest>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/transport-request/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<TransportRequest>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/transport-request/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<TransportRequest>, CollectionUpdates<TransportRequest, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/transport-request", 
            token = userToken,
        )
    }
    class LiveDealershipGroupApi(val httpUrl: String, val socketUrl: String): Api.DealershipGroupApi {
        override suspend fun default(userAccessToken: suspend () -> String): DealershipGroup = fetch(
            url = "$httpUrl/dealership-group/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<DealershipGroup>, userAccessToken: suspend () -> String): List<DealershipGroup> = fetch(
            url = "$httpUrl/dealership-group/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<DealershipGroup>, userAccessToken: suspend () -> String): List<Partial<DealershipGroup>> = fetch(
            url = "$httpUrl/dealership-group/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): DealershipGroup = fetch(
            url = "$httpUrl/dealership-group/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<DealershipGroup>, userAccessToken: suspend () -> String): List<DealershipGroup> = fetch(
            url = "$httpUrl/dealership-group/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: DealershipGroup, userAccessToken: suspend () -> String): DealershipGroup = fetch(
            url = "$httpUrl/dealership-group",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: DealershipGroup, userAccessToken: suspend () -> String): DealershipGroup = fetch(
            url = "$httpUrl/dealership-group/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<DealershipGroup>, userAccessToken: suspend () -> String): List<DealershipGroup> = fetch(
            url = "$httpUrl/dealership-group",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: DealershipGroup, userAccessToken: suspend () -> String): DealershipGroup = fetch(
            url = "$httpUrl/dealership-group/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<DealershipGroup>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership-group/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<DealershipGroup>, userAccessToken: suspend () -> String): EntryChange<DealershipGroup> = fetch(
            url = "$httpUrl/dealership-group/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<DealershipGroup>, userAccessToken: suspend () -> String): DealershipGroup = fetch(
            url = "$httpUrl/dealership-group/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<DealershipGroup>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership-group/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/dealership-group/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<DealershipGroup>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership-group/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<DealershipGroup>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/dealership-group/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<DealershipGroup>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/dealership-group/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipGroup>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/dealership-group/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveDealershipApi(val httpUrl: String, val socketUrl: String): Api.DealershipApi {
        override suspend fun default(userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/dealership/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Dealership>, userAccessToken: suspend () -> String): List<Dealership> = fetch(
            url = "$httpUrl/dealership/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Dealership>, userAccessToken: suspend () -> String): List<Partial<Dealership>> = fetch(
            url = "$httpUrl/dealership/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/dealership/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Dealership>, userAccessToken: suspend () -> String): List<Dealership> = fetch(
            url = "$httpUrl/dealership/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Dealership, userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/dealership",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Dealership, userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/dealership/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Dealership>, userAccessToken: suspend () -> String): List<Dealership> = fetch(
            url = "$httpUrl/dealership",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Dealership, userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/dealership/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Dealership>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Dealership>, userAccessToken: suspend () -> String): EntryChange<Dealership> = fetch(
            url = "$httpUrl/dealership/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Dealership>, userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/dealership/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Dealership>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/dealership/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Dealership>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Dealership>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/dealership/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Dealership>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/dealership/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Dealership>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/dealership/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun addUserToDealership(id: UUID, input: UserPartial, userAccessToken: suspend () -> String): UUID = fetch(
            url = "$httpUrl/dealership/${id.urlify()}/users/add",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun removeUserFromDealership(id: UUID, input: String, userAccessToken: suspend () -> String): Boolean = fetch(
            url = "$httpUrl/dealership/${id.urlify()}/users/remove",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveDealershipRelationshipApi(val httpUrl: String, val socketUrl: String): Api.DealershipRelationshipApi {
        override suspend fun default(userAccessToken: suspend () -> String): DealershipRelationship = fetch(
            url = "$httpUrl/dealership-relationship/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<DealershipRelationship>, userAccessToken: suspend () -> String): List<DealershipRelationship> = fetch(
            url = "$httpUrl/dealership-relationship/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<DealershipRelationship>, userAccessToken: suspend () -> String): List<Partial<DealershipRelationship>> = fetch(
            url = "$httpUrl/dealership-relationship/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: DealershipPair, userAccessToken: suspend () -> String): DealershipRelationship = fetch(
            url = "$httpUrl/dealership-relationship/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<DealershipRelationship>, userAccessToken: suspend () -> String): List<DealershipRelationship> = fetch(
            url = "$httpUrl/dealership-relationship/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: DealershipRelationship, userAccessToken: suspend () -> String): DealershipRelationship = fetch(
            url = "$httpUrl/dealership-relationship",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: DealershipPair, input: DealershipRelationship, userAccessToken: suspend () -> String): DealershipRelationship = fetch(
            url = "$httpUrl/dealership-relationship/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<DealershipRelationship>, userAccessToken: suspend () -> String): List<DealershipRelationship> = fetch(
            url = "$httpUrl/dealership-relationship",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: DealershipPair, input: DealershipRelationship, userAccessToken: suspend () -> String): DealershipRelationship = fetch(
            url = "$httpUrl/dealership-relationship/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<DealershipRelationship>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership-relationship/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: DealershipPair, input: Modification<DealershipRelationship>, userAccessToken: suspend () -> String): EntryChange<DealershipRelationship> = fetch(
            url = "$httpUrl/dealership-relationship/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: DealershipPair, input: Modification<DealershipRelationship>, userAccessToken: suspend () -> String): DealershipRelationship = fetch(
            url = "$httpUrl/dealership-relationship/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<DealershipRelationship>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership-relationship/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: DealershipPair, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/dealership-relationship/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<DealershipRelationship>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/dealership-relationship/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<DealershipRelationship>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/dealership-relationship/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<DealershipRelationship>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/dealership-relationship/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipRelationship>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/dealership-relationship/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveAuctionLaneApi(val httpUrl: String, val socketUrl: String): Api.AuctionLaneApi {
        override suspend fun default(userAccessToken: suspend () -> String): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<AuctionLane>, userAccessToken: suspend () -> String): List<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<AuctionLane>, userAccessToken: suspend () -> String): List<Partial<AuctionLane>> = fetch(
            url = "$httpUrl/auction-lane/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<AuctionLane>, userAccessToken: suspend () -> String): List<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: AuctionLane, userAccessToken: suspend () -> String): AuctionLane = fetch(
            url = "$httpUrl/auction-lane",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: AuctionLane, userAccessToken: suspend () -> String): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<AuctionLane>, userAccessToken: suspend () -> String): List<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: AuctionLane, userAccessToken: suspend () -> String): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<AuctionLane>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auction-lane/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<AuctionLane>, userAccessToken: suspend () -> String): EntryChange<AuctionLane> = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<AuctionLane>, userAccessToken: suspend () -> String): AuctionLane = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<AuctionLane>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auction-lane/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/auction-lane/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<AuctionLane>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/auction-lane/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<AuctionLane>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/auction-lane/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<AuctionLane>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/auction-lane/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<AuctionLane>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/auction-lane/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveBidApi(val httpUrl: String, val socketUrl: String): Api.BidApi {
        override suspend fun default(userAccessToken: suspend () -> String): Bid = fetch(
            url = "$httpUrl/bid/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Bid>, userAccessToken: suspend () -> String): List<Bid> = fetch(
            url = "$httpUrl/bid/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Bid>, userAccessToken: suspend () -> String): List<Partial<Bid>> = fetch(
            url = "$httpUrl/bid/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Bid>, userAccessToken: suspend () -> String): List<Bid> = fetch(
            url = "$httpUrl/bid/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Bid, userAccessToken: suspend () -> String): Bid = fetch(
            url = "$httpUrl/bid",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Bid, userAccessToken: suspend () -> String): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Bid>, userAccessToken: suspend () -> String): List<Bid> = fetch(
            url = "$httpUrl/bid",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Bid, userAccessToken: suspend () -> String): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Bid>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/bid/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Bid>, userAccessToken: suspend () -> String): EntryChange<Bid> = fetch(
            url = "$httpUrl/bid/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Bid>, userAccessToken: suspend () -> String): Bid = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Bid>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/bid/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/bid/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Bid>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/bid/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Bid>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/bid/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Bid>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/bid/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Bid>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/bid/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<Bid>, CollectionUpdates<Bid, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/bid", 
            token = userToken,
        )
    }
    class LiveLiveAuctionDataApi(val httpUrl: String, val socketUrl: String): Api.LiveAuctionDataApi {
        override suspend fun default(userAccessToken: suspend () -> String): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<LiveAuctionData>, userAccessToken: suspend () -> String): List<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<LiveAuctionData>, userAccessToken: suspend () -> String): List<Partial<LiveAuctionData>> = fetch(
            url = "$httpUrl/live-auction-data/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<LiveAuctionData>, userAccessToken: suspend () -> String): List<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: LiveAuctionData, userAccessToken: suspend () -> String): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: LiveAuctionData, userAccessToken: suspend () -> String): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<LiveAuctionData>, userAccessToken: suspend () -> String): List<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: LiveAuctionData, userAccessToken: suspend () -> String): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<LiveAuctionData>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/live-auction-data/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LiveAuctionData>, userAccessToken: suspend () -> String): EntryChange<LiveAuctionData> = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<LiveAuctionData>, userAccessToken: suspend () -> String): LiveAuctionData = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<LiveAuctionData>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/live-auction-data/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/live-auction-data/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<LiveAuctionData>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/live-auction-data/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<LiveAuctionData>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/live-auction-data/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<LiveAuctionData>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/live-auction-data/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<LiveAuctionData>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/live-auction-data/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun whoLastCallToEnroll(userAccessToken: suspend () -> String): List<Dealership> = fetch(
            url = "$httpUrl/live-auction-data/lastEnrollCallCheck",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun start(id: UUID, userAccessToken: suspend () -> String): LiveAuctionData? = fetch(
            url = "$httpUrl/live-auction-data/start/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun notifyHover(id: UUID, dealership: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/hover/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun notifyUnhover(id: UUID, dealership: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/unhover/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun notifyJoin(id: UUID, dealership: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/join/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun notifyLeave(id: UUID, dealership: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/live-auction-data/inform/${id.urlify()}/leave/${dealership.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<LiveAuctionData>, CollectionUpdates<LiveAuctionData, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/live-auction-data", 
            token = userToken,
        )
    }
    class LiveSellerTalkingPointApi(val httpUrl: String, val socketUrl: String): Api.SellerTalkingPointApi {
        override suspend fun default(userAccessToken: suspend () -> String): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<SellerTalkingPoint>, userAccessToken: suspend () -> String): List<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<SellerTalkingPoint>, userAccessToken: suspend () -> String): List<Partial<SellerTalkingPoint>> = fetch(
            url = "$httpUrl/seller-talking-points/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<SellerTalkingPoint>, userAccessToken: suspend () -> String): List<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: SellerTalkingPoint, userAccessToken: suspend () -> String): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: SellerTalkingPoint, userAccessToken: suspend () -> String): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<SellerTalkingPoint>, userAccessToken: suspend () -> String): List<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: SellerTalkingPoint, userAccessToken: suspend () -> String): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<SellerTalkingPoint>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/seller-talking-points/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SellerTalkingPoint>, userAccessToken: suspend () -> String): EntryChange<SellerTalkingPoint> = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<SellerTalkingPoint>, userAccessToken: suspend () -> String): SellerTalkingPoint = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<SellerTalkingPoint>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/seller-talking-points/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/seller-talking-points/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<SellerTalkingPoint>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/seller-talking-points/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<SellerTalkingPoint>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/seller-talking-points/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<SellerTalkingPoint>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/seller-talking-points/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<SellerTalkingPoint>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/seller-talking-points/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override fun updates(userToken: String): TypedWebSocket<Condition<SellerTalkingPoint>, CollectionUpdates<SellerTalkingPoint, UUID>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/seller-talking-points", 
            token = userToken,
        )
    }
    class LiveSavedSearchApi(val httpUrl: String, val socketUrl: String): Api.SavedSearchApi {
        override suspend fun default(userAccessToken: suspend () -> String): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<SavedSearch>, userAccessToken: suspend () -> String): List<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<SavedSearch>, userAccessToken: suspend () -> String): List<Partial<SavedSearch>> = fetch(
            url = "$httpUrl/saved-searches/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<SavedSearch>, userAccessToken: suspend () -> String): List<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: SavedSearch, userAccessToken: suspend () -> String): SavedSearch = fetch(
            url = "$httpUrl/saved-searches",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: SavedSearch, userAccessToken: suspend () -> String): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<SavedSearch>, userAccessToken: suspend () -> String): List<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: SavedSearch, userAccessToken: suspend () -> String): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<SavedSearch>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/saved-searches/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SavedSearch>, userAccessToken: suspend () -> String): EntryChange<SavedSearch> = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<SavedSearch>, userAccessToken: suspend () -> String): SavedSearch = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<SavedSearch>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/saved-searches/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/saved-searches/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<SavedSearch>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/saved-searches/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<SavedSearch>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/saved-searches/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<SavedSearch>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/saved-searches/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<SavedSearch>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/saved-searches/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveNotificationApi(val httpUrl: String, val socketUrl: String): Api.NotificationApi {
        override suspend fun default(userAccessToken: suspend () -> String): Notification = fetch(
            url = "$httpUrl/notification/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Notification>, userAccessToken: suspend () -> String): List<Notification> = fetch(
            url = "$httpUrl/notification/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Notification>, userAccessToken: suspend () -> String): List<Partial<Notification>> = fetch(
            url = "$httpUrl/notification/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Notification>, userAccessToken: suspend () -> String): List<Notification> = fetch(
            url = "$httpUrl/notification/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Notification, userAccessToken: suspend () -> String): Notification = fetch(
            url = "$httpUrl/notification",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Notification, userAccessToken: suspend () -> String): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Notification>, userAccessToken: suspend () -> String): List<Notification> = fetch(
            url = "$httpUrl/notification",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: Notification, userAccessToken: suspend () -> String): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Notification>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/notification/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Notification>, userAccessToken: suspend () -> String): EntryChange<Notification> = fetch(
            url = "$httpUrl/notification/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Notification>, userAccessToken: suspend () -> String): Notification = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Notification>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/notification/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/notification/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Notification>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/notification/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Notification>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/notification/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Notification>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/notification/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Notification>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/notification/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveSubscriptionApi(val httpUrl: String, val socketUrl: String): Api.SubscriptionApi {
        override suspend fun createSubscription(dealership: UUID, userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/subscriptions/create-subscription/${dealership.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override suspend fun getPortalLink(dealershipId: UUID, userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/subscriptions/${dealershipId.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
    }
    class LiveFakerApi(val httpUrl: String, val socketUrl: String): Api.FakerApi {
        override suspend fun saveState(userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/fake-data/save-state",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override suspend fun loadState(userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/fake-data/load-state",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override suspend fun resetTestData(input: ResetDataRequest, userAccessToken: suspend () -> String): String = fetch(
            url = "$httpUrl/fake-data/reset",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveMakeApi(val httpUrl: String, val socketUrl: String): Api.MakeApi {
        override suspend fun default(userAccessToken: (suspend () -> String)?): Make = fetch(
            url = "$httpUrl/makes/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Make>, userAccessToken: (suspend () -> String)?): List<Make> = fetch(
            url = "$httpUrl/makes/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Make>, userAccessToken: (suspend () -> String)?): List<Partial<Make>> = fetch(
            url = "$httpUrl/makes/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: String, userAccessToken: (suspend () -> String)?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Make>, userAccessToken: (suspend () -> String)?): List<Make> = fetch(
            url = "$httpUrl/makes/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Make, userAccessToken: (suspend () -> String)?): Make = fetch(
            url = "$httpUrl/makes",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: String, input: Make, userAccessToken: (suspend () -> String)?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Make>, userAccessToken: (suspend () -> String)?): List<Make> = fetch(
            url = "$httpUrl/makes",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: String, input: Make, userAccessToken: (suspend () -> String)?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Make>, userAccessToken: (suspend () -> String)?): Int = fetch(
            url = "$httpUrl/makes/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>, userAccessToken: (suspend () -> String)?): EntryChange<Make> = fetch(
            url = "$httpUrl/makes/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<Make>, userAccessToken: (suspend () -> String)?): Make = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Make>, userAccessToken: (suspend () -> String)?): Int = fetch(
            url = "$httpUrl/makes/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: String, userAccessToken: (suspend () -> String)?): Unit = fetch(
            url = "$httpUrl/makes/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Make>, userAccessToken: (suspend () -> String)?): Int = fetch(
            url = "$httpUrl/makes/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Make>, userAccessToken: (suspend () -> String)?): Map<String, Int> = fetch(
            url = "$httpUrl/makes/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Make>, userAccessToken: (suspend () -> String)?): Double? = fetch(
            url = "$httpUrl/makes/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>, userAccessToken: (suspend () -> String)?): Map<String, Double?> = fetch(
            url = "$httpUrl/makes/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun sync(userAccessToken: suspend () -> String): List<Make> = fetch(
            url = "$httpUrl/makes/sync",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
        override suspend fun syncAll(userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/makes/syncAll",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
    }
    class LiveModelApi(val httpUrl: String, val socketUrl: String): Api.ModelApi {
        override suspend fun default(userAccessToken: suspend () -> String): Model = fetch(
            url = "$httpUrl/models/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<Model>, userAccessToken: suspend () -> String): List<Model> = fetch(
            url = "$httpUrl/models/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Model>, userAccessToken: suspend () -> String): List<Partial<Model>> = fetch(
            url = "$httpUrl/models/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: String, userAccessToken: suspend () -> String): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<Model>, userAccessToken: suspend () -> String): List<Model> = fetch(
            url = "$httpUrl/models/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: Model, userAccessToken: suspend () -> String): Model = fetch(
            url = "$httpUrl/models",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: String, input: Model, userAccessToken: suspend () -> String): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<Model>, userAccessToken: suspend () -> String): List<Model> = fetch(
            url = "$httpUrl/models",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: String, input: Model, userAccessToken: suspend () -> String): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Model>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/models/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<Model>, userAccessToken: suspend () -> String): EntryChange<Model> = fetch(
            url = "$httpUrl/models/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<Model>, userAccessToken: suspend () -> String): Model = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Model>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/models/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: String, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/models/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<Model>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/models/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Model>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/models/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Model>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/models/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Model>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/models/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun sync(make: String, userAccessToken: suspend () -> String): List<Model> = fetch(
            url = "$httpUrl/models/sync/${make.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
    }
    class LiveSubscriptionPaymentApi(val httpUrl: String, val socketUrl: String): Api.SubscriptionPaymentApi {
        override suspend fun default(userAccessToken: suspend () -> String): SubscriptionPayment = fetch(
            url = "$httpUrl/subscription-payments/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<SubscriptionPayment>, userAccessToken: suspend () -> String): List<SubscriptionPayment> = fetch(
            url = "$httpUrl/subscription-payments/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<SubscriptionPayment>, userAccessToken: suspend () -> String): List<Partial<SubscriptionPayment>> = fetch(
            url = "$httpUrl/subscription-payments/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: suspend () -> String): SubscriptionPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<SubscriptionPayment>, userAccessToken: suspend () -> String): List<SubscriptionPayment> = fetch(
            url = "$httpUrl/subscription-payments/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: SubscriptionPayment, userAccessToken: suspend () -> String): SubscriptionPayment = fetch(
            url = "$httpUrl/subscription-payments",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: SubscriptionPayment, userAccessToken: suspend () -> String): SubscriptionPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<SubscriptionPayment>, userAccessToken: suspend () -> String): List<SubscriptionPayment> = fetch(
            url = "$httpUrl/subscription-payments",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: SubscriptionPayment, userAccessToken: suspend () -> String): SubscriptionPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<SubscriptionPayment>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/subscription-payments/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SubscriptionPayment>, userAccessToken: suspend () -> String): EntryChange<SubscriptionPayment> = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<SubscriptionPayment>, userAccessToken: suspend () -> String): SubscriptionPayment = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<SubscriptionPayment>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/subscription-payments/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: suspend () -> String): Unit = fetch(
            url = "$httpUrl/subscription-payments/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<SubscriptionPayment>, userAccessToken: suspend () -> String): Int = fetch(
            url = "$httpUrl/subscription-payments/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<SubscriptionPayment>, userAccessToken: suspend () -> String): Map<String, Int> = fetch(
            url = "$httpUrl/subscription-payments/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<SubscriptionPayment>, userAccessToken: suspend () -> String): Double? = fetch(
            url = "$httpUrl/subscription-payments/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<SubscriptionPayment>, userAccessToken: suspend () -> String): Map<String, Double?> = fetch(
            url = "$httpUrl/subscription-payments/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
    }
    class LiveDealershipJoinRequestApi(val httpUrl: String, val socketUrl: String): Api.DealershipJoinRequestApi {
        override suspend fun default(userAccessToken: (suspend () -> String)?): DealershipJoinRequest = fetch(
            url = "$httpUrl/dealership-join-requests/_default_",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun query(input: Query<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): List<DealershipJoinRequest> = fetch(
            url = "$httpUrl/dealership-join-requests/query",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): List<Partial<DealershipJoinRequest>> = fetch(
            url = "$httpUrl/dealership-join-requests/query-partial",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun detail(id: UUID, userAccessToken: (suspend () -> String)?): DealershipJoinRequest = fetch(
            url = "$httpUrl/dealership-join-requests/${id.urlify()}",
            method = HttpMethod.GET,
            token = userAccessToken,
        )
        override suspend fun insertBulk(input: List<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): List<DealershipJoinRequest> = fetch(
            url = "$httpUrl/dealership-join-requests/bulk",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun insert(input: DealershipJoinRequest, userAccessToken: (suspend () -> String)?): DealershipJoinRequest = fetch(
            url = "$httpUrl/dealership-join-requests",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun upsert(id: UUID, input: DealershipJoinRequest, userAccessToken: (suspend () -> String)?): DealershipJoinRequest = fetch(
            url = "$httpUrl/dealership-join-requests/${id.urlify()}",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkReplace(input: List<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): List<DealershipJoinRequest> = fetch(
            url = "$httpUrl/dealership-join-requests",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun replace(id: UUID, input: DealershipJoinRequest, userAccessToken: (suspend () -> String)?): DealershipJoinRequest = fetch(
            url = "$httpUrl/dealership-join-requests/${id.urlify()}",
            method = HttpMethod.PUT,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): Int = fetch(
            url = "$httpUrl/dealership-join-requests/bulk",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): EntryChange<DealershipJoinRequest> = fetch(
            url = "$httpUrl/dealership-join-requests/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): DealershipJoinRequest = fetch(
            url = "$httpUrl/dealership-join-requests/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = userAccessToken,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): Int = fetch(
            url = "$httpUrl/dealership-join-requests/bulk-delete",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun delete(id: UUID, userAccessToken: (suspend () -> String)?): Unit = fetch(
            url = "$httpUrl/dealership-join-requests/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = userAccessToken,
        )
        override suspend fun count(input: Condition<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): Int = fetch(
            url = "$httpUrl/dealership-join-requests/count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): Map<String, Int> = fetch(
            url = "$httpUrl/dealership-join-requests/group-count",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): Double? = fetch(
            url = "$httpUrl/dealership-join-requests/aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipJoinRequest>, userAccessToken: (suspend () -> String)?): Map<String, Double?> = fetch(
            url = "$httpUrl/dealership-join-requests/group-aggregate",
            method = HttpMethod.POST,
            token = userAccessToken,
            body = input
        )
        override suspend fun acceptDealership(id: UUID, userAccessToken: suspend () -> String): Dealership = fetch(
            url = "$httpUrl/dealership-join-requests/${id.urlify()}/accept",
            method = HttpMethod.POST,
            token = userAccessToken,
        )
    }
}

