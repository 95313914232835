package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.UrlLikePath
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.now
import com.lightningkite.nowLocal
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant

@Routable("/notifications")
class NotificationsScreen : KiteUiScreen {
    override fun ViewWriter.render() {
        val lastCheck = Property<Instant>(Instant.DISTANT_PAST)
        stack {
            launch {
                lastCheck set (currentSession().me()?.notificationsLastCheckedAt ?: now())
                currentSession().users.get(currentSession().userId.await()).modify(modification {
                    it.notificationsLastCheckedAt assign now()
                })
            }
            gravity(Align.Center, Align.Stretch) - sizedBox(SizeConstraints(width = 70.rem)) - col {
                atEnd - link {
//                    text(Strings.notificationSettings)
                    icon(Icon.settings, Strings.notificationSettings)
                    to = { NotificationSettingsScreen() }
                }
                separator()
                expanding - stack {
                    val a = shared {
                        val myDealerships = currentUser()?.managesDealerships ?: setOf()
                        val session = currentSession()
                        session.notifications.watch(Query(
                            condition = condition<Notification> { it.receiver inside myDealerships },
                            orderBy = sort<Notification> { it.at.descending() }
                        ))
                    }
                    val data = shared {
                        a()()
                    }
                    recyclerView {
                        children(data) {
                            card - link {
                                ::opacity {
                                    if(it().at > lastCheck()) 1.0 else 0.5
                                }
                                row {
                                    expanding - col {
                                        h3 { ::content { it.await().title } }
                                        text { ::content { it.await().content ?: "" } }
                                    }
                                    col {
                                        atEnd - text { ::content { it.await().at.renderToString(RenderSize.Abbreviation) } }
                                        atEnd - text {
                                            ::content {
                                                currentSessionNullable.awaitNotNull().dealerships.get(it.await().receiver)
                                                    .await()?.name ?: "-"
                                            }
                                        }
                                    }
                                }
                                ::to {
                                    try {
                                        it().link?.let { { AutoRoutes.parse(UrlLikePath.fromUrlString(it)) ?: NotificationsScreen() } }
                                    } catch(e: Exception) {
                                        null
                                    } ?: { NotificationsScreen() }
                                }
                            }
                        }
                    }
                    stack {
                        exists = false
                        ::exists { data().isEmpty() }
                        nothingSelected(Strings.youHaveNoNotificationsYet)
                    }
                }
            }
        }
    }
}

@Routable("notifications/settings")
class NotificationSettingsScreen : KiteUiScreen {
    override fun ViewWriter.render() {
        stack {
            gravity(Align.Center, Align.Stretch) - sizedBox(SizeConstraints(width = 70.rem)) - scrolls - col {
                h1(Strings.notificationSettings)
                separator()
                rowCollapsingToColumn(60.rem) {
                    expanding - button {
                        text(Strings.sendATestNotification)
                        onClick {
                            val s = currentSessionNullable.await() ?: return@onClick
                            val token = fcmToken.value ?: return@onClick
                            s.fcmToken.testInAppNotifications(token)
                        }
                    }
                    expanding - button {
                        text(Strings.sendATestNotificationToDealership)
                        onClick {
                            val s = currentSessionNullable.await() ?: return@onClick
                            val cid = currentDealershipId.await() ?: return@onClick
                            val user = currentUser.await() ?: return@onClick
                            val dealer = currentDealership.await() ?: return@onClick
                            s.notifications.insert(
                                Notification(
                                    receiver = cid,
                                    title = Strings.testNotificationX(nowLocal().time.renderToString()),
                                    content = "A test notification was requested for ${dealer.name} by ${user.email}.",
                                    link = "/notifications"
                                )
                            )
                        }
                    }
                }
                separator()
                NotificationTopic.values().groupBy { it.section }.forEach { (section, types) ->
                    col {
                        h2(section)
                        types.forEach { type ->
                            row {
                                expanding - compact - col {
                                    h6(type.title)
                                    subtext(type.description)
                                }
                                card - toggleButton {
                                    col {
                                        spacing = 0.1.rem
                                        centered - icon {
                                            source = Icon.notification
                                            description = Strings.inApp
                                        }
                                        centered - subtext(Strings.inApp)
                                    }
                                    checked bind shared {
                                        currentSession().me.awaitNotNull().notify(type).push
                                    }.withWrite { value ->
                                        currentSession().users.get(currentSession().userId.await()).modify(modification {
                                            it.notify(type).push assign value
                                        })
                                    }
//                                    checked bind currentUser.prop { it.notify(type).push }
                                }
                                card - toggleButton {
                                    col {
                                        spacing = 0.1.rem
                                        icon {
                                            source = Icon.email
                                            description = Strings.email2
                                        }
                                        subtext(Strings.email2)
                                    }
                                    checked bind shared {
                                        currentSession().me.awaitNotNull().notify(type).email
                                    }.withWrite { value ->
                                        currentSession().users.get(currentSession().userId.await()).modify(modification {
                                            it.notify(type).email assign value
                                        })
                                    }
//                                    checked bind currentUser.prop { it.notify(type).email }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}