package com.ilussobsa.views

import com.ilussobsa.Resources
import com.ilussobsa.Strings
import com.ilussobsa.User
import com.ilussobsa.formatPhoneNumber
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.canvas.TextAlign
import com.lightningkite.kiteui.views.direct.*
import kotlinx.coroutines.launch


@ViewModifierDsl3
fun ViewWriter.pageWithMaxWidth(width: Dimension = 90.rem, setup: Stack.() -> Unit) =
//    stack { setup() }
    stack {
        gravity(Align.Center, Align.Stretch) - sizeConstraints(width = width) - setup()
    }

fun ViewWriter.profilePicture(user: Readable<User>) = profilePicture(
    name = shared { user.invoke().name },
    image = shared { user().profilePicture?.toImage() },
)
fun ViewWriter.profilePicture(name: Readable<String>, image: Readable<ImageSource?>) {
    unpadded - themeFromLast { it.important().copy(cornerRadii = CornerRadii.RatioOfSize()) } - sizeConstraints(
        width = 5.rem,
        height = 5.rem
    ) - stack {
        spacing = 0.px
        centered - tweakTheme { it.copy(font = it.font.copy(size = 2.5.rem)) } - text {
            align = Align.Center
            ::content {
                val f = name().firstOrNull()?.toString() ?: "?"
                val l = name().split(' ').takeIf { it.size > 1 }?.lastOrNull()?.firstOrNull()?.toString() ?: ""
                f + l
            }
        }
        image {
            scaleType = ImageScaleType.Crop
            ::source { image.await() }
            this.description = ""
        }
    }
}

fun ViewWriter.distanceFilter(distanceLimit: Writable<Int?>) {
    val distanceOptions = listOf(50, 100, 200)
    val showCustomDist = Property(false)
    radioToggleButton {
        launch {
            showCustomDist set (distanceLimit() !in (distanceOptions + listOf(null)))
        }
        text(Strings.anywhere)
        checked bind shared { distanceLimit() == null && !showCustomDist() }.withWrite {
            if (it) {
                showCustomDist set false
                distanceLimit set null
            }
        }
    }
    distanceOptions.forEach { dist ->
        radioToggleButton {
            text(Strings.withinXMiles(dist))
            checked bind shared { distanceLimit() == dist && !showCustomDist() }.withWrite {
                if (it) {
                    showCustomDist set false
                    distanceLimit set dist
                }
            }
        }
    }
    toggleButton {
        text(Strings.withinCustomRange)
        checked bind showCustomDist
    }

    row {
        ::exists { showCustomDist.await() }
        centered - sizeConstraints(minWidth = 5.rem) - fieldTheme - numberInput {
            keyboardHints = KeyboardHints.integer
            content bind distanceLimit.asDouble()
        }
        centered - label {
            content = "miles"
        }

    }
}

fun ViewWriter.contactInfo(title: String, user: Readable<User>) {
    card - col {
        h6(title)
        row {
            profilePicture(user.waitForNotNull)
            compact - col {
                text { ::content { user().name } }
                externalLink {
                    spacing = 0.px
                    text { ::content { user.await().email } }
                    ::to { "mailto:${user.awaitNotNull().email}" }
                }
                externalLink {
                    spacing = 0.px
                    ::exists{ user.await().phoneNumber !== null }
                    ::to { "tel:${user.awaitNotNull().phoneNumber?.filter { it.isDigit() }}" }
                    text {
                        ::content{ user.awaitNotNull().phoneNumber?.formatPhoneNumber() ?: "" }
                    }
                }
            }
        }
    }
}

fun ViewWriter.onlyIfLazy(condition: ReactiveContext.()->Boolean, inner: ViewWriter.()->Unit) {
    stack {
        val split = split()
        reactiveScope {
            clearChildren()
            if(condition()) split.inner()
        }
    }
}