package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderTimeToString
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.nowLocal
import kotlinx.datetime.DayOfWeek

@Routable("/auctions")
class AuctionsScreen() : KiteUiScreen {

    override val title: Readable<String> = shared {
        Strings.liveAuctions
    }
    val query = shared {
        Query(
            condition<AuctionLane> {
                Condition.And(
                    listOfNotNull(
                        condition(true)
                    )
                )
            },
            orderBy = sort { it.startsAt.ascending() },
            limit = 500,
        )
    }

    override fun ViewWriter.render() {
        stack {
            col {
                val toListen = shared {
                    currentSessionNullable.awaitNotNull().auctions.query(query.await())
                }
                card - col {
                    h1(Strings.liveAuctions)
                    text {
                        ::content {
                            if (nowLocal().date.dayOfWeek == auctionWeekday)
                                Strings.auctionsAreRunningTodayAtTheTimesListed
                            else
                                Strings.allAuctionsAreRunEveryFridayAtThe
                        }
                    }
                }
                expanding - recyclerView {
                    children(shared { toListen.await().await() }) {
                        card - link {
                            row {
                                centered - text { ::content { it.await().nextOrTodayStart.renderTimeToString(RenderSize.Abbreviation)} }
                                expanding - compact - col {
                                    h3 { ::content { it.await().name } }
                                    text { ::content { it.await().description } }
                                }
                                centered - text { ::content { "${it.await().vehicles} vehicles" } }
                            }
                            ::to { val id = it.await()._id; { LiveAuctionScreen(id) } }
                        }
                    }
                }
            }
        }
    }
}