// Automatically generated based off ReportedException.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.exceptions

import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.*
import kotlinx.datetime.Clock
import com.lightningkite.now
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlinx.datetime.Instant
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant
fun prepareReportedExceptionGroupFields() {
    val props: Array<SerializableProperty<ReportedExceptionGroup, *>> = arrayOf(ReportedExceptionGroup__id, ReportedExceptionGroup_lastOccurredAt, ReportedExceptionGroup_count, ReportedExceptionGroup_context, ReportedExceptionGroup_server, ReportedExceptionGroup_message, ReportedExceptionGroup_trace)
    ReportedExceptionGroup.serializer().properties { props }
}
val <K> DataClassPath<K, ReportedExceptionGroup>._id: DataClassPath<K, Int> get() = this[ReportedExceptionGroup__id]
val <K> DataClassPath<K, ReportedExceptionGroup>.lastOccurredAt: DataClassPath<K, Instant> get() = this[ReportedExceptionGroup_lastOccurredAt]
val <K> DataClassPath<K, ReportedExceptionGroup>.count: DataClassPath<K, Int> get() = this[ReportedExceptionGroup_count]
val <K> DataClassPath<K, ReportedExceptionGroup>.context: DataClassPath<K, String> get() = this[ReportedExceptionGroup_context]
val <K> DataClassPath<K, ReportedExceptionGroup>.server: DataClassPath<K, String> get() = this[ReportedExceptionGroup_server]
val <K> DataClassPath<K, ReportedExceptionGroup>.message: DataClassPath<K, String> get() = this[ReportedExceptionGroup_message]
val <K> DataClassPath<K, ReportedExceptionGroup>.trace: DataClassPath<K, String> get() = this[ReportedExceptionGroup_trace]
inline val ReportedExceptionGroup.Companion.path: DataClassPath<ReportedExceptionGroup, ReportedExceptionGroup> get() = path<ReportedExceptionGroup>()


object ReportedExceptionGroup__id: SerializableProperty<ReportedExceptionGroup, Int> {
    override val name: String = "_id"
    override fun get(receiver: ReportedExceptionGroup): Int = receiver._id
    override fun setCopy(receiver: ReportedExceptionGroup, value: Int) = receiver.copy(_id = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = ReportedExceptionGroup.serializer().tryFindAnnotations("_id")
}
object ReportedExceptionGroup_lastOccurredAt: SerializableProperty<ReportedExceptionGroup, Instant> {
    override val name: String = "lastOccurredAt"
    override fun get(receiver: ReportedExceptionGroup): Instant = receiver.lastOccurredAt
    override fun setCopy(receiver: ReportedExceptionGroup, value: Instant) = receiver.copy(lastOccurredAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = ReportedExceptionGroup.serializer().tryFindAnnotations("lastOccurredAt")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastOccurredAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)count([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)context([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)server([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)message([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)trace([^a-zA-Z0-9]|$)
}
object ReportedExceptionGroup_count: SerializableProperty<ReportedExceptionGroup, Int> {
    override val name: String = "count"
    override fun get(receiver: ReportedExceptionGroup): Int = receiver.count
    override fun setCopy(receiver: ReportedExceptionGroup, value: Int) = receiver.copy(count = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = ReportedExceptionGroup.serializer().tryFindAnnotations("count")
    override val default: Int = 1 // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastOccurredAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)count([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)context([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)server([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)message([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)trace([^a-zA-Z0-9]|$)
}
object ReportedExceptionGroup_context: SerializableProperty<ReportedExceptionGroup, String> {
    override val name: String = "context"
    override fun get(receiver: ReportedExceptionGroup): String = receiver.context
    override fun setCopy(receiver: ReportedExceptionGroup, value: String) = receiver.copy(context = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ReportedExceptionGroup.serializer().tryFindAnnotations("context")
}
object ReportedExceptionGroup_server: SerializableProperty<ReportedExceptionGroup, String> {
    override val name: String = "server"
    override fun get(receiver: ReportedExceptionGroup): String = receiver.server
    override fun setCopy(receiver: ReportedExceptionGroup, value: String) = receiver.copy(server = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ReportedExceptionGroup.serializer().tryFindAnnotations("server")
}
object ReportedExceptionGroup_message: SerializableProperty<ReportedExceptionGroup, String> {
    override val name: String = "message"
    override fun get(receiver: ReportedExceptionGroup): String = receiver.message
    override fun setCopy(receiver: ReportedExceptionGroup, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ReportedExceptionGroup.serializer().tryFindAnnotations("message")
}
object ReportedExceptionGroup_trace: SerializableProperty<ReportedExceptionGroup, String> {
    override val name: String = "trace"
    override fun get(receiver: ReportedExceptionGroup): String = receiver.trace
    override fun setCopy(receiver: ReportedExceptionGroup, value: String) = receiver.copy(trace = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ReportedExceptionGroup.serializer().tryFindAnnotations("trace")
}
