
package com.ilussobsa.sdk

import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.lightningkite.serialization.*
import kotlinx.datetime.*
import kotlin.String
import com.lightningkite.lightningserver.files.UploadInformation
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.ilussobsa.Make
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.ilussobsa.DealershipJoinRequest
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.ilussobsa.User
import com.lightningkite.lightningdb.ListChange
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.subject.Session
import com.ilussobsa.FcmToken
import com.ilussobsa.DynamicSettings
import com.ilussobsa.VehicleRelationship
import com.ilussobsa.DealershipVehiclePair
import com.ilussobsa.Vehicle
import com.ilussobsa.WinnerAndPrice
import com.lightningkite.lightningdb.CollectionUpdates
import com.ilussobsa.TransportRequest
import com.ilussobsa.DealershipGroup
import com.ilussobsa.Dealership
import com.ilussobsa.UserPartial
import kotlin.Boolean
import com.ilussobsa.DealershipRelationship
import com.ilussobsa.DealershipPair
import com.ilussobsa.AuctionLane
import com.ilussobsa.Bid
import com.ilussobsa.LiveAuctionData
import com.ilussobsa.SellerTalkingPoint
import com.ilussobsa.SavedSearch
import com.ilussobsa.Notification
import com.ilussobsa.ResetDataRequest
import com.ilussobsa.Model
import com.ilussobsa.SubscriptionPayment
import com.lightningkite.lightningserver.auth.proof.OtpSecret
import com.lightningkite.lightningserver.auth.proof.EstablishOtp
import com.lightningkite.lightningserver.auth.proof.PasswordSecret
import com.lightningkite.lightningserver.auth.proof.EstablishPassword

open class AbstractAnonymousSession(val api: Api) {
    val user: AbstractAnonymousSessionUserApi = AbstractAnonymousSessionUserApi(api.user)
    val emailProof: AbstractAnonymousSessionEmailProofApi = AbstractAnonymousSessionEmailProofApi(api.emailProof)
    val otpSecret: AbstractAnonymousSessionOtpSecretApi = AbstractAnonymousSessionOtpSecretApi(api.otpSecret)
    val oneTimePasswordProof: AbstractAnonymousSessionOneTimePasswordProofApi = AbstractAnonymousSessionOneTimePasswordProofApi(api.oneTimePasswordProof)
    val passwordSecret: AbstractAnonymousSessionPasswordSecretApi = AbstractAnonymousSessionPasswordSecretApi(api.passwordSecret)
    val passwordProof: AbstractAnonymousSessionPasswordProofApi = AbstractAnonymousSessionPasswordProofApi(api.passwordProof)
    val userAuth: AbstractAnonymousSessionUserAuthApi = AbstractAnonymousSessionUserAuthApi(api.userAuth)
    val userSession: AbstractAnonymousSessionUserSessionApi = AbstractAnonymousSessionUserSessionApi(api.userSession)
    val fcmToken: AbstractAnonymousSessionFcmTokenApi = AbstractAnonymousSessionFcmTokenApi(api.fcmToken)
    val dynamicSettings: AbstractAnonymousSessionDynamicSettingsApi = AbstractAnonymousSessionDynamicSettingsApi(api.dynamicSettings)
    val vehicleRelationship: AbstractAnonymousSessionVehicleRelationshipApi = AbstractAnonymousSessionVehicleRelationshipApi(api.vehicleRelationship)
    val vehicle: AbstractAnonymousSessionVehicleApi = AbstractAnonymousSessionVehicleApi(api.vehicle)
    val transportRequest: AbstractAnonymousSessionTransportRequestApi = AbstractAnonymousSessionTransportRequestApi(api.transportRequest)
    val dealershipGroup: AbstractAnonymousSessionDealershipGroupApi = AbstractAnonymousSessionDealershipGroupApi(api.dealershipGroup)
    val dealership: AbstractAnonymousSessionDealershipApi = AbstractAnonymousSessionDealershipApi(api.dealership)
    val dealershipRelationship: AbstractAnonymousSessionDealershipRelationshipApi = AbstractAnonymousSessionDealershipRelationshipApi(api.dealershipRelationship)
    val auctionLane: AbstractAnonymousSessionAuctionLaneApi = AbstractAnonymousSessionAuctionLaneApi(api.auctionLane)
    val bid: AbstractAnonymousSessionBidApi = AbstractAnonymousSessionBidApi(api.bid)
    val liveAuctionData: AbstractAnonymousSessionLiveAuctionDataApi = AbstractAnonymousSessionLiveAuctionDataApi(api.liveAuctionData)
    val sellerTalkingPoint: AbstractAnonymousSessionSellerTalkingPointApi = AbstractAnonymousSessionSellerTalkingPointApi(api.sellerTalkingPoint)
    val savedSearch: AbstractAnonymousSessionSavedSearchApi = AbstractAnonymousSessionSavedSearchApi(api.savedSearch)
    val notification: AbstractAnonymousSessionNotificationApi = AbstractAnonymousSessionNotificationApi(api.notification)
    val subscription: AbstractAnonymousSessionSubscriptionApi = AbstractAnonymousSessionSubscriptionApi(api.subscription)
    val faker: AbstractAnonymousSessionFakerApi = AbstractAnonymousSessionFakerApi(api.faker)
    val make: AbstractAnonymousSessionMakeApi = AbstractAnonymousSessionMakeApi(api.make)
    val model: AbstractAnonymousSessionModelApi = AbstractAnonymousSessionModelApi(api.model)
    val subscriptionPayment: AbstractAnonymousSessionSubscriptionPaymentApi = AbstractAnonymousSessionSubscriptionPaymentApi(api.subscriptionPayment)
    val dealershipJoinRequest: AbstractAnonymousSessionDealershipJoinRequestApi = AbstractAnonymousSessionDealershipJoinRequestApi(api.dealershipJoinRequest)
    suspend fun logInViaApple(): String = api.logInViaApple()
    suspend fun logInViaGoogle(): String = api.logInViaGoogle()
    suspend fun logInViaMicrosoft(): String = api.logInViaMicrosoft()
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    open class AbstractAnonymousSessionUserApi(val api: Api.UserApi) {
    }
    open class AbstractAnonymousSessionEmailProofApi(val api: Api.EmailProofApi): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    open class AbstractAnonymousSessionOtpSecretApi(val api: Api.OtpSecretApi) {
    }
    open class AbstractAnonymousSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    open class AbstractAnonymousSessionPasswordSecretApi(val api: Api.PasswordSecretApi) {
    }
    open class AbstractAnonymousSessionPasswordProofApi(val api: Api.PasswordProofApi): PasswordProofClientEndpoints {
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = api.provePasswordOwnership(input)
    }
    open class AbstractAnonymousSessionUserAuthApi(val api: Api.UserAuthApi): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
    }
    open class AbstractAnonymousSessionUserSessionApi(val api: Api.UserSessionApi) {
    }
    open class AbstractAnonymousSessionFcmTokenApi(val api: Api.FcmTokenApi) {
    }
    open class AbstractAnonymousSessionDynamicSettingsApi(val api: Api.DynamicSettingsApi) {
    }
    open class AbstractAnonymousSessionVehicleRelationshipApi(val api: Api.VehicleRelationshipApi) {
    }
    open class AbstractAnonymousSessionVehicleApi(val api: Api.VehicleApi) {
    }
    open class AbstractAnonymousSessionTransportRequestApi(val api: Api.TransportRequestApi) {
    }
    open class AbstractAnonymousSessionDealershipGroupApi(val api: Api.DealershipGroupApi) {
    }
    open class AbstractAnonymousSessionDealershipApi(val api: Api.DealershipApi) {
    }
    open class AbstractAnonymousSessionDealershipRelationshipApi(val api: Api.DealershipRelationshipApi) {
    }
    open class AbstractAnonymousSessionAuctionLaneApi(val api: Api.AuctionLaneApi) {
    }
    open class AbstractAnonymousSessionBidApi(val api: Api.BidApi) {
    }
    open class AbstractAnonymousSessionLiveAuctionDataApi(val api: Api.LiveAuctionDataApi) {
    }
    open class AbstractAnonymousSessionSellerTalkingPointApi(val api: Api.SellerTalkingPointApi) {
    }
    open class AbstractAnonymousSessionSavedSearchApi(val api: Api.SavedSearchApi) {
    }
    open class AbstractAnonymousSessionNotificationApi(val api: Api.NotificationApi) {
    }
    open class AbstractAnonymousSessionSubscriptionApi(val api: Api.SubscriptionApi) {
    }
    open class AbstractAnonymousSessionFakerApi(val api: Api.FakerApi) {
    }
    open class AbstractAnonymousSessionMakeApi(val api: Api.MakeApi): ClientModelRestEndpoints<Make, String> {
        override suspend fun default(): Make = api.default(null)
        override suspend fun query(input: Query<Make>): List<Make> = api.query(input, null)
        override suspend fun queryPartial(input: QueryPartial<Make>): List<Partial<Make>> = api.queryPartial(input, null)
        override suspend fun detail(id: String): Make = api.detail(id, null)
        override suspend fun insertBulk(input: List<Make>): List<Make> = api.insertBulk(input, null)
        override suspend fun insert(input: Make): Make = api.insert(input, null)
        override suspend fun upsert(id: String, input: Make): Make = api.upsert(id, input, null)
        override suspend fun bulkReplace(input: List<Make>): List<Make> = api.bulkReplace(input, null)
        override suspend fun replace(id: String, input: Make): Make = api.replace(id, input, null)
        override suspend fun bulkModify(input: MassModification<Make>): Int = api.bulkModify(input, null)
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>): EntryChange<Make> = api.modifyWithDiff(id, input, null)
        override suspend fun modify(id: String, input: Modification<Make>): Make = api.modify(id, input, null)
        override suspend fun bulkDelete(input: Condition<Make>): Int = api.bulkDelete(input, null)
        override suspend fun delete(id: String): Unit = api.delete(id, null)
        override suspend fun count(input: Condition<Make>): Int = api.count(input, null)
        override suspend fun groupCount(input: GroupCountQuery<Make>): Map<String, Int> = api.groupCount(input, null)
        override suspend fun aggregate(input: AggregateQuery<Make>): Double? = api.aggregate(input, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>): Map<String, Double?> = api.groupAggregate(input, null)
    }
    open class AbstractAnonymousSessionModelApi(val api: Api.ModelApi) {
    }
    open class AbstractAnonymousSessionSubscriptionPaymentApi(val api: Api.SubscriptionPaymentApi) {
    }
    open class AbstractAnonymousSessionDealershipJoinRequestApi(val api: Api.DealershipJoinRequestApi): ClientModelRestEndpoints<DealershipJoinRequest, UUID> {
        override suspend fun default(): DealershipJoinRequest = api.default(null)
        override suspend fun query(input: Query<DealershipJoinRequest>): List<DealershipJoinRequest> = api.query(input, null)
        override suspend fun queryPartial(input: QueryPartial<DealershipJoinRequest>): List<Partial<DealershipJoinRequest>> = api.queryPartial(input, null)
        override suspend fun detail(id: UUID): DealershipJoinRequest = api.detail(id, null)
        override suspend fun insertBulk(input: List<DealershipJoinRequest>): List<DealershipJoinRequest> = api.insertBulk(input, null)
        override suspend fun insert(input: DealershipJoinRequest): DealershipJoinRequest = api.insert(input, null)
        override suspend fun upsert(id: UUID, input: DealershipJoinRequest): DealershipJoinRequest = api.upsert(id, input, null)
        override suspend fun bulkReplace(input: List<DealershipJoinRequest>): List<DealershipJoinRequest> = api.bulkReplace(input, null)
        override suspend fun replace(id: UUID, input: DealershipJoinRequest): DealershipJoinRequest = api.replace(id, input, null)
        override suspend fun bulkModify(input: MassModification<DealershipJoinRequest>): Int = api.bulkModify(input, null)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<DealershipJoinRequest>): EntryChange<DealershipJoinRequest> = api.modifyWithDiff(id, input, null)
        override suspend fun modify(id: UUID, input: Modification<DealershipJoinRequest>): DealershipJoinRequest = api.modify(id, input, null)
        override suspend fun bulkDelete(input: Condition<DealershipJoinRequest>): Int = api.bulkDelete(input, null)
        override suspend fun delete(id: UUID): Unit = api.delete(id, null)
        override suspend fun count(input: Condition<DealershipJoinRequest>): Int = api.count(input, null)
        override suspend fun groupCount(input: GroupCountQuery<DealershipJoinRequest>): Map<String, Int> = api.groupCount(input, null)
        override suspend fun aggregate(input: AggregateQuery<DealershipJoinRequest>): Double? = api.aggregate(input, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipJoinRequest>): Map<String, Double?> = api.groupAggregate(input, null)
    }
}

abstract class AbstractUserSession(api: Api, userToken: String, userAccessToken: suspend () -> String) {
    abstract val api: Api
    abstract val userToken: String
    abstract val userAccessToken: suspend () -> String
    val user: UserSessionUserApi = UserSessionUserApi(api.user, userToken, userAccessToken)
    val emailProof: UserSessionEmailProofApi = UserSessionEmailProofApi(api.emailProof, userToken, userAccessToken)
    val otpSecret: UserSessionOtpSecretApi = UserSessionOtpSecretApi(api.otpSecret, userToken, userAccessToken)
    val oneTimePasswordProof: UserSessionOneTimePasswordProofApi = UserSessionOneTimePasswordProofApi(api.oneTimePasswordProof, userToken, userAccessToken)
    val passwordSecret: UserSessionPasswordSecretApi = UserSessionPasswordSecretApi(api.passwordSecret, userToken, userAccessToken)
    val passwordProof: UserSessionPasswordProofApi = UserSessionPasswordProofApi(api.passwordProof, userToken, userAccessToken)
    val userAuth: UserSessionUserAuthApi = UserSessionUserAuthApi(api.userAuth, userToken, userAccessToken)
    val userSession: UserSessionUserSessionApi = UserSessionUserSessionApi(api.userSession, userToken, userAccessToken)
    val fcmToken: UserSessionFcmTokenApi = UserSessionFcmTokenApi(api.fcmToken, userToken, userAccessToken)
    val dynamicSettings: UserSessionDynamicSettingsApi = UserSessionDynamicSettingsApi(api.dynamicSettings, userToken, userAccessToken)
    val vehicleRelationship: UserSessionVehicleRelationshipApi = UserSessionVehicleRelationshipApi(api.vehicleRelationship, userToken, userAccessToken)
    val vehicle: UserSessionVehicleApi = UserSessionVehicleApi(api.vehicle, userToken, userAccessToken)
    val transportRequest: UserSessionTransportRequestApi = UserSessionTransportRequestApi(api.transportRequest, userToken, userAccessToken)
    val dealershipGroup: UserSessionDealershipGroupApi = UserSessionDealershipGroupApi(api.dealershipGroup, userToken, userAccessToken)
    val dealership: UserSessionDealershipApi = UserSessionDealershipApi(api.dealership, userToken, userAccessToken)
    val dealershipRelationship: UserSessionDealershipRelationshipApi = UserSessionDealershipRelationshipApi(api.dealershipRelationship, userToken, userAccessToken)
    val auctionLane: UserSessionAuctionLaneApi = UserSessionAuctionLaneApi(api.auctionLane, userToken, userAccessToken)
    val bid: UserSessionBidApi = UserSessionBidApi(api.bid, userToken, userAccessToken)
    val liveAuctionData: UserSessionLiveAuctionDataApi = UserSessionLiveAuctionDataApi(api.liveAuctionData, userToken, userAccessToken)
    val sellerTalkingPoint: UserSessionSellerTalkingPointApi = UserSessionSellerTalkingPointApi(api.sellerTalkingPoint, userToken, userAccessToken)
    val savedSearch: UserSessionSavedSearchApi = UserSessionSavedSearchApi(api.savedSearch, userToken, userAccessToken)
    val notification: UserSessionNotificationApi = UserSessionNotificationApi(api.notification, userToken, userAccessToken)
    val subscription: UserSessionSubscriptionApi = UserSessionSubscriptionApi(api.subscription, userToken, userAccessToken)
    val faker: UserSessionFakerApi = UserSessionFakerApi(api.faker, userToken, userAccessToken)
    val make: UserSessionMakeApi = UserSessionMakeApi(api.make, userToken, userAccessToken)
    val model: UserSessionModelApi = UserSessionModelApi(api.model, userToken, userAccessToken)
    val subscriptionPayment: UserSessionSubscriptionPaymentApi = UserSessionSubscriptionPaymentApi(api.subscriptionPayment, userToken, userAccessToken)
    val dealershipJoinRequest: UserSessionDealershipJoinRequestApi = UserSessionDealershipJoinRequestApi(api.dealershipJoinRequest, userToken, userAccessToken)
    suspend fun logInViaApple(): String = api.logInViaApple()
    suspend fun logInViaGoogle(): String = api.logInViaGoogle()
    suspend fun logInViaMicrosoft(): String = api.logInViaMicrosoft()
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun getServerHealth(): ServerHealth = api.getServerHealth(userAccessToken)
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    class UserSessionUserApi(val api: Api.UserApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<User, UUID>, ClientModelRestEndpointsPlusWs<User, UUID> {
        override suspend fun default(): User = api.default(userAccessToken)
        override suspend fun query(input: Query<User>): List<User> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<User>): List<Partial<User>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): User = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<User>): List<User> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: User): User = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: User): User = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<User>): List<User> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: User): User = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<User>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>): EntryChange<User> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<User>): User = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<User>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<User>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<User>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<User>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun watch(): TypedWebSocket<Query<User>, ListChange<User>> = api.watch(userToken)
    }
    class UserSessionEmailProofApi(val api: Api.EmailProofApi,val userToken:String, val userAccessToken: suspend () -> String): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class UserSessionOtpSecretApi(val api: Api.OtpSecretApi,val userToken:String, val userAccessToken: suspend () -> String) {
    }
    class UserSessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val userToken:String, val userAccessToken: suspend () -> String): OneTimePasswordProofClientEndpoints {
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class UserSessionPasswordSecretApi(val api: Api.PasswordSecretApi,val userToken:String, val userAccessToken: suspend () -> String) {
    }
    class UserSessionPasswordProofApi(val api: Api.PasswordProofApi,val userToken:String, val userAccessToken: suspend () -> String): PasswordProofClientEndpoints {
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = api.provePasswordOwnership(input)
    }
    class UserSessionUserAuthApi(val api: Api.UserAuthApi,val userToken:String, val userAccessToken: suspend () -> String): UserAuthClientEndpoints<UUID>, AuthenticatedUserAuthClientEndpoints<User, UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun createSubSession(input: SubSessionRequest): String = api.createSubSession(input, userAccessToken)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
        override suspend fun getSelf(): User = api.getSelf(userAccessToken)
        override suspend fun terminateSession(): Unit = api.terminateSession(userAccessToken)
        override suspend fun terminateOtherSession(sessionId: UUID): Unit = api.terminateOtherSession(sessionId, userAccessToken)
    }
    class UserSessionUserSessionApi(val api: Api.UserSessionApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Session<User, UUID>, UUID> {
        override suspend fun query(input: Query<Session<User, UUID>>): List<Session<User, UUID>> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>): List<Partial<Session<User, UUID>>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Session<User, UUID> = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Session<User, UUID>): Session<User, UUID> = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>): EntryChange<Session<User, UUID>> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>): Session<User, UUID> = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Session<User, UUID>>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionFcmTokenApi(val api: Api.FcmTokenApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<FcmToken, String> {
        override suspend fun default(): FcmToken = api.default(userAccessToken)
        override suspend fun query(input: Query<FcmToken>): List<FcmToken> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<FcmToken>): List<Partial<FcmToken>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: String): FcmToken = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<FcmToken>): List<FcmToken> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: FcmToken): FcmToken = api.insert(input, userAccessToken)
        override suspend fun upsert(id: String, input: FcmToken): FcmToken = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<FcmToken>): List<FcmToken> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: String, input: FcmToken): FcmToken = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<FcmToken>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: String, input: Modification<FcmToken>): EntryChange<FcmToken> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: String, input: Modification<FcmToken>): FcmToken = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<FcmToken>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<FcmToken>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<FcmToken>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<FcmToken>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<FcmToken>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun testInAppNotifications(id: String): String = api.testInAppNotifications(id, userAccessToken)
    }
    class UserSessionDynamicSettingsApi(val api: Api.DynamicSettingsApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<DynamicSettings, String> {
        override suspend fun default(): DynamicSettings = api.default(userAccessToken)
        override suspend fun query(input: Query<DynamicSettings>): List<DynamicSettings> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<DynamicSettings>): List<Partial<DynamicSettings>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: String): DynamicSettings = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<DynamicSettings>): List<DynamicSettings> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: DynamicSettings): DynamicSettings = api.insert(input, userAccessToken)
        override suspend fun upsert(id: String, input: DynamicSettings): DynamicSettings = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<DynamicSettings>): List<DynamicSettings> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: String, input: DynamicSettings): DynamicSettings = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<DynamicSettings>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: String, input: Modification<DynamicSettings>): EntryChange<DynamicSettings> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: String, input: Modification<DynamicSettings>): DynamicSettings = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<DynamicSettings>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<DynamicSettings>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<DynamicSettings>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<DynamicSettings>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<DynamicSettings>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionVehicleRelationshipApi(val api: Api.VehicleRelationshipApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<VehicleRelationship, DealershipVehiclePair> {
        override suspend fun default(): VehicleRelationship = api.default(userAccessToken)
        override suspend fun query(input: Query<VehicleRelationship>): List<VehicleRelationship> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<VehicleRelationship>): List<Partial<VehicleRelationship>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: DealershipVehiclePair): VehicleRelationship = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<VehicleRelationship>): List<VehicleRelationship> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: VehicleRelationship): VehicleRelationship = api.insert(input, userAccessToken)
        override suspend fun upsert(id: DealershipVehiclePair, input: VehicleRelationship): VehicleRelationship = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<VehicleRelationship>): List<VehicleRelationship> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: DealershipVehiclePair, input: VehicleRelationship): VehicleRelationship = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<VehicleRelationship>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: DealershipVehiclePair, input: Modification<VehicleRelationship>): EntryChange<VehicleRelationship> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: DealershipVehiclePair, input: Modification<VehicleRelationship>): VehicleRelationship = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<VehicleRelationship>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: DealershipVehiclePair): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<VehicleRelationship>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<VehicleRelationship>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<VehicleRelationship>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<VehicleRelationship>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionVehicleApi(val api: Api.VehicleApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Vehicle, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Vehicle, UUID> {
        override suspend fun default(): Vehicle = api.default(userAccessToken)
        override suspend fun query(input: Query<Vehicle>): List<Vehicle> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Vehicle>): List<Partial<Vehicle>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Vehicle = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Vehicle>): List<Vehicle> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Vehicle): Vehicle = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Vehicle): Vehicle = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Vehicle>): List<Vehicle> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Vehicle): Vehicle = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Vehicle>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Vehicle>): EntryChange<Vehicle> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Vehicle>): Vehicle = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Vehicle>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Vehicle>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Vehicle>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Vehicle>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Vehicle>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun markManualCompletion(id: UUID, input: WinnerAndPrice): Vehicle = api.markManualCompletion(id, input, userAccessToken)
        suspend fun acceptInitialOffer(id: UUID): Vehicle = api.acceptInitialOffer(id, userAccessToken)
        suspend fun acceptCounterOffer(id: UUID): Vehicle = api.acceptCounterOffer(id, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<Vehicle>, CollectionUpdates<Vehicle, UUID>> = api.updates(userToken)
    }
    class UserSessionTransportRequestApi(val api: Api.TransportRequestApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<TransportRequest, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<TransportRequest, UUID> {
        override suspend fun default(): TransportRequest = api.default(userAccessToken)
        override suspend fun query(input: Query<TransportRequest>): List<TransportRequest> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<TransportRequest>): List<Partial<TransportRequest>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): TransportRequest = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<TransportRequest>): List<TransportRequest> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: TransportRequest): TransportRequest = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: TransportRequest): TransportRequest = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<TransportRequest>): List<TransportRequest> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: TransportRequest): TransportRequest = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<TransportRequest>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<TransportRequest>): EntryChange<TransportRequest> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<TransportRequest>): TransportRequest = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<TransportRequest>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<TransportRequest>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<TransportRequest>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<TransportRequest>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<TransportRequest>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<TransportRequest>, CollectionUpdates<TransportRequest, UUID>> = api.updates(userToken)
    }
    class UserSessionDealershipGroupApi(val api: Api.DealershipGroupApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<DealershipGroup, UUID> {
        override suspend fun default(): DealershipGroup = api.default(userAccessToken)
        override suspend fun query(input: Query<DealershipGroup>): List<DealershipGroup> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<DealershipGroup>): List<Partial<DealershipGroup>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): DealershipGroup = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<DealershipGroup>): List<DealershipGroup> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: DealershipGroup): DealershipGroup = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: DealershipGroup): DealershipGroup = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<DealershipGroup>): List<DealershipGroup> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: DealershipGroup): DealershipGroup = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<DealershipGroup>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<DealershipGroup>): EntryChange<DealershipGroup> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<DealershipGroup>): DealershipGroup = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<DealershipGroup>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<DealershipGroup>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<DealershipGroup>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<DealershipGroup>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipGroup>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionDealershipApi(val api: Api.DealershipApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Dealership, UUID> {
        override suspend fun default(): Dealership = api.default(userAccessToken)
        override suspend fun query(input: Query<Dealership>): List<Dealership> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Dealership>): List<Partial<Dealership>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Dealership = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Dealership>): List<Dealership> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Dealership): Dealership = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Dealership): Dealership = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Dealership>): List<Dealership> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Dealership): Dealership = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Dealership>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Dealership>): EntryChange<Dealership> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Dealership>): Dealership = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Dealership>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Dealership>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Dealership>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Dealership>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Dealership>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun addUserToDealership(id: UUID, input: UserPartial): UUID = api.addUserToDealership(id, input, userAccessToken)
        suspend fun removeUserFromDealership(id: UUID, input: String): Boolean = api.removeUserFromDealership(id, input, userAccessToken)
    }
    class UserSessionDealershipRelationshipApi(val api: Api.DealershipRelationshipApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<DealershipRelationship, DealershipPair> {
        override suspend fun default(): DealershipRelationship = api.default(userAccessToken)
        override suspend fun query(input: Query<DealershipRelationship>): List<DealershipRelationship> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<DealershipRelationship>): List<Partial<DealershipRelationship>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: DealershipPair): DealershipRelationship = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<DealershipRelationship>): List<DealershipRelationship> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: DealershipRelationship): DealershipRelationship = api.insert(input, userAccessToken)
        override suspend fun upsert(id: DealershipPair, input: DealershipRelationship): DealershipRelationship = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<DealershipRelationship>): List<DealershipRelationship> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: DealershipPair, input: DealershipRelationship): DealershipRelationship = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<DealershipRelationship>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: DealershipPair, input: Modification<DealershipRelationship>): EntryChange<DealershipRelationship> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: DealershipPair, input: Modification<DealershipRelationship>): DealershipRelationship = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<DealershipRelationship>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: DealershipPair): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<DealershipRelationship>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<DealershipRelationship>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<DealershipRelationship>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipRelationship>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionAuctionLaneApi(val api: Api.AuctionLaneApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<AuctionLane, UUID> {
        override suspend fun default(): AuctionLane = api.default(userAccessToken)
        override suspend fun query(input: Query<AuctionLane>): List<AuctionLane> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<AuctionLane>): List<Partial<AuctionLane>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): AuctionLane = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<AuctionLane>): List<AuctionLane> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: AuctionLane): AuctionLane = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: AuctionLane): AuctionLane = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<AuctionLane>): List<AuctionLane> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: AuctionLane): AuctionLane = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<AuctionLane>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<AuctionLane>): EntryChange<AuctionLane> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<AuctionLane>): AuctionLane = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<AuctionLane>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<AuctionLane>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<AuctionLane>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<AuctionLane>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<AuctionLane>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionBidApi(val api: Api.BidApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Bid, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<Bid, UUID> {
        override suspend fun default(): Bid = api.default(userAccessToken)
        override suspend fun query(input: Query<Bid>): List<Bid> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Bid>): List<Partial<Bid>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Bid = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Bid>): List<Bid> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Bid): Bid = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Bid): Bid = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Bid>): List<Bid> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Bid): Bid = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Bid>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Bid>): EntryChange<Bid> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Bid>): Bid = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Bid>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Bid>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Bid>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Bid>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Bid>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<Bid>, CollectionUpdates<Bid, UUID>> = api.updates(userToken)
    }
    class UserSessionLiveAuctionDataApi(val api: Api.LiveAuctionDataApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<LiveAuctionData, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<LiveAuctionData, UUID> {
        override suspend fun default(): LiveAuctionData = api.default(userAccessToken)
        override suspend fun query(input: Query<LiveAuctionData>): List<LiveAuctionData> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<LiveAuctionData>): List<Partial<LiveAuctionData>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): LiveAuctionData = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<LiveAuctionData>): List<LiveAuctionData> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: LiveAuctionData): LiveAuctionData = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: LiveAuctionData): LiveAuctionData = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<LiveAuctionData>): List<LiveAuctionData> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: LiveAuctionData): LiveAuctionData = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<LiveAuctionData>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<LiveAuctionData>): EntryChange<LiveAuctionData> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<LiveAuctionData>): LiveAuctionData = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<LiveAuctionData>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<LiveAuctionData>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<LiveAuctionData>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<LiveAuctionData>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<LiveAuctionData>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun whoLastCallToEnroll(): List<Dealership> = api.whoLastCallToEnroll(userAccessToken)
        suspend fun start(id: UUID): LiveAuctionData? = api.start(id, userAccessToken)
        suspend fun notifyHover(id: UUID, dealership: UUID): Unit = api.notifyHover(id, dealership, userAccessToken)
        suspend fun notifyUnhover(id: UUID, dealership: UUID): Unit = api.notifyUnhover(id, dealership, userAccessToken)
        suspend fun notifyJoin(id: UUID, dealership: UUID): Unit = api.notifyJoin(id, dealership, userAccessToken)
        suspend fun notifyLeave(id: UUID, dealership: UUID): Unit = api.notifyLeave(id, dealership, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<LiveAuctionData>, CollectionUpdates<LiveAuctionData, UUID>> = api.updates(userToken)
    }
    class UserSessionSellerTalkingPointApi(val api: Api.SellerTalkingPointApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<SellerTalkingPoint, UUID>, ClientModelRestEndpointsPlusUpdatesWebsocket<SellerTalkingPoint, UUID> {
        override suspend fun default(): SellerTalkingPoint = api.default(userAccessToken)
        override suspend fun query(input: Query<SellerTalkingPoint>): List<SellerTalkingPoint> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<SellerTalkingPoint>): List<Partial<SellerTalkingPoint>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): SellerTalkingPoint = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<SellerTalkingPoint>): List<SellerTalkingPoint> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: SellerTalkingPoint): SellerTalkingPoint = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: SellerTalkingPoint): SellerTalkingPoint = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<SellerTalkingPoint>): List<SellerTalkingPoint> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: SellerTalkingPoint): SellerTalkingPoint = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<SellerTalkingPoint>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SellerTalkingPoint>): EntryChange<SellerTalkingPoint> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<SellerTalkingPoint>): SellerTalkingPoint = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<SellerTalkingPoint>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<SellerTalkingPoint>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<SellerTalkingPoint>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<SellerTalkingPoint>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<SellerTalkingPoint>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        override fun updates(): TypedWebSocket<Condition<SellerTalkingPoint>, CollectionUpdates<SellerTalkingPoint, UUID>> = api.updates(userToken)
    }
    class UserSessionSavedSearchApi(val api: Api.SavedSearchApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<SavedSearch, UUID> {
        override suspend fun default(): SavedSearch = api.default(userAccessToken)
        override suspend fun query(input: Query<SavedSearch>): List<SavedSearch> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<SavedSearch>): List<Partial<SavedSearch>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): SavedSearch = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<SavedSearch>): List<SavedSearch> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: SavedSearch): SavedSearch = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: SavedSearch): SavedSearch = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<SavedSearch>): List<SavedSearch> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: SavedSearch): SavedSearch = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<SavedSearch>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SavedSearch>): EntryChange<SavedSearch> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<SavedSearch>): SavedSearch = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<SavedSearch>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<SavedSearch>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<SavedSearch>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<SavedSearch>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<SavedSearch>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionNotificationApi(val api: Api.NotificationApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Notification, UUID> {
        override suspend fun default(): Notification = api.default(userAccessToken)
        override suspend fun query(input: Query<Notification>): List<Notification> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Notification>): List<Partial<Notification>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): Notification = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Notification>): List<Notification> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Notification): Notification = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: Notification): Notification = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Notification>): List<Notification> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: Notification): Notification = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Notification>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Notification>): EntryChange<Notification> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<Notification>): Notification = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Notification>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Notification>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Notification>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Notification>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Notification>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionSubscriptionApi(val api: Api.SubscriptionApi,val userToken:String, val userAccessToken: suspend () -> String) {
        suspend fun createSubscription(dealership: UUID): Dealership = api.createSubscription(dealership, userAccessToken)
        suspend fun getPortalLink(dealershipId: UUID): String = api.getPortalLink(dealershipId, userAccessToken)
    }
    class UserSessionFakerApi(val api: Api.FakerApi,val userToken:String, val userAccessToken: suspend () -> String) {
        suspend fun saveState(): String = api.saveState(userAccessToken)
        suspend fun loadState(): String = api.loadState(userAccessToken)
        suspend fun resetTestData(input: ResetDataRequest): String = api.resetTestData(input, userAccessToken)
    }
    class UserSessionMakeApi(val api: Api.MakeApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Make, String> {
        override suspend fun default(): Make = api.default(userAccessToken)
        override suspend fun query(input: Query<Make>): List<Make> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Make>): List<Partial<Make>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: String): Make = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Make>): List<Make> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Make): Make = api.insert(input, userAccessToken)
        override suspend fun upsert(id: String, input: Make): Make = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Make>): List<Make> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: String, input: Make): Make = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Make>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>): EntryChange<Make> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: String, input: Modification<Make>): Make = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Make>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Make>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Make>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Make>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun sync(): List<Make> = api.sync(userAccessToken)
        suspend fun syncAll(): Unit = api.syncAll(userAccessToken)
    }
    class UserSessionModelApi(val api: Api.ModelApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<Model, String> {
        override suspend fun default(): Model = api.default(userAccessToken)
        override suspend fun query(input: Query<Model>): List<Model> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<Model>): List<Partial<Model>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: String): Model = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<Model>): List<Model> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: Model): Model = api.insert(input, userAccessToken)
        override suspend fun upsert(id: String, input: Model): Model = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<Model>): List<Model> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: String, input: Model): Model = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<Model>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: String, input: Modification<Model>): EntryChange<Model> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: String, input: Modification<Model>): Model = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<Model>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: String): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<Model>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<Model>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<Model>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Model>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun sync(make: String): List<Model> = api.sync(make, userAccessToken)
    }
    class UserSessionSubscriptionPaymentApi(val api: Api.SubscriptionPaymentApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<SubscriptionPayment, UUID> {
        override suspend fun default(): SubscriptionPayment = api.default(userAccessToken)
        override suspend fun query(input: Query<SubscriptionPayment>): List<SubscriptionPayment> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<SubscriptionPayment>): List<Partial<SubscriptionPayment>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): SubscriptionPayment = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<SubscriptionPayment>): List<SubscriptionPayment> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: SubscriptionPayment): SubscriptionPayment = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: SubscriptionPayment): SubscriptionPayment = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<SubscriptionPayment>): List<SubscriptionPayment> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: SubscriptionPayment): SubscriptionPayment = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<SubscriptionPayment>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<SubscriptionPayment>): EntryChange<SubscriptionPayment> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<SubscriptionPayment>): SubscriptionPayment = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<SubscriptionPayment>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<SubscriptionPayment>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<SubscriptionPayment>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<SubscriptionPayment>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<SubscriptionPayment>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
    }
    class UserSessionDealershipJoinRequestApi(val api: Api.DealershipJoinRequestApi,val userToken:String, val userAccessToken: suspend () -> String): ClientModelRestEndpoints<DealershipJoinRequest, UUID> {
        override suspend fun default(): DealershipJoinRequest = api.default(userAccessToken)
        override suspend fun query(input: Query<DealershipJoinRequest>): List<DealershipJoinRequest> = api.query(input, userAccessToken)
        override suspend fun queryPartial(input: QueryPartial<DealershipJoinRequest>): List<Partial<DealershipJoinRequest>> = api.queryPartial(input, userAccessToken)
        override suspend fun detail(id: UUID): DealershipJoinRequest = api.detail(id, userAccessToken)
        override suspend fun insertBulk(input: List<DealershipJoinRequest>): List<DealershipJoinRequest> = api.insertBulk(input, userAccessToken)
        override suspend fun insert(input: DealershipJoinRequest): DealershipJoinRequest = api.insert(input, userAccessToken)
        override suspend fun upsert(id: UUID, input: DealershipJoinRequest): DealershipJoinRequest = api.upsert(id, input, userAccessToken)
        override suspend fun bulkReplace(input: List<DealershipJoinRequest>): List<DealershipJoinRequest> = api.bulkReplace(input, userAccessToken)
        override suspend fun replace(id: UUID, input: DealershipJoinRequest): DealershipJoinRequest = api.replace(id, input, userAccessToken)
        override suspend fun bulkModify(input: MassModification<DealershipJoinRequest>): Int = api.bulkModify(input, userAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<DealershipJoinRequest>): EntryChange<DealershipJoinRequest> = api.modifyWithDiff(id, input, userAccessToken)
        override suspend fun modify(id: UUID, input: Modification<DealershipJoinRequest>): DealershipJoinRequest = api.modify(id, input, userAccessToken)
        override suspend fun bulkDelete(input: Condition<DealershipJoinRequest>): Int = api.bulkDelete(input, userAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, userAccessToken)
        override suspend fun count(input: Condition<DealershipJoinRequest>): Int = api.count(input, userAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<DealershipJoinRequest>): Map<String, Int> = api.groupCount(input, userAccessToken)
        override suspend fun aggregate(input: AggregateQuery<DealershipJoinRequest>): Double? = api.aggregate(input, userAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipJoinRequest>): Map<String, Double?> = api.groupAggregate(input, userAccessToken)
        suspend fun acceptDealership(id: UUID): Dealership = api.acceptDealership(id, userAccessToken)
    }
}

abstract class AbstractAnySession(api: Api, anyToken: String, anyAccessToken: suspend () -> String) {
    abstract val api: Api
    abstract val anyToken: String
    abstract val anyAccessToken: suspend () -> String
    val user: AnySessionUserApi = AnySessionUserApi(api.user, anyToken, anyAccessToken)
    val emailProof: AnySessionEmailProofApi = AnySessionEmailProofApi(api.emailProof, anyToken, anyAccessToken)
    val otpSecret: AnySessionOtpSecretApi = AnySessionOtpSecretApi(api.otpSecret, anyToken, anyAccessToken)
    val oneTimePasswordProof: AnySessionOneTimePasswordProofApi = AnySessionOneTimePasswordProofApi(api.oneTimePasswordProof, anyToken, anyAccessToken)
    val passwordSecret: AnySessionPasswordSecretApi = AnySessionPasswordSecretApi(api.passwordSecret, anyToken, anyAccessToken)
    val passwordProof: AnySessionPasswordProofApi = AnySessionPasswordProofApi(api.passwordProof, anyToken, anyAccessToken)
    val userAuth: AnySessionUserAuthApi = AnySessionUserAuthApi(api.userAuth, anyToken, anyAccessToken)
    val userSession: AnySessionUserSessionApi = AnySessionUserSessionApi(api.userSession, anyToken, anyAccessToken)
    val fcmToken: AnySessionFcmTokenApi = AnySessionFcmTokenApi(api.fcmToken, anyToken, anyAccessToken)
    val dynamicSettings: AnySessionDynamicSettingsApi = AnySessionDynamicSettingsApi(api.dynamicSettings, anyToken, anyAccessToken)
    val vehicleRelationship: AnySessionVehicleRelationshipApi = AnySessionVehicleRelationshipApi(api.vehicleRelationship, anyToken, anyAccessToken)
    val vehicle: AnySessionVehicleApi = AnySessionVehicleApi(api.vehicle, anyToken, anyAccessToken)
    val transportRequest: AnySessionTransportRequestApi = AnySessionTransportRequestApi(api.transportRequest, anyToken, anyAccessToken)
    val dealershipGroup: AnySessionDealershipGroupApi = AnySessionDealershipGroupApi(api.dealershipGroup, anyToken, anyAccessToken)
    val dealership: AnySessionDealershipApi = AnySessionDealershipApi(api.dealership, anyToken, anyAccessToken)
    val dealershipRelationship: AnySessionDealershipRelationshipApi = AnySessionDealershipRelationshipApi(api.dealershipRelationship, anyToken, anyAccessToken)
    val auctionLane: AnySessionAuctionLaneApi = AnySessionAuctionLaneApi(api.auctionLane, anyToken, anyAccessToken)
    val bid: AnySessionBidApi = AnySessionBidApi(api.bid, anyToken, anyAccessToken)
    val liveAuctionData: AnySessionLiveAuctionDataApi = AnySessionLiveAuctionDataApi(api.liveAuctionData, anyToken, anyAccessToken)
    val sellerTalkingPoint: AnySessionSellerTalkingPointApi = AnySessionSellerTalkingPointApi(api.sellerTalkingPoint, anyToken, anyAccessToken)
    val savedSearch: AnySessionSavedSearchApi = AnySessionSavedSearchApi(api.savedSearch, anyToken, anyAccessToken)
    val notification: AnySessionNotificationApi = AnySessionNotificationApi(api.notification, anyToken, anyAccessToken)
    val subscription: AnySessionSubscriptionApi = AnySessionSubscriptionApi(api.subscription, anyToken, anyAccessToken)
    val faker: AnySessionFakerApi = AnySessionFakerApi(api.faker, anyToken, anyAccessToken)
    val make: AnySessionMakeApi = AnySessionMakeApi(api.make, anyToken, anyAccessToken)
    val model: AnySessionModelApi = AnySessionModelApi(api.model, anyToken, anyAccessToken)
    val subscriptionPayment: AnySessionSubscriptionPaymentApi = AnySessionSubscriptionPaymentApi(api.subscriptionPayment, anyToken, anyAccessToken)
    val dealershipJoinRequest: AnySessionDealershipJoinRequestApi = AnySessionDealershipJoinRequestApi(api.dealershipJoinRequest, anyToken, anyAccessToken)
    suspend fun logInViaApple(): String = api.logInViaApple()
    suspend fun logInViaGoogle(): String = api.logInViaGoogle()
    suspend fun logInViaMicrosoft(): String = api.logInViaMicrosoft()
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    class AnySessionUserApi(val api: Api.UserApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionEmailProofApi(val api: Api.EmailProofApi,val anyToken:String, val anyAccessToken: suspend () -> String): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    class AnySessionOtpSecretApi(val api: Api.OtpSecretApi,val anyToken:String, val anyAccessToken: suspend () -> String): ClientModelRestEndpoints<OtpSecret, UUID> {
        override suspend fun query(input: Query<OtpSecret>): List<OtpSecret> = api.query(input, anyAccessToken)
        override suspend fun queryPartial(input: QueryPartial<OtpSecret>): List<Partial<OtpSecret>> = api.queryPartial(input, anyAccessToken)
        override suspend fun detail(id: UUID): OtpSecret = api.detail(id, anyAccessToken)
        override suspend fun insertBulk(input: List<OtpSecret>): List<OtpSecret> = api.insertBulk(input, anyAccessToken)
        override suspend fun insert(input: OtpSecret): OtpSecret = api.insert(input, anyAccessToken)
        override suspend fun upsert(id: UUID, input: OtpSecret): OtpSecret = api.upsert(id, input, anyAccessToken)
        override suspend fun bulkReplace(input: List<OtpSecret>): List<OtpSecret> = api.bulkReplace(input, anyAccessToken)
        override suspend fun replace(id: UUID, input: OtpSecret): OtpSecret = api.replace(id, input, anyAccessToken)
        override suspend fun bulkModify(input: MassModification<OtpSecret>): Int = api.bulkModify(input, anyAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<OtpSecret>): EntryChange<OtpSecret> = api.modifyWithDiff(id, input, anyAccessToken)
        override suspend fun modify(id: UUID, input: Modification<OtpSecret>): OtpSecret = api.modify(id, input, anyAccessToken)
        override suspend fun bulkDelete(input: Condition<OtpSecret>): Int = api.bulkDelete(input, anyAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, anyAccessToken)
        override suspend fun count(input: Condition<OtpSecret>): Int = api.count(input, anyAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<OtpSecret>): Map<String, Int> = api.groupCount(input, anyAccessToken)
        override suspend fun aggregate(input: AggregateQuery<OtpSecret>): Double? = api.aggregate(input, anyAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<OtpSecret>): Map<String, Double?> = api.groupAggregate(input, anyAccessToken)
    }
    class AnySessionOneTimePasswordProofApi(val api: Api.OneTimePasswordProofApi,val anyToken:String, val anyAccessToken: suspend () -> String): AuthenticatedOneTimePasswordProofClientEndpoints, OneTimePasswordProofClientEndpoints {
        override suspend fun establishOneTimePassword(input: EstablishOtp): String = api.establishOneTimePassword(input, anyAccessToken)
        override suspend fun proveOTP(input: IdentificationAndPassword): Proof = api.proveOTP(input)
    }
    class AnySessionPasswordSecretApi(val api: Api.PasswordSecretApi,val anyToken:String, val anyAccessToken: suspend () -> String): ClientModelRestEndpoints<PasswordSecret, UUID> {
        override suspend fun query(input: Query<PasswordSecret>): List<PasswordSecret> = api.query(input, anyAccessToken)
        override suspend fun queryPartial(input: QueryPartial<PasswordSecret>): List<Partial<PasswordSecret>> = api.queryPartial(input, anyAccessToken)
        override suspend fun detail(id: UUID): PasswordSecret = api.detail(id, anyAccessToken)
        override suspend fun insertBulk(input: List<PasswordSecret>): List<PasswordSecret> = api.insertBulk(input, anyAccessToken)
        override suspend fun insert(input: PasswordSecret): PasswordSecret = api.insert(input, anyAccessToken)
        override suspend fun upsert(id: UUID, input: PasswordSecret): PasswordSecret = api.upsert(id, input, anyAccessToken)
        override suspend fun bulkReplace(input: List<PasswordSecret>): List<PasswordSecret> = api.bulkReplace(input, anyAccessToken)
        override suspend fun replace(id: UUID, input: PasswordSecret): PasswordSecret = api.replace(id, input, anyAccessToken)
        override suspend fun bulkModify(input: MassModification<PasswordSecret>): Int = api.bulkModify(input, anyAccessToken)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PasswordSecret>): EntryChange<PasswordSecret> = api.modifyWithDiff(id, input, anyAccessToken)
        override suspend fun modify(id: UUID, input: Modification<PasswordSecret>): PasswordSecret = api.modify(id, input, anyAccessToken)
        override suspend fun bulkDelete(input: Condition<PasswordSecret>): Int = api.bulkDelete(input, anyAccessToken)
        override suspend fun delete(id: UUID): Unit = api.delete(id, anyAccessToken)
        override suspend fun count(input: Condition<PasswordSecret>): Int = api.count(input, anyAccessToken)
        override suspend fun groupCount(input: GroupCountQuery<PasswordSecret>): Map<String, Int> = api.groupCount(input, anyAccessToken)
        override suspend fun aggregate(input: AggregateQuery<PasswordSecret>): Double? = api.aggregate(input, anyAccessToken)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PasswordSecret>): Map<String, Double?> = api.groupAggregate(input, anyAccessToken)
    }
    class AnySessionPasswordProofApi(val api: Api.PasswordProofApi,val anyToken:String, val anyAccessToken: suspend () -> String): AuthenticatedPasswordProofClientEndpoints, PasswordProofClientEndpoints {
        override suspend fun establishPassword(input: EstablishPassword): Unit = api.establishPassword(input, anyAccessToken)
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = api.provePasswordOwnership(input)
    }
    class AnySessionUserAuthApi(val api: Api.UserAuthApi,val anyToken:String, val anyAccessToken: suspend () -> String): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
    }
    class AnySessionUserSessionApi(val api: Api.UserSessionApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionFcmTokenApi(val api: Api.FcmTokenApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionDynamicSettingsApi(val api: Api.DynamicSettingsApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionVehicleRelationshipApi(val api: Api.VehicleRelationshipApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionVehicleApi(val api: Api.VehicleApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionTransportRequestApi(val api: Api.TransportRequestApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionDealershipGroupApi(val api: Api.DealershipGroupApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionDealershipApi(val api: Api.DealershipApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionDealershipRelationshipApi(val api: Api.DealershipRelationshipApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionAuctionLaneApi(val api: Api.AuctionLaneApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionBidApi(val api: Api.BidApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionLiveAuctionDataApi(val api: Api.LiveAuctionDataApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionSellerTalkingPointApi(val api: Api.SellerTalkingPointApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionSavedSearchApi(val api: Api.SavedSearchApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionNotificationApi(val api: Api.NotificationApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionSubscriptionApi(val api: Api.SubscriptionApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionFakerApi(val api: Api.FakerApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionMakeApi(val api: Api.MakeApi,val anyToken:String, val anyAccessToken: suspend () -> String): ClientModelRestEndpoints<Make, String> {
        override suspend fun default(): Make = api.default(null)
        override suspend fun query(input: Query<Make>): List<Make> = api.query(input, null)
        override suspend fun queryPartial(input: QueryPartial<Make>): List<Partial<Make>> = api.queryPartial(input, null)
        override suspend fun detail(id: String): Make = api.detail(id, null)
        override suspend fun insertBulk(input: List<Make>): List<Make> = api.insertBulk(input, null)
        override suspend fun insert(input: Make): Make = api.insert(input, null)
        override suspend fun upsert(id: String, input: Make): Make = api.upsert(id, input, null)
        override suspend fun bulkReplace(input: List<Make>): List<Make> = api.bulkReplace(input, null)
        override suspend fun replace(id: String, input: Make): Make = api.replace(id, input, null)
        override suspend fun bulkModify(input: MassModification<Make>): Int = api.bulkModify(input, null)
        override suspend fun modifyWithDiff(id: String, input: Modification<Make>): EntryChange<Make> = api.modifyWithDiff(id, input, null)
        override suspend fun modify(id: String, input: Modification<Make>): Make = api.modify(id, input, null)
        override suspend fun bulkDelete(input: Condition<Make>): Int = api.bulkDelete(input, null)
        override suspend fun delete(id: String): Unit = api.delete(id, null)
        override suspend fun count(input: Condition<Make>): Int = api.count(input, null)
        override suspend fun groupCount(input: GroupCountQuery<Make>): Map<String, Int> = api.groupCount(input, null)
        override suspend fun aggregate(input: AggregateQuery<Make>): Double? = api.aggregate(input, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Make>): Map<String, Double?> = api.groupAggregate(input, null)
    }
    class AnySessionModelApi(val api: Api.ModelApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionSubscriptionPaymentApi(val api: Api.SubscriptionPaymentApi,val anyToken:String, val anyAccessToken: suspend () -> String) {
    }
    class AnySessionDealershipJoinRequestApi(val api: Api.DealershipJoinRequestApi,val anyToken:String, val anyAccessToken: suspend () -> String): ClientModelRestEndpoints<DealershipJoinRequest, UUID> {
        override suspend fun default(): DealershipJoinRequest = api.default(null)
        override suspend fun query(input: Query<DealershipJoinRequest>): List<DealershipJoinRequest> = api.query(input, null)
        override suspend fun queryPartial(input: QueryPartial<DealershipJoinRequest>): List<Partial<DealershipJoinRequest>> = api.queryPartial(input, null)
        override suspend fun detail(id: UUID): DealershipJoinRequest = api.detail(id, null)
        override suspend fun insertBulk(input: List<DealershipJoinRequest>): List<DealershipJoinRequest> = api.insertBulk(input, null)
        override suspend fun insert(input: DealershipJoinRequest): DealershipJoinRequest = api.insert(input, null)
        override suspend fun upsert(id: UUID, input: DealershipJoinRequest): DealershipJoinRequest = api.upsert(id, input, null)
        override suspend fun bulkReplace(input: List<DealershipJoinRequest>): List<DealershipJoinRequest> = api.bulkReplace(input, null)
        override suspend fun replace(id: UUID, input: DealershipJoinRequest): DealershipJoinRequest = api.replace(id, input, null)
        override suspend fun bulkModify(input: MassModification<DealershipJoinRequest>): Int = api.bulkModify(input, null)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<DealershipJoinRequest>): EntryChange<DealershipJoinRequest> = api.modifyWithDiff(id, input, null)
        override suspend fun modify(id: UUID, input: Modification<DealershipJoinRequest>): DealershipJoinRequest = api.modify(id, input, null)
        override suspend fun bulkDelete(input: Condition<DealershipJoinRequest>): Int = api.bulkDelete(input, null)
        override suspend fun delete(id: UUID): Unit = api.delete(id, null)
        override suspend fun count(input: Condition<DealershipJoinRequest>): Int = api.count(input, null)
        override suspend fun groupCount(input: GroupCountQuery<DealershipJoinRequest>): Map<String, Int> = api.groupCount(input, null)
        override suspend fun aggregate(input: AggregateQuery<DealershipJoinRequest>): Double? = api.aggregate(input, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<DealershipJoinRequest>): Map<String, Double?> = api.groupAggregate(input, null)
    }
}

