// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDealershipRelationshipFields() {
    val props: Array<SerializableProperty<DealershipRelationship, *>> = arrayOf(DealershipRelationship__id)
    DealershipRelationship.serializer().properties { props }
}
val <K> DataClassPath<K, DealershipRelationship>._id: DataClassPath<K, DealershipPair> get() = this[DealershipRelationship__id]
inline val DealershipRelationship.Companion.path: DataClassPath<DealershipRelationship, DealershipRelationship> get() = path<DealershipRelationship>()


object DealershipRelationship__id: SerializableProperty<DealershipRelationship, DealershipPair> {
    override val name: String = "_id"
    override fun get(receiver: DealershipRelationship): DealershipPair = receiver._id
    override fun setCopy(receiver: DealershipRelationship, value: DealershipPair) = receiver.copy(_id = value)
    override val serializer: KSerializer<DealershipPair> = DealershipPair.serializer()
    override val annotations: List<Annotation> = DealershipRelationship.serializer().tryFindAnnotations("_id")
}
