package com.ilussobsa

import com.ilussobsa.utils.defaultAppTheme
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.forms.prepareModelsClient
import com.lightningkite.kiteui.navigation.DefaultSerializersModule
import com.lightningkite.kiteui.navigation.PlatformNavigator
import com.lightningkite.kiteui.navigation.ScreenNavigator
import com.lightningkite.kiteui.navigation.basePath
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.KeyCodes
import com.lightningkite.serialization.ClientModule
import kotlin.js.json
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.*
import org.w3c.dom.css.get
import org.w3c.dom.events.KeyboardEvent
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

fun main() {
//    // If hosted not at the base,
//    if(basePath != "/") {
//        // default the API to same site
//        launchGlobal {
//            selectedApi set ApiOption.SameServer
//        }
//    }
    com.ilussobsa.sentry.init(
        json(
            "dsn" to "https://23d79003859741cbb5d146a6a9882f06@sentry24.lightningkite.com/7"
        )
    )
    Throwable_report = { ex, ctx ->
        ex.printStackTrace2()
        com.ilussobsa.sentry.captureException(ex)
    }
    window.asDynamic().setFcmToken = setFcmToken

    com.lightningkite.prepareModelsShared()
    prepareModelsShared()
    prepareModelsClient()
    DefaultSerializersModule = ClientModule
    root(defaultAppTheme) { app(ScreenNavigator { AutoRoutes }, ScreenNavigator { AutoRoutes }) }
}