package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.utils.logo
import com.ilussobsa.utils.priceField
import com.ilussobsa.utils.schedule
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.ImportantSemantic
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.titledSection
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.roundToInt
import kotlin.time.Duration
import kotlinx.coroutines.delay



@Routable("simulation")
class SimulationScreen : KiteUiScreen {

    val startingPrice = Property(25000)
    val realisticValue = Property(25000)
    val data = Property<List<SimulationStep>>(listOf())
    val interestingDataPoint = Property<SimulationStep?>(null)
    override fun ViewWriter.render() {
        col {
            h1("Simulator")
            row {
                expanding - label {
                    content = "Starting Price"
                    priceField(startingPrice.nullable())
                }
                expanding - label {
                    content = "Realistic Value"
                    priceField(realisticValue.nullable())
                }
                val rerun = BasicListenable()
                important - button {
                    text("See different simulation")
                    onClick {
                        rerun.invokeAll()
                    }
                }
                reactiveScope {
                    rerunOn(rerun)
                    val events = ArrayList<SimulationStep>()
                    auctionSimulation(
                        startingPrice.await(),
                        realisticValue.await(),
                        onSimulationStep = { events.add(it) })
                    data.value = events
                }
            }
            row {
                expanding - text {
                    reactiveSuspending {
                        val stats = SimStatistics()
                        content = "Running 1000 simulations..."
                        repeat(100) {
                            delay(1)
                            repeat(10) {
                                stats += auctionSimulation(
                                    asking = startingPrice.await(),
                                    realisticValue = realisticValue.await(),
                                    onSimulationStep = null
                                )
                            }
                        }
                        content = "${stats.duration.roundSeconds} seconds average, ${
                            stats.price.toInt().renderPriceInDollars()
                        } average sale, ${stats.bids.toInt()} bid average"
                    }
                }
                expanding - text {
                    ::content content@{
                        val events = data.await()
                        val last = events.lastOrNull() ?: return@content "No simulation run yet"
                        "This simulation sold for ${last.state.base.renderPriceInDollars()} with ${last.state.bids} bids after ${last.seconds} seconds"
                    }
                }
            }
            row {
                weight(1f) - text("Dealership")
                weight(1f) - text("Base Evaluation")
                weight(1f) - text("Confidence")
                weight(1f) - text("Range at highlighted step")
            }
            weight(1f) - scrolls - col {
                forEachUpdating(shared { data.await().lastOrNull()?.dealerships ?: listOf() }) {
                    row {
                        weight(1f) - text { ::content { it.await().dealership.name } }
                        weight(1f) - text { ::content { it.await().interestBase.renderPriceInDollars() } }
                        weight(1f) - text { ::content { (it.await().confidence * 100).toInt().toString() + "%" } }
                        weight(1f) - text {
                            ::content {
                                val dealer = it.await()
                                interestingDataPoint.await()?.let {
                                    it.dealerships.find { d -> dealer.dealership._id == d.dealership._id }?.let { d ->
                                        "${d.lowInterest.renderPriceInDollars()} - ${d.highInterest.renderPriceInDollars()}"
                                    }
                                } ?: "-"
                            }
                        }
                    }
                }
            }
            row {
                weight(1f) - text("Time")
                weight(1f) - text("Asking Price")
                weight(1f) - text("Dealerships Who Attempted Bid")
                weight(1f) - text("Current Winner")
                weight(1f) - text("Dealerships Hovering")
                space(2.0)
                space(2.0)
            }
            fun ViewWriter.stepRow(it: Readable<SimulationStep>) {
                row {
                    weight(1f) - compact - row {
                        icon { source = Icon.schedule }
                        text {
                            ::content {
                                it.await().seconds.toString() + "\nDrop in ${
                                    it.await().let { (it.state.timeout - it.time).roundSeconds }
                                }"
                            }
                        }
                    }
                    weight(1f) - text {
                        ::content {
                            it.await().state.let {
                                it.asking.renderPriceInDollars() + "\n(${it.base.renderPriceInDollars()} + ${it.increment.price.renderPriceInDollars()})"
                            }
                        }
                    }
                    weight(1f) - text {
                        ::content { it.await().precedingEvents.count { it is SimulationEvent.Bid }.toString() }
                    }
                    weight(1f) - text {
                        dynamicTheme {
                            if (it.await().precedingEvents.any { it is SimulationEvent.Bid }) ImportantSemantic
                            else null
                        }
                        ::content {
                            it.await().state.winningBid?.buyer?.let { b -> it.await().dealerships.find { d -> b == d.dealership._id }?.dealership?.name }
                                ?: "None"
                        }
                    }
                    weight(1f) - text {
                        ::content {
                            val step = it.await()
                            step.state.hovers.size.toString() + " " + step.state.hovers.joinToString(
                                ", ",
                                "(",
                                ")"
                            ) { b ->
                                step.dealerships.find { d -> b == d.dealership._id }?.dealership?.name?.last()
                                    ?.toString() ?: "-"
                            }
                        }
                    }
                    radioButton {
                        checked bind shared { interestingDataPoint() == it() }
                            .withWrite { v -> if(v) interestingDataPoint set it() }
                    }
                    space(2.0)
                }
            }
            run {
                val index = Property(0)
                reactiveSuspending {
                    repeat(data.await().size) {
                        index set it
                        delay(1000)
                    }
                }
                val it = shared {
                    val d = data.await()
                    val i = index.await()
                    if (i >= d.size) return@shared SimulationStep(
                        now(),
                        0L,
                        LiveAuctionData(uuid(), uuid()),
                        dealerships = listOf(),
                        precedingEvents = listOf()
                    )
                    d[i]
                }
                stepRow(it)
            }
            separator()
            weight(2f) - recyclerView {
                children(data) {
//                    text {
//                        ::content { it.await().toString() }
//                    }
                    stepRow(it)
                }
            }
        }
    }
}

private val Duration.roundSeconds: Int get() = (inWholeMilliseconds.toDouble() / 1000.0).roundToInt()