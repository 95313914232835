// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareSubscriptionPaymentFields() {
    val props: Array<SerializableProperty<SubscriptionPayment, *>> = arrayOf(SubscriptionPayment__id, SubscriptionPayment_dealership, SubscriptionPayment_at, SubscriptionPayment_method, SubscriptionPayment_priceInCents)
    SubscriptionPayment.serializer().properties { props }
}
val <K> DataClassPath<K, SubscriptionPayment>._id: DataClassPath<K, UUID> get() = this[SubscriptionPayment__id]
val <K> DataClassPath<K, SubscriptionPayment>.dealership: DataClassPath<K, UUID> get() = this[SubscriptionPayment_dealership]
val <K> DataClassPath<K, SubscriptionPayment>.at: DataClassPath<K, Instant> get() = this[SubscriptionPayment_at]
val <K> DataClassPath<K, SubscriptionPayment>.method: DataClassPath<K, String> get() = this[SubscriptionPayment_method]
val <K> DataClassPath<K, SubscriptionPayment>.priceInCents: DataClassPath<K, Int> get() = this[SubscriptionPayment_priceInCents]
inline val SubscriptionPayment.Companion.path: DataClassPath<SubscriptionPayment, SubscriptionPayment> get() = path<SubscriptionPayment>()


object SubscriptionPayment__id: SerializableProperty<SubscriptionPayment, UUID> {
    override val name: String = "_id"
    override fun get(receiver: SubscriptionPayment): UUID = receiver._id
    override fun setCopy(receiver: SubscriptionPayment, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = SubscriptionPayment.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object SubscriptionPayment_dealership: SerializableProperty<SubscriptionPayment, UUID> {
    override val name: String = "dealership"
    override fun get(receiver: SubscriptionPayment): UUID = receiver.dealership
    override fun setCopy(receiver: SubscriptionPayment, value: UUID) = receiver.copy(dealership = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = SubscriptionPayment.serializer().tryFindAnnotations("dealership")
}
object SubscriptionPayment_at: SerializableProperty<SubscriptionPayment, Instant> {
    override val name: String = "at"
    override fun get(receiver: SubscriptionPayment): Instant = receiver.at
    override fun setCopy(receiver: SubscriptionPayment, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = SubscriptionPayment.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
object SubscriptionPayment_method: SerializableProperty<SubscriptionPayment, String> {
    override val name: String = "method"
    override fun get(receiver: SubscriptionPayment): String = receiver.method
    override fun setCopy(receiver: SubscriptionPayment, value: String) = receiver.copy(method = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = SubscriptionPayment.serializer().tryFindAnnotations("method")
    override val default: String = "Not recorded"
}
object SubscriptionPayment_priceInCents: SerializableProperty<SubscriptionPayment, Int> {
    override val name: String = "priceInCents"
    override fun get(receiver: SubscriptionPayment): Int = receiver.priceInCents
    override fun setCopy(receiver: SubscriptionPayment, value: Int) = receiver.copy(priceInCents = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = SubscriptionPayment.serializer().tryFindAnnotations("priceInCents")
    override val default: Int = 0
}
