package com.ilussobsa

import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.views.dealershipProfile
import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.padded
import com.lightningkite.kiteui.views.direct.stack

@Routable("dealership/{id}")
class DealershipProfileScreen(val id: UUID): KiteUiScreen {
    override val title: Readable<String> = shared { currentSessionNullable.awaitNotNull().dealerships[id].await()?.name ?: Strings.vehicleDetail }
    override fun ViewWriter.render() {
        padded - stack {
            dealershipProfile(id)
        }
    }
}