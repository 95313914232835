// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun preparePurchasedInfoFields() {
    val props: Array<SerializableProperty<PurchasedInfo, *>> = arrayOf(PurchasedInfo_buyer, PurchasedInfo_price, PurchasedInfo_at)
    PurchasedInfo.serializer().properties { props }
}
val <K> DataClassPath<K, PurchasedInfo>.buyer: DataClassPath<K, UUID> get() = this[PurchasedInfo_buyer]
val <K> DataClassPath<K, PurchasedInfo>.price: DataClassPath<K, PriceInDollars> get() = this[PurchasedInfo_price]
val <K> DataClassPath<K, PurchasedInfo>.at: DataClassPath<K, Instant> get() = this[PurchasedInfo_at]
inline val PurchasedInfo.Companion.path: DataClassPath<PurchasedInfo, PurchasedInfo> get() = path<PurchasedInfo>()


object PurchasedInfo_buyer: SerializableProperty<PurchasedInfo, UUID> {
    override val name: String = "buyer"
    override fun get(receiver: PurchasedInfo): UUID = receiver.buyer
    override fun setCopy(receiver: PurchasedInfo, value: UUID) = receiver.copy(buyer = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = PurchasedInfo.serializer().tryFindAnnotations("buyer")
}
object PurchasedInfo_price: SerializableProperty<PurchasedInfo, PriceInDollars> {
    override val name: String = "price"
    override fun get(receiver: PurchasedInfo): PriceInDollars = receiver.price
    override fun setCopy(receiver: PurchasedInfo, value: PriceInDollars) = receiver.copy(price = value)
    override val serializer: KSerializer<PriceInDollars> = PriceInDollars.serializer()
    override val annotations: List<Annotation> = PurchasedInfo.serializer().tryFindAnnotations("price")
}
object PurchasedInfo_at: SerializableProperty<PurchasedInfo, Instant> {
    override val name: String = "at"
    override fun get(receiver: PurchasedInfo): Instant = receiver.at
    override fun setCopy(receiver: PurchasedInfo, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = PurchasedInfo.serializer().tryFindAnnotations("at")
    override val default: Instant = now()
}
