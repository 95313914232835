package com.ilussobsa.game

import com.ilussobsa.Strings
import com.ilussobsa.utils.appThemePreference
import com.ilussobsa.utils.defaultAppTheme
import com.lightningkite.kiteui.models.Angle
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.FontAndStyle
import com.lightningkite.kiteui.models.HeaderSemantic
import com.lightningkite.kiteui.views.canvas.*
import com.lightningkite.kiteui.views.direct.CanvasDelegate
import kotlin.math.*
import kotlin.random.Random
import kotlin.time.Duration
import kotlin.time.Duration.Companion.ZERO
import kotlin.time.Duration.Companion.seconds
import kotlin.time.DurationUnit


abstract class GameDelegate: CanvasDelegate() {

    val objects = ArrayList<GameObject>()

    var debug: Boolean = false

    var worldLeft: Double = 0.0
    var worldRight: Double = 0.0
    var worldTop: Double = 0.0
    var worldBottom: Double = 0.0
    var worldWidth: Double = 0.0
    var worldHeight: Double = 0.0

    override fun draw(context: DrawingContext2D) = with(context) {
        save()
        try {
            clear()
            val minSize = min(width, height)
            translate((width - minSize) / 2, (height - minSize) / 2)
            scale(minSize / 1000.0, minSize / 1000.0)
            if (debug) {
                fillPaint = Color.gray(0.9f)
                fillRect(499.0, 0.0, 2.0, 1000.0)
                fillRect(0.0, 499.0, 1000.0, 2.0)
            }
            this@GameDelegate.worldWidth = 1000.0 * width / minSize
            this@GameDelegate.worldHeight = 1000.0 * height / minSize
            this@GameDelegate.worldLeft = 500 - this@GameDelegate.worldWidth / 2
            this@GameDelegate.worldRight = 500 + this@GameDelegate.worldWidth / 2
            this@GameDelegate.worldTop = 500 - this@GameDelegate.worldHeight / 2
            this@GameDelegate.worldBottom = 500 + this@GameDelegate.worldHeight / 2
            objects.forEach {
                it.draw(context)
            }
        } finally {
            restore()
        }
    }
    fun onStep(action: (Double)->Boolean) = objects.add(object: GameObject {
        override fun step(seconds: Double): Boolean {
            return action(seconds)
        }
    })
    open fun step(seconds: Double) {
        objects.filter { it.step(seconds) }
        invalidate()
    }
}


interface GameObject {
    fun step(seconds: Double): Boolean  = false
    fun draw(context: DrawingContext2D) {}
}

data class Point(var x: Double = 0.0, var y: Double = 0.0)
// 1000x1000 is screen size

abstract class PositionedGameObject: GameObject {
    val current: Point = Point()
    var radius: Double = 0.0
    var shake = 0.0

    override fun draw(context: DrawingContext2D) = with(context) {
        save()
        translate(current.x, current.y)
        translate(shake * Random.nextDouble() - shake / 2, shake * Random.nextDouble() - shake / 2)
        scale(radius / 1000.0, radius / 1000.0)
        innerDraw(context)
        restore()
    }
    abstract fun innerDraw(context: DrawingContext2D)
}

abstract class Announcement: PositionedGameObject() {
    val destination: Point = Point()
    val announcementDestination: Point = Point(500.0, 500.0)
    var alpha = 0.0
    var life = 0.0
    companion object {
        const val fadeOutTime = -0.25
        const val stampAnimationEndTime = 0.15
        const val stayAnimationEndTime = 0.5
        const val moveUpAnimationEndTime = 0.75
    }
    var startRadius: Double = 800.0
    var fullRadius: Double = 400.0
    var endRadius: Double = 200.0
    var die = false

    fun reset() {
        life = fadeOutTime
    }
    
    val rangeActions = listOf(
        RangeAction(fadeOutTime..0.0) {
            current.x = destination.x
            current.y = destination.y
            alpha = 1 - it
            radius = (endRadius..0.01).applyRatio(it)
        },
        RangeAction(0.0..stampAnimationEndTime) {
            current.x = announcementDestination.x
            current.y = announcementDestination.y
            alpha = it
            radius = (startRadius..fullRadius).applyRatio(it)
        },
        RangeAction(stampAnimationEndTime..stayAnimationEndTime, ::easeLinear) {
            current.x = announcementDestination.x
            current.y = announcementDestination.y
            alpha = 1.0
            radius = fullRadius
        },
        RangeAction(stayAnimationEndTime..moveUpAnimationEndTime, ::easeSine) {
            current.x = (announcementDestination.x..destination.x).applyRatio(it)
            current.y = (announcementDestination.y..destination.y).applyRatio(it)
            alpha = 1.0
            radius = (fullRadius..endRadius).applyRatio(it)
        },
    )
    
    override fun step(seconds: Double): Boolean {
        life += seconds
        rangeActions.firstOrNull { life in it.range }?.let { it(life) }
        return die
    }
}

open class AnimatedChangingText(val isTitle: Boolean = false): Announcement() {
    var text: String = ""
        set(value) {
            if(field == value) return
            reset()
            field = value
        }
    var lastText = ""
    var fixedTextSize: Double? = null
    override fun step(seconds: Double): Boolean {
        if(life > 0) lastText = text
        return super.step(seconds)
    }
    override fun innerDraw(context: DrawingContext2D) = with(context) {
        fillPaint = (appThemePreference.value?.theme ?: defaultAppTheme).foreground.closestColor().copy(alpha = alpha.toFloat())
        strokePaint = (appThemePreference.value?.theme ?: defaultAppTheme).background.closestColor().copy(alpha = alpha.toFloat())
        val size = fixedTextSize ?: (1000.0 / (lastText.length.coerceAtLeast(1) * 0.5))
        font(size, if(isTitle) (appThemePreference.value?.theme ?: defaultAppTheme)[HeaderSemantic].theme.font else (appThemePreference.value?.theme ?: defaultAppTheme).font)
        lineWidth = size / 15.0
        textAlign(TextAlign.center)
        drawOutlinedText(lastText, 0.0, 0.0)
        drawText(lastText, 0.0, 0.0)
    }
}

class Clock(): PositionedGameObject() {
    var timeMax = 45.seconds
    var shakeTime = 10.seconds
    var bigMarkCount = 3
    var smallMarkCount = 5
    var time: Duration
        get() = timeMax - timeRemaining
        set(value) { timeRemaining = timeMax - value }
    var timeRemaining: Duration = timeMax
    var active: Boolean = true
    override fun step(seconds: Double): Boolean {
        if(active) {
            timeRemaining -= seconds.seconds
            if (timeRemaining < ZERO) timeRemaining = ZERO
            val diff = (timeRemaining) / shakeTime
            if (timeRemaining <= ZERO) shake = 0.0
            else shake = (1.0 - diff).coerceIn(0.0, 1.0).times(20.0)
        }
        return false
    }

    override fun innerDraw(context: DrawingContext2D) = with(context) {
        fillPaint = Color.white
        beginPath()
        appendArc(0.0, 0.0, 1000.0, Angle.zero, Angle.circle, true)
        closePath()
        fill()

        strokePaint = Color.black
        fillPaint = Color.black

        lineWidth = 40.0
        beginPath()
        appendArc(0.0, 0.0, 1000.0, Angle.zero, Angle.circle, true)
        closePath()
        stroke()

        lineWidth = 20.0
        repeat(bigMarkCount) {
            val angle = Angle(it.toDouble() / bigMarkCount)
            beginPath()
            moveTo(
                900.0 * (angle + Angle.quarterTurn * 3f).cos(),
                900.0 * (angle + Angle.quarterTurn * 3f).sin()
            )
            lineTo(
                800.0 * (angle + Angle.quarterTurn * 3f).cos(),
                800.0 * (angle + Angle.quarterTurn * 3f).sin()
            )
            stroke()

            repeat(smallMarkCount) {
                val angle = Angle(it.toDouble() / (bigMarkCount * smallMarkCount)) + angle
                beginPath()
                moveTo(
                    900.0 * (angle + Angle.quarterTurn * 3f).cos(),
                    900.0 * (angle + Angle.quarterTurn * 3f).sin()
                )
                lineTo(
                    850.0 * (angle + Angle.quarterTurn * 3f).cos(),
                    850.0 * (angle + Angle.quarterTurn * 3f).sin()
                )
                stroke()
            }
        }

        beginPath()
        val behindX = 100.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0) + 0.25).cos()
        val behindY = 100.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0) + 0.25).sin()
        moveTo(behindX, behindY)
        quadraticCurveTo(
            100.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0) + 0.5).cos(),
            100.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0) + 0.5).sin(),
            900.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0) + 0.75).cos(),
            900.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0) + 0.75).sin()
        )
        quadraticCurveTo(
            100.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0)).cos(),
            100.0 * Angle(turns = (time / timeMax).coerceAtMost(1.0)).sin(),
            behindX,
            behindY
        )
        closePath()
        fill()
    }
}



class CommentText: PositionedGameObject() {
    var inLife = -0.2
    var life = inLife
    var maxLife = 2.0
    var color = Color.black
    var outline = Color.white
    val alpha get() = if(life < 0.0) (inLife - life) / inLife else (maxLife - life) / maxLife
    var text = "TEST"
    var fixedTextSize: Double? = null
    override fun step(seconds: Double): Boolean {
        life += seconds
        current.y -= seconds * 70.0
        return life > maxLife
//        return false
    }
    override fun innerDraw(context: DrawingContext2D) = with(context) {
        fillPaint = color.copy(alpha = alpha.toFloat())
        strokePaint = outline.copy(alpha = alpha.toFloat())
        val size = fixedTextSize ?: (1000.0 / (text.length.coerceAtLeast(1) * 0.5))
        font(size, (appThemePreference.value?.theme ?: defaultAppTheme).font)
        lineWidth = size / 15.0
        textAlign(TextAlign.center)
        drawOutlinedText(text, 0.0, 0.0)
        drawText(text, 0.0, 0.0)
    }

}

class ClockWithFinalCount: GameObject {
    val clockCount = AnimatedChangingText()
    val clock = Clock()
    init {
        clock.timeMax = 45.seconds
        clock.timeRemaining = 12.seconds
        clock.bigMarkCount = 3
        clock.smallMarkCount = 3
        clock.current.x = 500.0
        clock.current.y = 150.0
        clock.radius = 100.0

        clockCount.text = ""
        clockCount.destination.x = clock.current.x
        clockCount.destination.y = clock.current.y + 60
        clockCount.announcementDestination.x = 500.0
        clockCount.announcementDestination.y = 500.0
        clockCount.fixedTextSize = 600.0
        clockCount.endRadius = 50.0
    }
    override fun draw(context: DrawingContext2D) {
        clock.draw(context)
        clockCount.draw(context)
    }

    override fun step(seconds: Double): Boolean {
        clock.step(seconds)
        clockCount.step(seconds)
        val secondsRemaining = (clock.timeRemaining + Announcement.fadeOutTime.seconds - Announcement.stampAnimationEndTime.seconds).toDouble(
            DurationUnit.SECONDS).let(::ceil).toInt()
        if(secondsRemaining == 0) {
            clockCount.text = "TIME!"
        }else if (secondsRemaining <= 10) {
            clockCount.text = secondsRemaining.toString()
        } else {
            clockCount.text = ""
        }
        return false
    }
}

fun ClosedFloatingPointRange<Double>.getRatio(value: Double): Double = (value - start) / (endInclusive - start)
fun ClosedFloatingPointRange<Double>.applyRatio(value: Double): Double = value * (endInclusive - start) + start
class RangeAction(val range: ClosedFloatingPointRange<Double>, val ease: (Double)->Double = ::easeInCircular, val action: (ratio: Double)->Unit) {
    operator fun invoke(value: Double) = action(ease(range.getRatio(value)))
}

fun easeSine(x: Double) = -(cos(PI * x) - 1) / 2
fun easeLinear(x: Double) = x
fun easeInCircular(x: Double) = 1 - sqrt(1 - x * x)