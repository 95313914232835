package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*

@Routable("live-auction/{id}/runlist")
class LiveAuctionRunlistScreen(val id: UUID) : KiteUiScreen {
    override val title: Readable<String> get() = Constant("Runlist")

    override fun ViewWriter.render() {
        val auction = shared { currentSession().auctions.get(id)() }
        val live = shared { currentSession().liveAuctionDataCache.watch(id)() }
        val runlistMeta = shared {
            currentSessionNullable.awaitNotNull().vehicles.watch(
                Query(
                    orderBy = sort { it.orderingValue.ascending() },
                    limit = 1000,
                    condition = auction.awaitNotNull().runlist
                )
            )
        }
        val runlist = shared { runlistMeta.await().await() }
        col {
            val jumpToCurrent = BasicListenable()
            padded - stack {
                compact - important - button {
                    centered - text(Strings.jumpToCurrent)
                    ::exists { live() != null }
                    onClick { jumpToCurrent.invokeAll() }
                }
            }
            expanding - padded - recyclerView {
                children(runlist) {
                    compact - link {
                        dynamicTheme {
                            if (live.await()?.currentVehicle == it.await()._id) SelectedSemantic
                            else null
                        }
                        sellingLotShortDetails(it)
                        ::to {
                            val id = it.await()._id
                            { VehicleDetailScreen(id) }
                        }
                    }
                }
                reactiveScope {
                    rerunOn(jumpToCurrent)
                    val current = live.awaitOnce()?.currentVehicle
                    val index = runlist.awaitOnce().indexOfFirst { it._id == current }
                    if (index != -1) scrollToIndex(index, Align.Start)
                }
            }
        }

    }
}