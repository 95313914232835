package com.ilussobsa.utils

import com.ilussobsa.PriceInDollars
import com.ilussobsa.Strings
import com.ilussobsa.Vehicle
import com.ilussobsa.path
import com.ilussobsa.reserve
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.uuid

fun String.toUuidOrNull(): UUID? = try {
    this.takeIf { it != "null" }?.let { uuid(it) }
} catch (e: IllegalArgumentException) {
    null
}

private val emailPattern = Regex("^[a-zA-Z0-9-+._]+@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z0-9-]+$")
fun String.isEmail(): Boolean = matches(emailPattern)


interface HasActions {
    val actions: Readable<List<NavElement>>
}

fun ViewWriter.priceField(price: Writable<PriceInDollars?>, setup: ContainingView.(NumberField)->Unit = {}) {
    compact - fieldTheme - row {
        centered - h3("$")
        val f: NumberField
        centered - sizeConstraints(width = 6.5.rem) - numberField {
//            spacing = 0.4.rem
            f = this
            content bind price.asDouble()
        }
        setup(f)
    }
}