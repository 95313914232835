package com.ilussobsa

import com.lightningkite.UUID
import com.lightningkite.default
import com.lightningkite.now
import com.lightningkite.uuid
import kotlin.math.roundToInt
import kotlin.random.Random
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant

data class SimulationStep(
    val time: Instant,
    val seconds: Long,
    val state: LiveAuctionData,
    val dealerships: List<SimulationStepDealership>,
    val precedingEvents: List<SimulationEvent>,
) {
    override fun toString(): String {
        return precedingEvents.joinToString("") {
            when(it) {
                is SimulationEvent.Bid -> {
                    val d = dealerships.find { d -> d.dealership._id == it.bid.buyer }
                    "  ${d?.dealership?.name} bids ${it.bid.price}, since they think it is worth between ${d?.lowInterest?.renderPriceInDollars()} and ${d?.highInterest?.renderPriceInDollars()}\n"
                }
                is SimulationEvent.Hover -> {
                    val d = dealerships.find { d -> d.dealership._id == it.dealership }
                    "  ${d?.dealership?.name} hovers over their bid button, since they think it is worth between ${d?.lowInterest?.renderPriceInDollars()} and ${d?.highInterest?.renderPriceInDollars()}\n"
                }
                is SimulationEvent.Leave -> {
                    val d = dealerships.find { d -> d.dealership._id == it.dealership }
                    "  ${d?.dealership?.name} leaves their bid button, since they think it is worth between ${d?.lowInterest?.renderPriceInDollars()} and ${d?.highInterest?.renderPriceInDollars()}\n"
                }
            }
        } + "${seconds.toString().padStart(3, '-')} Asking ${state.asking.renderPriceInDollars()} (${state.base.renderPriceInDollars()} + ${state.increment.price.renderPriceInDollars()})... drop in ${(state.timeout - time).inWholeSeconds} seconds"
    }
}

data class SimStatistics(
    var count: Int = 0,
    var durationSum: Duration = 0.seconds,
    var priceSum: PriceInDollars = 0,
    var bidSum: Int = 0,
) {
    val duration get() = durationSum / count
    val price get() = priceSum.toDouble() / count
    val bids get() = bidSum.toDouble() / count
    operator fun plusAssign(liveAuctionData: LiveAuctionData) {
        count++
        durationSum += liveAuctionData.duration
        priceSum += liveAuctionData.winningBidPrice ?: 0
        bidSum += liveAuctionData.bids
    }
    fun print() {
        println("Average Duration: $duration")
        println("Average Price: ${price.toInt().renderPriceInDollars()}")
        println("Average Bids: ${bids.toInt().renderPriceInDollars()}")
    }
}

sealed interface SimulationEvent {
    data class Bid(val bid: com.ilussobsa.Bid) : SimulationEvent
    data class Hover(val dealership: UUID) : SimulationEvent
    data class Leave(val dealership: UUID) : SimulationEvent
}

data class SimulationStepDealership(
    val dealership: Dealership,
    val interestBase: PriceInDollars,
    val confidence: Double,
    var lastStablePriceSeen: PriceInDollars,
    val interestBaseWithBids: Int,
    val interest: PriceInDollars,
    val lowInterest: PriceInDollars,
    val highInterest: PriceInDollars,
)

fun auctionSimulation(
    asking: PriceInDollars,
    realisticValue: PriceInDollars,
    virtualBidders: List<Dealership> = (0..5).map {
        Dealership(
            name = "Sample Dealership ${'A' + it}",
            makes = setOf(),
            address = Address.MOCK,
            usedCarManager = uuid()
        )
    },
    random: Random = Random,
    onSimulationStep: ((SimulationStep) -> Unit)? = { println(it) },
): LiveAuctionData {
    val oldClock = Clock.default

    class VirtualClock : Clock {
        val start = oldClock.now()
        var time = start
        val seconds get() = (time - start).inWholeSeconds
        operator fun plusAssign(duration: Duration) {
            time += duration
        }

        override fun now(): Instant = time
    }

    val virtualClock = VirtualClock()
    Clock.default = virtualClock

    try {
        var state = LiveAuctionData(
            _id = uuid(),
            currentVehicle = uuid(),
            base = asking.minus(1).roundDown(asking.startIncrement().price),
        )
        val delayedStateArray = arrayListOf(state, state)
        fun delayedState() = delayedStateArray[0]
//        fun delayedState() = state

        class DealershipData(
            val dealership: Dealership,
            val interestBase: PriceInDollars = realisticValue + random.nextInt(-realisticValue / 8, realisticValue / 8),
            val confidence: Double = .5 + Random.nextDouble() * .5
        ) {
            var lastStablePriceSeen: PriceInDollars = state.asking * 7 / 8
            val interestBaseWithBids: Int
                get() {
                    val ds = delayedState()
                    ds.winningBid?.let {
                        lastStablePriceSeen = it.price
                    }
                    if (ds.winningBid == null && now() - ds.lastAskChange > 5.seconds) {
                        lastStablePriceSeen = ds.asking * 3 / 4
                    }
                    return (interestBase * confidence + lastStablePriceSeen * (1 - confidence)).toInt()
                }

            // Simulates the chaos that is human behavior
            val interest: PriceInDollars
                get() = random.nextInt(lowInterest, highInterest)
            val lowInterest: PriceInDollars
                get() = interestBaseWithBids - interestBaseWithBids / 16
            val highInterest: PriceInDollars
                get() = interestBaseWithBids + interestBaseWithBids / 32

            override fun toString(): String = "${dealership.name}'s independent base valuation is ${interestBase.renderPriceInDollars()} with a confidence of ${(confidence * 100).roundToInt()}%"
        }

        val bidders = virtualBidders.map { DealershipData(it) }

        // Simulate by second
        while (virtualClock.seconds < 10000) {
            virtualClock += 1.seconds

            val currentState = state.copy()
            val events = ArrayList<SimulationEvent>()

            bidders.forEach {
                if (it.interest > delayedState().asking) {
                    if (it.dealership._id != currentState.winningBid?.buyer && delayedState().asking == currentState.asking) {
                        state = state.onWinningBid(
                            Bid(
                                vehicle = state.currentVehicle!!,
                                buyer = it.dealership._id,
                                price = delayedState().asking
                            ).also { events.add(SimulationEvent.Bid(it)) }
                        )?.invoke(state) ?: state
                    }
                }
                if (it.highInterest > delayedState().base.incrementedToRounded(delayedState().base.lowestIncrement().price)) {
                    state = state.onHover(it.dealership._id)(state).also { new ->
                        if (new.hovers.size != state.hovers.size)
                            events.add(SimulationEvent.Hover(it.dealership._id))
                    }
                } else {
                    state = state.onUnhover(it.dealership._id)(state).also { new ->
                        if (new.hovers.size != state.hovers.size)
                            events.add(SimulationEvent.Leave(it.dealership._id))
                    }
                }
            }

            if (now() > state.timeout) {
                val new = state.onTimeout()?.invoke(state)
                if (new == null) {
                    break
                } else {
                    state = new
                }
            }

            onSimulationStep?.invoke(SimulationStep(
                time = virtualClock.now(),
                seconds = virtualClock.seconds,
                state = state,
                dealerships = bidders.map {
                    SimulationStepDealership(
                        dealership = it.dealership,
                        interestBase = it.interestBase,
                        confidence = it.confidence,
                        lastStablePriceSeen = it.lastStablePriceSeen,
                        interestBaseWithBids = it.interestBaseWithBids,
                        interest = it.interest,
                        lowInterest = it.lowInterest,
                        highInterest = it.highInterest,
                    )
                },
                precedingEvents = events
            ))
            delayedStateArray.add(state)
            delayedStateArray.removeAt(0)
        }
        return state
    } finally {
        Clock.default = oldClock
    }
}