// Automatically generated based off OtpSecret.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.auth.proof

import com.lightningkite.UUID
import com.lightningkite.lightningdb.ExperimentalLightningServer
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.time.Duration
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlin.time.Duration
fun prepareKnownDeviceSecretFields() {
    val props: Array<SerializableProperty<KnownDeviceSecret, *>> = arrayOf(KnownDeviceSecret__id, KnownDeviceSecret_subjectType, KnownDeviceSecret_subjectId, KnownDeviceSecret_hash, KnownDeviceSecret_deviceInfo, KnownDeviceSecret_establishedAt, KnownDeviceSecret_lastUsedAt, KnownDeviceSecret_expiresAt, KnownDeviceSecret_disabledAt)
    KnownDeviceSecret.serializer().properties { props }
}
val <K> DataClassPath<K, KnownDeviceSecret>._id: DataClassPath<K, UUID> get() = this[KnownDeviceSecret__id]
val <K> DataClassPath<K, KnownDeviceSecret>.subjectType: DataClassPath<K, String> get() = this[KnownDeviceSecret_subjectType]
val <K> DataClassPath<K, KnownDeviceSecret>.subjectId: DataClassPath<K, String> get() = this[KnownDeviceSecret_subjectId]
val <K> DataClassPath<K, KnownDeviceSecret>.hash: DataClassPath<K, String> get() = this[KnownDeviceSecret_hash]
val <K> DataClassPath<K, KnownDeviceSecret>.deviceInfo: DataClassPath<K, String> get() = this[KnownDeviceSecret_deviceInfo]
val <K> DataClassPath<K, KnownDeviceSecret>.establishedAt: DataClassPath<K, Instant> get() = this[KnownDeviceSecret_establishedAt]
val <K> DataClassPath<K, KnownDeviceSecret>.lastUsedAt: DataClassPath<K, Instant?> get() = this[KnownDeviceSecret_lastUsedAt]
val <K> DataClassPath<K, KnownDeviceSecret>.expiresAt: DataClassPath<K, Instant?> get() = this[KnownDeviceSecret_expiresAt]
val <K> DataClassPath<K, KnownDeviceSecret>.disabledAt: DataClassPath<K, Instant?> get() = this[KnownDeviceSecret_disabledAt]
inline val KnownDeviceSecret.Companion.path: DataClassPath<KnownDeviceSecret, KnownDeviceSecret> get() = path<KnownDeviceSecret>()


object KnownDeviceSecret__id: SerializableProperty<KnownDeviceSecret, UUID> {
    override val name: String = "_id"
    override fun get(receiver: KnownDeviceSecret): UUID = receiver._id
    override fun setCopy(receiver: KnownDeviceSecret, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = UUID.serializer()
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)deviceInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object KnownDeviceSecret_subjectType: SerializableProperty<KnownDeviceSecret, String> {
    override val name: String = "subjectType"
    override fun get(receiver: KnownDeviceSecret): String = receiver.subjectType
    override fun setCopy(receiver: KnownDeviceSecret, value: String) = receiver.copy(subjectType = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("subjectType")
}
object KnownDeviceSecret_subjectId: SerializableProperty<KnownDeviceSecret, String> {
    override val name: String = "subjectId"
    override fun get(receiver: KnownDeviceSecret): String = receiver.subjectId
    override fun setCopy(receiver: KnownDeviceSecret, value: String) = receiver.copy(subjectId = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("subjectId")
}
object KnownDeviceSecret_hash: SerializableProperty<KnownDeviceSecret, String> {
    override val name: String = "hash"
    override fun get(receiver: KnownDeviceSecret): String = receiver.hash
    override fun setCopy(receiver: KnownDeviceSecret, value: String) = receiver.copy(hash = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("hash")
}
object KnownDeviceSecret_deviceInfo: SerializableProperty<KnownDeviceSecret, String> {
    override val name: String = "deviceInfo"
    override fun get(receiver: KnownDeviceSecret): String = receiver.deviceInfo
    override fun setCopy(receiver: KnownDeviceSecret, value: String) = receiver.copy(deviceInfo = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("deviceInfo")
}
object KnownDeviceSecret_establishedAt: SerializableProperty<KnownDeviceSecret, Instant> {
    override val name: String = "establishedAt"
    override fun get(receiver: KnownDeviceSecret): Instant = receiver.establishedAt
    override fun setCopy(receiver: KnownDeviceSecret, value: Instant) = receiver.copy(establishedAt = value)
    override val serializer: KSerializer<Instant> = Instant.serializer()
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("establishedAt")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)deviceInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object KnownDeviceSecret_lastUsedAt: SerializableProperty<KnownDeviceSecret, Instant?> {
    override val name: String = "lastUsedAt"
    override fun get(receiver: KnownDeviceSecret): Instant? = receiver.lastUsedAt
    override fun setCopy(receiver: KnownDeviceSecret, value: Instant?) = receiver.copy(lastUsedAt = value)
    override val serializer: KSerializer<Instant?> = Instant.serializer().nullable2
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("lastUsedAt")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)deviceInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object KnownDeviceSecret_expiresAt: SerializableProperty<KnownDeviceSecret, Instant?> {
    override val name: String = "expiresAt"
    override fun get(receiver: KnownDeviceSecret): Instant? = receiver.expiresAt
    override fun setCopy(receiver: KnownDeviceSecret, value: Instant?) = receiver.copy(expiresAt = value)
    override val serializer: KSerializer<Instant?> = Instant.serializer().nullable2
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("expiresAt")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)deviceInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object KnownDeviceSecret_disabledAt: SerializableProperty<KnownDeviceSecret, Instant?> {
    override val name: String = "disabledAt"
    override fun get(receiver: KnownDeviceSecret): Instant? = receiver.disabledAt
    override fun setCopy(receiver: KnownDeviceSecret, value: Instant?) = receiver.copy(disabledAt = value)
    override val serializer: KSerializer<Instant?> = Instant.serializer().nullable2
    override val annotations: List<Annotation> = KnownDeviceSecret.serializer().tryFindAnnotations("disabledAt")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)deviceInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
