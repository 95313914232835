package com.lightningkite.kiteui.forms

import com.lightningkite.serialization.*
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsClient() { ClientModelsObject }
object ClientModelsObject {
    init {
        SerializationRegistry.master.registerClient()
    }
}

fun SerializationRegistry.registerClient() {
    register(com.lightningkite.kiteui.forms.KnownDeviceSecretInfoStuff.serializer())
}
