package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.utils.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import kotlinx.serialization.Serializable

@Routable("/transport-requests")
class TransportRequestsScreen : KiteUiScreen {

    @Serializable
    enum class TransportRequestsTab(val text: String, val filter: () -> Condition<TransportRequest>) {
        Unquoted(Strings.unquoted, {
            condition {
                it.quoteRequested.neq(null) and
                        it.quoteEstablished.eq(null) and
                        it.accepted.eq(null)
            }
        }),
        Pending(Strings.pending, {
            condition { it.quoteEstablished.neq(null) and it.accepted.eq(null) and it.rejected.eq(null) }
        }),
        Rejected(Strings.transportDeclined, {
            condition { it.rejected.neq(null) }
        }),
        Accepted(Strings.workOrder, {
            condition {
                it.accepted.neq(null) and
                        it.complete.eq(null) and
                        it.rejected.eq(null)
            }
        }),
        Completed(Strings.transportCompleted, {
            condition { it.complete.neq(null) }
        })
    }

    @QueryParameter("selectedRequest")
    val selectedRequest = Property<UUID?>(null)

    @QueryParameter("filter")
    val selectedTab = Property(TransportRequestsTab.Unquoted)

    override fun ViewWriter.render() {
        expanding - row {
            col {
                scrollsHorizontally - compact - card - row {
                    gravity(Align.Start, Align.Center) - row {
                        for (tab in TransportRequestsTab.entries) {
                            radioToggleButton {
                                checked bind selectedTab.equalTo(tab)
                                text(tab.text)
                            }
                        }
                    }
                }
                expanding - swapView {
                    swapping(
                        current = { useTwoPane.await() },
                        views = { useTwoPane ->
                            navList - stack {
                                val items = shared {
                                    currentSessionNullable.awaitNotNull().transportRequests.query(
                                        Query(
                                            condition = selectedTab().filter(),
                                            orderBy = sort { it.quoteRequested.notNull.descending() },
                                            limit = 500
                                        )
                                    ).await()
                                }
                                recyclerView {
                                    children(items) {
                                        if (useTwoPane) {
                                            compact - radioToggleButton {
                                                transportRequestListItem(it.waitForNotNull)
                                                checked bind shared {
                                                    selectedRequest.await() == it.await()._id
                                                }.withWrite { value ->
                                                    if (value) {
                                                        selectedRequest.set(it.await()._id)
                                                    }
                                                }
                                            }
                                        } else {
                                            compact - button {
                                                transportRequestListItem(it.waitForNotNull)
                                                onClick {
                                                    it.awaitNotNull().let {
                                                        //navigator.navigate(VehicleDetailScreen(it._id))
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                centered - text {
                                    content = Strings.noTransportRequestsToShow
                                    ::exists { items.await().isEmpty() }
                                }
                            }
                        }
                    )
                }
            }
            expanding - swapView {
                ::exists { useTwoPane.await() }
                swapping(
                    current = { selectedRequest.await() },
                    views = { selectedRequest ->
                        if (selectedRequest == null) {
                            stack {
                                centered - text(Strings.pleaseSelectATransportQuoteRequestOnThe)
                            }
                            return@swapping
                        }
                        transportRequestDetailView(selectedRequest)
                    }
                )
            }
        }
    }
}

fun ViewWriter.transportRequestListItem(it: Readable<TransportRequest>) {
    row {
        centered - expanding - compact - col {
            HeaderSemantic.onNext - text {
                ::content {
                    it.awaitNotNull().requester?.let { currentSessionNullable.awaitNotNull().dealerships[it].awaitNotNull().name } ?: Strings.unknownDealership
                }
            }
            subtext {
                ::content {
                    it.awaitNotNull().vehicle?.let { currentSessionNullable.awaitNotNull().vehicles[it].awaitNotNull().ymmt } ?: Strings.unknownVehicle
                }
            }
            subtext {
                ::content { it().pickup.address.cityState + " to " + it().dropoff.address.cityState }
            }
        }
    }
}

fun ViewWriter.transportRequestDetailView(id: UUID) {
    val transportRequest = shared { currentSessionNullable.awaitNotNull().transportRequests[id]() }
    scrolls - col {
        val vehicle = shared { transportRequest.awaitNotNull().vehicle?.let { currentSessionNullable.awaitNotNull().vehicles[it] } }
        row {
            fun one(title: String, side: Readable<TransportAddress>) {
                card - col {
                    h4(title)
                    row {
                        text { ::content { side().contactName + "\n" + side().contactBusiness + "\n" + side().address.toStringNewline() } }
                        centered - centered - compact - button {
                            sizeConstraints(1.5.rem, 1.5.rem) - icon {
                                source = Icon.copy
                                description = Strings.copyToClipboard
                            }
                            onClick { ExternalServices.setClipboardText(side().contactName + "\n" + side().contactBusiness + "\n" + side().address.toStringNewline()) }
                        } in hintPopover(preferredDirection = PopoverPreferredDirection.belowCenter) {
                            h6(Strings.copyToClipboard)
                        }
                    }
                    externalLink {
                        ::exists{ side().contactEmail.isNotBlank() }
                        text { ::content { side().contactEmail } }
                        ::to { "mailto:${side().contactEmail}" }
                    }
                    externalLink {
                        ::exists{ side().contactPhone.isNotBlank() }
                        ::to { "tel:${side().contactPhone.filter { it.isDigit() }}" }
                        text {
                            ::content{ side().contactPhone.formatPhoneNumber() }
                        }
                    }
                }
            }
            one(Strings.pickup, shared { transportRequest.awaitNotNull().pickup })
            one(Strings.dropOff2, shared { transportRequest.awaitNotNull().dropoff })
            expanding - card - col {
                h4(Strings.notes)
                text {
                    ::content { transportRequest.awaitNotNull().deliveryMessage ?: "" }
                }
            }
        }
        card - col {
            h4(Strings.quoteStatus)
            row {
                bold - text(Strings.amount)
                ::exists { transportRequest.awaitNotNull().quoteEstablished != null }
                text {
                    ::content { transportRequest.awaitNotNull().quote?.renderPriceInDollars() ?: "" }
                }
            }
            row {
                fun statusIconAndText(
                    affirmative: ReactiveContext.() -> Boolean,
                    negative: ReactiveContext.() -> Boolean,
                    label: ReactiveContext.() -> String,
                    subtext: ReactiveContext.() -> String,
                ) {
                    expanding - col {
                        centered - icon {
                            ::source {
                                if (negative()) {
                                    Icon.xCircle
                                } else if (affirmative()) {
                                    Icon.checkCircle
                                } else {
                                    Icon.emptyCircle
                                }
                            }
                        }
                        centered - text {
                            (::content)(label)
                        }
                        centered - subtext {
                            (::content)(subtext)
                        }
                    }
                }
                statusIconAndText(
                    affirmative = { transportRequest()?.quoteRequested != null },
                    negative = { false },
                    label = { Strings.requested },
                    subtext = { transportRequest()?.quoteRequested?.renderToString(RenderSize.Abbreviation) ?: "" },
                )
                statusIconAndText(
                    affirmative = { transportRequest()?.quoteEstablished != null },
                    negative = { false },
                    label = { Strings.quoted },
                    subtext = {
                        transportRequest()?.quoteEstablished?.renderToString(RenderSize.Abbreviation) ?: ""
                    },
                )
                statusIconAndText(
                    affirmative = { transportRequest()?.accepted != null },
                    negative = { transportRequest()?.rejected != null },
                    label = {
                        if (transportRequest()?.status == TransportRequest.Status.Rejected) Strings.rejected else Strings.accepted
                    },
                    subtext = {
                        (transportRequest()?.rejected ?: transportRequest()?.accepted)
                            ?.renderToString(RenderSize.Abbreviation) ?: ""
                    },
                )
                statusIconAndText(
                    affirmative = { transportRequest()?.complete != null },
                    negative = { false },
                    label = { Strings.transportCompleted },
                    subtext = {
                        transportRequest()?.complete
                            ?.renderToString(RenderSize.Abbreviation) ?: ""
                    },
                )
            }
        }
        card - col {
            ::exists { transportRequest.awaitNotNull().quoteEstablished == null }
            h2(Strings.establishQuoteAmount)
            row {
                val quoteAmount = Property<Int?>(null)
                centered - priceField(quoteAmount)
                centered - text("+")
                centered - col {
                    spacing = 0.px
                    h3 {
                        ::content {
                            quoteAmount()?.times(TransportRequest.fee)?.toInt()?.renderPriceInDollars() ?: "___"
                        }
                    }
                    subtext(Strings.platformFee)
                }
                centered - text("=")
                centered - col {
                    spacing = 0.px
                    h3 {
                        ::content {
                            quoteAmount()?.times(TransportRequest.fee + 1.0)?.toInt()?.renderPriceInDollars() ?: "___"
                        }
                    }
                    subtext(Strings.total)
                }
                important - compact - button {
                    centered - text(Strings.submit)
                    onClick {
                        currentSession().transportRequests.get(id).modify(modification {
                            it.quoteBase assign quoteAmount.awaitNotNull()
                            it.quoteEstablished assign now()
                        })
                    }
                }
            }
        }
        card - col {
            ::exists { transportRequest.awaitNotNull().complete == null && transportRequest.awaitNotNull().accepted != null }
            h2(Strings.markComplete)
            centered - important - button {
                text(Strings.theVehicleHasBeenReceived)
                onClick {
                    currentSession().transportRequests.get(id).modify(modification {
                        it.complete assign now()
                    })
                }
            }
        }
        expanding - swapView {
            swapping(current = { transportRequest.await()?.vehicle }) {
                if (it == null) space()
                else vehicleDetail(it, goto = {}, backButtonInfo = null)
            }
        }
    }
}