package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.utils.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

private data class ExtraInfoPlus(
    val icon: Icon,
    val text: String,
    val getInfo: ExtraInfo
)

fun ViewWriter.issueIcons(selected: Readable<Vehicle>, size: Dimension = 2.rem) {
    compact - row {
        forEachUpdating(shared {
            buildList<ExtraInfoPlus> {
                val v = selected.awaitNotNull()
                v.completelyClean?.let { add(ExtraInfoPlus(Icon.clean, Strings.completelyClean, it)) }
                if(v.transmission == Transmission.Manual) { add(ExtraInfoPlus(Icon.manual, Strings.manualTransmission, ExtraInfo(Strings.thisVehicleHasAManualTransmission))) }
                v.paintwork?.let { add(ExtraInfoPlus(Icon.paintwork, Strings.paintWork, it)) }
                v.warningLights?.let { add(ExtraInfoPlus(Icon.warning, Strings.warningLights, it)) }
                v.towRequired?.let { add(ExtraInfoPlus(Icon.towing, Strings.towRequired, it)) }
                v.nonRunner?.let { add(ExtraInfoPlus(Icon.close, Strings.nonRunner, it)) }
                v.structuralDamage?.let { add(ExtraInfoPlus(Icon.damage, Strings.structuralDamage, it)) }
                v.airConditioningIssue?.let { add(ExtraInfoPlus(Icon.acIssues, Strings.airConditioningIssue, it)) }
                v.transmissionIssue?.let { add(ExtraInfoPlus(Icon.close, Strings.transmissionIssue, it)) }
                v.odometerIssue?.let { add(ExtraInfoPlus(Icon.speedometer, Strings.odometerIssue, it)) }
                v.canadian?.let { add(ExtraInfoPlus(Icon.canada, Strings.canadian, it)) }
                v.titleNotPresent?.let { add(ExtraInfoPlus(Icon.missingTitle, Strings.titleNotPresent, it)) }
                v.salvage?.let { add(ExtraInfoPlus(Icon.recycling, Strings.salvage, it)) }
                v.lemonLaw?.let { add(ExtraInfoPlus(Icon.lemon, Strings.lemonLaw, it)) }
                v.flood?.let { add(ExtraInfoPlus(Icon.waterDrop, Strings.floodDamage, it)) }
                v.stolenOrRecovery?.let { add(ExtraInfoPlus(Icon.money, Strings.stolenRecovery, it)) }
                v.rentalOrTaxi?.let { add(ExtraInfoPlus(Icon.taxi, Strings.rentalTaxi, it)) }
                v.trueMileageUnknown?.let { add(ExtraInfoPlus(Icon.close, Strings.trueMileageUnknown, it)) }
            }
        }, 0) { extraInfo ->
            hintPopover(preferredDirection = PopoverPreferredDirection.belowCenter) {
                col {
                    h3 { ::content{ extraInfo.await().text } }
                    text { ::content { extraInfo.await().getInfo?.description ?: Strings.noAdditionalInformationGiven } }
                }
            }
            sizeConstraints(width = size, height = size)
            icon {
                ::source { extraInfo.await().icon }
                ::description { extraInfo.await().text }
            }
        }
    }
}
