// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareTransportRequestFields() {
    val props: Array<SerializableProperty<TransportRequest, *>> = arrayOf(TransportRequest__id, TransportRequest_requester, TransportRequest_pickup, TransportRequest_dropoff, TransportRequest_deliveryMessage, TransportRequest_vehicle, TransportRequest_quoteRequested, TransportRequest_quoteEstablished, TransportRequest_quote, TransportRequest_quoteBase, TransportRequest_platformFee, TransportRequest_accepted, TransportRequest_complete, TransportRequest_rejected)
    TransportRequest.serializer().properties { props }
}
val <K> DataClassPath<K, TransportRequest>._id: DataClassPath<K, UUID> get() = this[TransportRequest__id]
val <K> DataClassPath<K, TransportRequest>.requester: DataClassPath<K, UUID> get() = this[TransportRequest_requester]
val <K> DataClassPath<K, TransportRequest>.pickup: DataClassPath<K, TransportAddress> get() = this[TransportRequest_pickup]
val <K> DataClassPath<K, TransportRequest>.dropoff: DataClassPath<K, TransportAddress> get() = this[TransportRequest_dropoff]
val <K> DataClassPath<K, TransportRequest>.deliveryMessage: DataClassPath<K, String?> get() = this[TransportRequest_deliveryMessage]
val <K> DataClassPath<K, TransportRequest>.vehicle: DataClassPath<K, UUID> get() = this[TransportRequest_vehicle]
val <K> DataClassPath<K, TransportRequest>.quoteRequested: DataClassPath<K, Instant?> get() = this[TransportRequest_quoteRequested]
val <K> DataClassPath<K, TransportRequest>.quoteEstablished: DataClassPath<K, Instant?> get() = this[TransportRequest_quoteEstablished]
val <K> DataClassPath<K, TransportRequest>.quote: DataClassPath<K, PriceInDollars?> get() = this[TransportRequest_quote]
val <K> DataClassPath<K, TransportRequest>.quoteBase: DataClassPath<K, PriceInDollars?> get() = this[TransportRequest_quoteBase]
val <K> DataClassPath<K, TransportRequest>.platformFee: DataClassPath<K, PriceInDollars?> get() = this[TransportRequest_platformFee]
val <K> DataClassPath<K, TransportRequest>.accepted: DataClassPath<K, Instant?> get() = this[TransportRequest_accepted]
val <K> DataClassPath<K, TransportRequest>.complete: DataClassPath<K, Instant?> get() = this[TransportRequest_complete]
val <K> DataClassPath<K, TransportRequest>.rejected: DataClassPath<K, Instant?> get() = this[TransportRequest_rejected]
inline val TransportRequest.Companion.path: DataClassPath<TransportRequest, TransportRequest> get() = path<TransportRequest>()


object TransportRequest__id: SerializableProperty<TransportRequest, UUID> {
    override val name: String = "_id"
    override fun get(receiver: TransportRequest): UUID = receiver._id
    override fun setCopy(receiver: TransportRequest, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object TransportRequest_requester: SerializableProperty<TransportRequest, UUID> {
    override val name: String = "requester"
    override fun get(receiver: TransportRequest): UUID = receiver.requester
    override fun setCopy(receiver: TransportRequest, value: UUID) = receiver.copy(requester = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("requester")
}
object TransportRequest_pickup: SerializableProperty<TransportRequest, TransportAddress> {
    override val name: String = "pickup"
    override fun get(receiver: TransportRequest): TransportAddress = receiver.pickup
    override fun setCopy(receiver: TransportRequest, value: TransportAddress) = receiver.copy(pickup = value)
    override val serializer: KSerializer<TransportAddress> = TransportAddress.serializer()
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("pickup")
}
object TransportRequest_dropoff: SerializableProperty<TransportRequest, TransportAddress> {
    override val name: String = "dropoff"
    override fun get(receiver: TransportRequest): TransportAddress = receiver.dropoff
    override fun setCopy(receiver: TransportRequest, value: TransportAddress) = receiver.copy(dropoff = value)
    override val serializer: KSerializer<TransportAddress> = TransportAddress.serializer()
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("dropoff")
}
object TransportRequest_deliveryMessage: SerializableProperty<TransportRequest, String?> {
    override val name: String = "deliveryMessage"
    override fun get(receiver: TransportRequest): String? = receiver.deliveryMessage
    override fun setCopy(receiver: TransportRequest, value: String?) = receiver.copy(deliveryMessage = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("deliveryMessage")
    override val default: String? = null
}
object TransportRequest_vehicle: SerializableProperty<TransportRequest, UUID> {
    override val name: String = "vehicle"
    override fun get(receiver: TransportRequest): UUID = receiver.vehicle
    override fun setCopy(receiver: TransportRequest, value: UUID) = receiver.copy(vehicle = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("vehicle")
}
object TransportRequest_quoteRequested: SerializableProperty<TransportRequest, Instant?> {
    override val name: String = "quoteRequested"
    override fun get(receiver: TransportRequest): Instant? = receiver.quoteRequested
    override fun setCopy(receiver: TransportRequest, value: Instant?) = receiver.copy(quoteRequested = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("quoteRequested")
    override val default: Instant? = null
}
object TransportRequest_quoteEstablished: SerializableProperty<TransportRequest, Instant?> {
    override val name: String = "quoteEstablished"
    override fun get(receiver: TransportRequest): Instant? = receiver.quoteEstablished
    override fun setCopy(receiver: TransportRequest, value: Instant?) = receiver.copy(quoteEstablished = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("quoteEstablished")
    override val default: Instant? = null
}
object TransportRequest_quote: SerializableProperty<TransportRequest, PriceInDollars?> {
    override val name: String = "quote"
    override fun get(receiver: TransportRequest): PriceInDollars? = receiver.quote
    override fun setCopy(receiver: TransportRequest, value: PriceInDollars?) = receiver.copy(quote = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("quote")
    override val default: PriceInDollars? = null
}
object TransportRequest_quoteBase: SerializableProperty<TransportRequest, PriceInDollars?> {
    override val name: String = "quoteBase"
    override fun get(receiver: TransportRequest): PriceInDollars? = receiver.quoteBase
    override fun setCopy(receiver: TransportRequest, value: PriceInDollars?) = receiver.copy(quoteBase = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("quoteBase")
    override val default: PriceInDollars? = null
}
object TransportRequest_platformFee: SerializableProperty<TransportRequest, PriceInDollars?> {
    override val name: String = "platformFee"
    override fun get(receiver: TransportRequest): PriceInDollars? = receiver.platformFee
    override fun setCopy(receiver: TransportRequest, value: PriceInDollars?) = receiver.copy(platformFee = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("platformFee")
    override val default: PriceInDollars? = null
}
object TransportRequest_accepted: SerializableProperty<TransportRequest, Instant?> {
    override val name: String = "accepted"
    override fun get(receiver: TransportRequest): Instant? = receiver.accepted
    override fun setCopy(receiver: TransportRequest, value: Instant?) = receiver.copy(accepted = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("accepted")
    override val default: Instant? = null
}
object TransportRequest_complete: SerializableProperty<TransportRequest, Instant?> {
    override val name: String = "complete"
    override fun get(receiver: TransportRequest): Instant? = receiver.complete
    override fun setCopy(receiver: TransportRequest, value: Instant?) = receiver.copy(complete = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("complete")
    override val default: Instant? = null
}
object TransportRequest_rejected: SerializableProperty<TransportRequest, Instant?> {
    override val name: String = "rejected"
    override fun get(receiver: TransportRequest): Instant? = receiver.rejected
    override fun setCopy(receiver: TransportRequest, value: Instant?) = receiver.copy(rejected = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = TransportRequest.serializer().tryFindAnnotations("rejected")
    override val default: Instant? = null
}
