package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.render
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.modification
import com.lightningkite.now
import com.lightningkite.serialization.notNull
import kotlinx.coroutines.launch

@Routable("vehicle/{id}/complete/seller")
class SellingCompleteSellerScreen(val id: UUID) : KiteUiScreen {
    val roughSellReport = IssueComponent(Strings.theyMadeReceivingPaymentDifficult)
    val buyerDidNotHonor = SimpleIssueComponent(Strings.dealCancelled, Strings.reportWillCancelTransaction)
    override fun ViewWriter.render() {
        dismissBackground {

            launch {
                val vehicle = currentSession().vehicles.get(id).awaitNotNull()
                roughSellReport.wasIssue set (vehicle.roughSellReport != null)
                roughSellReport.explanation set (vehicle.roughSellReport?.description ?: "")
                roughSellReport.severity set (vehicle.roughSellReport?.severity ?: Severity.Minor)
            }

            centered - scrolls - card -  col {
                spacing = 2.rem
                h1(Strings.reportIssues)
                roughSellReport.render(this)
                buyerDidNotHonor.render(this)
                reactiveScope {
                    if (buyerDidNotHonor.wasIssue()) {
                        roughSellReport.wasIssue.value = false
                    }
                    roughSellReport.enabled.value = !buyerDidNotHonor.wasIssue()
                }
                row {
                    expanding - space()
                    danger - button {
                        text(Strings.removeIssueReport)
                        onClick {
                            currentSessionNullable.awaitNotNull().vehicles.get(id).modify(modification {
                                it.roughSellReport assign roughSellReport.reset().report()
                            })
                            navigator.dismiss()
                        }
                    }
                    important - button {
                        text(Strings.submitIssueReport)
                        onClick {
                            currentSessionNullable.awaitNotNull().vehicles.get(id).modify(modification {
                                if (buyerDidNotHonor.wasIssue()) {
                                    it.cancelled assign now()
                                    it.cancellationReason assign CancellationReason.CANCELLED_BY_SELLER
                                    it.completion.notNull.sold assign false
                                    it.roughSellReport assign null
                                    it.roughBuyReport assign null
                                    it.inaccuracyReport assign null
                                } else {
                                    it.roughSellReport assign roughSellReport.report()
                                }
                            })
                            navigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}

class SimpleIssueComponent(private val description: String, private val warningText: String) : Screen {
    val wasIssue = Property(false)
    override fun ViewWriter.render() {
        col {
            row {
                checkbox {checked bind wasIssue }
                centered - text(description)
            }
            text {
                ::exists { wasIssue() }
                content = warningText
            }
        }
    }
}

class IssueComponent(val description: String) : Screen {
    val enabled = Property(true)
    val wasIssue = Property(false)
    val explanation = Property("")
    val severity = Property(Severity.Minor)
    override fun ViewWriter.render() {
        col {
            row {
                checkbox {
                    checked bind wasIssue
                    ::enabled { this@IssueComponent.enabled() }
                }
                centered - text(description)
            }
            col {
                ::exists { wasIssue.await() }
                label {
                    content = Strings.explanation
                    fieldTheme - textArea {
                        ::enabled { this@IssueComponent.enabled() }
                        content bind explanation
                        hint = Strings.explainTheSituationHere
                    }
                }
                label {
                    content = Strings.severity
                    row {
                        for (value in Severity.entries) {
                            expanding - card - radioToggleButton {
                                ::enabled { this@IssueComponent.enabled() }
                                centered - text(value.display)
                                checked bind severity.equalTo(value)
                            }
                        }
                    }
                }
            }
        }
    }

    fun report(): IssueReport? = if (wasIssue.value) IssueReport(
        explanation.value,
        severity.value
    ) else null

    suspend fun reset(): IssueComponent {
        wasIssue.set(false)
        explanation.set("")
        severity.set(Severity.Minor)
        return this
    }
}