package com.lightningkite

import com.lightningkite.serialization.*
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsShared() { SharedModelsObject }
object SharedModelsObject {
    init {
        SerializationRegistry.master.registerShared()
        SerializableAnnotation.parser<com.lightningkite.lightningdb.AdminSearchFields>("com.lightningkite.lightningdb.AdminSearchFields") { SerializableAnnotation("com.lightningkite.lightningdb.AdminSearchFields", values = mapOf("fields" to SerializableAnnotationValue(it.fields))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.AdminTableColumns>("com.lightningkite.lightningdb.AdminTableColumns") { SerializableAnnotation("com.lightningkite.lightningdb.AdminTableColumns", values = mapOf("fields" to SerializableAnnotationValue(it.fields))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.AdminTitleFields>("com.lightningkite.lightningdb.AdminTitleFields") { SerializableAnnotation("com.lightningkite.lightningdb.AdminTitleFields", values = mapOf("fields" to SerializableAnnotationValue(it.fields))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.AdminHidden>("com.lightningkite.lightningdb.AdminHidden") { SerializableAnnotation("com.lightningkite.lightningdb.AdminHidden", values = mapOf()) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.AdminViewOnly>("com.lightningkite.lightningdb.AdminViewOnly") { SerializableAnnotation("com.lightningkite.lightningdb.AdminViewOnly", values = mapOf()) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Multiline>("com.lightningkite.lightningdb.Multiline") { SerializableAnnotation("com.lightningkite.lightningdb.Multiline", values = mapOf()) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.UiWidget>("com.lightningkite.lightningdb.UiWidget") { SerializableAnnotation("com.lightningkite.lightningdb.UiWidget", values = mapOf("type" to SerializableAnnotationValue(it.type))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.JsonSchemaFormat>("com.lightningkite.lightningdb.JsonSchemaFormat") { SerializableAnnotation("com.lightningkite.lightningdb.JsonSchemaFormat", values = mapOf("format" to SerializableAnnotationValue(it.format))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.IntegerRange>("com.lightningkite.lightningdb.IntegerRange") { SerializableAnnotation("com.lightningkite.lightningdb.IntegerRange", values = mapOf("min" to SerializableAnnotationValue(it.min), "max" to SerializableAnnotationValue(it.max))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.FloatRange>("com.lightningkite.lightningdb.FloatRange") { SerializableAnnotation("com.lightningkite.lightningdb.FloatRange", values = mapOf("min" to SerializableAnnotationValue(it.min), "max" to SerializableAnnotationValue(it.max))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.ExpectedPattern>("com.lightningkite.lightningdb.ExpectedPattern") { SerializableAnnotation("com.lightningkite.lightningdb.ExpectedPattern", values = mapOf("pattern" to SerializableAnnotationValue(it.pattern))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.DisplayName>("com.lightningkite.lightningdb.DisplayName") { SerializableAnnotation("com.lightningkite.lightningdb.DisplayName", values = mapOf("text" to SerializableAnnotationValue(it.text))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Hint>("com.lightningkite.lightningdb.Hint") { SerializableAnnotation("com.lightningkite.lightningdb.Hint", values = mapOf("text" to SerializableAnnotationValue(it.text))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.MimeType>("com.lightningkite.lightningdb.MimeType") { SerializableAnnotation("com.lightningkite.lightningdb.MimeType", values = mapOf("types" to SerializableAnnotationValue(it.types), "maxSize" to SerializableAnnotationValue(it.maxSize))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.MaxLength>("com.lightningkite.lightningdb.MaxLength") { SerializableAnnotation("com.lightningkite.lightningdb.MaxLength", values = mapOf("size" to SerializableAnnotationValue(it.size), "average" to SerializableAnnotationValue(it.average))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.MaxSize>("com.lightningkite.lightningdb.MaxSize") { SerializableAnnotation("com.lightningkite.lightningdb.MaxSize", values = mapOf("size" to SerializableAnnotationValue(it.size), "average" to SerializableAnnotationValue(it.average))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Denormalized>("com.lightningkite.lightningdb.Denormalized") { SerializableAnnotation("com.lightningkite.lightningdb.Denormalized", values = mapOf("calculationId" to SerializableAnnotationValue(it.calculationId))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Description>("com.lightningkite.lightningdb.Description") { SerializableAnnotation("com.lightningkite.lightningdb.Description", values = mapOf("text" to SerializableAnnotationValue(it.text))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.References>("com.lightningkite.lightningdb.References") { SerializableAnnotation("com.lightningkite.lightningdb.References", values = mapOf("references" to SerializableAnnotationValue(it.references), "reverseName" to SerializableAnnotationValue(it.reverseName))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.MultipleReferences>("com.lightningkite.lightningdb.MultipleReferences") { SerializableAnnotation("com.lightningkite.lightningdb.MultipleReferences", values = mapOf("references" to SerializableAnnotationValue(it.references), "reverseName" to SerializableAnnotationValue(it.reverseName))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.DoesNotNeedLabel>("com.lightningkite.lightningdb.DoesNotNeedLabel") { SerializableAnnotation("com.lightningkite.lightningdb.DoesNotNeedLabel", values = mapOf()) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Group>("com.lightningkite.lightningdb.Group") { SerializableAnnotation("com.lightningkite.lightningdb.Group", values = mapOf("name" to SerializableAnnotationValue(it.name))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Sentence>("com.lightningkite.lightningdb.Sentence") { SerializableAnnotation("com.lightningkite.lightningdb.Sentence", values = mapOf("text" to SerializableAnnotationValue(it.text))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Importance>("com.lightningkite.lightningdb.Importance") { SerializableAnnotation("com.lightningkite.lightningdb.Importance", values = mapOf("size" to SerializableAnnotationValue(it.size))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Index>("com.lightningkite.lightningdb.Index") { SerializableAnnotation("com.lightningkite.lightningdb.Index", values = mapOf()) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.Unique>("com.lightningkite.lightningdb.Unique") { SerializableAnnotation("com.lightningkite.lightningdb.Unique", values = mapOf()) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.TextIndex>("com.lightningkite.lightningdb.TextIndex") { SerializableAnnotation("com.lightningkite.lightningdb.TextIndex", values = mapOf("fields" to SerializableAnnotationValue(it.fields))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.IndexSet>("com.lightningkite.lightningdb.IndexSet") { SerializableAnnotation("com.lightningkite.lightningdb.IndexSet", values = mapOf("fields" to SerializableAnnotationValue(it.fields))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.UniqueSet>("com.lightningkite.lightningdb.UniqueSet") { SerializableAnnotation("com.lightningkite.lightningdb.UniqueSet", values = mapOf("fields" to SerializableAnnotationValue(it.fields))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.NamedIndex>("com.lightningkite.lightningdb.NamedIndex") { SerializableAnnotation("com.lightningkite.lightningdb.NamedIndex", values = mapOf("indexName" to SerializableAnnotationValue(it.indexName))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.NamedUnique>("com.lightningkite.lightningdb.NamedUnique") { SerializableAnnotation("com.lightningkite.lightningdb.NamedUnique", values = mapOf("indexName" to SerializableAnnotationValue(it.indexName))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.NamedIndexSet>("com.lightningkite.lightningdb.NamedIndexSet") { SerializableAnnotation("com.lightningkite.lightningdb.NamedIndexSet", values = mapOf("fields" to SerializableAnnotationValue(it.fields), "indexName" to SerializableAnnotationValue(it.indexName))) }
        SerializableAnnotation.parser<com.lightningkite.lightningdb.NamedUniqueSet>("com.lightningkite.lightningdb.NamedUniqueSet") { SerializableAnnotation("com.lightningkite.lightningdb.NamedUniqueSet", values = mapOf("fields" to SerializableAnnotationValue(it.fields), "indexName" to SerializableAnnotationValue(it.indexName))) }
        com.lightningkite.lightningserver.auth.oauth.prepareOauthClientFields()
        com.lightningkite.lightningserver.auth.oauth.prepareOauthClientSecretFields()
        com.lightningkite.lightningserver.auth.proof.prepareOtpSecretFields()
        com.lightningkite.lightningserver.auth.proof.preparePasswordSecretFields()
        com.lightningkite.lightningserver.auth.proof.prepareKnownDeviceSecretFields()
        com.lightningkite.lightningserver.auth.subject.prepareSessionFields()
        com.lightningkite.lightningserver.exceptions.prepareReportedExceptionGroupFields()
        com.lightningkite.lightningserver.externalintegration.prepareExternalAsyncTaskRequestFields()
        com.lightningkite.lightningserver.files.prepareUploadForNextRequestFields()
    }
}

fun SerializationRegistry.registerShared() {
    register(com.lightningkite.GeoCoordinate.serializer())
    register(com.lightningkite.TrimmedString.serializer())
    register(com.lightningkite.CaselessString.serializer())
    register(com.lightningkite.TrimmedCaselessString.serializer())
    register(com.lightningkite.EmailAddress.serializer())
    register(com.lightningkite.PhoneNumber.serializer())
    register(com.lightningkite.UUID.serializer())
    register(com.lightningkite.ZonedDateTime.serializer())
    register(com.lightningkite.OffsetDateTime.serializer())
    register(com.lightningkite.lightningdb.Aggregate.serializer())
    register(com.lightningkite.lightningdb.CollectionChanges.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.CollectionChanges.serializer(it[0]) }
    register(com.lightningkite.lightningdb.CollectionUpdates.serializer(NothingSerializer(), NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.CollectionUpdates.serializer(it[0], it[1]) }
    register(com.lightningkite.lightningdb.Condition.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.Never.serializer())
    register(com.lightningkite.lightningdb.Condition.Always.serializer())
    register(com.lightningkite.lightningdb.Condition.And.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.And.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.Or.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.Or.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.Not.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.Not.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.Equal.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.Equal.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.NotEqual.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.NotEqual.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.Inside.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.Inside.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.NotInside.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.NotInside.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.GreaterThan.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.GreaterThan.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.LessThan.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.LessThan.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.GreaterThanOrEqual.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.GreaterThanOrEqual.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.LessThanOrEqual.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.LessThanOrEqual.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.StringContains.serializer())
    register(com.lightningkite.lightningdb.Condition.RawStringContains.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.RawStringContains.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.GeoDistance.serializer())
    register(com.lightningkite.lightningdb.Condition.FullTextSearch.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.FullTextSearch.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.RegexMatches.serializer())
    register(com.lightningkite.lightningdb.Condition.IntBitsClear.serializer())
    register(com.lightningkite.lightningdb.Condition.IntBitsSet.serializer())
    register(com.lightningkite.lightningdb.Condition.IntBitsAnyClear.serializer())
    register(com.lightningkite.lightningdb.Condition.IntBitsAnySet.serializer())
    register(com.lightningkite.lightningdb.Condition.ListAllElements.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.ListAllElements.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.ListAnyElements.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.ListAnyElements.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.ListSizesEquals.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.ListSizesEquals.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.SetAllElements.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.SetAllElements.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.SetAnyElements.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.SetAnyElements.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.SetSizesEquals.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.SetSizesEquals.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.Exists.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.Exists.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.OnKey.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.OnKey.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Condition.IfNotNull.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Condition.IfNotNull.serializer(it[0]) }
    register(com.lightningkite.lightningdb.EntryChange.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.EntryChange.serializer(it[0]) }
    register(com.lightningkite.lightningdb.GroupCountQuery.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.GroupCountQuery.serializer(it[0]) }
    register(com.lightningkite.lightningdb.AggregateQuery.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.AggregateQuery.serializer(it[0]) }
    register(com.lightningkite.lightningdb.GroupAggregateQuery.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.GroupAggregateQuery.serializer(it[0]) }
    register(com.lightningkite.lightningdb.ListChange.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.ListChange.serializer(it[0]) }
    register(com.lightningkite.lightningdb.MassModification.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.MassModification.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.Nothing.serializer())
    register(com.lightningkite.lightningdb.Modification.Chain.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.Chain.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.IfNotNull.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.IfNotNull.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.Assign.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.Assign.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.CoerceAtMost.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.CoerceAtMost.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.CoerceAtLeast.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.CoerceAtLeast.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.Increment.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.Increment.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.Multiply.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.Multiply.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.AppendString.serializer())
    register(com.lightningkite.lightningdb.Modification.AppendRawString.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.AppendRawString.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.ListAppend.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.ListAppend.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.ListRemove.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.ListRemove.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.ListRemoveInstances.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.ListRemoveInstances.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.ListDropFirst.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.ListDropFirst.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.ListDropLast.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.ListDropLast.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.ListPerElement.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.ListPerElement.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.SetAppend.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.SetAppend.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.SetRemove.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.SetRemove.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.SetRemoveInstances.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.SetRemoveInstances.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.SetDropFirst.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.SetDropFirst.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.SetDropLast.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.SetDropLast.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.SetPerElement.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.SetPerElement.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.Combine.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.Combine.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.ModifyByKey.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.ModifyByKey.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Modification.RemoveKeys.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Modification.RemoveKeys.serializer(it[0]) }
    register(com.lightningkite.lightningdb.Query.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.Query.serializer(it[0]) }
    register(com.lightningkite.lightningdb.QueryPartial.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.QueryPartial.serializer(it[0]) }
    register(com.lightningkite.lightningdb.SortPart.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningdb.SortPart.serializer(it[0]) }
    register(com.lightningkite.lightningserver.LSError.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthClient.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthClientSecret.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthResponse.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthCode.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthCodeRequest.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthPromptType.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthResponseMode.serializer())
    register(com.lightningkite.lightningserver.auth.oauth.OauthAccessType.serializer())
    register(com.lightningkite.lightningserver.auth.old.EmailPinLogin.serializer())
    register(com.lightningkite.lightningserver.auth.old.PhonePinLogin.serializer())
    register(com.lightningkite.lightningserver.auth.old.PasswordLogin.serializer())
    register(com.lightningkite.lightningserver.auth.proof.OtpHashAlgorithm.serializer())
    register(com.lightningkite.lightningserver.auth.proof.OtpSecret.serializer())
    register(com.lightningkite.lightningserver.auth.proof.PasswordSecret.serializer())
    register(com.lightningkite.lightningserver.auth.proof.KnownDeviceSecret.serializer())
    register(com.lightningkite.lightningserver.auth.proof.EstablishPassword.serializer())
    register(com.lightningkite.lightningserver.auth.proof.EstablishOtp.serializer())
    register(com.lightningkite.lightningserver.auth.proof.FinishProof.serializer())
    register(com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword.serializer())
    register(com.lightningkite.lightningserver.auth.proof.ProofMethodInfo.serializer())
    register(com.lightningkite.lightningserver.auth.proof.ProofOption.serializer())
    register(com.lightningkite.lightningserver.auth.proof.Proof.serializer())
    register(com.lightningkite.lightningserver.auth.proof.KnownDeviceOptions.serializer())
    register(com.lightningkite.lightningserver.auth.proof.KnownDeviceSecretAndExpiration.serializer())
    register(com.lightningkite.lightningserver.auth.subject.SubSessionRequest.serializer())
    register(com.lightningkite.lightningserver.auth.subject.Session.serializer(NothingSerializer(), NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningserver.auth.subject.Session.serializer(it[0], it[1]) }
    register(com.lightningkite.lightningserver.auth.subject.LogInRequest.serializer())
    register(com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods.serializer(it[0]) }
    register(com.lightningkite.lightningserver.auth.subject.ProofsCheckResult.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningserver.auth.subject.ProofsCheckResult.serializer(it[0]) }
    register(com.lightningkite.lightningserver.db.DumpType.serializer())
    register(com.lightningkite.lightningserver.db.DumpRequest.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.lightningserver.db.DumpRequest.serializer(it[0]) }
    register(com.lightningkite.lightningserver.exceptions.ReportedExceptionGroup.serializer())
    register(com.lightningkite.lightningserver.externalintegration.ExternalAsyncTaskRequest.serializer())
    register(com.lightningkite.lightningserver.files.UploadForNextRequest.serializer())
    register(com.lightningkite.lightningserver.files.UploadInformation.serializer())
    register(com.lightningkite.lightningserver.schema.LightningServerKSchema.serializer())
    register(com.lightningkite.lightningserver.schema.LightningServerKSchemaInterface.serializer())
    register(com.lightningkite.lightningserver.schema.LightningServerKSchemaEndpoint.serializer())
    register(com.lightningkite.lightningserver.serverhealth.HealthStatus.serializer())
    register(com.lightningkite.lightningserver.serverhealth.HealthStatus.Level.serializer())
    register(com.lightningkite.lightningserver.serverhealth.ServerHealth.serializer())
    register(com.lightningkite.lightningserver.serverhealth.ServerHealth.Memory.serializer())
    register(com.lightningkite.lightningserver.typed.BulkRequest.serializer())
    register(com.lightningkite.lightningserver.typed.BulkResponse.serializer())
    register(com.lightningkite.lightningserver.websocket.MultiplexMessage.serializer())
    register(com.lightningkite.serialization.DataClassPathPartial.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.serialization.DataClassPathPartial.serializer(it[0]) }
    register(com.lightningkite.serialization.Partial.serializer(NothingSerializer()).descriptor.serialName) { com.lightningkite.serialization.Partial.serializer(it[0]) }
    register(com.lightningkite.serialization.VirtualAlias.serializer())
    register(com.lightningkite.serialization.VirtualTypeParameter.serializer())
    register(com.lightningkite.serialization.VirtualStruct.serializer())
    register(com.lightningkite.serialization.VirtualEnum.serializer())
    register(com.lightningkite.serialization.VirtualEnumOption.serializer())
    register(com.lightningkite.serialization.VirtualField.serializer())
    register(com.lightningkite.serialization.VirtualTypeReference.serializer())
    register(com.lightningkite.serialization.SerializableAnnotation.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.NullValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.BooleanValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.ByteValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.ShortValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.IntValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.LongValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.FloatValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.DoubleValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.CharValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.StringValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.ClassValue.serializer())
    register(com.lightningkite.serialization.SerializableAnnotationValue.ArrayValue.serializer())
    register(com.lightningkite.Length.serializer())
    register(com.lightningkite.Area.serializer())
    register(com.lightningkite.Volume.serializer())
    register(com.lightningkite.Mass.serializer())
    register(com.lightningkite.Speed.serializer())
    register(com.lightningkite.Acceleration.serializer())
    register(com.lightningkite.Force.serializer())
    register(com.lightningkite.Pressure.serializer())
    register(com.lightningkite.Energy.serializer())
    register(com.lightningkite.Power.serializer())
    register(com.lightningkite.Temperature.serializer())
    register(com.lightningkite.validation.ValidationIssue.serializer())
    register(com.lightningkite.validation.ValidationIssuePart.serializer())
}
