// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDealershipPairFields() {
    val props: Array<SerializableProperty<DealershipPair, *>> = arrayOf(DealershipPair_from, DealershipPair_to)
    DealershipPair.serializer().properties { props }
}
val <K> DataClassPath<K, DealershipPair>.from: DataClassPath<K, UUID> get() = this[DealershipPair_from]
val <K> DataClassPath<K, DealershipPair>.to: DataClassPath<K, UUID> get() = this[DealershipPair_to]
inline val DealershipPair.Companion.path: DataClassPath<DealershipPair, DealershipPair> get() = path<DealershipPair>()


object DealershipPair_from: SerializableProperty<DealershipPair, UUID> {
    override val name: String = "from"
    override fun get(receiver: DealershipPair): UUID = receiver.from
    override fun setCopy(receiver: DealershipPair, value: UUID) = receiver.copy(from = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DealershipPair.serializer().tryFindAnnotations("from")
}
object DealershipPair_to: SerializableProperty<DealershipPair, UUID> {
    override val name: String = "to"
    override fun get(receiver: DealershipPair): UUID = receiver.to
    override fun setCopy(receiver: DealershipPair, value: UUID) = receiver.copy(to = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DealershipPair.serializer().tryFindAnnotations("to")
}
