package com.ilussobsa.views

import com.ilussobsa.Address
import com.ilussobsa.Strings
import com.ilussobsa.TransportAddress
import com.ilussobsa.TransportRequest
import com.ilussobsa.UsState
import com.ilussobsa.sdk.*
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import kotlinx.datetime.Clock

@Routable("/request-transport-quote/{vehicleId}")
class RequestTransportQuoteScreen(val vehicleId: UUID) : KiteUiScreen {

    @QueryParameter("pickup")
    val pickup = Property<TransportAddress>(TransportAddress(Address.MOCK, "", "", ""))
    @QueryParameter("dropoff")
    val dropoff = Property<TransportAddress>(TransportAddress(Address.MOCK, "", "", ""))
    @QueryParameter("deliveryMessage")
    val deliveryMessage = Property("")
    
    private fun ViewWriter.address(title: String, prop: Property<TransportAddress>) {
        col {
            bold - text(title)
            label {
                content = Strings.business
                fieldTheme - textField {
                    hint = Strings.business
                    content bind shared { prop().contactBusiness }.withWrite { value -> prop.modify { it.copy(contactName = value) } }
                }
            }
            label {
                content = Strings.contactName
                fieldTheme - textField {
                    hint = Strings.name1
                    content bind shared { prop().contactName }.withWrite { value -> prop.modify { it.copy(contactName = value) } }
                }
            }
            label {
                content = Strings.contactPhone
                fieldTheme - textField {
                    hint = "000-000-0000"
                    content bind shared { prop().contactPhone }.withWrite { value -> prop.modify { it.copy(contactPhone = value) } }
                }
            }
            label {
                content = Strings.contactEmail
                fieldTheme - textField {
                    hint = "person@place.com"
                    content bind shared { prop().contactEmail }.withWrite { value -> prop.modify { it.copy(contactEmail = value) } }
                }
            }
            label {
                content = Strings.streetAddress
                fieldTheme - textField {
                    hint = Strings.placeholder123PlaceStreet
                    content bind shared { prop().address.street }.withWrite { value -> prop.modify { it.copy(address = it.address.copy(street = value)) } }
                }
            }
            label {
                content = Strings.city
                fieldTheme - textField {
                    hint = Strings.city
                    content bind shared { prop().address.city }.withWrite { value -> prop.modify { it.copy(address = it.address.copy(city = value)) } }
                }
            }
            label {
                content = Strings.state
                fieldTheme - select {
                    bind(
                        shared { prop().address.state }.withWrite { value -> prop.modify { it.copy(address = it.address.copy(state = value)) } },
                        Constant(UsState.entries),
                        render = {
                            it.toString()
                        }
                    )
                }
            }
        }
    }

    override fun ViewWriter.render() = dismissBackground {
        gravity(Align.Center, Align.Stretch) - padded - scrolls - card - col {
            h1(Strings.requestTransportQuote)
            rowCollapsingToColumn(50.rem) {
                expanding - card - address(Strings.pickup, pickup)
                expanding - card - address(Strings.dropOff1, dropoff)
            }
            col {
                bold - text(Strings.deliveryNotes1)
                fieldTheme - textArea {
                    content bind deliveryMessage
                    hint = Strings.deliveryNotes2
                }
            }
            gravity(Align.End, Align.Center) - row {
                button {
                    text(Strings.cancel)
                    onClick { navigator.dismiss() }
                }
                card - important - button {
                    text(Strings.submitTransportRequest)
                    onClick {
                        currentSession().transportRequests.insert(TransportRequest(
                            requester = currentDealershipId() ?: return@onClick,
                            pickup = pickup(),
                            dropoff = dropoff(),
                            vehicle = vehicleId,
                            quoteRequested = Clock.System.now(),
                            deliveryMessage = deliveryMessage(),
                        ))
                        navigator.dismiss()
                    }
                }
            }
        }
    }
}