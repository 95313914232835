package com.ilussobsa.views

import com.ilussobsa.game.*
import kotlin.time.Duration.Companion.seconds

class LiveBiddingDelegate : GameDelegate() {
    val clock = Clock()
    val reserveStatus = AnimatedChangingText()
    val winningBid = AnimatedChangingText()
    val winningBidBuyer = AnimatedChangingText()

    init {
        reserveStatus.announcementDestination.x = 500.0
        reserveStatus.announcementDestination.y = 400.0
        reserveStatus.destination.x = 700.0
        reserveStatus.destination.y = 420.0
        reserveStatus.text = ""
        reserveStatus.fullRadius = 500.0
        reserveStatus.endRadius = 300.0
        objects.add(reserveStatus)
        winningBid.announcementDestination.x = 500.0
        winningBid.announcementDestination.y = 500.0
        winningBid.destination.x = 700.0
        winningBid.destination.y = 500.0
        winningBid.text = "$100,000"
        winningBid.fixedTextSize = 280.0
        winningBid.endRadius = 300.0
        objects.add(winningBid)
        winningBidBuyer.announcementDestination.x = 500.0
        winningBidBuyer.announcementDestination.y = 560.0
        winningBidBuyer.destination.x = 700.0
        winningBidBuyer.destination.y = 540.0
        winningBidBuyer.text = "Some Dealership"
        winningBidBuyer.fixedTextSize = 140.0
        winningBidBuyer.endRadius = 200.0
        objects.add(winningBidBuyer)
        clock.bigMarkCount = 1
        clock.smallMarkCount = 5
        clock.timeMax = 5.seconds
        clock.shakeTime = 2.seconds
        objects.add(clock)
    }

    fun comment(text: String) {
        val obj = CommentText()
        obj.current.x = (200..800).random().toDouble()
        obj.current.y = (600..900).random().toDouble()
        obj.fixedTextSize = 200.0
        obj.radius = 200.0
        obj.text = text
        objects.add(obj)
    }
}