// Automatically generated based off subjectModels.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UNCHECKED_CAST", "UNUSED_PARAMETER", "UnusedImport")

package com.lightningkite.lightningserver.auth.subject

import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.auth.oauth.OauthClient
import com.lightningkite.lightningserver.auth.proof.ProofOption
import com.lightningkite.now
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.time.Duration
import kotlinx.datetime.Instant
import com.lightningkite.UUID
import com.lightningkite.lightningdb.AdminTableColumns
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.uuid
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID
fun prepareSessionFields() {
    Session.serializer(NothingSerializer(), NothingSerializer()).properties { args -> arrayOf(
        Session__id(args[0], args[1]),
        Session_secretHash(args[0], args[1]),
        Session_derivedFrom(args[0], args[1]),
        Session_label(args[0], args[1]),
        Session_subjectId(args[0], args[1]),
        Session_createdAt(args[0], args[1]),
        Session_lastUsed(args[0], args[1]),
        Session_expires(args[0], args[1]),
        Session_terminated(args[0], args[1]),
        Session_ips(args[0], args[1]),
        Session_userAgents(args[0], args[1]),
        Session_scopes(args[0], args[1]),
        Session_oauthClient(args[0], args[1]),
    ) }
}
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>._id: DataClassPath<ROOT, UUID> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session__id(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.secretHash: DataClassPath<ROOT, String> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_secretHash(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.derivedFrom: DataClassPath<ROOT, UUID?> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_derivedFrom(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.label: DataClassPath<ROOT, String?> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_label(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.subjectId: DataClassPath<ROOT, ID> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_subjectId(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.createdAt: DataClassPath<ROOT, Instant> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_createdAt(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.lastUsed: DataClassPath<ROOT, Instant> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_lastUsed(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.expires: DataClassPath<ROOT, Instant?> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_expires(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.terminated: DataClassPath<ROOT, Instant?> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_terminated(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.ips: DataClassPath<ROOT, Set<String>> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_ips(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.userAgents: DataClassPath<ROOT, Set<String>> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_userAgents(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.scopes: DataClassPath<ROOT, Set<String>> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_scopes(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }
inline val <ROOT, SUBJECT: HasId<ID>, ID: Comparable<ID>> DataClassPath<ROOT, Session<SUBJECT, ID>>.oauthClient: DataClassPath<ROOT, String?> get() = this.serializer.tryTypeParameterSerializers()!!.let { this[Session_oauthClient(it[0] as KSerializer<SUBJECT>, it[1] as KSerializer<ID>)] }


class Session__id<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Session<SUBJECT, ID>): UUID = receiver._id
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("_id")
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = -642392068
    override fun equals(other: Any?): Boolean = other is Session__id<* , * >
}
class Session_secretHash<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, String> {
    override val name: String = "secretHash"
    override fun get(receiver: Session<SUBJECT, ID>): String = receiver.secretHash
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: String) = receiver.copy(secretHash = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("secretHash")
    override fun hashCode(): Int = 1592257112
    override fun equals(other: Any?): Boolean = other is Session_secretHash<* , * >
}
class Session_derivedFrom<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, UUID?> {
    override val name: String = "derivedFrom"
    override fun get(receiver: Session<SUBJECT, ID>): UUID? = receiver.derivedFrom
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: UUID?) = receiver.copy(derivedFrom = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("derivedFrom")
    override val default: UUID? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = -1589462033
    override fun equals(other: Any?): Boolean = other is Session_derivedFrom<* , * >
}
class Session_label<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, String?> {
    override val name: String = "label"
    override fun get(receiver: Session<SUBJECT, ID>): String? = receiver.label
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: String?) = receiver.copy(label = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("label")
    override val default: String? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = -1755743742
    override fun equals(other: Any?): Boolean = other is Session_label<* , * >
}
class Session_subjectId<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, ID> {
    override val name: String = "subjectId"
    override fun get(receiver: Session<SUBJECT, ID>): ID = receiver.subjectId
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: ID) = receiver.copy(subjectId = value)
    override val serializer: KSerializer<ID> = iD
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("subjectId")
    override fun hashCode(): Int = -1218984977
    override fun equals(other: Any?): Boolean = other is Session_subjectId<* , * >
}
class Session_createdAt<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Session<SUBJECT, ID>): Instant = receiver.createdAt
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("createdAt")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = 724325531
    override fun equals(other: Any?): Boolean = other is Session_createdAt<* , * >
}
class Session_lastUsed<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, Instant> {
    override val name: String = "lastUsed"
    override fun get(receiver: Session<SUBJECT, ID>): Instant = receiver.lastUsed
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: Instant) = receiver.copy(lastUsed = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("lastUsed")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = 1358713187
    override fun equals(other: Any?): Boolean = other is Session_lastUsed<* , * >
}
class Session_expires<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, Instant?> {
    override val name: String = "expires"
    override fun get(receiver: Session<SUBJECT, ID>): Instant? = receiver.expires
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: Instant?) = receiver.copy(expires = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("expires")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = 1718049218
    override fun equals(other: Any?): Boolean = other is Session_expires<* , * >
}
class Session_terminated<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, Instant?> {
    override val name: String = "terminated"
    override fun get(receiver: Session<SUBJECT, ID>): Instant? = receiver.terminated
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: Instant?) = receiver.copy(terminated = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("terminated")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = 1731055795
    override fun equals(other: Any?): Boolean = other is Session_terminated<* , * >
}
class Session_ips<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, Set<String>> {
    override val name: String = "ips"
    override fun get(receiver: Session<SUBJECT, ID>): Set<String> = receiver.ips
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: Set<String>) = receiver.copy(ips = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("ips")
    override val default: Set<String> = setOf() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = -642086966
    override fun equals(other: Any?): Boolean = other is Session_ips<* , * >
}
class Session_userAgents<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, Set<String>> {
    override val name: String = "userAgents"
    override fun get(receiver: Session<SUBJECT, ID>): Set<String> = receiver.userAgents
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: Set<String>) = receiver.copy(userAgents = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("userAgents")
    override val default: Set<String> = setOf() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = -2008178723
    override fun equals(other: Any?): Boolean = other is Session_userAgents<* , * >
}
class Session_scopes<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, Set<String>> {
    override val name: String = "scopes"
    override fun get(receiver: Session<SUBJECT, ID>): Set<String> = receiver.scopes
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: Set<String>) = receiver.copy(scopes = value)
    override val serializer: KSerializer<Set<String>> = SetSerializer(String.serializer())
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("scopes")
    override fun hashCode(): Int = 1278615991
    override fun equals(other: Any?): Boolean = other is Session_scopes<* , * >
}
class Session_oauthClient<SUBJECT: HasId<ID>, ID: Comparable<ID>>(sUBJECT: KSerializer<SUBJECT>, iD: KSerializer<ID>): SerializableProperty<Session<SUBJECT, ID>, String?> {
    override val name: String = "oauthClient"
    override fun get(receiver: Session<SUBJECT, ID>): String? = receiver.oauthClient
    override fun setCopy(receiver: Session<SUBJECT, ID>, value: String?) = receiver.copy(oauthClient = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Session.serializer(NothingSerializer(), NothingSerializer()).tryFindAnnotations("oauthClient")
    override val default: String? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretHash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)derivedFrom([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)createdAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsed([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expires([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)terminated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)ips([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)userAgents([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scopes([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)oauthClient([^a-zA-Z0-9]|$)
    override fun hashCode(): Int = 952689012
    override fun equals(other: Any?): Boolean = other is Session_oauthClient<* , * >
}
