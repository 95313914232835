package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.views.dialogs.AddUserToDealership
import com.ilussobsa.views.dialogs.RemoveUserFromDealership
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*


@Routable("/dealership/{id}/manage/people")
class DealershipManagePeopleScreen(val id: UUID) : Screen {
    override fun ViewWriter.render() {
        val dealership = shared { currentSessionNullable.awaitNotNull().dealerships[id] }
        val items = shared {
            currentSession().users.watch(
                Query(
                    condition { it.managesDealerships.any { it eq id } },
                    sort { it.name.ascending() })
            ).await()
        }
        pageWithMaxWidth(80.rem) {
            col {
                ::exists { dealership() != null }
                card - row {
                    gravity(
                        Align.Start,
                        Align.Center
                    ) - weight(3f) - h2(Strings.users)
                    gravity(Align.End, Align.Center) - button {
                        icon(Icon.add, Strings.addAUser)
                        onClick {
                            dialogScreenNavigator.navigate(
                                AddUserToDealership(id)
                            )
                        }
                    }
                }

                expanding - recyclerView {
                    children(items) { user ->

                        card - col {
                            row {
                                expanding - col {
                                    spacing = 0.px
                                    h6 { ::content { user.await().name } }
                                    externalLink {
                                        spacing = 0.px
                                        subtext { ::content { user.await().email } }
                                        ::to { "mailto:${user.await().email}" }
                                    }
                                    externalLink {
                                        spacing = 0.px
                                        ::exists{ user.await()?.phoneNumber !== null }
                                        ::to { "tel:${user.await().phoneNumber?.filter { it.isDigit() }}" }
                                        subtext {
                                            ::content{ user.await().phoneNumber?.formatPhoneNumber() ?: "" }
                                        }
                                    }
                                }
                                rowCollapsingToColumn(50.rem) {
                                    radioToggleButton {
                                        checked bind shared {
                                            dealership().awaitNotNull().titleManager == user.awaitNotNull()._id
                                        }.withWrite {
                                            if (it) {
                                                dealership().modify(modification {
                                                    it.titleManager assign user.awaitNotNull()._id
                                                })
                                            } else {
                                                dealership().modify(modification {
                                                    it.titleManager assign nullUuid
                                                })
                                            }
                                        }
                                        centered - text(Strings.titleManager)
                                    }
                                    radioToggleButton {
                                        checked bind shared {
                                            dealership().awaitNotNull().usedCarManager == user.awaitNotNull()._id
                                        }.withWrite {
                                            if (it) {
                                                dealership().modify(modification {
                                                    it.usedCarManager assign user.awaitNotNull()._id
                                                })
                                            } else {
                                                dealership().modify(modification {
                                                    it.usedCarManager assign nullUuid
                                                })
                                            }
                                        }
                                        centered - text(Strings.usedCarManager)
                                    }
                                    radioToggleButton {
                                        checked bind shared {
                                            dealership().awaitNotNull().transportManager == user.awaitNotNull()._id
                                        }.withWrite {
                                            if (it) {
                                                dealership().modify(modification {
                                                    it.transportManager assign user.awaitNotNull()._id
                                                })
                                            } else {
                                                dealership().modify(modification {
                                                    it.transportManager assign nullUuid
                                                })
                                            }
                                        }
                                        centered - text(Strings.transportContact)
                                    }
                                    radioToggleButton {
                                        checked bind shared {
                                            dealership().awaitNotNull().billingContact == user.awaitNotNull()._id
                                        }.withWrite {
                                            if (it) {
                                                dealership().modify(modification {
                                                    it.billingContact assign user.awaitNotNull()._id
                                                })
                                            } else {
                                                dealership().modify(modification {
                                                    it.billingContact assign nullUuid
                                                })
                                            }
                                        }
                                        centered - text(Strings.billingContact)
                                    }
                                }
                                button {
                                    ::enabled {
                                        val u = user()
                                        (u._id != currentSession().me()?._id || u.role >= UserRole.Manager) && u._id !in dealership().awaitNotNull()
                                            .let {
                                                setOf(
                                                    it.titleManager,
                                                    it.transportManager,
                                                    it.usedCarManager,
                                                )
                                            }
                                    }
                                    icon(Icon.delete, Strings.delete)
                                    onClick {
                                        dialogScreenNavigator.navigate(
                                            RemoveUserFromDealership(id, user)
                                        )
                                    }
                                } in hintPopover(
                                    preferredDirection = PopoverPreferredDirection(true, false, Align.Center)
                                ) {
                                    stack {
                                        sizeConstraints(maxWidth = 20.rem) - text {
                                            align = Align.Center
                                            ::content{
                                                val u = user()
                                                val dealership = dealership.awaitNotNull()
                                                when {
                                                    u._id == currentSession().me()?._id && u.role < UserRole.Manager -> Strings.youCantRemoveYourselfFromTheDealership
                                                    u._id in dealership.awaitNotNull()
                                                        .let {
                                                            setOf(
                                                                it.titleManager,
                                                                it.transportManager,
                                                                it.billingContact,
                                                                it.usedCarManager,
                                                            )
                                                        } -> Strings.youCantRemoveManagersFromTheDealershipMove

                                                    else -> Strings.removeTheUser
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }

        }
    }
}