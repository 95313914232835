// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareAttachmentFields() {
    val props: Array<SerializableProperty<Attachment, *>> = arrayOf(Attachment_label, Attachment_file)
    Attachment.serializer().properties { props }
}
val <K> DataClassPath<K, Attachment>.label: DataClassPath<K, String> get() = this[Attachment_label]
val <K> DataClassPath<K, Attachment>.file: DataClassPath<K, ServerFile> get() = this[Attachment_file]
inline val Attachment.Companion.path: DataClassPath<Attachment, Attachment> get() = path<Attachment>()


object Attachment_label: SerializableProperty<Attachment, String> {
    override val name: String = "label"
    override fun get(receiver: Attachment): String = receiver.label
    override fun setCopy(receiver: Attachment, value: String) = receiver.copy(label = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Attachment.serializer().tryFindAnnotations("label")
}
object Attachment_file: SerializableProperty<Attachment, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: Attachment): ServerFile = receiver.file
    override fun setCopy(receiver: Attachment, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = Attachment.serializer().tryFindAnnotations("file")
}
