package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.LsErrorException
import com.ilussobsa.sdk.sessionToken
import com.ilussobsa.utils.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.DefaultJson
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.proof.Proof
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable

interface ControlsNavVisibility {
    fun ReactiveContext.showNav(): Boolean
}

@Routable("login")
class LogInScreen() : KiteUiScreen, ControlsNavVisibility {

    override fun ReactiveContext.showNav(): Boolean = false

    @QueryParameter
    val proof = Property<String?>(null)

    @QueryParameter
    val backend = Property<String?>(null)

    @QueryParameter
    val notice = Property<Notice?>(null)

    @Serializable
    enum class Notice(val message: String) {
        DealershipRequestSubmissionSuccess(Strings.applicationSubmitSuccess)
    }

    val email = Property("")
    val passcodeSecret = Property<String?>(null)
    val passcode = Property("")

    private val usePassword = "USE PASSWORD"

    override fun ViewWriter.render() {
        println("LoginScreen rendered")
        launchGlobal {
            println("Login screen would wipe session")
            // TODO: WTF
            sessionToken.value = null
        }

        unpadded - stack {
            spacing = 0.px
            expanding - video {
                source = Resources.loginBackVideo
                showControls = false
                loop = true
                scaleType = ImageScaleType.Crop
                launch {
                    volume set 0f
                    playing set true
                }
            }
            overimage - stack {
                spacing = 3.rem
                gravity(Align.Center, Align.Stretch) - sizeConstraints(width = 40.rem) - scrolls - col {
                    expanding - space()
                    centered - image {
                        scaleType = ImageScaleType.Fit
                        source = Resources.fullLogo(defaultAppTheme)
                        description = Strings.appName
                    }
                    separator()
                    onlyWhen { (passcodeSecret() == null).also { println("Show email? $it") } } - col {
                        val field: TextField
                        label {
                            content = Strings.email2
                            fieldTheme - textInput {
                                hint = "someone@dealership.com"
                                keyboardHints = KeyboardHints.email
                                content bind email
                                field = this
                            }
                        }
                        important - button {
                            ::enabled { email().matches(emailPattern) }
                            text {
                                align = Align.Center
                                content = Strings.sendALoginCode
                            }
                            onClickAssociatedField(field, action = ::sendEmail)
                        }
                        //            oauthButtons()
                        link {
                            text {
                                align = Align.Center
                                content = Strings.requestAccount
                            }
                            to = { DealershipRequestReviewDetailScreen() }
                        }
                        externalLink {
                            text {
                                align = Align.Center
                                content = Strings.support
                            }
                            to = "mailto:support@lussotechnologies.com"
                        }
                        link {
                            text {
                                align = Align.Center
                                content = Strings.privacyPolicy
                            }
                            to = { PrivacyPolicyScreen() }
                        }
                    }
                    onlyWhen { (passcodeSecret() != null).also { println("Show passcode? $it") } } - col {

                        suspend fun confirmPasscode() {
                            try {
                                val api = selectedApi.await().api
                                val secret = passcodeSecret.await()
                                val proof =
                                    if (secret == usePassword)
                                        api.passwordProof.provePasswordOwnership(
                                            IdentificationAndPassword(
                                                type = "User",
                                                property = "email",
                                                value = email(),
                                                password = passcode(),
                                            )
                                        )
                                    else
                                        api.emailProof.proveEmailOwnership(
                                            FinishProof(
                                                passcodeSecret.await() ?: throw Exception("This shouldn't be null. B"),
                                                passcode.await()
                                            )
                                        )
                                val session = api.userAuth.logIn(listOf(proof))
                                sessionToken.set(session.session ?: throw Exception("This shouldn't be null. C"))
                                println("Session token is set")
                                //                delay(1000)
                                screenNavigator.reset(DashboardScreen())
                                email set ""
                                passcodeSecret set null
                                passcode set ""
                            } catch (e: LsErrorException) {
                                alert(Strings.logInError, e.error.message)
                            } catch (e: Exception) {
                                e.printStackTrace()
                                Exception("Failed to confirm passcode", e).report()
                                alert(Strings.logInError, Strings.anUnknownErrorOccurredIsYourInternetConnection)
                            }
                        }

                        text {
                            align = Align.Center
                            content = Strings.anEmailWithAPasscodeWasSentTo(email.value)
                        }

                        val field: TextField
                        label {
                            content = Strings.passcode
                            fieldTheme - textField {
                                field = this
                                hint = "------"
                                content bind passcode
                                keyboardHints = KeyboardHints.id
                                reactiveScope {
                                    if (passcodeSecret<String?>() != null) requestFocus()
                                }
                            }
                        }
                        space()
                        important - button {
                            ::enabled { passcodeSecret() == usePassword || passcode.await().length == 6 }
                            text {
                                align = Align.Center
                                content = Strings.confirmPasscode
                            }
                            onClickAssociatedField(field, action = ::confirmPasscode)
                        }
                        externalLink {
                            text {
                                align = Align.Center
                                content = Strings.support
                            }
                            to = "mailto:support@lussotechnologies.com"
                        }
                        link {
                            text {
                                align = Align.Center
                                content = Strings.privacyPolicy
                            }
                            to = { PrivacyPolicyScreen() }
                        }

                        button {
                            val showIcon = Property(false)
                            onClick {
                                sendEmail()
                                launchGlobal {
                                    showIcon.value = true
                                    delay(3000)
                                    showIcon.value = false
                                }
                            }
                            centered - row {
                                centered - text {
                                    align = Align.Center
                                    content = Strings.sendMeANewCode
                                }
                                centered - onlyWhen { showIcon() } - icon {
                                    source = Icon.done.copy(width = 1.rem, height = 1.rem)
                                    description = Strings.done
                                }
                            }
                        }

                        button {
                            onClick {
                                passcodeSecret.value = null
                                passcode.value = ""
                            }
                            text {
                                align = Align.Center
                                content = Strings.useADifferentEmail
                            }
                        }
                    }
                    expanding - space()
                    centered - row {
                        centered - image {
                            source = Resources.lussoLogo(defaultAppTheme)
                            description = Strings.lusso
                        }
                        space()
                        centered - h6("© 2024")
                    }
                }
                atTopCenter - (card - row {
                    ::exists { notice() != null }
                    icon { source = Icon.checkCircle }
                    centered - text {
                        ::content { notice()?.message ?: "" }
                    }
                })
            }
            button {
                text {
                    align = Align.Center
                    ::content{
                        selectedApi.await().apiName
                    }
                } in centered in sizedBox(SizeConstraints(minWidth = 8.rem, minHeight = 2.rem))

                onClick {
                    if(email.value.endsWith("@lightningkite.com") || email.value.endsWith("@ilusso.com") || email.value.endsWith("@yourptg.com") || email.value.endsWith("@brandspecificauctions.com")) {
                        var newIndex = ApiOption.selectedIndex + 1
                        if (newIndex >= ApiOption.entries.size) {
                            newIndex = 0
                        }
                        ApiOption.selectedIndex = newIndex
                        selectedApi.set(ApiOption.entries[newIndex])
                    }
                }
            } in atTopEnd

            reactiveScope {
                backend.await()?.let { domain ->
                    ApiOption.values().find { it.api.httpUrl.contains(domain, true) }?.let {
                        selectedApi.value = it
                    }
                }
                val api = selectedApi.await().api
                val proof = proof.await() ?: return@reactiveScope
                launch {
                    val parsed = try {
                        DefaultJson.decodeFromString<Proof>(proof)
                    } catch (e: Exception) {
                        Exception("Failed to parse passed proof ${proof.take(10)}", e).report()
                        return@launch
                    }
                    val session = api.userAuth.logIn(listOf(parsed))
                    sessionToken.set(session.session ?: throw Exception("This shouldn't be null. A"))
                    delay(10)
                    navigator.replace(DashboardScreen())
                }
                launch {
                    this@LogInScreen.proof set null
                }
            }
        }
    }

    suspend fun sendEmail() {
        if (email() == "joseph+appstoretester@lightningkite.com") {
            passcodeSecret.value = usePassword
        } else {
            val passcodeResult = selectedApi.await().api.emailProof.beginEmailOwnershipProof(email.await())
            passcodeSecret.value = passcodeResult
        }
    }

}