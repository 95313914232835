package com.ilussobsa.views.dialogs

import com.ilussobsa.Strings
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

class ExpandedImage(val selected: ImageRemote) : KiteUiScreen {
    //    val selected = vehicles().get(selectedLot)
    override fun ViewWriter.render() {
        dismissBackground {
            spacing = 5.rem
            stack {
                zoomableImage {
                    source = this@ExpandedImage.selected
                    this.description = ""
                }
                gravity(Align.End, Align.Start) - important - button {
                    icon {
                        source = Icon.close
                    }
                    onClick {
                        navigator.dismiss()
                    }
                }
            }
        }
    }
}