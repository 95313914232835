package com.ilussobsa.views

import com.ilussobsa.Resources
import com.ilussobsa.Strings
import com.ilussobsa.game.GameDelegate
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.clockMillis
import com.lightningkite.kiteui.models.ImageScaleType
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.AnimationFrame
import com.lightningkite.kiteui.reactive.AppState
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.reactive.rerunOn
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*

@Routable("animation-test")
class AnimationTestingScreen: KiteUiScreen {
    override fun ViewWriter.render() {
        stack {
            row {
                weight(2f) - image {
                    source = Resources.placeholdersAngled
                    scaleType = ImageScaleType.Fit
                    this.description = ""
                }
                weight(1f) - space()
            }
            canvas {
                val delegate = LiveBiddingDelegate()
                this.delegate = delegate
                var last = clockMillis()

                var count = 3.1
                delegate.onStep {
                    count += it
                    if (count > 1.0) {
                        count = 0.0
                        delegate.comment(Strings.test)
                    }
                    false
                }

                reactiveScope {
                    rerunOn(AppState.animationFrame)
                    val now = clockMillis()
                    delegate.step((now - last) / 1000.0)
                    last = now
                }
            }
            col {
                weight(4f) - space()
                centered - important - button {
                }
                weight(1f) - space()
            }
        }
    }
}
