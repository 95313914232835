package com.ilussobsa.views.dialogs

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.utils.priceField
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*

class CounterOffer(val selectedLot: UUID) : KiteUiScreen {
    val counter = Property<Int?>(null)

    override fun ViewWriter.render() {
        dismissBackground {
            centered - card - sizeConstraints(
                width = 20.rem,
                height = 12.rem
            ) - col {
                centered - h4(Strings.enterACounterOffer)
                expanding - space()
                centered - priceField(counter)
                expanding - space()
                row {
                    expanding - col {
                        compact - button {
                            centered - h6(Strings.cancel)
                            onClick {
                                navigator.dismiss()
                            }
                        }
                    }
                    expanding - col {
                        important - compact - button {
                            centered - h6(Strings.submit)
                            onClick {
                                currentSession().vehicles.get(selectedLot).modify(modification {
                                    it.counterOffer assign counter.await()
                                })
                                navigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }
}