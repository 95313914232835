package com.ilussobsa.utils

import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.direct.animate
import kotlin.js.json
import org.w3c.dom.HTMLElement

actual fun RView.hoverEvents(
    onMouseEnter: suspend () -> Unit,
    onMouseLeave: suspend () -> Unit
) {
    this.native.addEventListener("mouseenter") {
        launchGlobal { onMouseEnter() }
    }
    this.native.addEventListener("mouseleave") {
        launchGlobal { onMouseLeave() }
    }
}
actual fun RView.animateEmphasis() {
    (native.element as? HTMLElement)?.animate(arrayOf(
        json("transform" to "none"),
        json("transform" to "scale(0.9)"),
        json("transform" to "none"),
    ), json(
        "duration" to 1000.0,
        "easing" to "linear"
    ))
}