// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDealershipFields() {
    val props: Array<SerializableProperty<Dealership, *>> = arrayOf(Dealership__id, Dealership_name, Dealership_description, Dealership_createdAt, Dealership_accuracyGrade, Dealership_sellingTransactionGrade, Dealership_buyingTransactionGrade, Dealership_buys, Dealership_bids, Dealership_sells, Dealership_stars, Dealership_url, Dealership_profileBanner, Dealership_profilePicture, Dealership_makes, Dealership_address, Dealership_additionalLocations, Dealership_dealershipGroup, Dealership_usedCarManager, Dealership_transportManager, Dealership_titleManager, Dealership_billingContact, Dealership_paymentCustomerId, Dealership_subscriptionId, Dealership_active, Dealership_lastPayment, Dealership_autoSellEnabled)
    Dealership.serializer().properties { props }
}
val <K> DataClassPath<K, Dealership>._id: DataClassPath<K, UUID> get() = this[Dealership__id]
val <K> DataClassPath<K, Dealership>.name: DataClassPath<K, String> get() = this[Dealership_name]
val <K> DataClassPath<K, Dealership>.description: DataClassPath<K, String> get() = this[Dealership_description]
val <K> DataClassPath<K, Dealership>.createdAt: DataClassPath<K, Instant> get() = this[Dealership_createdAt]
val <K> DataClassPath<K, Dealership>.accuracyGrade: DataClassPath<K, Grade> get() = this[Dealership_accuracyGrade]
val <K> DataClassPath<K, Dealership>.sellingTransactionGrade: DataClassPath<K, Grade> get() = this[Dealership_sellingTransactionGrade]
val <K> DataClassPath<K, Dealership>.buyingTransactionGrade: DataClassPath<K, Grade> get() = this[Dealership_buyingTransactionGrade]
val <K> DataClassPath<K, Dealership>.buys: DataClassPath<K, Int> get() = this[Dealership_buys]
val <K> DataClassPath<K, Dealership>.bids: DataClassPath<K, Int> get() = this[Dealership_bids]
val <K> DataClassPath<K, Dealership>.sells: DataClassPath<K, Int> get() = this[Dealership_sells]
val <K> DataClassPath<K, Dealership>.stars: DataClassPath<K, Int> get() = this[Dealership_stars]
val <K> DataClassPath<K, Dealership>.url: DataClassPath<K, String?> get() = this[Dealership_url]
val <K> DataClassPath<K, Dealership>.profileBanner: DataClassPath<K, ServerFile?> get() = this[Dealership_profileBanner]
val <K> DataClassPath<K, Dealership>.profilePicture: DataClassPath<K, ServerFile?> get() = this[Dealership_profilePicture]
val <K> DataClassPath<K, Dealership>.makes: DataClassPath<K, Set<MakeName>> get() = this[Dealership_makes]
val <K> DataClassPath<K, Dealership>.address: DataClassPath<K, Address> get() = this[Dealership_address]
val <K> DataClassPath<K, Dealership>.additionalLocations: DataClassPath<K, List<Address>> get() = this[Dealership_additionalLocations]
val <K> DataClassPath<K, Dealership>.dealershipGroup: DataClassPath<K, UUID?> get() = this[Dealership_dealershipGroup]
val <K> DataClassPath<K, Dealership>.usedCarManager: DataClassPath<K, UUID> get() = this[Dealership_usedCarManager]
val <K> DataClassPath<K, Dealership>.transportManager: DataClassPath<K, UUID> get() = this[Dealership_transportManager]
val <K> DataClassPath<K, Dealership>.titleManager: DataClassPath<K, UUID> get() = this[Dealership_titleManager]
val <K> DataClassPath<K, Dealership>.billingContact: DataClassPath<K, UUID> get() = this[Dealership_billingContact]
val <K> DataClassPath<K, Dealership>.paymentCustomerId: DataClassPath<K, String?> get() = this[Dealership_paymentCustomerId]
val <K> DataClassPath<K, Dealership>.subscriptionId: DataClassPath<K, String?> get() = this[Dealership_subscriptionId]
val <K> DataClassPath<K, Dealership>.active: DataClassPath<K, Boolean> get() = this[Dealership_active]
val <K> DataClassPath<K, Dealership>.lastPayment: DataClassPath<K, Instant> get() = this[Dealership_lastPayment]
val <K> DataClassPath<K, Dealership>.autoSellEnabled: DataClassPath<K, Boolean> get() = this[Dealership_autoSellEnabled]
inline val Dealership.Companion.path: DataClassPath<Dealership, Dealership> get() = path<Dealership>()


object Dealership__id: SerializableProperty<Dealership, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Dealership): UUID = receiver._id
    override fun setCopy(receiver: Dealership, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Dealership_name: SerializableProperty<Dealership, String> {
    override val name: String = "name"
    override fun get(receiver: Dealership): String = receiver.name
    override fun setCopy(receiver: Dealership, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("name")
}
object Dealership_description: SerializableProperty<Dealership, String> {
    override val name: String = "description"
    override fun get(receiver: Dealership): String = receiver.description
    override fun setCopy(receiver: Dealership, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("description")
    override val default: String = ""
}
object Dealership_createdAt: SerializableProperty<Dealership, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Dealership): Instant = receiver.createdAt
    override fun setCopy(receiver: Dealership, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = now()
}
object Dealership_accuracyGrade: SerializableProperty<Dealership, Grade> {
    override val name: String = "accuracyGrade"
    override fun get(receiver: Dealership): Grade = receiver.accuracyGrade
    override fun setCopy(receiver: Dealership, value: Grade) = receiver.copy(accuracyGrade = value)
    override val serializer: KSerializer<Grade> = Grade.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("accuracyGrade")
    override val default: Grade = Grade.Unknown
}
object Dealership_sellingTransactionGrade: SerializableProperty<Dealership, Grade> {
    override val name: String = "sellingTransactionGrade"
    override fun get(receiver: Dealership): Grade = receiver.sellingTransactionGrade
    override fun setCopy(receiver: Dealership, value: Grade) = receiver.copy(sellingTransactionGrade = value)
    override val serializer: KSerializer<Grade> = Grade.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("sellingTransactionGrade")
    override val default: Grade = Grade.Unknown
}
object Dealership_buyingTransactionGrade: SerializableProperty<Dealership, Grade> {
    override val name: String = "buyingTransactionGrade"
    override fun get(receiver: Dealership): Grade = receiver.buyingTransactionGrade
    override fun setCopy(receiver: Dealership, value: Grade) = receiver.copy(buyingTransactionGrade = value)
    override val serializer: KSerializer<Grade> = Grade.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("buyingTransactionGrade")
    override val default: Grade = Grade.Unknown
}
object Dealership_buys: SerializableProperty<Dealership, Int> {
    override val name: String = "buys"
    override fun get(receiver: Dealership): Int = receiver.buys
    override fun setCopy(receiver: Dealership, value: Int) = receiver.copy(buys = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("buys")
    override val default: Int = 0
}
object Dealership_bids: SerializableProperty<Dealership, Int> {
    override val name: String = "bids"
    override fun get(receiver: Dealership): Int = receiver.bids
    override fun setCopy(receiver: Dealership, value: Int) = receiver.copy(bids = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("bids")
    override val default: Int = 0
}
object Dealership_sells: SerializableProperty<Dealership, Int> {
    override val name: String = "sells"
    override fun get(receiver: Dealership): Int = receiver.sells
    override fun setCopy(receiver: Dealership, value: Int) = receiver.copy(sells = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("sells")
    override val default: Int = 0
}
object Dealership_stars: SerializableProperty<Dealership, Int> {
    override val name: String = "stars"
    override fun get(receiver: Dealership): Int = receiver.stars
    override fun setCopy(receiver: Dealership, value: Int) = receiver.copy(stars = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("stars")
    override val default: Int = 0
}
object Dealership_url: SerializableProperty<Dealership, String?> {
    override val name: String = "url"
    override fun get(receiver: Dealership): String? = receiver.url
    override fun setCopy(receiver: Dealership, value: String?) = receiver.copy(url = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("url")
    override val default: String? = null
}
object Dealership_profileBanner: SerializableProperty<Dealership, ServerFile?> {
    override val name: String = "profileBanner"
    override fun get(receiver: Dealership): ServerFile? = receiver.profileBanner
    override fun setCopy(receiver: Dealership, value: ServerFile?) = receiver.copy(profileBanner = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("profileBanner")
    override val default: ServerFile? = null
}
object Dealership_profilePicture: SerializableProperty<Dealership, ServerFile?> {
    override val name: String = "profilePicture"
    override fun get(receiver: Dealership): ServerFile? = receiver.profilePicture
    override fun setCopy(receiver: Dealership, value: ServerFile?) = receiver.copy(profilePicture = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("profilePicture")
    override val default: ServerFile? = null
}
object Dealership_makes: SerializableProperty<Dealership, Set<MakeName>> {
    override val name: String = "makes"
    override fun get(receiver: Dealership): Set<MakeName> = receiver.makes
    override fun setCopy(receiver: Dealership, value: Set<MakeName>) = receiver.copy(makes = value)
    override val serializer: KSerializer<Set<MakeName>> = SetSerializer(MakeName.serializer())
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("makes")
}
object Dealership_address: SerializableProperty<Dealership, Address> {
    override val name: String = "address"
    override fun get(receiver: Dealership): Address = receiver.address
    override fun setCopy(receiver: Dealership, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> = Address.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("address")
}
object Dealership_additionalLocations: SerializableProperty<Dealership, List<Address>> {
    override val name: String = "additionalLocations"
    override fun get(receiver: Dealership): List<Address> = receiver.additionalLocations
    override fun setCopy(receiver: Dealership, value: List<Address>) = receiver.copy(additionalLocations = value)
    override val serializer: KSerializer<List<Address>> = ListSerializer(Address.serializer())
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("additionalLocations")
    override val default: List<Address> = emptyList()
}
object Dealership_dealershipGroup: SerializableProperty<Dealership, UUID?> {
    override val name: String = "dealershipGroup"
    override fun get(receiver: Dealership): UUID? = receiver.dealershipGroup
    override fun setCopy(receiver: Dealership, value: UUID?) = receiver.copy(dealershipGroup = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("dealershipGroup")
    override val default: UUID? = null
}
object Dealership_usedCarManager: SerializableProperty<Dealership, UUID> {
    override val name: String = "usedCarManager"
    override fun get(receiver: Dealership): UUID = receiver.usedCarManager
    override fun setCopy(receiver: Dealership, value: UUID) = receiver.copy(usedCarManager = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("usedCarManager")
}
object Dealership_transportManager: SerializableProperty<Dealership, UUID> {
    override val name: String = "transportManager"
    override fun get(receiver: Dealership): UUID = receiver.transportManager
    override fun setCopy(receiver: Dealership, value: UUID) = receiver.copy(transportManager = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("transportManager")
}
object Dealership_titleManager: SerializableProperty<Dealership, UUID> {
    override val name: String = "titleManager"
    override fun get(receiver: Dealership): UUID = receiver.titleManager
    override fun setCopy(receiver: Dealership, value: UUID) = receiver.copy(titleManager = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("titleManager")
}
object Dealership_billingContact: SerializableProperty<Dealership, UUID> {
    override val name: String = "billingContact"
    override fun get(receiver: Dealership): UUID = receiver.billingContact
    override fun setCopy(receiver: Dealership, value: UUID) = receiver.copy(billingContact = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("billingContact")
}
object Dealership_paymentCustomerId: SerializableProperty<Dealership, String?> {
    override val name: String = "paymentCustomerId"
    override fun get(receiver: Dealership): String? = receiver.paymentCustomerId
    override fun setCopy(receiver: Dealership, value: String?) = receiver.copy(paymentCustomerId = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("paymentCustomerId")
    override val default: String? = null
}
object Dealership_subscriptionId: SerializableProperty<Dealership, String?> {
    override val name: String = "subscriptionId"
    override fun get(receiver: Dealership): String? = receiver.subscriptionId
    override fun setCopy(receiver: Dealership, value: String?) = receiver.copy(subscriptionId = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("subscriptionId")
    override val default: String? = null
}
object Dealership_active: SerializableProperty<Dealership, Boolean> {
    override val name: String = "active"
    override fun get(receiver: Dealership): Boolean = receiver.active
    override fun setCopy(receiver: Dealership, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("active")
    override val default: Boolean = true
}
object Dealership_lastPayment: SerializableProperty<Dealership, Instant> {
    override val name: String = "lastPayment"
    override fun get(receiver: Dealership): Instant = receiver.lastPayment
    override fun setCopy(receiver: Dealership, value: Instant) = receiver.copy(lastPayment = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("lastPayment")
    override val default: Instant = Instant.fromEpochMilliseconds(0)
}
object Dealership_autoSellEnabled: SerializableProperty<Dealership, Boolean> {
    override val name: String = "autoSellEnabled"
    override fun get(receiver: Dealership): Boolean = receiver.autoSellEnabled
    override fun setCopy(receiver: Dealership, value: Boolean) = receiver.copy(autoSellEnabled = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Dealership.serializer().tryFindAnnotations("autoSellEnabled")
    override val default: Boolean = false
}
