package com.ilussobsa

import com.lightningkite.nowLocal

val Vehicle.certifiedPreOwned: String?
    get() {
        if (salvage != null) return null
        if (lemonLaw != null) return null
        if (flood != null) return null
        if (stolenOrRecovery != null) return null
        if (rentalOrTaxi != null) return null
        if (trueMileageUnknown != null) return null
        val currentYear = nowLocal().date.year
        fun yearWithin(years: Int) = (year?.toInt() ?: 0) > currentYear - years
        fun mileageUnder(mileage: Int) = (odometer ?: Int.MAX_VALUE) < mileage
        when (make) {
            "Ford" -> {
                if (fuelType == FuelType.Electric) {
                    if (mileageUnder(80000) &&
                        yearWithin(6)
                    ) return "Ford EV Certified"
                } else {
                    if (mileageUnder(80000) &&
                        yearWithin(6)
                    ) return "Ford Gold Certified"
                    if (mileageUnder(150000) &&
                        yearWithin(9)
                    ) return "Ford Blue Certified"
                }
            }

            "Hyundai" -> {
                if (mileageUnder(75000) && yearWithin(6)) return "Hyundai Certified Pre Owned"
                else return null
            }

            "Chevrolet" -> {
                if (mileageUnder(75000) && yearWithin(7)) return "Chevrolet Certified Pre Owned"
                else return null

            }

            "GMC" -> {
                if (mileageUnder(75000) &&
                    yearWithin(7)
                ) return "GMC Certified Pre Owned"
                else return null
            }

            "BMW" -> {
                if (mileageUnder(75000) &&
                    yearWithin(7)
                ) return "BMW Certified Pre Owned"
                else return null
            }

            "Mercedes-Benz" -> {
                if (mileageUnder(75000) &&
                    yearWithin(7)
                ) return "Mercedes-Benz Certified Pre Owned"
                else return null
            }

            "Chrysler" -> {
                if (mileageUnder(75000) &&
                    yearWithin(5)
                ) return "Chrysler Certified Pre Owned Base"
                if (mileageUnder(119999) &&
                    yearWithin(11)
                ) return "Chrysler Certified Pre Owned Go"
            }

            "Dodge" -> {
                if (mileageUnder(75000) &&
                    yearWithin(5)
                ) return "Dodge Certified Pre Owned Base"
                if (mileageUnder(119999) &&
                    yearWithin(11)
                ) return "Dodge Certified Pre Owned Go"
            }

            "Jeep" -> {
                if (mileageUnder(75000) &&
                    yearWithin(5)
                ) return "Jeep Certified Pre Owned Base"
                if (mileageUnder(119999) &&
                    yearWithin(11)
                ) return "Jeep Certified Pre Owned Go"
            }

            "Ram" -> {
                if (mileageUnder(75000) &&
                    yearWithin(5)
                ) return "Ram Certified Pre Owned Base"
                if (mileageUnder(119999) &&
                    yearWithin(11)
                ) return "Ram Certified Pre Owned Go"
            }

            "Nissan" -> {
                if (fuelType == FuelType.Electric) {
                    if(mileageUnder(80000) &&
                            yearWithin(7)) return "Nissan EV Certified Pre Owned"
                } else {
                    if (mileageUnder(80000) &&
                        yearWithin(7)
                    ) return "Nissan Certified"
                    if (mileageUnder(100000) &&
                        yearWithin(11)
                    ) return "Nissan Certified Select"
                }
            }

            "Toyota" -> {
                if (mileageUnder(85000) &&
                    yearWithin(7)
                ) return "Toyota Gold Certified"
                if (mileageUnder(60000) &&
                    mileageUnder(125000) &&
                    yearWithin(11)
                ) return "Toyota Silver Certified"

            }

            "Honda" -> {
                if (mileageUnder(12000) &&
                    yearWithin(2)
                ) return "HondaTrue Certified+"
                if (mileageUnder(80000) &&
                    yearWithin(7)
                ) return "HondaTrue Certified"
                if (yearWithin(11)) return "HondaTrue Used"

            }

            else -> {}
        }
        return null
    }