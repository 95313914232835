package com.ilussobsa.views

import com.ilussobsa.Strings
import com.ilussobsa.accepted
import com.ilussobsa.rejected
import com.ilussobsa.renderPriceInDollars
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.modification
import com.lightningkite.now

@Routable("/transport-quote/{quoteId}")
class ViewTransportQuoteScreen(val quoteId: UUID) : KiteUiScreen {

    val transportRequest = shared { currentSessionNullable.awaitNotNull().transportRequests[quoteId]() }
    val vehicle = shared { transportRequest()?.vehicle?.let { currentSessionNullable.awaitNotNull().vehicles[it] }?.await() }

    override fun ViewWriter.render() = dismissBackground {
        centered - padded - card - sizeConstraints(minWidth = 30.rem) - col {
                row {
                    centered - expanding - h1(Strings.transportQuote)
                    centered - button {
                        icon {
                            source = Icon.close
                            description = Strings.close
                        }
                        onClick { navigator.dismiss() }
                    }
                }
                row {
                    expanding - text {
                        ::content {
                            Strings.transportForX(vehicle()?.vin ?: "unknown vehicle")
                        }
                    }
                    text {
                        ::content {
                            transportRequest()?.quote?.renderPriceInDollars() ?: "$-.--"
                        }
                    }
                }
                separator()
                gravity(Align.End, Align.Center) - row {

                    affirmative - button {
                        ::exists { transportRequest()?.accepted == null && transportRequest()?.rejected == null }
                        text(Strings.accept)
                        onClick {
                            currentSession().transportRequests.get(quoteId).modify(modification {
                                it.accepted assign now()
                            })
                            navigator.dismiss()
                        }
                    }
                    button {
                        ::exists { transportRequest()?.accepted == null && transportRequest()?.rejected == null }
                        text(Strings.decline)
                        onClick {
                            currentSession().transportRequests.get(quoteId).modify(modification {
                                it.rejected assign now()
                            })
                            navigator.dismiss()
                        }
                    }
                    expanding - centered - text {
                        ::exists { transportRequest()?.accepted != null || transportRequest()?.rejected != null }
                        ::content {
                            if (transportRequest()?.rejected != null) Strings.transportDeclined
                            else if (transportRequest()?.accepted != null) Strings.accepted
                            else ""
                        }
                    }
                }
            }

    }
}